import { observable, action, runInAction } from "mobx";
import { RootStore } from "./rootStore";
// import { toast } from "react-toastify";
import { IVehicle } from "../models/vehicle";
import agent from "../api/agent";


export default class VehicleStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable vehicleRegistry = new Map<number, IVehicle>();
  @observable loadingInitial = false;
  @observable submitting = false;

  @observable vehicle: IVehicle | null = null;

  @action loadVehicles = async () => {
    this.loadingInitial = true;

    try {
      const vehicles = await agent.Vehicle.list();

      runInAction("loading vehicles", () => {
        vehicles.forEach((vehicle) => {
          this.vehicleRegistry.set(vehicle.vehicleID, vehicle);
        });

        this.loadingInitial = false;
      });

    } catch (error) {
      runInAction("load activities error", () => {
        this.loadingInitial = false;
      });

      console.log(error);
    }
  };

  @action loadVehicle = async (id: number) => {
    let vehicle = this.getVehicle(id);

    if (vehicle) {
      this.vehicle = vehicle;
      return vehicle;
    } else {
      this.loadingInitial = true;

      try {
        vehicle = await agent.Vehicle.details(id);

        runInAction("getting vehicle", () => {
          if(vehicle) {
            this.vehicle = vehicle;
            this.vehicleRegistry.set(vehicle.vehicleID, vehicle);
          }
          this.loadingInitial = false;
        });
        return vehicle;
      } catch (error) {
        runInAction("get vehicle error", () => {
          this.loadingInitial = false;
        });

        console.log(error);
      }
    }
  };  
  /*************************************
  *
  *************************************/

  @action clearVehicle = () => {
    this.vehicle = null;
  };

  /*************************************
  *
  *************************************/
  
  getVehicle = (id: number) => {
    return this.vehicleRegistry.get(id);
  };

}