export interface IServerBoundIncidentReport {
    checklist_id: string,
    incidentReport: IIncidentReport
}

export interface IIncidentReport {
    id: string;
    createdBy: string;

    lostTime: boolean;
    firstAid: boolean;
    vehicleDamage: boolean;
    injuryIllness: boolean;
    disabling: boolean;
    restrictedDuty: boolean;
    propDamage: boolean;
    airEmission: boolean;
    accReleaseSpill: boolean;
    nearMiss: boolean;
    thirdParty: boolean;
    theft: boolean;
    other: boolean;
    otherDesc: string;

    whoseInjury: string;
    incidentDate: Date;
    incidentTime: Date;
    reportDate: Date;
    startedTime: Date;
    projNum: string;
    clientName: string;
    onDuty: string;
    location: string;
    witness: string;
    nature: string;
    task: string;
    desc: string;
    factors: string;

    treatmentLocation: string;
    treatmentDate: Date | null;
    treatedBy: string;
    drPhone: string;
    restrictions: string;
    nextAppt: string;
    diagnosis: string;
    outOfWork: boolean;
    outStartDate: Date | null;
    outEndDate: Date | null;

    comments: string;
    files?: string[];
    
    supervisorEmail?: string;

    deleted: boolean;
}

export interface IIncidentReportFormValues extends Partial<IIncidentReport> {
}

export class IncidentReportFormValues implements IIncidentReport {
    id: string = '';
    createdBy: string = '';

    incidentType: string = '';
    lostTime: boolean = false;
    firstAid: boolean = false;
    vehicleDamage: boolean = false;
    injuryIllness: boolean = false;
    disabling: boolean = false;
    restrictedDuty: boolean = false;
    propDamage: boolean = false;
    airEmission: boolean = false;
    accReleaseSpill: boolean = false;
    nearMiss: boolean = false;
    thirdParty: boolean = false;
    theft: boolean = false;
    other: boolean = false;
    otherDesc: string = '';

    whoseInjury: string = '';
    name: string = '';
    incidentDate: Date = new Date();
    incidentTime: Date = new Date();
    reportDate: Date = new Date();
    startedTime: Date = new Date();
    projNum: string = '';
    clientName: string = '';
    onDuty: string = '';
    location: string = '';
    witness: string = '';
    nature: string = '';
    task: string = '';
    desc: string = '';
    factors: string = '';

    treatmentLocation: string = '';
    treatmentDate: Date | null = null;
    treatedBy: string = '';
    drPhone: string = '';
    restrictions: string = '';
    nextAppt: string = '';
    diagnosis: string = '';
    outOfWork: boolean = false;
    outStartDate: Date | null = null;
    outEndDate: Date | null = null;

    comments: string = '';
    fileArray?: [] = [];
    deleted: boolean = false;


    constructor(init?: IIncidentReportFormValues) {
        Object.assign(this, init);
    }

}

export interface IIncidentReportSearch {
    who: string;
    months: number[];
    years: number[];
}

export class IncidentReportSearchFormValues implements IIncidentReportSearch {
    who: string = '';
    months: number[] = [];    
    years: number[] = [];    

    constructor(init?: IIncidentReportSearch) {

        Object.assign(this, init);
    }
}
