import { IDiscipline, DisciplineFormValues } from "./discipline";

export interface IVehicleInspectionEnvelope {
    vehicleInspections: IVehicleInspection[];
    vehicleInspectionCount: number;
}

export interface IVehicleInspection {

    id: string;

    submitterEmail: string;
    office: string;
    discipline: IDiscipline;
    dateSubmitted: Date;

    vehicle_Keyid?: number;
    currentMileage: number;
    nextOilChangeMileage: number;

    inspectionDueDate?: Date;
    registration?: Date;
    insurance?: Date;

    brakesStatus: string;
    brakesComment: string;

    emergencyBrakeStatus: string;
    emergencyBrakeComment: string;

    hornStatus: string;
    hornComment: string;

    headlightsStatus: string;
    headlightsComment: string;

    tailLightsStatus: string;
    tailLightsComment: string;

    brakeLightsStatus: string;
    brakeLightsComment: string;

    turnSignalsStatus: string;
    turnSignalsComment: string;

    backupLightsStatus: string;
    backupLightsComment: string;

    windshieldWipersStatus: string;
    windshieldWipersComment: string;

    vehicleInteriorStatus: string;
    vehicleInteriorComment: string;

    vehicleExteriorStatus: string;
    vehicleExteriorComment: string;

    equipmentStorageAreaStatus: string;
    equipmentStorageAreaComment: string;

    mirrorsStatus: string;
    mirrorsComment: string;

    windshieldStatus: string;
    windshieldComment: string;

    rearWindowStatus: string;
    rearWindowComment: string;

    windowsStatus: string;
    windowsComment: string;

    tireConditionStatus: string;
    tireConditionComment: string;

    tireTreadStatus: string;
    tireTreadComment: string;

    brakeFluidStatus: string;
    brakeFluidComment: string;

    coolantAntifreezeStatus: string;
    coolantAntifreezeComment: string;

    oilStatus: string;
    oilComment: string;

    steeringFluidStatus: string;
    steeringFluidComment: string;

    transmissionFluidStatus: string;
    transmissionFluidComment: string;

    windshieldFluidStatus: string;
    windshieldFluidComment: string;

    firstAidKitStatus: string;
    firstAidKitComment: string;

    flaresStatus: string;
    flaresComment: string;

    sdsStatus: string;
    sdsComment: string;

    flashlightStatus: string;
    flashlightComment: string;

    fireExtinguisherStatus: string;
    fireExtinguisherComment: string;

    spillKitStatus: string;
    spillKitComment: string;

    backupCameraStatus: string;
    backupCameraComment: string;

    backupAlarmStatus: string;
    backupAlarmComment: string;

    strobeFrontStatus: string;
    strobeFrontComment: string;

    strobeBackStatus: string;
    strobeBackComment: string;

    otherIssues: boolean;
    otherIssuesExplanation: string;

    files?: string[];

    deleted: boolean;
}

export class VehicleInspectionFormValues implements IVehicleInspection {

    id: string = '';

    submitterEmail: string = '';
    office: string = '';
    discipline: IDiscipline = new DisciplineFormValues();
    dateSubmitted: Date = new Date();

    vehicle_Keyid?: number = undefined;

    currentMileage: number = 0;
    nextOilChangeMileage: number = 0;

    inspectionDueDate?: Date;
    registration?: Date;
    insurance?: Date;

    brakesStatus: string = '';
    brakesComment: string = '';

    emergencyBrakeStatus: string = '';
    emergencyBrakeComment: string = '';

    hornStatus: string = '';
    hornComment: string = '';

    headlightsStatus: string = '';
    headlightsComment: string = '';

    tailLightsStatus: string = '';
    tailLightsComment: string = '';

    brakeLightsStatus: string = '';
    brakeLightsComment: string = '';

    turnSignalsStatus: string = '';
    turnSignalsComment: string = '';

    backupLightsStatus: string = '';
    backupLightsComment: string = '';

    windshieldWipersStatus: string = '';
    windshieldWipersComment: string = '';

    vehicleInteriorStatus: string = '';
    vehicleInteriorComment: string = '';

    vehicleExteriorStatus: string = '';
    vehicleExteriorComment: string = '';

    equipmentStorageAreaStatus: string = '';
    equipmentStorageAreaComment: string = '';

    mirrorsStatus: string = '';
    mirrorsComment: string = '';

    windshieldStatus: string = '';
    windshieldComment: string = '';

    rearWindowStatus: string = '';
    rearWindowComment: string = '';

    windowsStatus: string = '';
    windowsComment: string = '';

    tireConditionStatus: string = '';
    tireConditionComment: string = '';

    tireTreadStatus: string = '';
    tireTreadComment: string = '';

    brakeFluidStatus: string = '';
    brakeFluidComment: string = '';

    coolantAntifreezeStatus: string = '';
    coolantAntifreezeComment: string = '';

    oilStatus: string = '';
    oilComment: string = '';

    steeringFluidStatus: string = '';
    steeringFluidComment: string = '';

    transmissionFluidStatus: string = '';
    transmissionFluidComment: string = '';

    windshieldFluidStatus: string = '';
    windshieldFluidComment: string = '';

    firstAidKitStatus: string = '';
    firstAidKitComment: string = '';

    flaresStatus: string = '';
    flaresComment: string = '';

    sdsStatus: string = '';
    sdsComment: string = '';

    flashlightStatus: string = '';
    flashlightComment: string = '';

    fireExtinguisherStatus: string = '';
    fireExtinguisherComment: string = '';

    spillKitStatus: string = '';
    spillKitComment: string = '';

    backupCameraStatus: string = '';
    backupCameraComment: string = '';

    backupAlarmStatus: string = '';
    backupAlarmComment: string = '';

    strobeFrontStatus: string = '';
    strobeFrontComment: string = '';

    strobeBackStatus: string = '';
    strobeBackComment: string = '';

    otherIssues: boolean = false;
    otherIssuesExplanation: string = '';

    fileArray?: [] = [];

    deleted: boolean = false;

    constructor(init?: VehicleInspectionFormValues) {
        Object.assign(this, init);
    }
}

export interface IVehicleInspectionSearch {
    status: number[];
    vehicles: number[];   
    disciplines: string[];
    offices: string[];
    users: string[];
    months: number[];
    years: number[];
}
export class VehicleInspectionSearchFormValues implements IVehicleInspectionSearch {
    status: number[] = [];
    vehicles: number[] = [];
    disciplines: string[] = [];
    offices: string[] = [];
    users: string[] = [];
    months: number[] = [];
    years: number[] = [];

    constructor(init?: IVehicleInspectionSearch) {
        Object.assign(this, init);
    }
}