import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Segment, Grid, GridColumn } from 'semantic-ui-react';
import { Link } from "react-router-dom";

import { ICorrectiveAction } from '../../../app/models/correctiveAction';
import { prettyPrintDate } from '../../../app/common/util/util';

const CorrectiveActionListDetails: React.FC<{corAct: ICorrectiveAction}> = ({corAct}) => {
    const dueDate = corAct.dueDate ? new Date(corAct.dueDate!) : 'N/A';
    const completionDate = corAct.completionDate ? new Date(corAct.completionDate!) : 'N/A';

    return (
        <>
            <Segment>
                <Grid columns={3} celled='internally'>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            <div>Non-conformance: <span className='block'>{corAct.nonConformance}</span></div>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Button as={Link} to={`/corrective-action/manage/${corAct.id}`} color="orange" icon='pencil' className='vertical-center'/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div>Comments: <span className='accidentDetail'>{corAct.comments}</span></div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div>Description: <span className='accidentDetail'>{corAct.description}</span></div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div>Responsible Party: <span className='accidentDetail'>
                                {corAct.responsiblePartyEmail ?
                                    <a href={`mailto:${corAct.responsiblePartyEmail}`}>{corAct.responsiblePartyEmail}</a>
                                    : 'N/A'}</span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <GridColumn width={7}>
                            <div>Due Date: <span className='accidentDetail'>{(dueDate instanceof Date) ? prettyPrintDate(dueDate) : 'N/A'}</span></div>
                        </GridColumn>
                        <GridColumn width={9}>
                            <div>Completion Date: <span className='accidentDetail'>{(completionDate instanceof Date) ? prettyPrintDate(completionDate) : 'N/A'}</span></div>
                        </GridColumn>
                    </Grid.Row>
                    <Grid.Row>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    )
};

export default observer(CorrectiveActionListDetails);