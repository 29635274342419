export interface IVehicleAccidentPassenger {
    id: string;
    listedOrder: number;
    name: string;
    phone: string;
    address: string;
    vehicle: string;
    injuries: string;
}

export class VehicleAccidentPassengerFormValues implements IVehicleAccidentPassenger {
    id: string = '';
    listedOrder: number = 0;
    name: string = "";
    phone: string = "";
    address: string = "";
    vehicle: string = '';
    injuries: string = '';
}