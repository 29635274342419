export interface IVehicleAccidentWitness {
    id: string;
    listedOrder: number;
    name: string;
    phone: string;
    address: string;
}

export class VehicleAccidentWitnessFormValues implements IVehicleAccidentWitness {
    id: string = "";
    listedOrder: number = 0;
    name: string = "";
    phone: string = "";
    address: string = "";
}