import React, { useContext, useEffect, useState } from "react";
// import { Grid, Segment, Icon } from "semantic-ui-react";

import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { NotificationSearchFormValues } from "../../../app/models/notification";
import NotificationList from "./NotificationList";

const NotificationDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserEmail } = rootStore.userStore;
  const { 
    loadUserNotifications, 
    // notificationList, 
    loadingInitial, 
    setPage, 
    page, 
    totalPages,
    clearNotificationRegistry,
    setPredicate,
    getPredicate,
  } = rootStore.notificationStore;
  const [notificationSearch, setNotificationSearch] = useState(new NotificationSearchFormValues());
  const [loadingNext, setLoadingNext] = useState(false);

  const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadUserNotifications().then(() => setLoadingNext(false));
	};

  const createUserSearchParams = () => {
    var testData = [accountInfoUserEmail,];
    setNotificationSearch({...notificationSearch, users: testData as string[] });
    setPredicate("users", JSON.stringify(testData));
  };


  /*************************************
  * Render the selected filters
  *************************************/
  const loadStorePredicate = () => {
    let storePred = getPredicate();

    let usersStr: string | undefined = storePred.get("users")?.toString().replace('[', '').replace(']', '');
    let usersArray: string[] | undefined = usersStr?.split(',');

    let searchVals = new NotificationSearchFormValues();
    if (usersArray !== undefined) searchVals.users = usersArray;

    setNotificationSearch(searchVals);
  }
  


  useEffect(() => {
    loadStorePredicate();
    createUserSearchParams();
    clearNotificationRegistry();
		loadUserNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (loadingInitial && page === 0) return <LoadingComponent content="Loading your Notifications..." />;
  else
    return (
      <>
        <PageTitle title="Your Notifications" />

        <InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false}>
          <NotificationList />
        </InfiniteScroll>
      </>
    );
};

export default observer(NotificationDashboard);

