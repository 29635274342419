import { ICorrectiveAction } from "./correctiveAction";
import { IOtherItem, OtherItemFormValues } from "./otherItem";
import { ISafetyChecklist, SafetyChecklistFormValues } from "./safetyChecklist";

export interface IFieldSafetyAuditEnvelope {
    fieldSafetyAudits: IFieldSafetyAudit[];
    fieldSafetyAuditCount: number;
}

export interface IFieldSafetyAudit {

    id: string;
    submitterEmail: string;
    discipline_id: string;
    created: Date;

    sslApproval: boolean;
    completed: boolean;

    safetyChecklist_id: string;
    safetyChecklist: ISafetyChecklist;

    auditeeEmails: string[]

    administrative1: string;
    administrative2: string;
    administrative3: string;
    administrative4: string;
    administrative5: string;
    administrative6: string;
    administrative7: string;
    administrative8: string;

    electrical1: string;
    electrical2: string;
    electrical3: string;
    electrical4: string;
    electrical5: string;
    electrical6: string;
    electrical7: string;

    emergencies1: string;
    emergencies2: string;
    emergencies3: string;
    emergencies4: string;
    emergencies5: string;

    excavationTrenching1: string;
    excavationTrenching2: string;
    excavationTrenching3: string;
    excavationTrenching4: string;
    excavationTrenching5: string;
    excavationTrenching6: string;
    excavationTrenching7: string;
    excavationTrenching8: string;
    excavationTrenching9: string;

    fallProtection1: string;
    fallProtection2: string;
    fallProtection3: string;
    fallProtection4: string;
    fallProtection5: string;
    fallProtection6: string;
    fallProtection7: string;
    fallProtection8: string;
    fallProtection9: string;
    fallProtection10: string;
    fallProtection11: string;

    firePrevention1: string;
    firePrevention2: string;
    firePrevention3: string;
    firePrevention4: string;

    general1: string;
    general2: string;
    general3: string;
    general4: string;
    general5: string;
    general6: string;
    general7: string;

    ladders1: string;
    ladders2: string;
    ladders3: string;
    ladders4: string;

    ppe1: string;
    ppe2: string;
    ppe3: string;
    ppe4: string;
    ppe5: string;
    ppe6: string;
    ppeInUse: string;

    radiation1: string;
    radiation2: string;
    radiation3: string;
    radiation4: string;
    radiation5: string;

    roadway1: string;
    roadway2: string;
    roadway3: string;
    roadway4: string;

    scaffolding1: string;
    scaffolding2: string;
    scaffolding3: string;
    scaffolding4: string;
    scaffolding5: string;
    scaffolding6: string;
    scaffolding7: string;
    scaffolding8: string;

    tools1: string;
    tools2: string;
    tools3: string;
    tools4: string;
    tools5: string;
    tools6: string;
    tools7: string;
    tools8: string;
    tools9: string;

    water1: string;
    water2: string;
    water3: string;
    water4: string;

    //otherItems: string[];
    otherItems: string[];
    otherItemsObject: IOtherItem[];

    correctiveActions: ICorrectiveAction[];

    files?: string[];
    deleted: boolean;
}

export class FieldSafetyAuditFormValues implements IFieldSafetyAudit {

    id: string = '';

    submitterEmail: string = '';
    discipline_id: string = '';
    created: Date = new Date();

    sslApproval: boolean = false;
    completed: boolean = false;

    safetyChecklist_id: string = '';
    safetyChecklist: ISafetyChecklist = new SafetyChecklistFormValues();

    auditeeEmails: string[] = [];

    administrative1: string= '';
    administrative2: string= '';
    administrative3: string= '';
    administrative4: string= '';
    administrative5: string= '';
    administrative6: string= '';
    administrative7: string= '';
    administrative8: string= '';

    electrical1: string = '';
    electrical2: string = '';
    electrical3: string = '';
    electrical4: string = '';
    electrical5: string = '';
    electrical6: string = '';
    electrical7: string = '';

    emergencies1: string = '';
    emergencies2: string = '';
    emergencies3: string = '';
    emergencies4: string = '';
    emergencies5: string = '';

    excavationTrenching1: string = '';
    excavationTrenching2: string = '';
    excavationTrenching3: string = '';
    excavationTrenching4: string = '';
    excavationTrenching5: string = '';
    excavationTrenching6: string = '';
    excavationTrenching7: string = '';
    excavationTrenching8: string = '';
    excavationTrenching9: string = '';

    fallProtection1: string = '';
    fallProtection2: string = '';
    fallProtection3: string = '';
    fallProtection4: string = '';
    fallProtection5: string = '';
    fallProtection6: string = '';
    fallProtection7: string = '';
    fallProtection8: string = '';
    fallProtection9: string = '';
    fallProtection10: string = '';
    fallProtection11: string = '';

    firePrevention1: string = '';
    firePrevention2: string = '';
    firePrevention3: string = '';
    firePrevention4: string = '';

    general1: string = '';
    general2: string = '';
    general3: string = '';
    general4: string = '';
    general5: string = '';
    general6: string = '';
    general7: string = '';

    ladders1: string = '';
    ladders2: string = '';
    ladders3: string = '';
    ladders4: string = '';

    ppe1: string = '';
    ppe2: string = '';
    ppe3: string = '';
    ppe4: string = '';
    ppe5: string = '';
    ppe6: string = '';
    ppeInUse: string = '';

    radiation1: string = '';
    radiation2: string = '';
    radiation3: string = '';
    radiation4: string = '';
    radiation5: string = '';

    roadway1: string = '';
    roadway2: string = '';
    roadway3: string = '';
    roadway4: string = '';

    scaffolding1: string = '';
    scaffolding2: string = '';
    scaffolding3: string = '';
    scaffolding4: string = '';
    scaffolding5: string = '';
    scaffolding6: string = '';
    scaffolding7: string = '';
    scaffolding8: string = '';

    tools1: string = '';
    tools2: string = '';
    tools3: string = '';
    tools4: string = '';
    tools5: string = '';
    tools6: string = '';
    tools7: string = '';
    tools8: string = '';
    tools9: string = '';

    water1: string = '';
    water2: string = '';
    water3: string = '';
    water4: string = '';

    //otherItems: string[] = ["test 1", "test 2",];
    otherItemsObject: IOtherItem[] = [new OtherItemFormValues(),];
    otherItems: string[] = [];

    correctiveActions: ICorrectiveAction[] = []
    //correctiveActions: ICorrectiveAction[] = [new CorrectiveActionFormValues(),]

    fileArray?: string[] = [];

    deleted: boolean = false;

    constructor(init?: FieldSafetyAuditFormValues) {
        Object.assign(this,init);
        if (init !== null && init !== undefined && this.otherItems) {
            this.otherItemsObject = this.otherItems.map((x: string) => ({description : x}));
        }
      }
}


export interface IFieldSafetyAuditSearch {
    disciplines: string[];
    users: string[];
    months: number[];
    years: number[];
    projectNumber: string;

}

export class FieldSafetyAuditSearchFormValues implements IFieldSafetyAuditSearch {
    disciplines: string[] = [];
    users: string[] = [];
    months: number[] = [];
    years: number[] = [];
    projectNumber: string = '';

    constructor(init?: IFieldSafetyAuditSearch) {

        Object.assign(this, init);
    }
}

//-----------------------------------------------------

export interface ICompanyAuditChartData {
    totalCompleted: number;
    goalCompleted: number;
    goal: number;
}

export class CompanyAuditChartValues { 
    totalCompleted: number = 0;
    goalCompleted: number = 0;
    goal: number = 0;
}

//-----------------------------------------------------

export interface INonConformanceData {
    nonConformance: string;
    amount: number;
}

//-----------------------------------------------------
export interface IDisciplineData {
    name: string;
    completed: number;
    goal: number;
    toReachGoal: number;
}

export class DisciplineValues implements IDisciplineData {
    name: string = '';
    completed: number = 0;
    goal: number = 0;
    toReachGoal: number = 0;
}

//-----------------------------------------------------
export interface IFieldSafetyAuditDashboardChartData {
    completedCompanyAudits?: ICompanyAuditChartData,
    nonConformancesByType?: INonConformanceData[],
    completedDisciplineAuditData?: IDisciplineData[],
}

export class FieldSafetyAuditDashboardChartValues implements IFieldSafetyAuditDashboardChartData {
    completedCompanyAudits: ICompanyAuditChartData = new CompanyAuditChartValues();
    completedDisciplineAuditData: IDisciplineData[] = [];
    nonConformancesByType: INonConformanceData[] = [];
}