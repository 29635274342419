import { observable, action, computed, runInAction } from "mobx";
import { SyntheticEvent } from "react";
import { IVehicleInspection } from "../models/vehicleInspection";
import { IVehicle, VehicleFormValues } from "../models/vehicle";

import agent from "../api/agent";
import { history } from "../..";
import { toast } from "react-toastify";
import { RootStore } from "./rootStore";
import { IUser } from "../models/user";
import { localizeDateTime } from "../common/util/util";
import { IDiscipline } from "../models/discipline";

const PAGE_SIZE = 8;

export default class VehicleInspectionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable vehicleInspectionRegistry = new Map<string, IVehicleInspection>();
  @observable userRegistry = new Map<string, IUser>();

  @observable vehicleInspection: IVehicleInspection | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable vehicles: IVehicle[] = [];
  @observable vehicle: IVehicle | null = null;
  @observable vehicleRegistry = new Map<number, IVehicle>();

  @observable disciplines: IDiscipline[] = [];

  @observable reportData: IVehicleInspection[] = [];

  @observable vehicleInspectionCount = 0;
  @observable page = 0;
  @observable predicate = new Map<string, string | number | boolean>();

  @action redefineDatesWithTimeZones = (vehicleInspection: IVehicleInspection) => {
    vehicleInspection.dateSubmitted = localizeDateTime(new Date(vehicleInspection.dateSubmitted));
    vehicleInspection.registration = localizeDateTime(new Date(vehicleInspection.registration!));
    vehicleInspection.insurance = localizeDateTime(new Date(vehicleInspection.insurance!));
    if (vehicleInspection.inspectionDueDate){
      vehicleInspection.inspectionDueDate = localizeDateTime(new Date(vehicleInspection.inspectionDueDate!));
    }
  };

  @computed get totalPages() {
    return Math.ceil(this.vehicleInspectionCount / PAGE_SIZE);
  }

  @action setCount = (count: number) => {
    this.vehicleInspectionCount = count;
  };

  @action setPage = (page: number) => {
    this.page = page;
  };

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  /*************************************
   *
   *************************************/
  getPredicate = () => {
    return this.predicate;
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));

    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });

    return params;
  }

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearVehicleInspectionRegistry = () => {
    this.vehicleInspectionRegistry.clear();
    this.page = 0;
  };

  @computed get vehicleInspectionsByDefault() {
    return Array.from(this.vehicleInspectionRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.dateSubmitted.toString()) -
        Date.parse(a.dateSubmitted.toString())
    ); // sort in descending order
  }

  /*************************************
   *
   *************************************/

  @computed get vehicleInspectionsByDate() {
    return this.groupVehicleInspectionsByDate(
      Array.from(this.vehicleInspectionRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupVehicleInspectionsByDate(vehicleInspections: IVehicleInspection[]) {
    const sortedVehicleInspections = vehicleInspections.sort(
      (a, b) => a.dateSubmitted.getTime() - b.dateSubmitted.getTime()
    );

    return Object.entries(
      sortedVehicleInspections.reduce((vehicleInspections, vehicleInspection) => {
        const date = vehicleInspection.dateSubmitted.toISOString().split("T")[0];
        vehicleInspections[date] = vehicleInspections[date]
          ? [...vehicleInspections[date], vehicleInspection]
          : [vehicleInspection];
        return vehicleInspections;
      }, {} as { [key: string]: IVehicleInspection[] })
    );

  }

  /*************************************
   *
   *************************************/

  @action loadVehicleInspections = async () => {
    this.loadingInitial = true;

    try {
      const response = await agent.VehicleInspection.list(this.axiosParams);

      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);

      runInAction("loading vehicle checklists", () => {
        response.data.vehicleInspections.forEach((vehicleInspection: IVehicleInspection) => {
          this.redefineDatesWithTimeZones(vehicleInspection);
          this.vehicleInspectionRegistry.set(vehicleInspection.id, vehicleInspection);
        });

      });
      this.loadingInitial = false;
    } catch (error) {
      runInAction("load vehicle checklists error", () => {
        this.loadingInitial = false;
      });

      console.log(error);
    }
  };

  @action loadVehicleInspectionReport = async () => {
    this.loadingInitial = true;
    try {
      const response = await agent.VehicleInspection.report(this.axiosParams);
      runInAction("loading vehicle inspection report", () => {
        this.reportData = [];
        response.data.vehicleInspections.forEach((vehicleInspection: IVehicleInspection) => {
          this.redefineDatesWithTimeZones(vehicleInspection);
          this.reportData.push(vehicleInspection);
        });
      });
      this.loadingInitial = false;
    } catch (error) {
      runInAction("load vehicle inspection report error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  }

  /*************************************
   *
   *************************************/

  @action loadVehicleInspection = async (id: string) => {
    let vehicleInspection = this.getVehicleInspection(id);  
    this.loadingInitial = true;
    try {
      vehicleInspection = await agent.VehicleInspection.details(id);
      runInAction("getting vehicle checklist", () => {
        if (vehicleInspection) {
          this.redefineDatesWithTimeZones(vehicleInspection);
          this.vehicleInspection = vehicleInspection;
          this.vehicleInspectionRegistry.set(vehicleInspection.id, vehicleInspection);
        }
        this.loadingInitial = false;
      });
      return vehicleInspection;
    } catch (error) {
      runInAction("get vehicle checklist error", () => {
        this.loadingInitial = false;
      });

      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  @action clearVehicleInspection = () => {
    this.vehicleInspection = null;
  };

  /*************************************
   *
   *************************************/

  @action clearVehicleInspections = () => {
    this.vehicleInspectionRegistry.clear();
  };

  /*************************************
   *
   *************************************/

  getVehicleInspection = (id: string) => {
    return this.vehicleInspectionRegistry.get(id);
  };

  /*************************************
   *
   *************************************/

  @action createVehicleInspection = async (vehicleInspection: FormData) => {
    this.submitting = true;
    try {
      var vc = await agent.VehicleInspection.create(vehicleInspection);

      runInAction("create vehicle checklist", () => {
        this.vehicleInspectionRegistry.set(vc.id, vc);
        this.submitting = false;
      });

      history.push(`/vehicle-inspection/${vc.id}`);
      toast.success("New Vehicle Inspection Added");
    } catch (error) {
      runInAction("create vehicle checklist error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  @action editVehicleInspection = async (id: string, vehicleInspection: FormData) => {
    this.submitting = true;

    try {
      var checklist = await agent.VehicleInspection.update(id, vehicleInspection);

      runInAction("editing vehicle checklist", () => {
        this.vehicleInspectionRegistry.set(checklist.id, checklist);
        this.vehicleInspection = checklist;
        this.submitting = false;
      });
      history.push(`/vehicle-inspection/${checklist.id}`);
      toast.success('Vehicle Inspection edited');
    } catch (error) {
      runInAction("edit vehicle checklist error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  @action deleteVehicleInspection = async (id: string) => {
    this.submitting = true;
    try {
      var sc = await agent.VehicleInspection.delete(id);
      runInAction("deleting vehicle inspection", () => {
        this.submitting = false;
      });
      history.push('/');
      toast.success('Vehicle Inspection deleted');
    } catch (error) {
      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  @action loadVehicles = async () => {
    this.loadingInitial = true;

    try {
      const response = await agent.Vehicle.list();

      runInAction("loading vehicle info", () => {
        // TODO: check to make sure response is not NULL

        this.vehicles = response;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load vehicle info error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @action loadUsers = async () => {
    try {
      const users = await agent.Worker.list();
      runInAction("loading users", () => {
        users.forEach((user) => {
          this.userRegistry.set(user.aoid, user);
        });
      });
    } catch (error) {
      runInAction("load projects error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @computed get userList() {
    return Array.from(this.userRegistry.values()).sort(
      (a, b) => a.formattedName.localeCompare(b.formattedName));
  }

  /*************************************
   * Vehicle Fleet DB Lookup
   *************************************/
   getVehicle = (id: number) => {
    return this.vehicleRegistry.get(id);
  };

   @action loadVehicle = async (id: number) => {
    let vehicle = new VehicleFormValues();
    try {
      vehicle = await agent.Vehicle.details(id);
      return vehicle;
    } catch (error) {
      runInAction("get vehicle error", () => {
        this.loadingInitial = false;
      });

      console.log(error);
    }
  };

  /*************************************
   * Discipline-related actions
   *************************************/

  @action loadDisciplines = async () => {
    try {
      const response = await agent.Discipline.list();
      runInAction("loading all disciplines", () => {
        this.disciplines = response;
        //this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load all disciplines error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  }
}