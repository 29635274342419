export interface IOtherItem {
    description: string;
}

export class OtherItemFormValues implements IOtherItem {

    description: string = "";

    constructor(init?: OtherItemFormValues) {
        Object.assign(this,init);
      }
}