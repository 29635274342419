import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";

import { IProject } from "../models/project";
import { IDiscipline } from "../models/discipline";
import { IUser } from "../models/user";
import { RootStore } from "./rootStore";

export default class GeneralStore {

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable disciplineRegistry = new Map<string, IDiscipline>();
  @observable projectRegistry = new Map<string, IProject>();
  @observable userRegistry = new Map<string, IUser>();

  @observable userGroups: string[] = [];

  @observable userSupervisorEmail: string = '';

  @observable loadingInitial = true;
  @observable submitting = false;
  @observable target = "";

  isAdmin = (userEmail: string | undefined): boolean => {
    if (!userEmail) return false;
    var groupList = [
      "Development Department",
      "Safety Coordinator",
    ];
    if(this.userGroups.some(group => groupList.includes(group))){
      return true;
    }
    return false;
  }

  isVehicleAdmin = (userEmail: string | undefined): boolean => {
    if (!userEmail) return false;
    var groupList = [
      "Development Department",
      "Safety Coordinator",
      "Fleet Manager",
    ];
    if(this.userGroups.some(group => groupList.includes(group))){
      return true;
    }
    return false;
  }

  isFSAAdmin = (userEmail: string | undefined): boolean => {
    if (!userEmail) return false;
    var groupList = [
      "Discipline Leaders",
      "Discipline Safety Coordinators",
      "Executive Committee",
      "Safety Advocate Committee",
    ];
    if(this.userGroups.some(group => groupList.includes(group))){
      return true;
    }
    return false;
  }

  @action loadUserGroups = async () => {
    this.loadingInitial = true;
    try {
      let groups = await agent.Worker.getGroups();
      runInAction("getting groups", () => {
        if (groups) {
          this.userGroups = groups;
        }
        this.loadingInitial = false;
      });
      return;
    } catch (error) {
      runInAction("get groups error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  // @action loadSupervisorEmail = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     let supervisorEmail = 
  //   }
  // }

  /*************************************
  *
  *************************************/

  @computed get disciplineList() {
    //return Array.from(this.activityRegistry.values()).sort(
    //    (a, b) => Date.parse(a.date) - Date.parse(b.date))

    return Array.from(this.disciplineRegistry.values());
  }

  @computed get projectList() {

    return Array.from(this.projectRegistry.values()).sort(
      (a, b) => a.title.localeCompare(b.title)
    ).slice(0,50);
  }

  @computed get userList(){
    return Array.from(this.userRegistry.values()).sort(
      (a, b) => a.formattedName.localeCompare(b.formattedName)
    );

    //return Array.from(this.activityRegistry.values()).sort(
    //    (a, b) => Date.parse(a.date) - Date.parse(b.date))
  }

  @computed get sslList() {
    return this.getSSLList(
      Array.from(this.userRegistry.values())
    )?.sort(
      (a, b) => a.formattedName.localeCompare(b.formattedName)
    ).slice(0,50);
  }



  /*************************************
  *
  *************************************/

  projectDropdownResults(params: any, projects: IProject[]) {
    if (params){
      return Array.from(projects.values()).filter(
        project => project.title.includes(params)
      );

    }else {
      return Array.from(projects.values()).sort(
        (a, b) => a.title.localeCompare(b.title)
      ).slice(0,50);
    }
  }

  getSSLList(users: IUser[]){
    if(users.length > 1){
      return users.filter((user) => user.isSiteSafetyLeader === true);
    } else {
      return null;
    }
  }
  

  @action loadUsers = async () => {
    try {
      const users = await agent.Worker.list();
      runInAction("loading users", () => {
        users.forEach((user) => {
          this.userRegistry.set(user.aoid, user);
        });
      });
      this.loadingInitial = true;
    } catch (error) {
      runInAction("load users error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/



  /*************************************
   *
   *************************************/

  @action checkProjectNumber = async (projectNumber: string) => {

    try {
      let project: IProject = {
        title: projectNumber,
        description: '',
        hasChargeable: '',
        projectStatus: '',
      };
      const response = await agent.Project.exists(project.title);
      return response;
    } catch (error) {
      console.log(error);
      console.log('Error checking project number');
    }
  };
};