import React, { useContext, useState, useEffect, Fragment } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Form, Segment, Header, Button, Grid, DropdownProps, Step, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { OnChange } from "react-final-form-listeners";

import CheckboxInput from "../../../app/common/form/CheckboxInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import { SiteSafetyBriefingFormValues } from "../../../app/models/siteSafetyBriefing";
import { combineValidators, isRequired } from "revalidate";
import MultipleSelectInput from "../../../app/common/form/MultipleSelectInput";
import { StepButtons } from '../../../app/common/form/StepButtons';
import RenderIf from "../../../app/common/form/RenderIf";

const equipList = [
	{ name: "machete", label: "Machete" },
	{ name: "brush", label: "Brush hook or Brush axe" },
	{ name: "pointedShovel", label: "Pointed shovel" },
	{ name: "postHole", label: "Post Hole digger" },
	{ name: "pryBar", label: "Pry bar" },
	{ name: "lightHammer", label: "3-4 lb hammer" },
	{ name: "sledgeHammer", label: "6-8 lb sledge hammer" },
	{ name: "masonHammer", label: "Mason hammer" },
	{ name: "coldChisels", label: "Cold chisels" },
	{ name: "clothTape", label: "100'-200' cloth tape" },
	{ name: "retractableTape", label: "25' retractable tape" },
	{ name: "foldingRule", label: "6' folding rule" },
	{ name: "measuringWheel", label: "Measuring wheel" },
	{ name: "stakeBag", label: "Stake bag or bucket" },
	{ name: "lathBag", label: "Lath bag" },
	{ name: "manholeHook", label: "Manhole opening device" },
	{ name: "bullPin", label: "Bull pin" },
	{ name: "broom", label: "Broom" },
	{ name: "cordlessDrill", label: "Cordless drill" },
	{ name: "cordlessGrinder", label: "Cordless grinder" },
	{ name: "socketWrenchset", label: "Socket / Wrench set" },
	{ name: "punch", label: "Punch set" },
];

const validate = combineValidators({
	onSitePersonnel: isRequired("Who's on Site"),
	PPE: isRequired("PPE Used"),
});

const SiteSafetyBriefingForm = (params: any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addSiteSafetyForm } = rootStore.safetyChecklistStore;
	const { userList, loadUsers } = rootStore.generalStore;

	const [whosOnSite, setWhosOnSite] = useState([''])
	const [equipVisible, setEquipVisible] = useState(new Map<string, boolean>());
	const [step, setStep] = useState(1);

	const isStepValid = (values: SiteSafetyBriefingFormValues): string | null => {
		// TODO implement validations
		return null;
		// switch(step) {
		//   case 1:
		// 	let e1;
		// 	if (e1 = isFieldEmpty(values.siteSafetyLeaderEmail)) return e1;
		// 	else if (e1 = isFieldEmpty(values.discipline_id)) return e1;
		// 	else return null;
		//   case 2: return null;
		//   case 3: return null;
		//   case 4:
		// 	let e4; 
		// 	if (e4 = isFieldEmpty(values.emergencyCoordinatorEmail)) return e4;
		// 	else if (e4 = isFieldEmpty(values.emergencyReportingNumber)) return e4;
		// 	else if (e4 = isFieldEmpty(values.emergencyMedicalFacility)) return e4;
		// 	else if (e4 = isFieldEmpty(values.medicalFacilityNumber)) return e4;
		// 	else if (e4 = isFieldEmpty(values.emergencySignal)) return e4;
		// 	else if (e4 = isFieldEmpty(values.evacRouteAndMusterPoint)) return e4;
		// 	else if (e4 = isFieldEmpty(values.medicalFacilityAddress)) return e4;
		// 	else return null;
		//   case 5: return null;
		//   default: return 'invalid step number';
		// }
	  };

	// create dictionary to map 'no' answers to visible text fields
	useEffect(() => {
		let tempDict: any = new Map<string, boolean>();
		equipList.forEach((element: any) => {
			tempDict[element] = false;
		});
		setEquipVisible(tempDict);
		loadUsers();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnSiteChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setWhosOnSite(data.value as string[]);
	};

	const handleFinalFormSubmit = (values: SiteSafetyBriefingFormValues) => {
		const { ...siteSafety } = values;
		addSiteSafetyForm({
			checklist_id: params.id,
			siteSafety: values,
		});
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					validate={validate}
					// initialValues={fallHazards}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					render={({
						handleSubmit,
						invalid,
						pristine,
						form: {
							mutators: { push },
						},
						values,
					}) => (
						<Form onSubmit={handleSubmit} id="siteSafetyForm" className="sopForm">
							
							<Header as="h3">Site Safety Briefing Form</Header>
							<Step.Group fluid size='mini'>
								<Step completed={step > 1} active={step === 1}>
									<Icon name="unordered list"/>
									<Step.Content>
									<Step.Title>General</Step.Title>
									</Step.Content>
								</Step>

								<Step completed={step > 2} active={step === 2}>
									<Icon name="user"/>
									<Step.Content>
										<Step.Title>People</Step.Title>
									</Step.Content>
								</Step>

								<Step completed={step > 3} active={step === 3}>
									<Icon name="wrench"/>
									<Step.Content>
									<Step.Title>Equipment</Step.Title>
									</Step.Content>
								</Step>
							</Step.Group>

							<StepButtons
								isValid={() => isStepValid(values)}
								step={step}
								prevStep={() => { setStep(step - 1); }}
								nextStep={() => { setStep(step + 1); }}
								stepmax={3}
								scrollToTop={false}
							/>
							
							<RenderIf condition={step === 1}>
								<Header>General</Header>
								<Field
									label="Review &amp; Understand CED H&amp;S checklist, and selected site SOP"
									name="understand"
									component={CheckboxInput}
									/>
								<Field
									label="Vehicle Walk Around complete with front and rear cones in place"
									name="vehicleWalkAround"
									component={CheckboxInput}
								/>

								<div className="ui divider"></div>
								<Field label="Discuss Job Scope" name="discussJobScope" component={CheckboxInput} />
								<Field label="At Office with Project Manager" name="discussAtOffice" component={CheckboxInput} />
								<Field label="On-Site with CED Personnel" name="discussOnSite" component={CheckboxInput} />
								<Field label="Via Phone Call with Project Manager" name="discussPhone" component={CheckboxInput} />
								<Field label="Site Safety Leader from Client" name="discussFromClient" component={CheckboxInput} />
								<div className="ui divider"></div>

								<Field label="Discuss PPE required to complete job scope" name="discussPPE" component={CheckboxInput} />

								<div className="ui divider"></div>
								<Field label="Discuss added human protection and location" name="discussAddedProtection" component={CheckboxInput} />
								<Field label="Flagman (Railroad)" name="discussFlagman" component={CheckboxInput} />
								<Field label="Watchman" name="discussWatchman" component={CheckboxInput} />
								<Field label="Police Traffic Control" name="discussPoliceTraffic" component={CheckboxInput} />
								<Field label="Police Safety" name="discussPoliceSafety" component={CheckboxInput} />
								<Field label="N/A" name="discussNotApplicable" component={CheckboxInput} />
								<Field label="Other (specify)" name="discussOther" component={CheckboxInput} />
								<Field
									placeholder="Specifiy..."
									name="discussOther_Explain"
									component={TextInput}
									type={values.discussOther ? "text" : "hidden"}
									/>
								<div className="ui divider"></div>

								<Field label="Discuss Working Safely Around Tools and Equipment" name="discussWorkingSafely" component={CheckboxInput} />
								<Field label="Discuss Housekeeping and Clean Up" name="discussHousekeeping" component={CheckboxInput} />
								<Field
									label="Inspect Operating Equipment Machines and Operators, Including Vehicle"
									name="inspectEquipment"
									component={CheckboxInput}
									/>
								<Field
									label="Inspect Tools and Equipment - See attached list before proceeding"
									name="inspectTools"
									component={CheckboxInput}
									/>
								<Field
									label="Discuss Prior Safety Precautions to be Taken to Address Hazards and Conditions Identified"
									name="discussPrecautions"
									component={CheckboxInput}
									/>
								<Field label="Completed client required JSA/HAS Forms (if applicable)" name="completedClientJSAHASForms" component={CheckboxInput} />

								<div className="ui divider"></div>
								<Field
									label="Identify All Potential Hazards, Significant Objects and Conditions In and Around work Area such as"
									name="identifyHazards"
									component={CheckboxInput}
									/>

								<Field label="Utilities and Power Lines" name="identifyUtilities" component={CheckboxInput} />
								<Field label="Overhead Structures (overhead building, bridge overpass)" name="identifyOverheadStructures" component={CheckboxInput} />
								<Field label="Grade Crossing Switches" name="identifyGradeXingSwitches" component={CheckboxInput} />
								<Field label="Nauseating Smells" name="identifyNauseatingSmells" component={CheckboxInput} />
								<Field label="Poisonous Plants" name="identifyPoisonousPlants" component={CheckboxInput} />
								<Field label="Wildlife / Insects" name="identifyWildlife" component={CheckboxInput} />
								<Field label="Traffic" name="identifyTraffic" component={CheckboxInput} />
								<Field label="Other (Specify)" name="identifyOther" component={CheckboxInput} />
								<Field
									placeholder="Specify..."
									name="identifyOther_Explain"
									component={TextInput}
									type={values.identifyOther ? "text" : "hidden"}
									/>
								<div className="ui divider"></div>

								<Field
									label="Call Project Manager to Discuss Additional On-Site Conditions That Are Potential Hazards, and update H&amp;S checklist"
									name="callProjectManager"
									component={CheckboxInput}
									/>
								<Field label="No Contact, Call Geographic Leader" name="callGeoLeader" component={CheckboxInput} />
								<Field label="No Contact, Call Lisa DeBenedetto" name="callSafetyCoordinator" component={CheckboxInput} />
								<Field label="N/A" name="callNotApplicable" component={CheckboxInput} />
							</RenderIf>
								
							<RenderIf condition={step === 2}>
								<Header as='h4'>Who's on site?</Header>
								<Field
									name="onSitePersonnel"
									search
									placeholder="Select Personnel..."
									value={whosOnSite}
									component={MultipleSelectInput}
									onChange={handleOnSiteChange}
									options={userList?.map((user) => ({
										key: "onSite-"+user.aoid,
										value: user.email,
										text: user.formattedName,
									}))}
								/>
									
								<Header as='h4'>What PPE was used?</Header>
								<Field
									name='PPE'
									// placeholder='What PPE was used?'
									component={TextInput}
								/>
							</RenderIf>

							<RenderIf condition={step === 3}>
								<Header>Equipment List</Header>
								<div>Check the box if the item is in good state of repair. If not, explain. Supervisor Notified.</div>
								<br />
								<Grid>
									{equipList.map((item) => (
										<Fragment key={item.name}>
											<Grid.Row className="gridRow">
												<div className="block">
													<Field label={item.label} name={item.name + "Good"} component={CheckboxInput} />

													<OnChange name={item.name + "Good"}>
														{(val: any, preVal: any) => {
															let tempDict = new Map(equipVisible);
															tempDict.set(`${item.name}`, val);
															setEquipVisible(tempDict);
														}}
													</OnChange>
													<Field
														name={item.name + "Notes"}
														placeholder="Notes..."
														component={TextInput}
														type={equipVisible.get(item.name) ? "hidden" : "text"}
														/>
												</div>
											</Grid.Row>
										</Fragment>
									))}

								</Grid>
								<br/>
								<Button loading={submitting} disabled={invalid || pristine} floated="right" positive type="submit" content="Submit" />
							</RenderIf>

							<StepButtons
								isValid={() => isStepValid(values)}
								step={step}
								prevStep={() => { setStep(step - 1); }}
								nextStep={() => { setStep(step + 1); }}
								stepmax={3}
								scrollToTop={false}
							/>
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(SiteSafetyBriefingForm);
