import React, { useContext } from "react";
import { Button, Segment, Header, Form } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";

import CheckboxInput from "../../app/common/form/CheckboxInput";
import { ISafetyChecklist } from "../../app/models/safetyChecklist";

const DeleteChecklist: React.FC<{safetyChecklist: ISafetyChecklist}> = (p) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const {
    loadUserNotifications,
  } = rootStore.notificationStore;
  const {
      deleteSafetyChecklist, 
  } = rootStore.safetyChecklistStore;

  const labelText = 'Please confirm you wish to delete this Safety Checklist.'

  const handleFinalFormSubmit = () => {
    const payload = new FormData();
    payload.set('safetyChecklistId', p.safetyChecklist.id);
    Promise.all([
      deleteSafetyChecklist(p.safetyChecklist.id),
      loadUserNotifications()
    ]);
    closeModal();
  }

  return (
    <Segment clearing>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        render={({
          handleSubmit,
          values }) => (

          <Form onSubmit={handleSubmit}>
            <Header as='h3' content='Delete Safety Checklist' />

            <Field
              component={CheckboxInput}
              name='confirm'
              label={labelText}
              type='checkbox'
            />
          
            <div className='ui two buttons'>
              <Button
                color='red'
                onClick={() => closeModal()}
                content="Cancel"
                type='button'
              />
              <Button
                disabled={!values.confirm}
                color='green'
                content="Submit"
                type='submit'
              />
            </div>
          </Form>
        )}
      />
    </Segment>
  );
};

export default DeleteChecklist;
