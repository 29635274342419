import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Dropdown, DropdownProps, Segment, Grid } from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import { observer } from "mobx-react-lite";

import { RootStoreContext } from "../../../app/stores/rootStore";
import { VehicleInspectionSearchFormValues, IVehicleInspection, VehicleInspectionFormValues } from "../../../app/models/vehicleInspection";
import { offices } from "../../../app/common/options/officesOptions";

import { CSVLink, CSVDownload } from "react-csv";
import RenderIf from "../../../app/common/form/RenderIf";
import { report } from "process";

import { months, years } from "../../../app/common/options/datesOptions";

const VehicleInspectionSearch = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		setPredicate, 
		clearPredicate, 
		loadVehicleInspections, 
		clearVehicleInspectionRegistry, 
		loadUsers, 
		userList, 
		vehicles, 
		getPredicate, 
		loadVehicleInspectionReport,
		reportData,
		disciplines,
		loadDisciplines
	} = rootStore.vehicleInspectionStore;

	const [searchParams, setSearchParams] = useState(new VehicleInspectionSearchFormValues());

	const handleDiscChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setSearchParams({ ...searchParams, disciplines: data.value as string[] });
		setPredicate("disciplines", JSON.stringify(data.value));
	};

	const handleUsersChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setSearchParams({ ...searchParams, users: data.value as string[] });
		setPredicate("users", JSON.stringify(data.value));
	};

	const handleVehiclesChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setSearchParams({ ...searchParams, vehicles: data.value as number[] });
		setPredicate("vehicles", JSON.stringify(data.value));
	};

	const handleOfficesChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setSearchParams({ ...searchParams, offices: data.value as string[] });
		setPredicate("offices", JSON.stringify(data.value));
	};

	const handleMonthsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setSearchParams({...searchParams, months: data.value as number[] }); 
		setPredicate("months", JSON.stringify(data.value));
	};
	const handleYearsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => { 
		setSearchParams({...searchParams, years: data.value as number[] }); 
		setPredicate("years", JSON.stringify(data.value));
	};

	/*************************************
	 *
	 *************************************/
	const handleFinalFormSubmit = (values: VehicleInspectionSearchFormValues) => {
		clearVehicleInspectionRegistry();
		loadVehicleInspections();
	};

	/*************************************
	 * Render the selected filters
	 *************************************/
	 const loadStorePredicate = () => {
		let storePred = getPredicate();

		let statusStr: string | undefined = storePred.get("status")?.toString().replace('[', '').replace(']', '');
		let statusAry: number[] | undefined = statusStr?.split(',').map(v => parseInt(v, 10));

		let vehiclesStr: string | undefined = storePred.get("vehicles")?.toString().replace('[', '').replace(']', '');
		let vehiclesAry: number[] | undefined = vehiclesStr?.split(',').map(v => parseInt(v, 10));

		let discsStr: string | undefined = storePred.get("disciplines")?.toString().replace('[', '').replace(']', '').replaceAll('"', '');
		let discsAry: string[] | undefined = discsStr?.split(',');
		
		let officesStr: string | undefined = storePred.get("offices")?.toString().replace('[', '').replace(']', '');
		let officesAry: string[] | undefined = officesStr?.split('"');
		// cleanup choppy array which includes '' and ','
		if (officesAry) {
			let i = 0;
			while (i < officesAry.length) {
				if (officesAry[i] === ',' || officesAry[i] === '') {
					officesAry.splice(i, 1);
				} else {
					++i;
				}
			}
		}

		let usersStr: string | undefined = storePred.get("users")?.toString().replace('[', '').replace(']', '').replaceAll('"', '');
		let usersAry: string[] | undefined = usersStr?.split(',');

		let monthsStr: string | undefined = storePred.get("months")?.toString().replace('[', '').replace(']', '');
		let monthsNumAry: number[] | undefined = monthsStr?.split(',').map(y => parseInt(y, 10));
		
		let yearsStr: string | undefined = storePred.get("years")?.toString().replace('[', '').replace(']', '');
		let yearsNumAry: number[] | undefined = yearsStr?.split(',').map(y => parseInt(y, 10));

		let searchVals = new VehicleInspectionSearchFormValues();
		if (statusAry !== undefined) searchVals.status = statusAry;
		if (vehiclesAry !== undefined) searchVals.vehicles = vehiclesAry;
		if (discsAry !== undefined) searchVals.disciplines = discsAry;
		if (officesAry !== undefined) searchVals.offices = officesAry;
		if (usersAry !== undefined) searchVals.users = usersAry;
		if (monthsNumAry !== undefined) searchVals.months = monthsNumAry;
		if (yearsNumAry !== undefined) searchVals.years = yearsNumAry;

		setSearchParams(searchVals);
	}


	/*************************************
	 *
	 *************************************/
	const resetSearch = (form: any) => {
		setSearchParams(new VehicleInspectionSearchFormValues());
		clearPredicate();
		form.reset();
		clearVehicleInspectionRegistry();
		loadVehicleInspections();
	};

	useEffect(() => {
		loadDisciplines();
		loadUsers();
		loadStorePredicate();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Segment clearing>
			<FinalForm
				onSubmit={handleFinalFormSubmit}
				initialValues={searchParams}
				render={({ handleSubmit, form }) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group widths="equal">
							<Dropdown
								name="vehicles"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Vehicles"
								onChange={handleVehiclesChange}
								value={searchParams.vehicles}
								options={vehicles.map((v) => ({
									key: v.vehicleID,
									value: v.vehicleID,
									text: v.wexpin,
								}))}
							/>
							<Dropdown
								name="disciplines"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Filter by Discipline"
								onChange={handleDiscChange}
								value={searchParams.disciplines}
								options={disciplines.map((discipline) => ({
									key: discipline.id,
									value: discipline.id,
									text: discipline.name,
								}))}
							/>
							<Dropdown
								name="users"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Users"
								onChange={handleUsersChange}
								value={searchParams.users}
								options={userList.map((user) => ({
									key: user.aoid,
									value: user.email,
									text: user.formattedName,
								}))}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Dropdown
								name="offices"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Offices"
								onChange={handleOfficesChange}
								value={searchParams.offices}
								options={offices.map((o) => ({
									//key: o.key,
									value: o,
									text: o,
								}))}
							/>
							<Dropdown
								name="months"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Month"
								onChange={handleMonthsChange}
								options={months}
								value={searchParams.months}
								/>
							<Dropdown
								name="years"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Filter by Year"
								onChange={handleYearsChange}
								options={years}
								value={searchParams.years}
							/>
						</Form.Group>

						<Grid>
							<Grid.Column textAlign="center">
								<Button positive type="submit" content="Search" />
								<Button color="red" type="button" content="Reset" onClick={() => resetSearch(form)} />
								<Button color="yellow" type="button" content="Generate Report" onClick={() => loadVehicleInspectionReport()} />
								<RenderIf condition={reportData.length > 0}>
									<Button type="button">
										<CSVLink data={reportData} filename="vehicleInspectionReport.csv">
											Download Report
										</CSVLink>
									</Button>
								</RenderIf>
								
							</Grid.Column>
						</Grid>
					</Form>
				)}
			/>
		</Segment>
	);
};

export default observer(VehicleInspectionSearch);
