import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { AuthenticationState } from "react-aad-msal";
import { Link, NavLink } from "react-router-dom";
import { Container, Dropdown, Menu, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import AboutForm from "../modals/AboutForm";
import ConfirmLogout from "../modals/ComfirmLogout";
import NotificationBell from "../notification/notificationBell/NotificationBell";
import "./NavBar.scss";
import RenderIf from "../../app/common/form/RenderIf";

interface IProps {
	login: () => void;
	logout: () => void;
	authenticationState: AuthenticationState;
}

const Logo = () => (
	<Menu.Item header as={NavLink} exact to="/" className="cedLogo">
		<img src="/assets/CED_Horizontal Logo_WHITE_RBG_small.png" alt="logo" />
	</Menu.Item>
);
// This logo with lines is only for printing purpose
const LineLogo = () => (
	<div className="lineLogo">
		<img src="/assets/Ced3.png" alt="logo" height="70px" />
	</div>
);
const NavBar: React.FC<IProps> = ({ login, logout, authenticationState }) => {
	const rootStore = useContext(RootStoreContext);
	const { openModal } = rootStore.modalStore;
	const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;

	const isInProgress = authenticationState === AuthenticationState.InProgress;
	const isAuthenticated =
		authenticationState === AuthenticationState.Authenticated;
	const isUnauthenticated =
		authenticationState === AuthenticationState.Unauthenticated;

	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		//const handleResize = _debounce(() => setWidth(window.innerWidth), 100)
		const handleResize = () => setWidth(window.innerWidth);

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<LineLogo />
			<Segment inverted className="noPrint">
				<Menu fixed="top" inverted size="large" className="headerMenu">
					<Container>
						<Logo />
						<Menu.Menu position="right">
							{width < 768 ? (
								<Menu.Item>
									<NotificationBell />
									<Dropdown className="icon" icon="sidebar" pointing item>
										<Dropdown.Menu>
											{(isUnauthenticated || isInProgress) && (
												<Dropdown.Item text="Login" onClick={login} />
											)}
											{isAuthenticated && (
												<Dropdown.Item
													text="Logout"
													onClick={() =>
														openModal(<ConfirmLogout logout={logout} />)
													}
												/>
											)}
											<Dropdown.Item text="Home" as={Link} to="/" />
											<Dropdown.Item
												href="https://maserc.sharepoint.com/sites/HealthAndSafetyFiles/Shared%20Documents/Forms/AllItems.aspx"
												target="_blank"
												rel="noreferrer"
											>
												Documents
											</Dropdown.Item>
											<Dropdown.Item
												text="About"
												onClick={() => openModal(<AboutForm />)}
											/>
										</Dropdown.Menu>
									</Dropdown>
								</Menu.Item>
							) : (
								<>
									{(isUnauthenticated || isInProgress) && (
										<Menu.Item name="Login" onClick={login} />
									)}
									{isAuthenticated && (
										<Menu.Item
											name="Logout"
											onClick={() =>
												openModal(<ConfirmLogout logout={logout} />)
											}
										/>
									)}
									<Menu.Item name="Home" as={Link} to="/" />
									<RenderIf condition={accountInfoUserEmail?.includes("@collierseng.com") === true}>
										<Menu.Item
											href="https://maserc.sharepoint.com/sites/HealthAndSafetyFiles/Shared%20Documents/Forms/AllItems.aspx"
											target="_blank"
											rel="noreferrer"
										>
										Documents
										</Menu.Item>
									</RenderIf>

									<RenderIf condition={accountInfoUserEmail?.includes("@hilgartwilson.com") === true}>
										<Menu.Item
											href="https://hilgartwilson.myhubintranet.com/CED-Integration-Hub"
											target="_blank"
											rel="noreferrer"
										>
										Documents
										</Menu.Item>
									</RenderIf>
									
									<Menu.Item className="bellMenu">
										<NotificationBell />
									</Menu.Item>
									<Menu.Item
										name="About"
										onClick={() => openModal(<AboutForm />)}
									/>
								</>
							)}
						</Menu.Menu>
					</Container>
				</Menu>
			</Segment>
		</>
	);
};

export default observer(NavBar);