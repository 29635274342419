export const fallHazLabels: any[] = [
    {
        groupName: 'locations',
        labels: [
            { key: 'location_1_1', label: 'Any other walking/working surface with an unprotected side or edge 6 feet or more above a lower level' },
            { key: 'location_1_2', label: 'Hoist areas 6 feet or more above a lower level' },
            { key: 'location_1_3', label: 'Excavations 6 feet or more in depth' },
            { key: 'location_1_4', label: 'Fall hazards from open pits, tanks, vats, ditches, etc' },
            { key: 'location_2_1', label: 'Stairway floor openings' },
            { key: 'location_2_2', label: 'Ladderway floor opening or platform' },
            { key: 'location_2_3', label: 'Hatchway and chute floor openings' },
            { key: 'location_2_4', label: 'Skylight floor openings' },
            { key: 'location_2_5', label: 'Pit and trapdoor floor openings' },
            { key: 'location_2_6', label: 'Manhole floor openings' },
            { key: 'location_2_7', label: 'Temporary floor openings' },
            { key: 'location_2_8', label: 'Floorholes into which persons can accidentally walk' },
            { key: 'location_2_9', label: 'Doors or gates which open directly into a stairway' },
            { key: 'location_2_10', label: 'Wall openings with drops of more than 6 feet' },
            { key: 'location_2_11', label: 'Window wall opening at stairway landings, floors, platforms, or balconies from which there is a drop of more than 6 feet' },
            { key: 'location_2_12', label: 'Temporary wall openings' },
            { key: 'location_2_13', label: 'Hazard of material falling' },
            { key: 'location_2_14', label: 'Open-sided platforms 6 feet or more above the adjacent floor or ground level' },
            { key: 'location_2_15', label: 'Runways with open sides 6 feet or more above the floor or ground level' },
            { key: 'location_2_16', label: 'Open-sided floors, walkways, platforms, or runways above or adjacent to dangerous equipment regardless of heights' },
            { key: 'location_2_17', label: 'Unguarded stairs with four or more risers with no handrail or guardrail' },
            { key: 'location_3_1', label: 'Ladders of more than 24 feet climb without cage or fall protection' },
            { key: 'location_3_2', label: 'Ladder landings' },
            { key: 'location_3_3', label: 'Landing platforms' },
            { key: 'location_4_1', label: 'Scaffolding with open sides more than 10 feet above the ground or the floor' },
            { key: 'location_4_2', label: 'Crawl boards or chicken ladders' }
        ]
    },
    {
        groupName: 'texts',
        labels: [
            { key: 'harness_Manufacturer', label: 'Harness manufacturer' },
            { key: 'harness_Model', label: 'Harness model' },
            { key: 'harness_Serial', label: 'Harness serial' },
            { key: 'lanyard_Manufacturer', label: 'Lanyard manufacturer' },
            { key: 'lanyard_Model', label: 'Lanyard model' },
            { key: 'lanyard_Serial', label: 'Lanyard serial' },
            { key: 'anchor_Manufacturer', label: 'Achor strap manufacturer' },
            { key: 'anchor_Model', label: 'Anchor strap model' },
            { key: 'anchor_Serial', label: 'Anchor strap serial' },
            { key: 'carabineer_Manufacturer', label: 'Carabineer manufacturer' },
            { key: 'carabineer_Model', label: 'Carabineer model' },
            { key: 'carabineer_Serial', label: 'Carabineer serial' }
        ]
    },
    {
        groupName: 'checkboxes',
        labels: [
            { key: 'b_MetalConnectorsNoDmg', label: 'Metal connectors, snap hooks, D-rings etc, must not show signs of damage, including: broken, distorted, sharp edges, burrs, cracks, worn parts, or corrosion. Note: Make sure all buckles work freely.' },
            { key: 'b_HarnessAndStrapsNoDmg', label: 'The harness and straps must not show signs of damage including: frayed, cut or broken fibers; tears, abrasions, mold, burns, or discoloration, pulled or cut stitches, knots, excessive soiling or paint buildup. Note: Broken stitches or exposed fall indicator stitching may be an indication the harness has been shock- loaded during a fall and must be discarded.' },
            { key: 'b_HarnessOriginalLabel', label: 'The harness must have an original label indicating the manufacturers intended load capacity.' },
            { key: 'b_LanyardNoDmg', label: 'The lanyards must not show signs of damage or stress or shock loading including: rust or staining, chemical or heat damage, or degradation. The lanyard must also have complete and legible labels including the manufacturer name and rated capacity. Note: Broken stitches or exposed fall indicator stitching may be an indication the harness has been shock loaded in a fall and must be discarded.' },
            { key: 'b_HardwareNoDmg', label: 'Hardware items must not show signs of damage, including: broken, distorted, sharp edges, burrs, cracks, worn parts, or corrosion.' },
            { key: 'b_WireRopeNoDmg', label: 'Wire rope must not be damaged, including: six (6) or more broken wires in one rope lay or three (3) or more broken wires in one strand, corrosion, permanent kinks, burn marks, bird caging or exposed core.' },
        ]
    }
]