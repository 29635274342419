import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Item, Grid, Segment } from "semantic-ui-react";

import { RootStoreContext } from "../../../app/stores/rootStore";
import IncidentReportListItem from "./IncidentReportListItem";

const IncidentReportList: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { incidentReportsByDefault } = rootStore.incidentReportStore;

	return (
		<Grid columns={2} stackable>
			<Fragment>
				{incidentReportsByDefault.map((incidentReport) => (
					<Grid.Column key={incidentReport.id}>
						<Segment clearing>
							<Item.Group>
								<IncidentReportListItem
									key={incidentReport.id}
									incidentReport={incidentReport}
								/>
							</Item.Group>
						</Segment>
					</Grid.Column>
				))}
			</Fragment>
		</Grid>
	);
};

export default observer(IncidentReportList);
