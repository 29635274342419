export interface IDiscipline {

    id: string;
    name: string;
    orgCode: string;
    safetyCoordinatorName: string;
    SafetyCoordinatorEmail: string;
    DisciplineLeaders: string[];
    Subdisciplines: string[];
    FsaCompletionTarget: number;
    safetyDropdowns: boolean;
}

export class DisciplineFormValues implements IDiscipline {

    id: string = '';
    name: string = '';
    orgCode: string = '';
    safetyCoordinatorName: string = '';
    SafetyCoordinatorEmail: string = '';
    DisciplineLeaders: string[] = [];
    Subdisciplines: string[] = [];
    FsaCompletionTarget: number = 0;
    safetyDropdowns: boolean = false;

    constructor(init?: DisciplineFormValues) {
        Object.assign(this,init);
      }
}