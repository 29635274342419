import React, { useContext, useState, useEffect } from "react";
import { Form, Segment, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Form as FinalForm } from "react-final-form";
import { combineValidators, composeValidators, hasLengthGreaterThan, hasLengthLessThan, isRequired, } from "revalidate";

import { FieldFileDropZone } from "../../../app/common/form/FileDropZone";

import { INearMiss, NearMissFormValues } from "../../../app/models/nearMiss";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import ConfirmCancelForm from '../../modals/ConfirmCancelForm';
import { FieldDate, FieldTextBox, FieldTextLine, FieldTime } from "../../../app/common/form/ConvenienceFields";
import { to24TimeString } from "../../../app/common/util/util";

const validate = combineValidators({
  dateOfIncident: isRequired("Date of Incident"),
  timeOfIncident: isRequired("Time of Incident"),
  location: composeValidators(
    isRequired("Location"),
    hasLengthLessThan(110)({
      message: "Location needs to be less than 110 characters",
    })
  )(),
  weather: composeValidators(
    isRequired("Weather"),
    hasLengthLessThan(81)({
      message: "This needs to be less than 80 characters",
    })
  )(),
  witnesses: composeValidators(
    isRequired("Witness"),
    hasLengthLessThan(110)({
      message: "This needs to be less than 110 characters",
    })
  )(),
  description: composeValidators(
    isRequired("Description"),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters",
    }),
    hasLengthLessThan(1201)({
      message: 'This needs to be less than 1200 characters'
    })
  )(),
  contributingFactors: composeValidators(
    isRequired("Contributing Factors"),
    hasLengthLessThan(601)({
      message: 'This needs to be less than 600 characters'
    })
  )(),
  preventions: composeValidators(
    isRequired("Preventions"),
    hasLengthLessThan(1201)({
      message: 'This needs to be less than 1201 characters'
    })
  )(),
  recommendedCorrectiveActions: composeValidators(
    isRequired("Recommended Corrective Actions"),
    hasLengthLessThan(501)({
      message: 'This needs to be less than 501 characters'
    })
  )(),
  personnelResponsibleEmail: composeValidators(
    isRequired("Personnel Responsible"),
    hasLengthLessThan(51)({
      message: 'This needs to be less than 50 characters'
    })
  )(),
  correctiveActionDate: isRequired("Corrective Action Due Date"),
  // comments: isRequired("Comments"),
});

interface DetailParams {
  id: string;
}

const NearMissForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createNearMiss,
    editNearMiss,
    submitting,
    loadNearMiss,
  } = rootStore.nearMissStore;
	const { openModal } = rootStore.modalStore;

  const [nearMiss, setNearMiss] = useState(new NearMissFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadNearMiss(match.params.id)
        .then((nearMiss) => {
          setNearMiss(new NearMissFormValues(nearMiss));
          setTimeInputs(nearMiss);
        })
        .finally(() => setLoading(false));
      }
  }, [loadNearMiss, match.params.id]);
  
  const setTimeInputs = (nearMiss: INearMiss) => {
    const time = to24TimeString(nearMiss.timeOfIncident);
    var timeControl = document.querySelector('input[name=timeOfIncident') as any;
    timeControl.value = time;
  }

  const handleFinalFormSubmit = (values: any) => {
    const payload = new FormData();
    // payload.set('office', values.office);
    let shortTime: any = values.timeOfIncident;
    if (typeof values.timeOfIncident === 'object') {
      shortTime = to24TimeString(values.timeOfIncident);
    }
    var timeConversion = new Date(`1970-01-01T${shortTime}:00`);
    payload.set('dateOfIncident', values.dateOfIncident.toISOString());
    payload.set('timeOfIncident', timeConversion.toISOString());
    payload.set('location', values.location);
    payload.set('weather', values.weather);
    payload.set('witnesses', values.witnesses);
    payload.set('description', values.description);
    payload.set('contributingFactors', values.contributingFactors);
    payload.set('preventions', values.preventions);
    payload.set('recommendedCorrectiveActions', values.recommendedCorrectiveActions);
    payload.set('personnelResponsibleEmail', values.personnelResponsibleEmail);
    payload.set('correctiveActionDate', values.correctiveActionDate.toISOString());
    payload.set('comments', values.comments);
    
    if(values.fileArray && values.fileArray !== undefined)
      for(var i = 0; i < values.fileArray.length; i++) {
        payload.append("newFiles", values.fileArray[i]);
      }
    
    if(!nearMiss.id) {
      createNearMiss(payload);
    } else {
      editNearMiss(nearMiss.id, payload);
    }
  };

  return (
    <>
      <PageTitle title="Near Miss Form" />
      <Segment>

        <Grid>
          <Grid.Column width={16}>
            <Segment >
            <div className="near-miss-disclaimer">
              <p>A near miss is an unplanned event that did not result in injury, illness, or damage - but had the potential to do so.
                Near miss incidents often precede loss producing events but may be overlooked as there was no harm (no injury, damage, or loss).
                Collecting near miss reports helps create a culture that seeks to identify and control hazards which will reduce risks and the potential for harm.
                Hazard Identification is a key part of any health &amp; safety program. If hazards aren't identified, then they can't be mitigated properly to prevent an incident.
              </p>
            </div>
            </Segment>

            <FinalForm
              validate={validate}
              initialValues={nearMiss}
              onSubmit={handleFinalFormSubmit}
              render={({ handleSubmit, invalid, pristine }) => (
                <Form onSubmit={handleSubmit} loading={loading}>
                  <Segment>
                    <Grid stackable columns={3}>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <FieldDate
                            name="dateOfIncident"
                            placeholder="MM/DD/YYYY"
                            label="Date of Condition/Incident"
                            value={nearMiss.dateOfIncident}
                          />
                        </Grid.Column>

                        <Grid.Column width={4}>
                          <FieldTime
                            name="timeOfIncident"
                            placeholder="hh:mm:ss pm"
                            label="Time of Condition/Incident"
                            // value={to24TimeString(nearMiss.timeOfIncident)}
                          />
                        </Grid.Column>

                        <Grid.Column width={8}>
                          <FieldTextLine
                            name="witnesses"
                            placeholder="Witnesses"
                            label="Witnesses"
                            value={nearMiss.witnesses}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldTextLine
                            name="location"
                            placeholder="GSP Southbound Exit 98"
                            label="Location"
                            value={nearMiss.location}
                          />
                        </Grid.Column>

                        <Grid.Column width={8}>
                          <FieldTextLine
                            name="weather"
                            placeholder="Overcast"
                            label="Weather conditions (if applicable)"
                            value={nearMiss.weather}
                          />
                        </Grid.Column>

                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldTextBox
                            name="description"
                            placeholder="Description"
                            label="Description"
                            value={nearMiss.description}
                          />
                        </Grid.Column>
                        
                        <Grid.Column width={8}>
                          <FieldTextBox
                            name="contributingFactors"
                            placeholder="Contributing Factors (those behaviors, omissions, or deficiencies that set the stage for the incident)"
                            label="Contributing Factors"
                            value={nearMiss.contributingFactors}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={8}>
                          <FieldTextBox
                            name="preventions"
                            placeholder="Describe how this can be prevented/mitigated"
                            label="Describe how this can be prevented/mitigated"
                            value={nearMiss.preventions}
                          />
                        </Grid.Column>

                        <Grid.Column width={8}>
                          <FieldTextBox
                            name="recommendedCorrectiveActions"
                            placeholder="Recommended Corrective Actions"
                            label="Recommended Corrective Actions"
                            value={nearMiss.recommendedCorrectiveActions}
                            />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={8}>

                          <FieldTextLine
                            name="personnelResponsibleEmail"
                            placeholder="Personnel responsible for corrective action"
                            label="Personnel responsible for corrective action"
                            value={nearMiss.personnelResponsibleEmail}
                            />
                          </Grid.Column>

                        <Grid.Column width={8}>
                          <FieldDate
                            name="correctiveActionDate"
                            placeholder="Planned completion for corrective actions"
                            label="Planned completion for corrective actions"
                            value={nearMiss.correctiveActionDate}
                            />
                          </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>

                  <Segment>
                    <Grid.Row>
                      <FieldTextBox
                        name="comments"
                        placeholder="Comments"
                        label="Comments"
                        value={nearMiss.comments}
                        />
                    </Grid.Row>
                    <br/>
                    <Grid.Row>
                      <FieldFileDropZone name="fileArray" accept={[
                        "image/*",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/msword",
                        "application/pdf"
                      ].join(',')} maxFiles={6} />
                    </Grid.Row>
                  </Segment>

                  <Button
                    loading={submitting}
                    disabled={loading || submitting }
                    floated="right"
                    positive
                    type="submit"
                    content="Submit"
                  />
                  <Button
                    disabled={loading}
                    color='yellow'
                    floated="right"
                    type="button"
                    content="Cancel"
                    onClick={()=>openModal(<ConfirmCancelForm />)}
                  />

                </Form>
              )}
            />
            {/* </Segment> */}
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};

export default observer(NearMissForm);
