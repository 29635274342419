import React, { useContext, useState, useEffect } from "react";
import { Header, Button, Form, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired, isRequiredIf, } from "revalidate";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { states } from '../../../app/common/options/statesOptions';
import { safariSafeDateIsoString, to24TimeString } from "../../../app/common/util/util";
import { VehicleAccidentReportFormValues } from "../../../app/models/vehicleAccidentReport";
import { VehicleAccidentPassengerFormValues, IVehicleAccidentPassenger } from "../../../app/models/vehicleAccidentPassenger";
import { VehicleAccidentWitnessFormValues, IVehicleAccidentWitness } from "../../../app/models/vehicleAccidentWitness";
import { RouteComponentProps } from "react-router-dom";
import { FieldFileDropZone } from "../../../app/common/form/FileDropZone";
import { FieldArray } from "react-final-form-arrays";
import { toast } from "react-toastify";

import arrayMutators from 'final-form-arrays'
import PageTitle from "../../../app/common/form/PageTitle";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import DateInput from "../../../app/common/form/DateInput";
import SelectInput from "../../../app/common/form/SelectInput";
import ConfirmCancelForm from '../../modals/ConfirmCancelForm';
import TimeInput from "../../../app/common/form/TimeInput";
import RenderIf from "../../../app/common/form/RenderIf";

const Required = (x:any, err:string = 'Required') => (x !== undefined && x !== null && x !== '')? undefined : err;
const RequiredIf = (x:any, condition:boolean, err:string = 'Required') => condition? Required(x, err) : undefined;

const newValidate = (values: any) => {
  const mergeObjs = (...objs:any[]) => Object.assign({}, ...objs);
  const validations = mergeObjs({
    date: Required(values.date),
    time: Required(values.time),
    streetIntersection: Required(values.streetIntersection),
    city: Required(values.city),
    state: Required(values.state),
    weatherConditions: Required(values.weatherConditions),
    accidentDescription: Required(values.accidentDescription),
    operatorName1: Required(values.operatorName1),
    operatorAddress1: Required(values.operatorAddress1),
    operatorPhone1: Required(values.operatorPhone1),
    operatorLicense1: Required(values.operatorLicense1),
    operatorDob1: Required(values.operatorDob1),
    operatorInsurance1: Required(values.operatorInsurance1),
    vehicleNumber1: Required(values.vehicleNumber1),
    vehicleMakeModel1: Required(values.vehicleMakeModel1),
    vehicleYear1: Required(values.vehicleYear1),
    vehicleLicense1: Required(values.vehicleLicense1),
    vehicleRegistration1: Required(values.vehicleRegistration1),
    ownerRegisteredName1: Required(values.ownerRegisteredName1),
    ownerAddress1: Required(values.ownerAddress1),
    ownerPhone1: Required(values.ownerPhone1),
    ownerInsurance1: Required(values.ownerInsurance1),
    citationDesc: RequiredIf(values.citationDesc, values.citationIssued),
    whoWasInjured: RequiredIf(values.whoWasInjured, values.anyInjuries),
    treatmentReceived: RequiredIf(values.treatmentReceived, values.anyInjuries),
  });

  return validations;
}

const validate = combineValidators({
  // date+time: time of accident
  date: isRequired("Date"),
  time: isRequired("Time"),
  streetIntersection: isRequired('Street, Intersection'),
  city: isRequired("City"),
  state: isRequired("State"),
  weatherConditions: isRequired("Weather Conditions"),
  accidentDescription: isRequired('Accident Description'),
  operatorName1: isRequired('Operator Name'),
  operatorAddress1: isRequired('Operator Address'),
  operatorPhone1: isRequired('Operator Phone'),
  operatorLicense1: isRequired('Operator License'),
  operatorDob1: isRequired('Operator Date of Birth'),
  operatorInsurance1: isRequired('Operator Insurance'),
  vehicleNumber1: isRequired('Vehicle Number'),
  vehicleMakeModel1: isRequired('Make / Model'),
  vehicleYear1: isRequired('Vehicle Year'),
  vehicleLicense1: isRequired('Vehicle License'),
  vehicleRegistration1: isRequired('Vehicle Registration'),
  ownerRegisteredName1: isRequired('Owner Registered Name'),
  ownerAddress1: isRequired('Owner Address'),
  ownerPhone1: isRequired('Owner Phone'),
  ownerInsurance1: isRequired('Owner Insurance'),
  // citationDesc: isRequiredIf(vehicleAccidentReport.citationIssued, "Citation Description"),
  // whoWasInjured: isRequiredIf(values && values.anyInjuries)("Who was injured?"),
  // treatmentReceived: isRequiredIf(values => values.anyInjuries)("What treatment was received?")
  //citationDesc: isRequired("Citation description")
});

interface DetailParams {
  id: string;
}

const VehicleAccidentReport: React.FC<RouteComponentProps<DetailParams>> = ({
  match//, history
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createVehicleAccidentReport,
    loadReport,
    editReport,
    submitting,
  } = rootStore.vehicleAccidentReportStore;
	const { openModal } = rootStore.modalStore;

  const [vehicleAccidentReport, setVehicleAccidentReport] = useState(new VehicleAccidentReportFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadReport(match.params.id)
        .then((vehicleAccidentReport: VehicleAccidentReportFormValues) => {
          let report = new VehicleAccidentReportFormValues(vehicleAccidentReport);
          if (vehicleAccidentReport.passengers?.length === 0) {
            report.passengers = [new VehicleAccidentPassengerFormValues()] as IVehicleAccidentPassenger[];
          }
          if (vehicleAccidentReport.witnesses?.length === 0) {
            report.witnesses = [new VehicleAccidentPassengerFormValues()] as IVehicleAccidentWitness[];
          }
          setVehicleAccidentReport(report);
          setTimeInputsOnLoad(report);
        })
        .finally(() => setLoading(false));
    } else {
      setTimeInputsInitial();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const setTimeInputsOnLoad = (report: VehicleAccidentReportFormValues) => {
    let time;
    if (report.dateTime) {
      time = to24TimeString(report.dateTime);
    }
    var timeControl = document.querySelector('input[name=time]') as any;
    timeControl.value = time;
  }

  const setTimeInputsInitial = () => {
    let time = to24TimeString(vehicleAccidentReport.time);
    var timeControl = document.querySelector('input[name=time]') as any;
    timeControl.value = time;
  }

  const handleFinalFormSubmit = (values: VehicleAccidentReportFormValues) => {
    let valid = true;
    let v = newValidate(values);
    Object.entries(v).forEach(x => {
      if (x[1] === 'ERROR'){
          console.log(x);
          valid = false;
      }
    });
    
    if (!valid) {
      toast.error('Form is invalid. Please make sure all selections have been made.');
      return;
    }

    const payload = new FormData();
    // datetime: time of accident
    let shortTime: any = values.time;
    if (typeof values.time === 'object') {
      shortTime = to24TimeString(values.time);
    }
    payload.set('dateTime', safariSafeDateIsoString(values.date, shortTime));
    payload.set('streetIntersection', values.streetIntersection);
    payload.set('city', values.city);
    payload.set('state', values.state);
    payload.set('weatherConditions', values.weatherConditions);
    payload.set('accidentDescription', values.accidentDescription);
    if(values.anyInjuries){
      payload.set('whoWasInjured', values.whoWasInjured);
      payload.set('treatmentReceived', values.treatmentReceived);
    }

    payload.set('operatorName1', values.operatorName1);
    payload.set('operatorAddress1', values.operatorAddress1);
    payload.set('operatorPhone1', values.operatorPhone1);
    payload.set('operatorLicense1', values.operatorLicense1);
    if(values.operatorDob1){
      payload.set('operatorDob1', values.operatorDob1.toISOString());
    }
    payload.set('operatorInsurance1', values.operatorInsurance1);
    payload.set('vehicleNumber1', values.vehicleNumber1);
    payload.set('vehicleMakeModel1', values.vehicleMakeModel1);
    payload.set('vehicleYear1', values.vehicleYear1);
    payload.set('vehicleLicense1', values.vehicleLicense1);
    payload.set('vehicleRegistration1', values.vehicleRegistration1);
    payload.set('ownerRegisteredName1', values.ownerRegisteredName1);
    payload.set('ownerAddress1', values.ownerAddress1);
    payload.set('ownerPhone1', values.ownerPhone1);
    payload.set('ownerInsurance1', values.ownerInsurance1);
    if(values.operatorName2        !== undefined && values.operatorName2        !== null && values.operatorName2         !== '') payload.set('operatorName2',        values.operatorName2);
    if(values.operatorAddress2     !== undefined && values.operatorAddress2     !== null && values.operatorAddress2      !== '') payload.set('operatorAddress2',     values.operatorAddress2);
    if(values.operatorPhone2       !== undefined && values.operatorPhone2       !== null && values.operatorPhone2        !== '') payload.set('operatorPhone2',       values.operatorPhone2);
    if(values.operatorLicense2     !== undefined && values.operatorLicense2     !== null && values.operatorLicense2      !== '') payload.set('operatorLicense2',     values.operatorLicense2);
    if(values.operatorDob2         !== undefined && values.operatorDob2         !== null                                       ){
      payload.set('operatorDob2', values.operatorDob2.toISOString());
      console.log(values.operatorDob2.toISOString());
    }
    if(values.operatorInsurance2   !== undefined && values.operatorInsurance2   !== null && values.operatorInsurance2    !== '') payload.set('operatorInsurance2',   values.operatorInsurance2);
    if(values.vehicleNumber2       !== undefined && values.vehicleNumber2       !== null && values.vehicleNumber2        !== '') payload.set('vehicleNumber2',       values.vehicleNumber2);
    if(values.vehicleMakeModel2    !== undefined && values.vehicleMakeModel2    !== null && values.vehicleMakeModel2     !== '') payload.set('vehicleMakeModel2',    values.vehicleMakeModel2);
    if(values.vehicleYear2         !== undefined && values.vehicleYear2         !== null && values.vehicleYear2          !== '') payload.set('vehicleYear2',         values.vehicleYear2);
    if(values.vehicleLicense2      !== undefined && values.vehicleLicense2      !== null && values.vehicleLicense2       !== '') payload.set('vehicleLicense2',      values.vehicleLicense2);
    if(values.vehicleRegistration2 !== undefined && values.vehicleRegistration2 !== null && values.vehicleRegistration2  !== '') payload.set('vehicleRegistration2', values.vehicleRegistration2);
    if(values.ownerRegisteredName2 !== undefined && values.ownerRegisteredName2 !== null && values.ownerRegisteredName2  !== '') payload.set('ownerRegisteredName2', values.ownerRegisteredName2);
    if(values.ownerAddress2        !== undefined && values.ownerAddress2        !== null && values.ownerAddress2         !== '') payload.set('ownerAddress2',        values.ownerAddress2);
    if(values.ownerPhone2          !== undefined && values.ownerPhone2          !== null && values.ownerPhone2           !== '') payload.set('ownerPhone2',          values.ownerPhone2);
    if(values.ownerInsurance2      !== undefined && values.ownerInsurance2      !== null && values.ownerInsurance2       !== '') payload.set('ownerInsurance2',      values.ownerInsurance2);
    if(values.whoContactedEmergency !== undefined && values.whoContactedEmergency !== null && values.whoContactedEmergency !== '') payload.set('whoContactedEmergency', values.whoContactedEmergency);
    payload.set('respondedPolice', values.respondedPolice.toString());
    payload.set('respondedEmt', values.respondedEmt.toString());
    payload.set('respondedFire', values.respondedFire.toString());
    if(values.policeOfficerName !== undefined && values.policeOfficerName !== null && values.policeOfficerName !== '') payload.set('policeOfficerName', values.policeOfficerName);
    if(values.policeBadgeNumber !== undefined && values.policeBadgeNumber !== null && values.policeBadgeNumber !== '') payload.set('policeBadgeNumber', values.policeBadgeNumber);
    if(values.policePrecinctDept !== undefined && values.policePrecinctDept !== null && values.policePrecinctDept !== '') payload.set('policePrecinctDept', values.policePrecinctDept);
    payload.set('citationIssued', values.citationIssued.toString());
    if(values.citationIssued){
      payload.set('citationDesc', values.citationDesc);
    }
    
    if(values.passengers !== undefined && values.passengers !== null && values.passengers[0].name !== undefined && values.passengers[0].name !== null && values.passengers[0].name !== '') 
      values.passengers.forEach((passenger,i) => Object.entries(passenger).forEach(([k,v]) => payload.append(`passengers[${i}].${k}`, v.toString())));
    
    if(values.witnesses !== undefined && values.witnesses !== null && values.witnesses[0].name !== undefined && values.witnesses[0].name !== null && values.witnesses[0].name !== '')
      values.witnesses.forEach((witness,i) => Object.entries(witness).forEach(([k,v]) => payload.append(`witnesses[${i}].${k}`, v.toString())));
    
    if(values.fileArray !== undefined && values.fileArray !== null)
      values.fileArray.forEach(f => payload.append('newFiles', f));
    if (!values.id) {
      createVehicleAccidentReport(payload);
    } else {
      editReport(values.id, payload);
    }
  };

  return (
    <>
      <PageTitle title="Vehicle Accident Report" />
      <Grid>
        <Grid.Column width={16}>
          <Segment clearing>
            <FinalForm
              validate={newValidate}
              initialValues={vehicleAccidentReport}
              onSubmit={handleFinalFormSubmit}
              mutators={{ ...arrayMutators }}
              loading={loading}
              render={({ handleSubmit, pristine, form: { mutators: { push, pop } }, values }) => (
                <Form onSubmit={handleSubmit} loading={loading}>
                  <Header as='h3'>Accident Details</Header>
                  <Segment>
                    <Form.Group widths="equal">
                      <Field label="Date of Accident" name="date" component={DateInput} date={true} value={vehicleAccidentReport.date} />
                      <Field label="Time of Accident" name="time" component={TimeInput} time={true} />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Field label='Street/Intersection' component={TextInput} width={5} name='streetIntersection' placeholder='Street / Intersection' value={vehicleAccidentReport.streetIntersection} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Field label='City' component={TextInput} width={2} name='city' placeholder='City' value={vehicleAccidentReport.city} />
                      <Field label='State' component={SelectInput} options={states} width={1} name='state' placeholder='State' search value={vehicleAccidentReport.state} />
                    </Form.Group>
                    <Field label='Weather Conditions' component={TextInput} name='weatherConditions' placeholder='Weather Conditions' value={vehicleAccidentReport.weatherConditions} />
                    <Field label='Accident Description' component={TextAreaInput} rows={4} name='accidentDescription' placeholder='Accident description' value={vehicleAccidentReport.accidentDescription} />
                    <Field component={CheckboxInput} name='anyInjuries' label='Were there any injuries?' type='checkbox' />
                    <RenderIf condition={values.anyInjuries}>
                      <Field label='Who was injured?'component={TextInput} name='whoWasInjured' placeholder='Who was injured?' value={vehicleAccidentReport.whoWasInjured} />
                      <Field label='What treatment was received?'component={TextInput} name='treatmentReceived' placeholder='What treatment was received?' value={vehicleAccidentReport.treatmentReceived} />
                    </RenderIf>
                  </Segment>

                  <Header as='h3'>Vehicle Operator Information</Header>
                  <Segment>
                    <Grid stackable columns={2}>
                      <Grid.Column>
                        <Header as='h4'>Colliers Operator</Header>
                        <Field label='Name' component={TextInput} name='operatorName1' placeholder='Operator Name' value={vehicleAccidentReport.operatorName1} />
                        <Field label='Address' component={TextInput} name='operatorAddress1' placeholder='Operator Address' value={vehicleAccidentReport.operatorAddress1} />
                        <Field label='Phone Number' component={TextInput} name='operatorPhone1' placeholder='Operator Phone Number' value={vehicleAccidentReport.operatorPhone1} />
                        <Field label='License Number/State' component={TextInput} name='operatorLicense1' placeholder='Operator License Number / State' value={vehicleAccidentReport.operatorLicense1} />
                        <Field label="Date of Birth" component={DateInput} date={true} name='operatorDob1' placeholder='Operator Date of Birth' value={vehicleAccidentReport.operatorDob1} />
                        <Field label='Operator Insurance Company/Policy Number' component={TextInput} name='operatorInsurance1' placeholder='Operator Insurance Company / Policy Number' value={vehicleAccidentReport.operatorInsurance1} />
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h4'>Operator 2</Header>
                        <Field label='Name' component={TextInput} name='operatorName2' placeholder='Operator Name' value={vehicleAccidentReport.operatorName2} />
                        <Field label='Address' component={TextInput} name='operatorAddress2' placeholder='Operator Address' value={vehicleAccidentReport.operatorAddress2} />
                        <Field label='Phone Number' component={TextInput} name='operatorPhone2' placeholder='Operator Phone Number' value={vehicleAccidentReport.operatorPhone2} />
                        <Field label='License Number/State' component={TextInput} name='operatorLicense2' placeholder='Operator License Number / State' value={vehicleAccidentReport.operatorLicense2} />
                        <Field label="Date of Birth" component={DateInput} date={true} name='operatorDob2' placeholder='Operator Date of Birth' value={vehicleAccidentReport.operatorDob2} />
                        <Field label='Operator Insurance Company/Policy Number'component={TextInput} name='operatorInsurance2' placeholder='Operator Insurance Company / Policy Number' value={vehicleAccidentReport.operatorInsurance2} />
                      </Grid.Column>
                    </Grid>
                  </Segment>

                  <Header>Vehicle Information</Header>
                  <Segment>
                    <Grid stackable columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as='h4'>Colliers Vehicle</Header>
                          <Field label='Vehicle Number' component={TextInput} name='vehicleNumber1' placeholder='Vehicle Number' value={vehicleAccidentReport.vehicleNumber1} />
                          <Form.Group widths='equal'>
                            <Field label='Make/Model' component={TextInput} name='vehicleMakeModel1' placeholder='Vehicle Make / Model' value={vehicleAccidentReport.vehicleMakeModel1} />
                            <Field label='Year' component={TextInput} name='vehicleYear1' placeholder='Vehicle Year' value={vehicleAccidentReport.vehicleYear1} width={6} />
                          </Form.Group>
                          <Field label='License Plate #/State' component={TextInput} name='vehicleLicense1' placeholder='Vehicle License Plate # / State' value={vehicleAccidentReport.vehicleLicense1} />
                          <Field label='Registration Number' component={TextInput} name='vehicleRegistration1' placeholder='Vehicle Registration Number' value={vehicleAccidentReport.vehicleRegistration1} />
                        </Grid.Column>
                        <Grid.Column>
                          <Header as='h4'>Vehicle 2</Header>
                          <Field label='Vehicle Number' component={TextInput} name='vehicleNumber2' placeholder='Vehicle Number' value={vehicleAccidentReport.vehicleNumber2} />
                          <Form.Group widths='equal'>
                            <Field label='Make/Model'  component={TextInput} name='vehicleMakeModel2' placeholder='Vehicle Make / Model' value={vehicleAccidentReport.vehicleMakeModel2} />
                            <Field label='Year' component={TextInput} width={6} name='vehicleYear2' placeholder='Vehicle Year' value={vehicleAccidentReport.vehicleYear2} />
                          </Form.Group>
                          <Field label='License Plate #/State' component={TextInput} name='vehicleLicense2' placeholder='Vehicle License Plate # / State' value={vehicleAccidentReport.vehicleLicense2} />
                          <Field label='Registration Number' component={TextInput} name='vehicleRegistration2' placeholder='Vehicle Registration Number' value={vehicleAccidentReport.vehicleRegistration2} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>

                  <Header>Vehicle Owner Information</Header>
                  <Segment>
                    <Grid stackable columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as='h4'>Colliers Personnel</Header>
                          <Field label='Owner Registered Name' component={TextInput} name='ownerRegisteredName1' placeholder='Owner Registered Name' value={vehicleAccidentReport.ownerRegisteredName1} />
                          <Field label='Owner Address' component={TextInput} name='ownerAddress1' placeholder='Owner Address' value={vehicleAccidentReport.ownerAddress1} />
                          <Field label='Owner Phone Number' component={TextInput} name='ownerPhone1' placeholder='Owner Phone Number' value={vehicleAccidentReport.ownerRegisteredName1} />
                          <Field label='Owner Insurance Company/Policy Number' component={TextInput} name='ownerInsurance1' placeholder='Owner Insurance Company / Policy Number' value={vehicleAccidentReport.ownerInsurance1} />
                        </Grid.Column>
                        <Grid.Column>
                          <Header as='h4'>Vehicle 2</Header>
                          <Field label='Owner Registered Name' component={TextInput} name='ownerRegisteredName2' placeholder='Owner Registered Name' value={vehicleAccidentReport.ownerRegisteredName2} />
                          <Field label='Owner Address' component={TextInput} name='ownerAddress2' placeholder='Owner Address' value={vehicleAccidentReport.ownerAddress2} />
                          <Field label='Owner Phone Number' component={TextInput} name='ownerPhone2' placeholder='Owner Phone Number' value={vehicleAccidentReport.ownerRegisteredName2} />
                          <Field label='Owner Insurance Company/Policy Number' component={TextInput} name='ownerInsurance2' placeholder='Owner Insurance Company / Policy Number' value={vehicleAccidentReport.ownerInsurance2} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>

                  <Header>Passenger Information</Header>
                  <FieldArray name="passengers">
                    {({ fields }) => fields.map((passenger_field_name, i) =>
                      <Segment key={passenger_field_name}>
                        <Header as='h4'>Passenger {i + 1}</Header>
                        <Form.Group widths='equal'>
                          <Field label='Name' component={TextInput} placeholder='Passenger Name' name={`${passenger_field_name}.name`} />
                          <Field label='Phone Number' component={TextInput} placeholder='Passenger Phone Number' name={`${passenger_field_name}.phone`} />
                        </Form.Group>
                        <Field label='Passenger Vehicle' component={TextInput} placeholder='Passenger Vehicle' name={`${passenger_field_name}.vehicle`} />
                        <Field label='Address' component={TextInput} placeholder='Passenger Address' name={`${passenger_field_name}.address`} />
                        <Field label='Injuries' component={TextInput} placeholder='Passenger Injuries' name={`${passenger_field_name}.injuries`} />

                        <div>
                          {values.passengers && values.passengers.length !== 1 && <Button basic color="red" type='button' onClick={() => fields.remove(i)}>Remove</Button>}
                          {values.passengers && values.passengers.length - 1 === i && <Button basic color='green' onClick={() => push('passengers', new VehicleAccidentPassengerFormValues())} type='button'>Add</Button>}
                        </div>
                      </Segment>
                    )}
                  </FieldArray>

                  <Header as='h3'>Witness Information</Header>
                  <FieldArray name='witnesses'>
                    {({ fields }) => fields.map((witness_field_name, i) =>
                      <Segment key={witness_field_name}>
                        <Header as='h4'>Witness {i + 1}</Header>
                        <Form.Group widths='equal'>
                          <Field label='Name' component={TextInput} placeholder='Witness Name' name={`${witness_field_name}.name`} />
                          <Field label='Phone Number' component={TextInput} placeholder='Witness Phone Number' name={`${witness_field_name}.phone`} />
                        </Form.Group>
                        <Field label='Address' component={TextInput} placeholder='Witness Address' name={`${witness_field_name}.address`} />

                        <div>
                          {values.witnesses && values.witnesses.length !== 1 && <Button basic color='red' onClick={() => fields.remove(i)} type='button'>Remove</Button>}
                          {values.witnesses && values.witnesses.length - 1 === i && <Button basic color='green' onClick={() => push('witnesses', new VehicleAccidentWitnessFormValues())} type='button'>Add</Button>}
                        </div>
                      </Segment>
                    )}
                  </FieldArray>

                  <Header as='h3'>Emergency Personnel Information</Header>
                  <Segment>
                    <Field label='Who contacted emergency personnel?' component={TextInput} name='whoContactedEmergency' placeholder='Who contacted emergency personnel?' value={vehicleAccidentReport.whoContactedEmergency} />

                    <div id='emergencyRadios'>
                      <p>Which agencies reponded?</p>
                      <Field component={CheckboxInput} name='respondedPolice' label='Police' type='checkbox' />
                      <Field component={CheckboxInput} name='respondedEmt' label='EMT' type='checkbox' />
                      <Field component={CheckboxInput} name='respondedFire' label='Fire' type='checkbox' />
                    </div>

                    <Form.Group widths='equal'>
                      <Field label='Police Officer Name' component={TextInput} name='policeOfficerName' placeholder='Police Officer Name' value={vehicleAccidentReport.policeOfficerName} />
                      <Field label='Officer/Badge Number' component={TextInput} name='policeBadgeNumber' placeholder='Officer / Badge Number' value={vehicleAccidentReport.policeBadgeNumber} />
                      <Field label='Precinct/Department' component={TextInput} name='policePrecinctDept' placeholder='Precinct / Department' value={vehicleAccidentReport.policePrecinctDept} />
                    </Form.Group>
                    <Field component={CheckboxInput} name='citationIssued' label='Was a citation issued?' type='checkbox' />
                    <RenderIf condition={values.citationIssued}>
                      <Field label='To whom and for what?'component={TextInput} name='citationDesc' placeholder='If yes, to whom and for what?' value={vehicleAccidentReport.citationDesc} />
                    </RenderIf>
                  </Segment>
                  
                  <Segment>
                    <Header>Attach sketch or photos of the scene of the accident</Header>
                    <Form.Group widths="equal">
                      <FieldFileDropZone name="fileArray" accept={[
                        "image/*",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/msword",
                        "application/pdf"
                      ].join(',')} maxFiles={6} />
                    </Form.Group>
                  </Segment>

                  <Button
                    loading={submitting}
                    disabled={ loading || submitting || pristine }
                    floated="right"
                    positive
                    type="submit"
                    content="Submit"
                  />
                  <Button
                    disabled={loading}
                    color='yellow'
                    floated="right"
                    type="button"
                    content="Cancel"
                    onClick={()=>openModal(<ConfirmCancelForm />)}
                    />
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(VehicleAccidentReport);
