import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import IncidentReportList from "./IncidentReportList";
import IncidentReportSearch from "./IncidentReportSearch";

const IncidentReportDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { loadIncidentReports, loadingInitial, setPage, page, totalPages } =
		rootStore.incidentReportStore;

	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadIncidentReports().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadIncidentReports();
	}, [loadIncidentReports]);

	if (loadingInitial && page === 0) {
		return <LoadingComponent content="Loading Incident/Injury Reports..." />;
	} else
		return (
			<>
				<PageTitle title="Incident/Injury Report Dashboard" />

				<IncidentReportSearch />

				<InfiniteScroll
					pageStart={0}
					loadMore={handleGetNext}
					hasMore={!loadingNext && page + 1 < totalPages}
					initialLoad={false}
				>
					<IncidentReportList />
				</InfiniteScroll>
			</>
		);
};

export default observer(IncidentReportDashboard);
