import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const combineDateAndTime = (date: Date, time: Date) => {
    const hours = time.getHours();
    const mins = time.getMinutes();
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return new Date(`${year}-${month}-${day} ${hours}:${mins}:00`);
}

export const onChangeHandler = (setValues: (value: React.SetStateAction<any>) => void, event: React.SyntheticEvent<HTMLElement>, data: any) => {
  setValues((old: any) => ({...old, [data.name]: data.value}));
}

export const isFieldEmpty = (inputValue: any): string | undefined => (inputValue === null || inputValue === undefined || inputValue === "") ? "Required fields cannot be empty." : undefined; 

export const isStringEmpty = (string: string) => {
  if (string === undefined || string === null || string === ''){
    return true;
  }
  return false;
}

export const localizeDateTime = (dateTime: Date): Date => new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000);

export const prettyPrintDate = (date?: Date): string | undefined => {
  if (date){
    date = new Date(date);  // ensure date is actually Date type
    if (date.getFullYear() === 0) return undefined;
    return format(date, 'M/d/yyyy');
  }
  return undefined
}

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const prettyPrintTime = (time: Date): String => formatInTimeZone(time, timezone, 'h:mm aa zzz');

export const prettyPrintDateTime = (date: Date, time?: Date): String => {
  date = new Date(date);  // ensure date is actually Date type
  if (date.getFullYear() === 0) return '';
  
  if (!time) time = date;
  return format(date, 'M/d/yyyy') + ", " + formatInTimeZone(time, timezone, 'h:mm aa zzz');
}

export const to24TimeString = (time: Date | string): string => {
  if (typeof time == 'string') time = new Date(time);
  return formatInTimeZone(time, timezone, 'HH:mm');
}

export const boolToYesNoNA = (variable: boolean): string =>{
  if(variable)
    return "Yes";
  else if(!variable)
    return "No";
  else
    return "N/A";
}

// convert into YYYY/MM/DD HH:MM:SS per iOS/Safari issue - see https://stackoverflow.com/questions/54726314/safari-returns-incorrect-value-for-date-toisostring/54733846#54733846
// use this function to combine a Date object and Time String (expected format HH:MM) into one field, 
export const safariSafeDateIsoString = (date: Date, timeString: string) => {
  return new Date(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${timeString}:00`).toISOString();

}