import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment } from "semantic-ui-react";
import { IIncidentReport } from "../../../app/models/incidentReport";
import { observer } from 'mobx-react-lite';
import { prettyPrintDateTime } from "../../../app/common/util/util";

const IncidentReportListItem: React.FC<{ incidentReport: IIncidentReport }> = ({ incidentReport }) => {

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>Employee Injured: {incidentReport.whoseInjury}</Item.Header>
              <Item.Meta>Submitted By {incidentReport.createdBy}</Item.Meta>
              <Item.Meta>Project: {incidentReport.projNum}</Item.Meta>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment secondary>
        <Icon name="calendar alternate outline" color="green"/> {prettyPrintDateTime(incidentReport.incidentDate, incidentReport.incidentTime)}
      </Segment>
      <Segment clearing>
        <Button
          as={Link}
          to={`/incident-report/${incidentReport.id}`}
          floated="right"
          content="View"
          color="blue"
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(IncidentReportListItem);
