import { observable, action, computed, runInAction } from "mobx";
import { SyntheticEvent } from "react";

import { INearMiss } from "../models/nearMiss";
import agent from "../api/agent";
import { history } from "../..";
import { toast } from "react-toastify";
import { RootStore } from "./rootStore";
import { localizeDateTime } from "../common/util/util";

const PAGE_SIZE = 8;

export default class NearMissStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable nearMissRegistry = new Map<string, INearMiss>();

  @observable nearMiss: INearMiss | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";

  @observable nearMissCount = 0;
  @observable page = 0;
  @observable predicate = new Map<string, string | number | boolean>();
  
  
  @action redefineDatesWithTimezones = (nearMiss: INearMiss) => {
    nearMiss.dateSubmitted = localizeDateTime(new Date(nearMiss.dateSubmitted));
    nearMiss.dateOfIncident = localizeDateTime(new Date(nearMiss.dateOfIncident));
    nearMiss.timeOfIncident = localizeDateTime(new Date(nearMiss.timeOfIncident));
    nearMiss.correctiveActionDate = localizeDateTime(new Date(nearMiss.correctiveActionDate));
  }

  /*************************************
   * begin pagination
   *************************************/
  
  @action setCount = (count: number) => {
    this.nearMissCount = count;
  };

  @action setPage = (page: number) => {
    this.page = page;
  }
  
  /*************************************
   * begin params
   *************************************/
  getPredicate = () => {
    return this.predicate;
  };


  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));
    
    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });
    
    return params;
  }

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearNearMissRegistry = () => {
    this.nearMissRegistry.clear();
    this.page = 0;
  };

  @computed get nearMissesByDefault() {
    return Array.from(this.nearMissRegistry.values()).sort(
      (a, b) =>
        b.dateOfIncident.getTime() - a.dateOfIncident.getTime()
    ); // sort in descending order
  }

  @computed get totalPages() {
    return Math.ceil(this.nearMissCount / PAGE_SIZE);
  }

  /*************************************
  *
  *************************************/
  get isEmpty() {
    return this.nearMissRegistry.size === 0;
  }

  /*************************************
   *
   *************************************/

  @computed get nearMissesByDate() {
    return this.groupNearMissesByDate(
      Array.from(this.nearMissRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupNearMissesByDate(nearMisses: INearMiss[]) {
    const sortedReports = nearMisses.sort(
      (a, b) => b.dateOfIncident.getTime() - a.dateOfIncident.getTime()
    );
    
    return Object.entries(
      sortedReports.reduce((nearMisses, nearMiss) => {
        const date = nearMiss.dateSubmitted.toISOString().split("T")[0];
        nearMisses[date] = nearMisses[date]
          ? [...nearMisses[date], nearMiss]
          : [nearMiss];
        return nearMisses;
      }, {} as { [key: string]: INearMiss[] })
    );

  }


  /*************************************
   *
   *************************************/
  @action clearNearMisses = () => {
    this.nearMissRegistry.clear();
  }

  /*************************************
   *
   *************************************/

  @action loadNearMisses = async () => {
    this.loadingInitial = true;

    try {
      const response = await agent.NearMiss.list(this.axiosParams);
      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);
      runInAction("loading near misses", () => {
        response.data.nearMisses.forEach((nearMiss: INearMiss) => {
          this.redefineDatesWithTimezones(nearMiss);
          
          this.nearMissRegistry.set(nearMiss.id, nearMiss);
        });
        
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load near misses error", () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };
  
  /*************************************
   *
   *************************************/
  
  @action loadNearMiss = async (id: string) => {
    this.loadingInitial = true;
    
    try {
      let nearMiss = await agent.NearMiss.details(id);

      runInAction("getting near miss", () => {
        if (nearMiss) {
          this.redefineDatesWithTimezones(nearMiss);
          this.nearMiss = nearMiss;
          this.nearMissRegistry.set(nearMiss.id, nearMiss);
        }
        this.loadingInitial = false;
      });
      return nearMiss;
    } catch (error) {
      runInAction("get near miss error", () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  @action clearNearMiss = () => {
    this.nearMiss = null;
  };

  /*************************************
   *
   *************************************/

  getNearMiss = (id: string) => {
    return this.nearMissRegistry.get(id);
  };

  /*************************************
   *
   *************************************/

  @action createNearMiss = async (nearMiss: FormData) => {
    this.submitting = true;
    try {
      // TODO test me
      var nm = await agent.NearMiss.create(nearMiss);

      runInAction("create near miss", () => {
        this.nearMissRegistry.set(nm.id, nm);
        this.submitting = false;
      });

      // history.push(`/`);     
      history.push(`/near-miss/${nm.id}`);
      toast.success("New Near Miss Added");
    } catch (error) {
      runInAction("create near miss error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  @action editNearMiss = async (id: string, nearMiss: FormData) => {
    this.submitting = true;

    try {
      var nm: INearMiss = await agent.NearMiss.update(id, nearMiss);
      
      runInAction("editing near miss", () => {
        this.redefineDatesWithTimezones(nm);
        this.nearMissRegistry.set(id, nm);
        this.nearMiss = nm;
        this.submitting = false;
      });
      history.push(`/near-miss/${id}`);
      toast.success('Near Miss edited');
    } catch (error) {
      runInAction("edit near miss error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  @action deleteNearMiss = async (id: string) => {
    this.submitting = true;
    try {
      var sc = await agent.NearMiss.delete(id);
      runInAction("deleting near miss", () => {
        this.submitting = false;
      });
      history.push('/');
      toast.success('Near Miss deleted');
    } catch (error) {
      toast.error("Problem submitting data");
      console.log(error);
    }
  };

}
