import React, { useContext, useEffect, useState } from "react";
import { Segment, Table} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { prettyPrintDate } from "../../../app/common/util/util";
import PageTitle from "../../../app/common/form/PageTitle";

interface DetailParams {
	id: string;
}

const SiteSafetyBriefingDetails: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
	const rootStore = useContext(RootStoreContext);
	const { siteSafetyBriefing, loadSiteSafetyBriefing, loadingInitial } = rootStore.safetyChecklistStore;
	const [ loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		loadSiteSafetyBriefing(match.params.id).then(() => setLoading(false));
	}, [loadSiteSafetyBriefing, match.params.id, history]);

	if (loadingInitial || loading) return <LoadingComponent content="Loading Site Safety Briefing ..." />;

	if (!siteSafetyBriefing) return <h2>Site Safety Briefing Not Found</h2>;

	return (
		<>
			<PageTitle title={`Site Safety Briefing - ${prettyPrintDate(siteSafetyBriefing.createDate)} `} /> 
			<Segment clearing>
      	<Table>
				<Table.Row>
					<Table.Cell>
					Review &amp; Understand CED H&amp;S checklist, and selected site SOP
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.understand ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Vehicle Walk Around complete with front and rear cones in place
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.vehicleWalkAround ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Discuss Job 
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussJobScope ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					At Office with Project Manager
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussAtOffice ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					On-Site with CED Personnel
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussOnSite ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Via Phone Call with Project Manager
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussPhone ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Site Safety Leader from Client
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussFromClient ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Discuss PPE required to complete job scope
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussPPE ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Discuss added human protection and location
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussAddedProtection ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Flagman (Railroad)
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussFlagman ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Watchman
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussWatchman ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Police Traffic Control
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussPoliceTraffic ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Police Safety
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussPoliceSafety ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					N/A
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussNotApplicable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Other (specify)
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussOther ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.discussOther_Explain}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Discuss Working Safely Around Tools and Equipment
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussWorkingSafely ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Discuss Housekeeping and Clean Up
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussHousekeeping ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Inspect Operating Equipment Machines and Operators, Including Vehicle
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.inspectEquipment ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Inspect Tools and Equipment - See attached list before proceeding
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.inspectTools ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Discuss Prior Safety Precautions to be Taken to Address Hazards and Conditions Identified
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.discussPrecautions ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Completed client required JSA/HAS Forms (if applicable)
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.completedClientJSAHASForms ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Identify All Potential Hazards, Significant Objects and Conditions In and Around work Area such as
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyHazards ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Utilities and Power Lines
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyUtilities ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Overhead Structures (overhead building, bridge overpass)
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyOverheadStructures ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Grade Crossing Switches
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyGradeXingSwitches ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Nauseating Smells
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyNauseatingSmells ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Poisonous Plants
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyPoisonousPlants ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Wildlife / Insects
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyWildlife ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Traffic
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyTraffic ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Other (Specify)
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.identifyOther ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.identifyOther_Explain}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Call Project Manager to Discuss Additional On-Site Conditions That Are Potential Hazards, and update H&amp;S checklist
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.callProjectManager ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					No Contact, Call Geographic Leader
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.callGeoLeader ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					No Contact, Call Lisa DeBenedetto
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.callSafetyCoordinator ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					N/A
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.callNotApplicable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
					</Table.Cell>
				</Table.Row>
			</Table>
				
			<Table>
				<Table.Row>
					<Table.Cell>
					Who's on site?
					</Table.Cell>
					<Table.Cell>
						<ul>
							{siteSafetyBriefing.onSitePersonnel.map((x: string) => <li>{x}</li>)}
						</ul>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
						What PPE was used?
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.PPE}
					</Table.Cell>
				</Table.Row>
			</Table>

			<Table>
				<Table.Row>
					<Table.Cell>
					Machete
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.macheteGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.macheteNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Brush hook or Brush axe
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.brushGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.brushNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Pointed shovel
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.pointedShovelGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.pointedShovelNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Post Hole digger
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.postHoleGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.postHoleNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Pry bar
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.pryBarGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.pryBarNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					3-4 lb hammer
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.lightHammerGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.lightHammerNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					6-8 lb sledge hammer
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.sledgeHammerGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.sledgeHammerNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Mason hammer
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.masonHammerGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.masonHammerNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Cold chisels
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.coldChiselsGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.coldChiselsNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					100'-200' cloth tape
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.clothTapeGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.clothTapeNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					25' retractable tape
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.retractableTapeGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.retractableTapeNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					6' folding rule
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.foldingRuleGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.foldingRuleNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Measuring wheel
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.measuringWheelGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.measuringWheelNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Stake bag or bucket
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.stakeBagGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.stakeBagNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Lath bag
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.lathBagGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.lathBagNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Manhole opening device
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.manholeHookGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.manholeHookNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Bull pin
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.bullPinGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.bullPinNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
						Broom
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.broomGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.broomNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Cordless drill
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.cordlessDrillGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.cordlessDrillNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Cordless grinder
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.cordlessGrinderGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.cordlessGrinderNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Socket / Wrench set
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.socketWrenchsetGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.socketWrenchsetNotes}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
					Punch set
					</Table.Cell>
					<Table.Cell>
						{siteSafetyBriefing.punchSetGood ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						{siteSafetyBriefing.punchSetNotes}
					</Table.Cell>
				</Table.Row>
			</Table>
			</Segment>
		</>
	);
};

export default observer(SiteSafetyBriefingDetails);