import React, { Fragment, useContext } from "react";
import { Item, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import NearMissListItem from "./NearMissListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";

const NearMissList: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { nearMissesByDefault } = rootStore.nearMissStore;

	return (
		<Grid columns={2} stackable>
			<Fragment>
				{nearMissesByDefault.map((nearMiss) => (
					<Grid.Column key={nearMiss.id}>
						<Segment clearing>
							<Item.Group>
								<NearMissListItem key={nearMiss.id} nearMiss={nearMiss} />
							</Item.Group>
						</Segment>
					</Grid.Column>
				))}
			</Fragment>
		</Grid>
	);
};

export default observer(NearMissList);
