import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { history } from "../..";
import { toast } from "react-toastify";

import { IServerBoundSiteSafety } from "../models/siteSafetyBriefing";
import { IVehicle } from "../models/vehicle";
import { IProject } from "../models/project";

import { AuthProvider } from "../layout/AuthProvider";
import { IDiscipline } from "../models/discipline";
import { ISituation } from "../models/situation";
import { IUser } from "../models/user";
import { IChemicalList } from "../models/chemicalInv";
import { IServerBoundFallHaz } from "../models/fallHaz";
import { IServerBoundTrafficControl } from "../models/trafficControl";
import { IServerBoundConfinedSpaceA } from "../models/confinedSpaceA";
import { URLSearchParams } from "url";
import { IServerBoundAerialLiftChecklist } from "../models/aerialLiftChecklist";
import { IServerBoundComment } from "../models/comment";

/*
NOTE: We tested out a fetch-based api call to try to alleviate the 600MiB SC object problem.
This approach did not seem to be any better than using Axios. It's still up in the air as to whether or not it's a client or server problem.
Something seems to be truncating the JSON body from the response but the response is too big to make any observations about its contents.

Further investigation is necessary. Alternatively, slimming down the response body (somehow) would be a power-move.
*/

// import { fil } from "date-fns/locale";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  async (config: AxiosRequestConfig<any>) => {

    const token = (await AuthProvider.getAccessToken()).accessToken;

    //console.log("accessToken: " + token);

    if (token) {
      if(config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config.headers = {
          Authorization: `Bearer ${token}`
        };
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error - make sure API is running!");
  }

  const { status, data, config } = error.response;

  if (status === 404) {
    history.push("/notfound");
  }

  if (
    status === 400 &&
    config.method === "get" &&
    data.errors.hasOwnProperty("id")
  ) {
    history.push("/notfound");
  }

  if (status === 500) {
    toast.error("Server error - check the terminal for more info!");
  }

  throw error;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
};

//--------------------------

const SafetyChecklist = {
  list: (params: URLSearchParams): Promise<any> => axios.get(`/safetychecklist/safety-checklists`, { params: params }),
  create: (safetyChecklist: FormData) => requests.post("/safetychecklist", safetyChecklist),
  details: (id: string) => requests.get(`/safetychecklist/${id}`),
  update: (id: string, safetyChecklist: FormData) => requests.put(`safetychecklist/${id}`, safetyChecklist),
  delete: (id: string) => requests.del(`/safetychecklist/${id}`),
  newChecklistInfo: (): any => requests.get("/safetychecklist/newChecklistInfo"),
  addChemicalList: (chemlist: IChemicalList) => requests.post(`/safetychecklist/${chemlist.checklist_id}/chemlist`, chemlist.list),
  addFallHaz: (fallHaz: IServerBoundFallHaz) => requests.post(`/safetychecklist/${fallHaz.checklist_id}/fallhazard`, fallHaz.fallhaz),
  fallHazardDetail:(id: string) => requests.get(`/fallhazard/${id}/`),
  addTrafficControl: (traffic: IServerBoundTrafficControl) => requests.post(`/safetychecklist/${traffic.checklist_id}/trafficcontrol`, traffic.trafficControl),
  trafficControlDetail:(id: string) => requests.get(`/trafficcontrol/${id}/`),
  addConfinedSpaceA: (space: IServerBoundConfinedSpaceA) => requests.post(`/safetychecklist/${space.checklist_id}/confinedspace`, space.confinedSpaceA),
  confinedSpaceDetail:(id: string) => requests.get(`/confinedspace/${id}/`),
  addSiteSafety: (site: IServerBoundSiteSafety) => requests.post(`/safetychecklist/${site.checklist_id}/sitesafetybriefing`, site.siteSafety),
  siteSafetyDetail:(id: string) => requests.get(`/sitesafetybriefing/${id}/`),
  addAerialLift: (aerial: IServerBoundAerialLiftChecklist) => requests.post(`/safetychecklist/${aerial.checklist_id}/aeriallift`, aerial.aerialLiftChecklist),
  aerialLiftDetail: (id: string) => requests.get(`/aeriallift/${id}/`),
  addComment: (commentWrapper: IServerBoundComment) => requests.post(`/safetychecklist/${commentWrapper.checklist_id}/comment/`, commentWrapper.comment),
  getGuid: (params: URLSearchParams): Promise<any> => axios.get(`/safetychecklist/getGuid`, {params: params}),
}

//--------------------------

const Notification = {
  list: (params: URLSearchParams): Promise<any> =>
    axios.get(`/notification/notifications`, { params: params }),
  details: (id: string) => requests.get(`/notification/${id}`),
  update: (id:string, nearMiss: FormData) => requests.put(`notification/${id}`, nearMiss),
  delete: (id: string) => requests.del(`/notification/${id}`),
  acknowledge: (id: string) => requests.put(`/notification/${id}/acknowledge`, {}),
  unreadCount: (): Promise<any> => axios.get('notification/unreadCount', {}),
  create: (notification: FormData) => requests.post("/notification", notification),
}

//--------------------------

const FieldSafetyAudit = {
  list: (params: URLSearchParams): Promise<any> =>
    axios.get(`/fieldsafetyaudit/field-safety-audits`, { params: params }),
  create: (fieldSafetyAudit: FormData) => requests.post("/fieldsafetyaudit", fieldSafetyAudit),
  details: (id: string) => requests.get(`/fieldsafetyaudit/${id}`),
  update: (id:string, fieldSafetyAudit: FormData) =>
    requests.put(`fieldsafetyaudit/${id}`, fieldSafetyAudit),
  delete: (id: string) => requests.del(`/fieldsafetyaudit/${id}`),
  newChecklistInfo: (): any => requests.get("/fieldsafetyaudit/newChecklistInfo"),
  modalInfo: (): any => requests.get("/fieldsafetyaudit/modalInfo"),
  getChartData: (): any => requests.get("fieldsafetyaudit/chartData"),
  sslApproval: (id: string) => requests.put(`/fieldsafetyaudit/${id}/sslApproval`, {}),
  markComplete: (id: string) => requests.put(`/fieldsafetyaudit/${id}/markComplete`, {}),
}

//--------------------------

const Vehicle = {
  list: (): Promise<IVehicle[]> => requests.get("/vehicle"),
  details: (id: number) => requests.get(`/vehicle/${id}`),
};

//--------------------------

const CorrectiveAction = {
  details: (id: string) => requests.get(`/correctiveaction/${id}`),
  update: (id: string, corAct: FormData) => requests.put(`correctiveaction/${id}`, corAct),
};

//--------------------------

const VehicleInspection = {
  list: (params: URLSearchParams): Promise<any> =>
    axios.get(`/vehicleinspection/vehicle-inspections`, { params: params }),
  report: (params: URLSearchParams): Promise<any> =>
    axios.get(`/vehicleinspection/vehicle-inspection-report`, { params: params }),
  create: (vehicleInspection: FormData) => requests.post("/vehicleinspection", vehicleInspection),
  details: (id: string) => requests.get(`/vehicleinspection/${id}`),
  update: (id: string, vehicleInspection: FormData) => requests.put(`/vehicleinspection/${id}`, vehicleInspection),
  delete: (id: string) => requests.del(`/vehicleinspection/${id}`)
};

//--------------------------

const NearMiss = {
  list: (params: URLSearchParams): Promise<any> =>
    axios.get(`/nearmiss/near-misses`, { params: params }),
  create: (nearMiss: FormData) => requests.post("/nearmiss", nearMiss),
  details: (id: string) => requests.get(`/nearmiss/${id}`),
  update: (id:string, nearMiss: FormData) => requests.put(`nearmiss/${id}`, nearMiss),
  delete: (id: string) => requests.del(`/nearmiss/${id}`)
}



//--------------------------

const Discipline = {
  list: (): Promise<IDiscipline[]> => requests.get("/discipline"),
  safetyList: (): Promise<IDiscipline[]> => requests.get("discipline/safety"),
  details: (id: string) => requests.get(`/discipline/${id}`),
}

//--------------------------

const Situation = {
  list: (): Promise<ISituation[]> => requests.get("/situation"),
  details: (id: string) => requests.get(`/situation/${id}`),
  newSafetyInfo: (): any => requests.get("/situation/newSafetyInfo"),
}

//--------------------------

const Project = {
  list: (): Promise<IProject[]> => requests.get("/project"),
  exists: (projectNumber: string) => requests.get(`/project/${projectNumber}/exists`),
}

//--------------------------

const Worker = {
  list: (): Promise<IUser[]> => requests.get("/worker"),
  sslList: (): Promise<IUser[]> => requests.get("/worker/siteSafetyLeaders"),
  getGroups: (): Promise<string[]> => requests.get("/worker/getGroups"),
}

//--------------------------

const VehicleAccidentReport = {
  list: (params: URLSearchParams): Promise<any> =>
    axios.get(`/vehicleAccidentReport/vehicle-accident-reports`, { params: params }),
  details: (id: string) => requests.get(`/vehicleAccidentReport/${id}`),
  create: (vehicleAccidentReport: FormData) => requests.post('/vehicleAccidentReport', vehicleAccidentReport),
  update: (id:string, vehicleAccidentReport: FormData) =>
    requests.put(`/vehicleAccidentReport/${id}`, vehicleAccidentReport),
  delete: (id: string) => requests.del(`/vehicleAccidentReport/${id}`)
}

//--------------------------

const IncidentReport = {
  list: (params: URLSearchParams): Promise<any> =>
    axios.get(`/incidentreport/incident-reports`, { params: params }),
  details: (id: string) => requests.get(`/incidentreport/${id}`),
  create: (incidentReport: FormData) => requests.post('/incidentreport', incidentReport),
  update: (incidentReport: FormData) =>
    requests.put(`/incidentreport/${incidentReport.get('id')}`, incidentReport),
  delete: (id: string) => requests.del(`/incidentreport/${id}`),
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  Vehicle,
  VehicleInspection,
  NearMiss,
  SafetyChecklist,
  FieldSafetyAudit,
  Discipline,
  Situation,
  Project,
  Worker,
  VehicleAccidentReport,
  IncidentReport,
  CorrectiveAction,
  Notification
};

