import React, { useContext, Fragment, useState, useEffect } from "react";
import NavBar from "../../features/nav/NavBar";
import Footer from "../../features/footer/Footer";
import { Container } from "semantic-ui-react";

import { observer } from "mobx-react-lite";
import "mobx-react-lite/batchingForReactDom"; // https://stackoverflow.com/questions/61654633/mobx-react-console-warning-related-observer

import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";

import Home from "../../features/home/Home";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import AzureAD, { IAzureADFunctionProps } from "react-aad-msal";
import { AuthProvider } from "./AuthProvider";

import SafetyChecklistForm from "../../features/safety/forms/SafetyChecklistForm";
import SafetyChecklistDetails from "../../features/safety/details/SafetyChecklistDetails";
import SafetyChecklistDashboard from "../../features/safety/dashboard/SafetyChecklistDashboard";
import SiteSafetyDetails from "../../features/safety/details/SiteSafetyDetails";
import TrafficControlDetails from "../../features/safety/details/TrafficControlDetails";
import FallHazDetails from "../../features/safety/details/FallHazDetails";
import ConfinedSpaceADetails from "../../features/safety/details/ConfinedSpaceADetails";
import AerialLiftDetails from "../../features/safety/details/AerialLiftDetails";

import FieldSafetyAuditForm from "../../features/fsa/form/FieldSafetyAuditForm";
import FieldSafetyAuditDetails from "../../features/fsa/details/FieldSafetyAuditDetails";
import FieldSafetyAuditDashboard from "../../features/fsa/dashboard/FieldSafetyAuditDashboard";

import NearMissForm from "../../features/nearmiss/form/NearMissForm";
import NearMissDetails from "../../features/nearmiss/details/NearMissDetails";
import NearMissDashboard from "../../features/nearmiss/dashboard/NearMissDashboard";


import VehicleInspectionForm from "../../features/vehicle/form/VehicleInspectionForm";
// import VehicleInspectionList from "../../features/vehicle/dashboard/VehicleInspectionList";
import VehicleInspectionDashboard from "../../features/vehicle/dashboard/VehicleInspectionDashboard";
import VehicleInspectionDetails from "../../features/vehicle/details/VehicleInspectionDetails";

import VehicleAccidentReport from "../../features/vehicleAccidentReport/form/VehicleAccidentReportForm";
import VehicleAccidentReportDashboard from "../../features/vehicleAccidentReport/dashboard/VehicleAccidentReportDashboard";
import VehicleAccidentReportDetails from "../../features/vehicleAccidentReport/details/VehicleAccidentReportDetails";

import ModalContainer from "../common/modals/ModalContainer";

import { RootStoreContext } from "../stores/rootStore";
import IncidentInjuryReportForm from "../../features/incidentReport/form/IncidentReportForm";
import IncidentReportDashboard from "../../features/incidentReport/dashboard/IncidentReportDashboard";
import IncidentReportDetails from "../../features/incidentReport/details/IncidentReportDetails";
import CorrectiveActionForm from "../../features/fsa/form/CorrectiveActionForm";
import NotificationDashboard from "../../features/notification/dashboard/NotificationDashboard";
import NotificationDetails from "../../features/notification/details/NotificationDetails";
import { NotificationSearchFormValues } from "../models/notification";

const App: React.FC<RouteComponentProps> = ({ location }) => {
	const rootStore = useContext(RootStoreContext);
	const { setAccountInfo, accountInfoUserEmail } = rootStore.userStore;
	const { 
        setPredicate, 
        loadUserNotifications, 
        clearNotificationRegistry, 
        getPredicate,
    } = rootStore.notificationStore;
	const { userGroups, loadUserGroups } = rootStore.generalStore;

	const [notificationSearch, setNotificationSearch] = useState(new NotificationSearchFormValues());

	const createUserSearchParams = () => {
        var testData = [accountInfoUserEmail,];
        setNotificationSearch({...notificationSearch, users: testData as string[] });
		setPredicate("users", JSON.stringify(testData));
	};

	
	/*************************************
	 * Render the selected filters
	 *************************************/
	const loadStorePredicate = () => {
		let storePred = getPredicate();
		
        let usersStr: string | undefined = storePred.get("users")?.toString().replace('[', '').replace(']', '');
        let usersArray: string[] | undefined = usersStr?.split(',');

		let searchVals = new NotificationSearchFormValues();
		if (usersArray !== undefined) searchVals.users = usersArray;

		setNotificationSearch(searchVals);
	}

	useEffect(() => {
		if(accountInfoUserEmail !== undefined && accountInfoUserEmail !== null && accountInfoUserEmail !== ""){
			loadStorePredicate();
			createUserSearchParams();
			clearNotificationRegistry();
			loadUserNotifications();
			// TODO I don't love this, there's gotta be a better way to check....should it really only check once anyway?
			if(userGroups.length === 0){
				loadUserGroups();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountInfoUserEmail]);

	return (
		<AzureAD provider={AuthProvider} forceLogin={true}>
			{({ login, logout, accountInfo, authenticationState, error }: IAzureADFunctionProps) => {
				setAccountInfo(accountInfo!);

				return (
					<Fragment>
						<ModalContainer />
						<ToastContainer position="bottom-right" />
						<NavBar login={login} logout={logout} authenticationState={authenticationState} />

						<Container id="main-content">
							<Switch>
								<Route exact path="/" component={Home} />

								<Route exact path="/safety-checklist/:id" component={SafetyChecklistDetails} />
								<Route exact path="/safety-checklists" component={SafetyChecklistDashboard} />
								<Route
									key={location.key}
									exact path={["/new-safety-checklist/:projectNumber?", "/safety-checklist/manage/:id", "/safety-checklist/template/:templateId?"]}
									component={SafetyChecklistForm}
								/>
								<Route exact path="/site-safety-briefing/:id" component={SiteSafetyDetails} />
								<Route exact path="/fall-hazard-assessment/:id" component={FallHazDetails} />
								<Route exact path="/traffic-control-assessment/:id" component={TrafficControlDetails} />
								<Route exact path="/confined-space-assessment/:id" component={ConfinedSpaceADetails} />
								<Route exact path="/aerial-lift-assessment/:id" component={AerialLiftDetails} />

								<Route exact path="/field-safety-audit/:id" component={FieldSafetyAuditDetails} />
								<Route exact path="/field-safety-audits" component={FieldSafetyAuditDashboard} />
								<Route
									key={location.key}
									exact path={["/new-field-safety-audit/:safetyChecklist_id?", "/field-safety-audit/manage/:id"]}
									component={FieldSafetyAuditForm}
								/>
								<Route
									key={location.key}
									exact path={["/corrective-action/manage/:id"]}
									component={CorrectiveActionForm}
								/>

								<Route exact path="/vehicle-inspection/:id" component={VehicleInspectionDetails} />
								<Route exact path="/vehicle-inspections" component={VehicleInspectionDashboard} />
								<Route
									key={location.key}
									exact path={["/new-vehicle-inspection", "/vehicle-inspection/manage/:id"]}
									component={VehicleInspectionForm}
								/>

								<Route exact path="/near-miss/:id" component={NearMissDetails} />
								<Route exact path="/near-misses" component={NearMissDashboard} />
								<Route 
									key={location.key} 
									exact path={["/new-near-miss", "/near-miss/manage/:id"]} 
									component={NearMissForm}
								/>

								<Route exact path="/vehicle-accident-report/:id" component={VehicleAccidentReportDetails} />
								<Route exact path="/vehicle-accident-reports" component={VehicleAccidentReportDashboard} />
								<Route
									key={location.key}
									exact path={["/new-vehicle-accident-report", "/vehicle-accident-report/manage/:id"]}
									component={VehicleAccidentReport}
								/>
								
								<Route exact path="/incident-report/:id" component={IncidentReportDetails} />
								<Route exact path="/incident-reports" component={IncidentReportDashboard} />
								<Route
									key={location.key}
									exact path={["/new-incident-report", "/incident-report/manage/:id"]}
									component={IncidentInjuryReportForm}
								/>
								
								<Route exact path="/notification/:id" component={NotificationDetails} />
								<Route exact path="/notifications" component={NotificationDashboard} />
								
								<Route component={NotFound} />
							</Switch>
						</Container>
						<div className="margin_bottom_40"></div>
						<div className="margin_bottom_40"></div>
						<Footer />
					</Fragment>
				);
			}}
		</AzureAD>
	);
};

export default withRouter(observer(App));
