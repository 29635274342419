import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Item, Grid, Segment } from "semantic-ui-react";

import { RootStoreContext } from "../../../app/stores/rootStore";
import VehicleAccidentReportListItem from "../dashboard/VehicleAccidentReportListItem";

const VehicleAccidentReportList: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { vehicleAccidentReportsByDefault } = rootStore.vehicleAccidentReportStore;

	return (
		<Grid columns={2} stackable>
			<Fragment>
				{vehicleAccidentReportsByDefault.map((vehicle) => (
					<Grid.Column key={vehicle.id}>
						<Segment clearing>
							<Item.Group>
								<VehicleAccidentReportListItem key={vehicle.id} report={vehicle} />
							</Item.Group>
						</Segment>
					</Grid.Column>
				))}
			</Fragment>
		</Grid>
	);
};

export default observer(VehicleAccidentReportList);
