import React, { useContext, useState } from "react";
import {Form, Button, Dropdown, DropdownProps, Segment, Grid} from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { observer } from "mobx-react-lite";
import { OnChange } from "react-final-form-listeners";

import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import { months, years } from "../../../app/common/options/datesOptions";
import { IncidentReportSearchFormValues } from "../../../app/models/incidentReport";

/*************************************
 *
 *************************************/

const IncidentReportSearch = () => {
	const rootStore = useContext(RootStoreContext);
	const {setPredicate, clearPredicate, loadIncidentReports, clearIncidentReportRegistry} = rootStore.incidentReportStore;

	const [incidentReportSearch, setIncidentReportSearch] = useState(new IncidentReportSearchFormValues());

	const handleMonthsChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setIncidentReportSearch({...incidentReportSearch, months: data.value as number[]});
		setPredicate("months", JSON.stringify(data.value));
	};
	const handleYearsChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setIncidentReportSearch({...incidentReportSearch, years: data.value as number[]});
		setPredicate("years", JSON.stringify(data.value));
	};

	/*************************************
	 *
	 *************************************/
	const handleFinalFormSubmit = (values: IncidentReportSearchFormValues) => {
		clearIncidentReportRegistry();
		loadIncidentReports();
	};

	/*************************************
	 *
	 *************************************/
	const resetSearch = (form: any) => {
		setIncidentReportSearch(new IncidentReportSearchFormValues());
		clearPredicate();
		form.reset();
		clearIncidentReportRegistry();
		loadIncidentReports();
	};

	return (
		<Segment clearing>
			<FinalForm
				onSubmit={handleFinalFormSubmit}
				initialValues={incidentReportSearch}
				render={({ handleSubmit, form }) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group widths="equal">
							<Field
								name="who"
								placeholder="Search by Whose Injured"
								component={TextInput}
								value={incidentReportSearch.who}
							/>
							<OnChange name="who">
								{(value, previous) => {
									setIncidentReportSearch({...incidentReportSearch, who: value});
									setPredicate("who", JSON.stringify(value));
								}}
							</OnChange>
							<Dropdown
								name="months"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Month"
								onChange={handleMonthsChange}
								options={months}
								value={incidentReportSearch.months}
							/>
							<Dropdown
								name="years"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Year"
								onChange={handleYearsChange}
								options={years}
								value={incidentReportSearch.years}
							/>
						</Form.Group>

						<Grid>
							<Grid.Column textAlign="center">
								<Button positive type="submit" content="Search" />
								<Button
									color="red"
									type="button"
									content="Reset"
									onClick={() => resetSearch(form)}
								/>
							</Grid.Column>
						</Grid>
					</Form>
				)}
			/>
		</Segment>
	);
};

export default observer(IncidentReportSearch);
