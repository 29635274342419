import { IDiscipline, DisciplineFormValues } from "./discipline";

export interface INearMissEnvelope {
  nearMisses: INearMiss[];
  nearMissCount: number;
}

export interface INearMiss {
  id: string;
  submitterEmail: string;
  office: string;
  discipline: IDiscipline;
  dateSubmitted: Date;
  lastModified?: Date;

  dateOfIncident: Date;
  timeOfIncident: Date;
  location: string;
  weather: string;
  witnesses: string;
  description: string;
  contributingFactors: string;
  preventions: string;
  recommendedCorrectiveActions: string;
  personnelResponsibleEmail: string;
  correctiveActionDate: Date;
  supervisorEmail: string;
  comments: string;
  files: [];
  deleted: boolean;
}

export class NearMissFormValues {
  id: string = '';
  submitterEmail: string = '';
  office: string = '';
  discipline: IDiscipline = new DisciplineFormValues();
  dateSubmitted: Date = new Date();
  lastModified: Date | undefined = undefined;

  dateOfIncident: Date = new Date();
  timeOfIncident: Date = new Date();
  location: string = '';
  weather: string = '';
  witnesses: string = '';
  description: string = '';
  contributingFactors: string = '';
  preventions: string = '';
  recommendedCorrectiveActions: string = '';
  personnelResponsibleEmail: string = '';
  correctiveActionDate: Date = new Date();
  supervisorEmail: string = '';
  comments: string = '';
  files: [] = [];

  constructor(init?: NearMissFormValues) {
    Object.assign(this, init);
  }
}

export interface INearMissSearch {
  disciplines: string[];
  months: number[];
  years: number[];
}
export class NearMissSearchFormValues implements INearMissSearch {
  disciplines: string[] = [];
  months: number[] = [];
  years: number[] = [];

  constructor(init?: INearMissSearch) {
    Object.assign(this, init);
  }
}