import { IVehicleAccidentPassenger, VehicleAccidentPassengerFormValues } from "./vehicleAccidentPassenger";
import { IVehicleAccidentWitness, VehicleAccidentWitnessFormValues } from "./vehicleAccidentWitness";
import { formatInTimeZone } from 'date-fns-tz';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export interface IVehicleAccidentReportEnvelope {
    vehicleAccidentReports: IVehicleAccidentReport[];
    vehicleAccidentReportCount: number;
}

export interface IVehicleAccidentReport {
    id: string;
    dateTime: Date;
    streetIntersection: string;
    city: string;
    state: string;
    weatherConditions: string;
    accidentDescription: string;
    whoWasInjured: string;
    treatmentReceived: string;

    // vehicle operator details
    operatorName1: string;
    operatorName2: string;
    operatorAddress1: string;
    operatorAddress2: string;
    operatorPhone1: string;
    operatorPhone2: string;
    operatorLicense1: string;
    operatorLicense2: string;
    operatorDob1: Date;
    operatorDob2: Date | null;
    operatorInsurance1: string;
    operatorInsurance2: string;

    // vehicle info
    vehicleNumber1: string;
    vehicleNumber2: string;
    vehicleMakeModel1: string;
    vehicleMakeModel2: string;
    vehicleYear1: string;
    vehicleYear2: string;
    vehicleLicense1: string;
    vehicleLicense2: string;
    vehicleRegistration1: string;
    vehicleRegistration2: string;

    // owner info
    ownerRegisteredName1: string;
    ownerRegisteredName2: string;
    ownerAddress1: string;
    ownerAddress2: string;
    ownerPhone1: string;
    ownerPhone2: string;
    ownerInsurance1: string;
    ownerInsurance2: string;

    // emergency personnel info
    whoContactedEmergency: string;
    respondedPolice: boolean;
    respondedEmt: boolean;
    respondedFire: boolean;
    policeOfficerName: string;
    policeBadgeNumber: string;
    policePrecinctDept: string;
    citationIssued: boolean;
    citationDesc: string;

    passengers?: IVehicleAccidentPassenger[];
    witnesses?: IVehicleAccidentWitness[];
    files: [];

    createdDate: Date;
    createdBy: string;

    deleted: boolean;

    supervisorEmail?: string;
}

export interface IVehicleAccidentReportFormValues extends Partial<IVehicleAccidentReport> {
    time: Date | null;
    date: Date | null;
    anyInjuries: boolean;
}

export class VehicleAccidentReportFormValues implements IVehicleAccidentReportFormValues {
    id?: string = undefined;
    date: Date = new Date();
    time: Date = new Date();
    dateTime?: Date = undefined;
    streetIntersection: string = "";
    cityState: string = "";
    city: string = "";
    state: string = "";
    weatherConditions: string = "";
    accidentDescription: string = "";
    anyInjuries: boolean = false;
    whoWasInjured: string = "";
    treatmentReceived: string = "";

    // vehicle operator details
    operatorName1: string = "";
    operatorName2: string = "";
    operatorAddress1: string = "";
    operatorAddress2: string = "";
    operatorPhone1: string = "";
    operatorPhone2: string = "";
    operatorLicense1: string = "";
    operatorLicense2: string = "";
    operatorDob1: Date = new Date();
    operatorDob2: Date | null = null;
    operatorInsurance1: string = "";
    operatorInsurance2: string = "";

    // vehicle info
    vehicleNumber1: string = "";
    vehicleNumber2: string = "";
    vehicleMakeModel1: string = "";
    vehicleMakeModel2: string = "";
    vehicleYear1: string = "";
    vehicleYear2: string = "";
    vehicleLicense1: string = "";
    vehicleLicense2: string = "";
    vehicleRegistration1: string = "";
    vehicleRegistration2: string = "";

    // owner info
    ownerRegisteredName1: string = "";
    ownerRegisteredName2: string = "";
    ownerAddress1: string = "";
    ownerAddress2: string = "";
    ownerPhone1: string = "";
    ownerPhone2: string = "";
    ownerInsurance1: string = "";
    ownerInsurance2: string = "";

    // emergency personnel info
    whoContactedEmergency: string = "";
    respondedPolice: boolean = false;
    respondedEmt: boolean = false;
    respondedFire: boolean = false;
    policeOfficerName: string = "";
    policeBadgeNumber: string = "";
    policePrecinctDept: string = "";
    citationIssued: boolean = false;
    citationDesc: string = "";

    passengers?: IVehicleAccidentPassenger[] = [new VehicleAccidentPassengerFormValues()];
    witnesses?: IVehicleAccidentWitness[] = [new VehicleAccidentWitnessFormValues()];

    createdDate: Date = new Date();
    fileArray: [] = [];

    constructor(init?: IVehicleAccidentReportFormValues) {
        if (init && init.dateTime) {
            init.time = init.dateTime;
            init.date = init.dateTime;
        }
        if(init && init.whoWasInjured){
            init.anyInjuries = true;
        }
        Object.assign(this, init);
    }
}

export interface IVehicleAccidentReportSearch {
    months: number[];
    years: number[];
    opName: string;
    desc: string;
}
export class VehicleAccidentReportSearchFormValues implements IVehicleAccidentReportSearch {
    months: number[] = [];    
    years: number[] = [];    
    opName: string = '';
    desc: string = '';
    
    constructor(init?: IVehicleAccidentReportSearch) {
        Object.assign(this, init);
    }
}