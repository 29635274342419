import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label, Select } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<number, HTMLElement>,
    FormFieldProps {}

const NumberSelectInput: React.FC<IProps> =  ({
    input,
    width,
    label,
    clearable,
    search,
    options,
    placeholder,
    meta: { touched, error }
  }) => {    
      return (
        <Form.Field error={touched && !!error} width={width}>
        <label>{label}</label>
        <Select 
          value={input.value}
          search={search}
          clearable={clearable}
          onChange={(e, data) => input.onChange(data.value)}
          placeholder={placeholder}
          options={options} 
        />
        {touched && error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
      </Form.Field>    )
}



export default NumberSelectInput
