import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import VehicleAccidentList from "./VehicleAccidentReportList";
import VehicleAccidentSearch from "./VehicleAccidentReportSearch";

const VehicleAccidentReportDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { 
		loadReports, 
		loadingInitial, 
		setPage, 
		page, 
		totalPages 
	} = rootStore.vehicleAccidentReportStore;

	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadReports().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadReports();
	}, [loadReports]);

	if (loadingInitial && page === 0) return <LoadingComponent content="Loading Vehicle Accident Reports..." />;
	else
		return (
			<>
				<PageTitle title="Vehicle Accident Report Dashboard" />

				<VehicleAccidentSearch />

				<InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false}>
					<VehicleAccidentList />
				</InfiniteScroll>
			</>
		);
};

export default observer(VehicleAccidentReportDashboard);
