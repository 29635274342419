import { IfsaQuestionGroup } from '../../models/fsaQuestion'

export const fsaQuestions: IfsaQuestionGroup[] = [
    {
        header: 'Administrative',
        propName: 'administrative',
        questions: [
            { key: 1, question: 'H&S checklist or Site Specific HASP completed, available, and acknowledged by all on site' },
            { key: 2, question: 'Employees trained to conduct assigned tasks' },
            { key: 3, question: 'Applicable SOPs understood' },
            { key: 4, question: 'Medical Surveillance is completed' },
            { key: 5, question: 'OSHA postings and logs posted or available' },
            { key: 6, question: 'Competent person(s) assigned by contractor where required' },
            { key: 7, question: 'Documented site inspections conducted by contractor on a regular basis' },
            { key: 8, question: 'Near misses properly reported in a timely fashion' }
        ]
    },
    {
        header: 'Electrical (only if personnel are using power tools/equipment)',
        propName: 'electrical',
        questions: [
            { key: 1, question: 'GFCI usage throughout the jobsite, or Assured Equip Grounding Conductor Program' },
            { key: 2, question: 'Construction rated conductors, prongs intact, and protected from damage' },
            { key: 3, question: 'Portable generators grounded per NEC requirements' },
            { key: 4, question: 'Temporary lighting and site electricity wired on separate circuits' },
            { key: 5, question: 'Electrical panels labeled. Completely enclosed, free of debris' },
            { key: 6, question: 'Are terminal boxes equipped with required covers? Circuits labeled?' },
            { key: 7, question: 'Temporary lighting protected from damage' }
        ]
    },
    {
        header: 'Emergencies',
        propName: 'emergencies',
        questions: [
            { key: 1, question: 'Emergency evacuation plan posted and/or communicated to site personnel' },
            { key: 2, question: 'First aid kit provided, properly stocked' },
            { key: 3, question: 'Emergency telephone numbers posted' },
            { key: 4, question: 'Spill kit available, including PPE' },
            { key: 5, question: 'Emergency exits & routes kept free of trip & slip hazard, free of obstructions' }
        ]
    },
    {
        header: 'Excavation and Trenching (only if personnel must enter or work adjacent to excavations and/or trenches)',
        propName: 'excavationTrenching',
        questions: [
            { key: 1, question: 'Employees protected from cave-ins & loose rock/soil that could roll into the excavation' },
            { key: 2, question: 'Spoils, materials & equipment set back at least 2 feet from the edge of the excavation' },
            { key: 3, question: 'One call. Utility locations identified and precautions taken to ensure contact does not occur' },
            { key: 4, question: 'Precautions taken to protect employees from water accumulation. Hoses braced from tipping' },
            { key: 5, question: 'Surface water or runoff diverted /controlled to prevent accumulation in the excavation' },
            { key: 6, question: 'Air in the excavation tested for oxygen deficiency, combustibles, other contaminants' },
            { key: 7, question: 'Exit (i.e. ladder, sloped wall) no further than 08 feet from ANY employee' },
            { key: 8, question: 'Combustion engines outside of excavation' },
            { key: 9, question: 'Excavation inspected and deemed safe by construction Competent Person' }
        ]
    },
    {
        header: 'Fall Protection (only if personnel are exposed to fall hazards)',
        propName: 'fallProtection',
        questions: [
            { key: 1, question: 'Anchorage secured to a structural member capable of supporting 5,000 lbs of force' },
            { key: 2, question: 'Anchorage placed above the user, not shared with other devices (i.e. lifting blocks)' },
            { key: 3, question: 'Free of damage' },
            { key: 4, question: 'Connections rated for fall protection. Double snap hooks' },
            { key: 5, question: 'System deployment distance less than the distance to the ground' },
            { key: 6, question: 'Conventional fall protection systems employed near wall openings, edges and open holes' },
            { key: 7, question: 'Fall protection systems in use when working above 6 feet from a surface' },
            { key: 8, question: 'Full body harness snug and properly connected' },
            { key: 9, question: 'Working surface structural stability and falling object protection' },
            { key: 10, question: 'Top rail of guardrail capable of handling 200 lbs of force. Midrail and toeboard installed' },
            { key: 11, question: 'Full railing in place, free of gaps. Toprail 42" (+/- 3"), midrail between toprail and surface, and toeboard' },
        ]
    },
    {
        header: 'Fire Prevention (only if personnel are using or exposed to flammable liquids)',
        propName: 'firePrevention',
        questions: [
            { key: 1, question: 'ABC Fire extinguishers available, readily accessible & inspected (monthly, annual)' },
            { key: 2, question: 'Flammable liquids stored in safety cans' },
            { key: 3, question: 'Flammable materials stored away from electrical panels' },
            { key: 4, question: 'Fuel tanks grounded. Grounding & bonding used when transferring contents' }
        ]
    },
    {
        header: 'General Site Safety',
        propName: 'general',
        questions: [
            { key: 1, question: 'Materials stacked and stored properly, nails removed from lumber' },
            { key: 2, question: 'Passageways and access points clear and free of obstructions' },
            { key: 3, question: 'Adequate illumination in work areas and access points' },
            { key: 4, question: 'Toilet and hand washing facilities available. Trash removed on a regular basis' },
            { key: 5, question: 'Potable water labeled and available. Drinking cups available' },
            { key: 6, question: 'Hazardous Materials labeled and properly stored' },
            { key: 7, question: 'Heavy equipment operated at a safe speed' },
        ]
    },
    {
        header: 'Ladders (only if personnel are using ladders for access on site)',
        propName: 'ladders',
        questions: [
            { key: 1, question: 'Ladders secured. Extend 3 feet above the edge of the landing' },
            { key: 2, question: 'Properly placed (3:4 ratio). Protected from accidental displacement' },
            { key: 3, question: 'Free of damage. Capable of handling intended weight' },
            { key: 4, question: 'Safe landings' }
        ]
    },
    {
        header: 'Personal Protection Equipment',
        propName: 'ppe',
        questions: [
            { key: 1, question: 'Eye & face protection worn by workers exposed to potential eye/face injury' },
            { key: 2, question: 'Hearing protective devices worn by workers where required' },
            { key: 3, question: 'Foot protection worn where required' },
            { key: 4, question: 'Hand protection in use where necessary' },
            { key: 5, question: 'Hard hats serviceable and worn by all employees and visitors' },
            { key: 6, question: 'Respirators properly stored & worn. APR cartridges appropriate for hazard' },
        ]
    },
    {
        header: 'Radiation Safety (only if personnel use equipment that contain radioactive materials or produce radiation)',
        propName: 'radiation',
        questions: [
            { key: 1, question: 'Shipping papers are readily accessible during transportation' },
            { key: 2, question: 'Gauges handled properly, protected from damage, under control of user, with source material shielded when not in active use' },
            { key: 3, question: 'Gauges transported in closed, doubly locked containers, secured against movement and theft' },
            { key: 4, question: 'Gauges properly secured when not in use' },
            { key: 5, question: 'Dosimetry badges worn properly (if personal dosimetry is required)' },
        ]
    },
    {
        header: 'Roadway and Traffic Safety (only if personnel must work on or adjacent to active roadways)',
        propName: 'roadway',
        questions: [
            { key: 1, question: 'ANSI approved reflective vests/jackets worn when working near vehicular traffic' },
            { key: 2, question: 'Traffic control plan has established by construction contractor, reviewed with employees' },
            { key: 3, question: 'Traffic control devices meet Manual on Uniform Traffic Control Devices (MUTCD) specifications' },
            { key: 4, question: 'Traffic control zones established and properly divided/marked' },
        ]
    },
    {
        header: 'Scaffolding (only if personnel are accessing scaffold)',
        propName: 'scaffolding',
        questions: [
            { key: 1, question: 'Plum, alike components used. Damaged components removed. Mudsills connected to scaffold' },
            { key: 2, question: 'Adequate access provided' },
            { key: 3, question: 'Fully railed (above 04 feet from a lower level). Railing adequate for the type of scaffold' },
            { key: 4, question: 'Working levels fully planked. Planking not bowing or cracking. OSHA-rated planking in use' },
            { key: 5, question: 'Erected away from electrical lines and not in use during inclement weather (unless authorized by CP)' },
            { key: 6, question: 'Adequate plank spacing and overlap; between the scaffold and the structure' },
            { key: 7, question: 'Scaffolds not used to stored materials overnight' },
            { key: 8, question: 'Assembled under the supervision of a competent person' },
        ]
    },
    {
        header: 'Tools (only if personnel are using tools)',
        propName: 'tools',
        questions: [
            { key: 1, question: 'UL rated three prong or double insulated. Cords free of damage' },
            { key: 2, question: "Used in accordance with the manufacturer's intended purpose" },
            { key: 3, question: 'Equipment guards installed' },
            { key: 4, question: 'Air connections secured. Compressed air for cleaning limited to 30 PSI' },
            { key: 5, question: 'Wet cutting equipment available or in use when cutting concrete' },
            { key: 6, question: 'Cutting & grinding disks RPM rating is less than or equal to rating of the tool' },
            { key: 7, question: 'Grinder disks ring tested and guards adequately spaced' },
            { key: 8, question: 'Tools free of cracks, splinters and mushroomed heads. Handles properly attached to the tool' },
            { key: 9, question: 'Proper tool used for the job. Neatly stored, safely carried' },
        ]
    },
    {
        header: 'Working Over or Near Water (only if personnel work over or near water)',
        propName: 'water',
        questions: [
            { key: 1, question: 'US Coast Guard-approved personal flotation device (PFD) worn' },
            { key: 2, question: 'PFD in good condition' },
            { key: 3, question: 'Water rescue devices (ring or throw buoy) available' },
            { key: 4, question: 'Approved means to board watercraft provided' },
        ]
    },
]