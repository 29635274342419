import React, { useContext } from "react";
import { Button, Grid, Segment, Header } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

interface IProps {
  logout: () => void;
}

const ConfirmLogout: React.FC<IProps> = ({logout}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    closeModal,
  } = rootStore.modalStore;

  return (
    <Segment clearing className="logoutModal">
      <Header as='h3'>
        Are you sure you want to log out?
      </Header>
      <Grid columns={2} textAlign='center'>
        <Grid.Column>
            <Button color='red' onClick={() => closeModal()} content="No"/>
        </Grid.Column>
        <Grid.Column>
            <Button color="green" onClick={logout} content='Yes' />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default ConfirmLogout;
