import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment } from "semantic-ui-react";
import { INearMiss } from "../../../app/models/nearMiss";
// import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { prettyPrintDateTime } from "../../../app/common/util/util";

const NearMissListItem: React.FC<{ nearMiss: INearMiss }> = ({ nearMiss }) => {

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>{nearMiss.submitterEmail}</Item.Header>
                <Item.Description>{nearMiss.office} | {nearMiss.discipline?.name}</Item.Description>
                <Item.Description><Icon name="clock" /> {prettyPrintDateTime(nearMiss.dateOfIncident, nearMiss.timeOfIncident)}</Item.Description>
              </Item.Content>
            </Item>
        </Item.Group>
      </Segment>
      <Segment clearing>
        <Button
          as={Link}
          to={`/near-miss/${nearMiss.id}`}
          floated="right"
          content="View"
          color="blue"
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(NearMissListItem);
