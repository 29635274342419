import { observable, action, runInAction } from "mobx";
import { toast } from "react-toastify";

import { history } from "../..";
import { RootStore } from "./rootStore";
import { ICorrectiveAction, CorrectiveActionUpdateFormValues } from "../models/correctiveAction";
import agent from "../api/agent";
import { localizeDateTime } from "../common/util/util";
import { DateTimePicker } from "react-widgets";

export default class CorrectiveActionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  
  @observable correctiveActionRegistry = new Map<string, ICorrectiveAction>();
  @observable correctiveAction: CorrectiveActionUpdateFormValues | null = null;
  @observable loading = false;
  @observable submitting = false;

  @action redefineDatesWithTimezones = (ca: ICorrectiveAction) => {
    if(ca.dueDate === null)
      ca.dueDate = undefined;
    else
      ca.dueDate = localizeDateTime(new Date(ca.dueDate!));
    if(ca.completionDate === null)
      ca.completionDate = undefined
    else
      ca.completionDate = localizeDateTime(new Date(ca.dueDate!));
    // this.correctiveAction!.dueDate = new Date(this.correctiveAction!.dueDate!);
    // this.correctiveAction!.completionDate = new Date(this.correctiveAction!.completionDate!);
  }
  
  @action loadCorrectiveAction = async (id: string) => {
    this.loading = true;

    try {
      let correctiveAction = await agent.CorrectiveAction.details(id);

      runInAction("getting corrective action", () => {
        if(correctiveAction) {
          this.redefineDatesWithTimezones(correctiveAction);
          this.correctiveAction = correctiveAction;
          this.correctiveActionRegistry.set(correctiveAction.id, correctiveAction);
        }
        this.loading = false;
      });
      return correctiveAction;
    } catch (error) {
      runInAction("get vehicle error", () => {
        this.loading = false;
      });
      
      console.log(error);
    }
  };
  
  @action editCorrectiveAction = async (id: string, corAct: FormData) => {
    this.submitting = true;
    
    try {
      let correctiveAction = await agent.CorrectiveAction.update(id, corAct);
      
      runInAction("editing corrective action", () => {
        if(correctiveAction) {
          this.redefineDatesWithTimezones(correctiveAction);
          this.correctiveAction = correctiveAction;
          this.correctiveActionRegistry.set(id, correctiveAction);
        }
        history.push(`/field-safety-audit/${correctiveAction.fieldSafetyAuditId}`);
        this.submitting = false;
      });
      return this.correctiveAction;
    } catch (error) {
      runInAction("edit corrective action", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };
}