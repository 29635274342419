export interface IServerBoundAerialLiftChecklist {
  checklist_id: string,
  aerialLiftChecklist: IAerialLiftChecklist
}

export interface IAerialLiftChecklist {
  id: string;
  isOperator: boolean;
  liftId: string;
  unitType: string;
  otherType: string;
  locationOfUse: string;

  controlsOkay: string;
  upperDriveControl: string;
  emergencyLoweringOkay: string;
  lowerOverride: string;
  controlsProtected: string;
  controlPanelClean: string;
  switchesOkay: string;
  lightsOkay: string;
  driveControlsOkay: string;
  alarmsOkay: string;
  decalsOkay: string;
  guardRails: string;
  platformClean: string;
  extensionLocks: string;
  checkForDefects: string;
  tiresOkay: string;
  brakesOkay: string;
  manualOnMWEP: string;
  fluidsOkay: string;
  batteryCharged: string;
  outriggersOkay: string;

  PPEOkay: boolean;
  isWindy: boolean;
  floorConditions: boolean;
  housekeeping: boolean;
  electrical: boolean;
  utilities: boolean;
  overheadObstructions: boolean;
  loads: boolean;
  traffic: boolean;

  comments: string;

  createdByEmail: string;
  createDate: Date;
  modifiedBy: string;
  modifiedOn: Date;

}

export interface IAerialLiftChecklistFormValues extends Partial<IAerialLiftChecklist> {
}

export class AerialLiftChecklistFormValues implements IAerialLiftChecklist {
  id: string = "";
  isOperator: boolean = false;
  liftId: string = "";
  unitType: string = "";
  otherType: string = "";
  locationOfUse: string = "";

  controlsOkay: string = "";
  upperDriveControl: string = "";
  emergencyLoweringOkay: string = "";
  lowerOverride: string = "";
  controlsProtected: string = "";
  controlPanelClean: string = "";
  switchesOkay: string = "";
  lightsOkay: string = "";
  driveControlsOkay: string = "";
  alarmsOkay: string = "";
  decalsOkay: string = "";
  guardRails: string = "";
  platformClean: string = "";
  extensionLocks: string = "";
  checkForDefects: string = "";
  tiresOkay: string = "";
  brakesOkay: string = "";
  manualOnMWEP: string = "";
  fluidsOkay: string = "";
  batteryCharged: string = "";
  outriggersOkay: string = "";

  PPEOkay: boolean = false;
  isWindy: boolean = false;
  floorConditions: boolean = false;
  housekeeping: boolean = false;
  electrical: boolean = false;
  utilities: boolean = false;
  overheadObstructions: boolean = false;
  loads: boolean = false;
  traffic: boolean = false;

  comments: string = '';

  createdByEmail: string = '';
  createDate: Date = new Date();
  modifiedBy: string = '';
  modifiedOn: Date = new Date();

}