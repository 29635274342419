import React, { useContext, useEffect, useState } from "react";
import { Grid, Segment, Button, Header } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { prettyPrintDate, prettyPrintTime } from "../../../app/common/util/util";
import RenderIf from "../../../app/common/form/RenderIf";
import DeleteNearMiss from "../../modals/DeleteNearMiss";

interface DetailParams {
  id: string;
}

const NearMissDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserEmail } = rootStore.userStore;
  const { isAdmin } = rootStore.generalStore;
  const { nearMiss, loadNearMiss, loadingInitial } = rootStore.nearMissStore;
  const { openModal } = rootStore.modalStore;



  useEffect(() => {
    loadNearMiss(match.params.id);
    // if submitter is not logged in, history.push(not auth)
  }, [loadNearMiss, match.params.id, history]);

  if (loadingInitial)
    return <LoadingComponent content="Loading Near Miss..." />;

  if (!nearMiss) return <h2>Near Miss Not Found</h2>;

  const canView = (email: string | undefined) => {
    if(email === null){
      return false;
    }else if(email === nearMiss.submitterEmail || isAdmin(email) || accountInfoUserEmail == nearMiss.supervisorEmail){
      return true;
    }else{
      return false;
    }
  }
  
	if (!canView(accountInfoUserEmail)){
    return <h2>Access restricted.</h2>
  }

  if (nearMiss.deleted) return <h2>This Near Miss has been deleted.</h2>


  return (
    <>
      <PageTitle title="Near Miss Details" />
      <Segment clearing>
                
        <RenderIf condition={isAdmin(accountInfoUserEmail)}>
        <Header as="h3">
          <Button color='red' floated="right" onClick={() => openModal(<DeleteNearMiss nearMiss={nearMiss} />)} className='noPrint'>Delete</Button>
          <Button as={Link} to={`/near-miss/manage/${nearMiss.id}`} color="orange" floated="right" className="noPrint">Edit</Button>
        </Header>
        </RenderIf>

        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column>
              {/* <NearMissDetailSubmissionInfo nearMiss={nearMiss} /> */}
              <p><b>Submitted By:</b><span className="accidentDetail"> {nearMiss.submitterEmail}</span></p>
              <p><b>Office:      </b><span className="accidentDetail">{nearMiss.office}</span></p>
              <p><b>Discipline:  </b><span className="accidentDetail">{nearMiss.discipline?.name}</span></p>

              <p><b>Witnesses:</b>
              <span className="accidentDetail">
                  {nearMiss.witnesses.split(",").map(w => w !== "" && w + " ")}
              </span></p>
            </Grid.Column>
            <Grid.Column width={8}>
              {/* <NearMissDetailIncidentInfo nearMiss={nearMiss} /> */}
              <p><b>Date of Incident:</b> <span className="accidentDetail"> {prettyPrintDate(nearMiss.dateOfIncident)}</span></p>
              <p><b>Time of Incident:</b> <span className="accidentDetail"> {prettyPrintTime(nearMiss.timeOfIncident)} </span></p>
              <p><b>Location:</b> <span className="accidentDetail"> {nearMiss.location}</span></p>
              <p><b>Weather:</b> <span className="accidentDetail"> {nearMiss.weather}</span></p>


            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {/* <NearMissDetailActionItemsInfo nearMiss={nearMiss} /> */}

              <p><b>Description:</b><span className="accidentDetail">{nearMiss.description}</span></p>
              <p><b>Contributing Factors:</b><span className="accidentDetail">{nearMiss.contributingFactors}</span></p>
              <p><b>Preventions:</b><span className="accidentDetail">{nearMiss.preventions}</span></p>
            </Grid.Column>
              
            <Grid.Column>
              <p><b>Recommended Corrective Actions:</b><span className="accidentDetail">{nearMiss.recommendedCorrectiveActions}</span></p>
              <p><b>Personnel Responsible:</b><span className="accidentDetail">{nearMiss.personnelResponsibleEmail}</span></p>
              <p><b>Corrective Action Date:</b><span className="accidentDetail">{prettyPrintDate(nearMiss.correctiveActionDate)}</span></p>
              <p><b>Comments:</b><span className="accidentDetail">{nearMiss.comments}</span></p>
              {nearMiss.files && nearMiss.files.length > 0 && <p><span><b>Attachments:</b></span><ul>{
                nearMiss.files.map((filename:string) => 
                  <li key={filename}><a target="_blank" rel='noreferrer' href={process.env.REACT_APP_ATTACHMENTS_URL + `/${nearMiss.id}/` + filename}>{filename}</a></li>)
              }</ul></p>}


            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Date Submitted: {prettyPrintDate(nearMiss.dateSubmitted)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default observer(NearMissDetails);
