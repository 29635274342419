import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Form, Segment, Header, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import CheckboxInput from "../../../app/common/form/CheckboxInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import { ConfinedSpaceAFormValues } from "../../../app/models/confinedSpaceA";
import { combineValidators, isRequired } from "revalidate";

const validate = combineValidators({
	location: isRequired("Location of Space"),
	description: isRequired("Description of Space"),
	// class: isRequired("Classification"),
});

const ConfinedSpaceAForm = (params: any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addConfinedSpaceA } = rootStore.safetyChecklistStore;

	const handleFinalFormSubmit = (values: ConfinedSpaceAFormValues) => {
		const { ...confinedSpaceA } = values;
		confinedSpaceA.spaceClass = getSpaceType(values);

		addConfinedSpaceA({
			checklist_id: params.id,
			confinedSpaceA: confinedSpaceA,
		});
	};

	const getSpaceType = (values: ConfinedSpaceAFormValues) => {
		// Instructions from Lisa on the form's logic 03-30-2023
		// If answer is “no” to 1 or more questions from #1-3, then it’s “not a confined space”
		if (!values.emplCanEnterAndWork || !values.limitedEntry || !values.designedForHumans){
			return "not a confined space";
		}
		// If the answer is “yes” to questions #1-3 and “no” to questions #4-12, then it is “a non-permit required confined space”
		// If the answer is “yes” to questions #1-3 and “yes” to any of questions #4-12, then it is “a permit required confined space”
		if (values.o2Problem || values.fireProblem || values.toxicProblem || values.engulfProblem || values.slipProblem || values.inwardConvergingWalls || values.energyProblem || values.movingMachineryProblem || values.processPipingProblem){
			return "permit-required confined space";
		} else {
			return "non-permit required confined space";
		}
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					validate={validate}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					render={({ handleSubmit, invalid, pristine, values }) => (
						<Form onSubmit={handleSubmit} id="fallHazForm" className="sopForm">
							<Header as="h3">Confined Space Appendix A Form</Header>
							<Grid>
								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											name="location"
											placeholder="Location of Space..."
											component={TextInput}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											name="description"
											placeholder="Description of Space..."
											component={TextInput}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="1. Can an employee enter the space and perform assigned work within the space?"
										name="emplCanEnterAndWork"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="2. Are there limited or restricted means of entry and/or exit?"
										name="limitedEntry"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="3. Was the space NOT designed for human occupancy?"
										name="designedForHumans"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="4. Can the space be oxygen deficient or enriched?"
											name="o2Problem"
											component={CheckboxInput}
										/>
										<Field
											name="o2Problem_Explain"
											placeholder="Explain..."
											component={TextInput}
											type={values.o2Problem ? "text" : "hidden"}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="5. Can the space contain a flammable atmosphere?"
											name="fireProblem"
											component={CheckboxInput}
										/>
										<Field
											name="fireProblem_Explain"
											placeholder="Explain..."
											component={TextInput}
											type={values.fireProblem ? "text" : "hidden"}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="6. Can the space contain a toxic atmosphere?"
											name="toxicProblem"
											component={CheckboxInput}
										/>
										<Field
											name="toxicProblem_Explain"
											placeholder="Explain..."
											component={TextInput}
											type={values.toxicProblem ? "text" : "hidden"}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="7. Is there material in the space that could engulf an entrant?"
										name="engulfProblem"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="7a. If yes, can the material be removed prior to entry?"
											name="engulfProblem_Removeable"
											component={CheckboxInput}
										/>
										<Field
											name="engulfProblem_Removeable_Explain"
											placeholder="If “no” to 7a, space is a permit required confined space. If “yes” to 7a, explain..."
											component={TextInput}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="8. Are there hazards on the walking/working surfaces that could cause entrants to slip or fall while in the confined space?"
										name="slipProblem"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="8a. If “yes” can these hazards be removed prior to entry? If “no” to 8a, space is permit required confined space."
											name="slipProblem_Removeable"
											component={CheckboxInput}
										/>
										<Field
											name="slipProblem_Removeable_Explain"
											placeholder="Explain..."
											component={TextInput}
											type={values.slipProblem_Removeable ? "text" : "hidden"}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="9. Does the space have inward converging walls?"
										name="inwardConvergingWalls"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="10. Does the space contain an energy source?"
										name="energyProblem"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="10a. If “yes” can the energy source be de-energized and locked out prior to entry?"
											name="energyProblem_Removeable"
											component={CheckboxInput}
										/>
										<Field
											name="energyProblem_Removeable_Explain"
											placeholder="If “no” to 10a, space is permit required confined space. If “yes” to 10a, explain..."
											component={TextInput}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="11. Does the space contain moving machinery or equipment?"
										name="movingMachineryProblem"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="11a. If “yes” can it be secured from outside the space?"
											name="movingMachineryProblem_Removeable"
											component={CheckboxInput}
										/>
										<Field
											name="movingMachineryProblem_Removeable_Explain"
											placeholder="If “no” to 11a, space is permit required confined space. If “yes” to 11a, explain..."
											component={TextInput}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<Field
										label="12. Is there process piping connected to the space?"
										name="processPipingProblem"
										component={CheckboxInput}
									/>
								</Grid.Row>

								<Grid.Row className="gridRow">
									<div className="block">
										<Field
											label="12a. If “yes” can the piping be blanked off, disconnected or isolated using a double block valve and bleeder system?"
											name="processPipingProblem_Removeable"
											component={CheckboxInput}
										/>
										<Field
											name="processPipingProblem_Removeable_Explain"
											placeholder="If “no” to 12a, space is a permit required confined space. If “yes” to 12a, explain..."
											component={TextInput}
										/>
									</div>
								</Grid.Row>

								<Grid.Row className="gridRow">
									Based on the above assessment this classifies the space as:{" "}
									{getSpaceType(values)}
								</Grid.Row>
							</Grid>
							<Button
								loading={submitting}
								disabled={invalid || pristine}
								floated="right"
								positive
								type="submit"
								content="Submit"
							/>
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(ConfinedSpaceAForm);
