import React, { Fragment, useContext } from "react";
import { Item, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import VehicleInspectionListItem from "./VehicleInspectionListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";

const VehicleInspectionList: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { vehicleInspectionsByDefault } = rootStore.vehicleInspectionStore;

	return (
		<Grid columns={2} stackable>
			<Fragment>
				{vehicleInspectionsByDefault.map((vehicle) => (
					<Grid.Column key={vehicle.id}>
						<Segment clearing>
							<Item.Group>
								<VehicleInspectionListItem key={vehicle.id} vehicleInspection={vehicle} />
							</Item.Group>
						</Segment>
					</Grid.Column>
				))}
			</Fragment>
		</Grid>
	);
};

export default observer(VehicleInspectionList);
