import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import SafetyChecklistList from "./SafetyChecklistList";
import SafetyChecklistSearch from "./SafetyChecklistSearch";

const SafetyChecklistDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { loadSafetyChecklists, loadingInitial, setPage, page, totalPages } = rootStore.safetyChecklistStore;

	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadSafetyChecklists().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadSafetyChecklists();
	}, [loadSafetyChecklists]);

	if (loadingInitial && page === 0) return <LoadingComponent content="Loading Safety Checklists..." />;
	else
		return (
			<>
				<PageTitle title="Safety Checklist Dashboard" />

				<SafetyChecklistSearch />

				<InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false}>
					<SafetyChecklistList />
				</InfiniteScroll>
			</>
		);
};

export default observer(SafetyChecklistDashboard);
