import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment } from "semantic-ui-react";
import { IVehicleInspection } from "../../../app/models/vehicleInspection";
import { IVehicle, VehicleFormValues} from "../../../app/models/vehicle";
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from "../../../app/stores/rootStore";
import { prettyPrintDateTime } from "../../../app/common/util/util";
import RenderIf from "../../../app/common/form/RenderIf";


const VehicleInspectionListItem: React.FC<{ vehicleInspection: IVehicleInspection }> = ({ vehicleInspection }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadVehicle } = rootStore.vehicleInspectionStore;
	const { accountInfoUserEmail } = rootStore.userStore;
  const { isAdmin } = rootStore.generalStore;
  
  const [v, setV] = useState(new VehicleFormValues())

  useEffect(() => {
    if(vehicleInspection.vehicle_Keyid !== 0 && vehicleInspection.vehicle_Keyid != null){
      loadVehicle(vehicleInspection.vehicle_Keyid).then((vehicle: IVehicle | undefined) => setV(vehicle!)); // <-- cursed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header><div><Icon name="car" /> {vehicleInspection.vehicle_Keyid? v.wexpin : 'Unable to match vehicle'}</div></Item.Header>
              <Item.Description>{vehicleInspection.submitterEmail}</Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <div>{vehicleInspection.office} | {vehicleInspection.discipline?.name}</div>
        <div><Icon name="calendar alternate outline" /> Created: {prettyPrintDateTime(vehicleInspection.dateSubmitted)}</div>
      </Segment>
      <Segment clearing>
        <Button
          as={Link}
          to={`/vehicle-inspection/${vehicleInspection.id}`}
          floated="right"
          content="View"
          color="blue"
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(VehicleInspectionListItem);
