import React, { StrictMode as Strict } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-toastify/dist/ReactToastify.min.css";
import "react-widgets/styles.css";

import "./app/layout/styles.css";
import App from "./app/layout/App";

import reportWebVitals from "./reportWebVitals";

import "semantic-ui-css/semantic.min.css";
import ScrollToTop from "./app/layout/ScrollToTop";
//import dateFnsLocalizer from "react-widgets-date-fns";

//import { AzureAD, AuthenticationState } from "react-aad-msal";
//import { AuthProvider } from "./app/layout/AuthProvider";

//new dateFnsLocalizer();

export const history = createBrowserHistory();

ReactDOM.render(
	<Strict>
		<Router history={history}>
			<ScrollToTop>
				<App />
			</ScrollToTop>
		</Router>
	</Strict>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
