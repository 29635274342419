import React, { useContext, useEffect, useState } from "react";
import { Grid, Table, Segment, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { IVehicle, VehicleFormValues } from "../../../app/models/vehicle";
import { prettyPrintDate, prettyPrintDateTime } from "../../../app/common/util/util";
import DeleteVehicleInspection from "../../modals/DeleteVehicleInspection";
import './VehicleInspectionDetailsPrint.scss'
import RenderIf from "../../../app/common/form/RenderIf";
interface DetailParams {
  id: string;
}

const VehicleInspectionDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { vehicleInspection, loadVehicleInspection, loadingInitial } = rootStore.vehicleInspectionStore;
  const { loadVehicle } = rootStore.vehicleStore;
  const { openModal } = rootStore.modalStore;
  const { isAdmin } = rootStore.generalStore;

  const [localVehicle, setLocalVehicle] = useState(new VehicleFormValues());
  const { accountInfoUserEmail } = rootStore.userStore;

  useEffect(() => {
    loadVehicleInspection(match.params.id)
      .then((vehicleInspection) => {
        if(vehicleInspection != null ){
          if(vehicleInspection.vehicle_Keyid !== 0 && vehicleInspection.vehicle_Keyid !== null){
            loadVehicle(vehicleInspection.vehicle_Keyid!)
              .then((localVehicle: IVehicle | undefined) => {
                setLocalVehicle(localVehicle!)
              });
          }
        }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, history]);

  if (loadingInitial)
    return <LoadingComponent content="Loading Vehicle Inspection..." />;

  if (!vehicleInspection) return <h2>Vehicle Inspection Not Found</h2>;
  
  if (vehicleInspection.deleted) return <h2>This Vehicle Inspection has been deleted.</h2>;

  const statusCharToString = (status: string) => {
    if (status === 'A')
      return 'Acceptable';
    else if (status=== 'U')
      return 'Unacceptable';
    else if (status === 'N' || status === 'NA')
      return 'Not Applicable';
    else
      return `STATUS ERROR -> ${status}`;
  }

  return (
    <>
      <PageTitle title="Vehicle Inspection Details" />
      
      <Segment clearing>
          <RenderIf condition={isAdmin(accountInfoUserEmail) || (vehicleInspection.submitterEmail === accountInfoUserEmail)}>
            <Segment clearing className="noPrint">
              <Button color='red' floated="right" onClick={() => openModal(<DeleteVehicleInspection vehicleInspection={vehicleInspection} />)} className="noPrint">Delete</Button>
              <Button as={Link} to={`/vehicle-inspection/manage/${vehicleInspection.id}`} color="orange" floated="right" className="noPrint">Edit</Button>
            </Segment>
          </RenderIf>
        <Grid columns={2} stackable>
          <Grid.Column>
          <Table compact basic='very'>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Vehicle</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{vehicleInspection.vehicle_Keyid? localVehicle.wexpin : 'Unable to match vehicle'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Submitter</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{vehicleInspection.submitterEmail}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Submitter Discipline</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{vehicleInspection.discipline?.name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Submitter Office</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{vehicleInspection.office}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Date Submitted</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{prettyPrintDateTime(vehicleInspection.dateSubmitted)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>

        <Grid.Column>
          <Table compact basic='very'>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Current Mileage</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{vehicleInspection.currentMileage}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next Oil Change Mileage</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{vehicleInspection.nextOilChangeMileage}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>State Inspection Due Date</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{prettyPrintDate(vehicleInspection.inspectionDueDate)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Vehicle Registration Expiration</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{prettyPrintDate(vehicleInspection.registration)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Vehicle Insurance Expiration</Table.Cell>
                <Table.Cell className='bold' textAlign="right">{prettyPrintDate(vehicleInspection.insurance)}</Table.Cell>
            </Table.Row>
            </Table.Body>
          </Table>
         </Grid.Column>
        </Grid>

        <Table striped celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Condition</Table.HeaderCell>
              <Table.HeaderCell>Defect/Explanation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Proper operation of:</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Brakes</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.brakesStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.brakesComment}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Emergency Brakes</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.emergencyBrakeStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.emergencyBrakeComment}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Horn</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.hornStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.hornComment}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table striped celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Condition</Table.HeaderCell>
              <Table.HeaderCell>Defect/Explanation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Proper operation and acceptable condition of:</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Headlights</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.headlightsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.headlightsComment}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Taillights</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.tailLightsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.tailLightsComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Brake Lights</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.brakeLightsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.brakeLightsComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Turn Signals</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.turnSignalsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.turnSignalsComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Back-up Lights</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.backupLightsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.backupLightsComment}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Windshield Wipers</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.windshieldWipersStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.windshieldWipersComment}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table striped celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Condition</Table.HeaderCell>
              <Table.HeaderCell>Defect/Explanation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Acceptable condition of:</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Vehicle Interior (clean & intact)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.vehicleInteriorStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.vehicleInteriorComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Vehicle Exterior (clean & intact)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.vehicleExteriorStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.vehicleExteriorComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Equipment Storage Area (clean & organized) [if applicable]</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.equipmentStorageAreaStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.equipmentStorageAreaComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Mirrors (clean & intact)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.mirrorsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.mirrorsComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Windshield (clean & intact)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.windshieldStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.windshieldComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Rear Window (clean & intact)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.rearWindowStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.rearWindowComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Windows (clean & intact)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.windowsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.windowsComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Tires – Inflation</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.tireConditionStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.tireConditionComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Tires – Condition & Tread</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.tireTreadStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.tireTreadComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Backup Camera</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.backupCameraStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.backupCameraComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Backup Alarm</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.backupAlarmStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.backupAlarmComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Strobe Light - Front</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.strobeFrontStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.strobeFrontComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Strobe Light - Back</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.strobeBackStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.strobeBackComment}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table striped celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Condition</Table.HeaderCell>
              <Table.HeaderCell>Defect/Explanation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Proper fluid levels:</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Brake</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.brakeFluidStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.brakeFluidComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Coolant/Antifreeze</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.coolantAntifreezeStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.coolantAntifreezeComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Oil</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.oilStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.oilComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Steering</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.steeringFluidStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.steeringFluidComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Transmission</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.transmissionFluidStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.transmissionFluidComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Windshield</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.windshieldFluidStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.windshieldFluidComment}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table striped celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Condition</Table.HeaderCell>
              <Table.HeaderCell>Defect/Explanation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Safety equipment present and in good condition:</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>First-Aid Kit</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.firstAidKitStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.firstAidKitComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Reflective warning triangle</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.flaresStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.flaresComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>SDSs</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.sdsStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.sdsComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Flashlight</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.flashlightStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.flashlightComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Fire Extinguisher (if applicable to project site)</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.fireExtinguisherStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.fireExtinguisherComment}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Spill Kit</Table.Cell>
              <Table.Cell>{statusCharToString(vehicleInspection.spillKitStatus)}</Table.Cell>
              <Table.Cell>{vehicleInspection.spillKitComment}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table striped celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <RenderIf condition={vehicleInspection.otherIssues}>
              <Table.Row>
                <Table.Cell>Other Deficiencies/Issues</Table.Cell>
                <Table.Cell>{vehicleInspection.otherIssuesExplanation}</Table.Cell>
              </Table.Row>
            </RenderIf>

            <Table.Row>
              <Table.Cell>
                Files
              </Table.Cell>
              <Table.Cell>
                {vehicleInspection.files ? <>
                    <ul>{vehicleInspection.files.map((filename:string, i:number) => 
                      <li key={i}><a target="_blank" rel='noreferrer' href={process.env.REACT_APP_ATTACHMENTS_URL + `/${vehicleInspection.id}/` + filename}>{filename}</a></li>)}</ul> 
                  </>  : <>None found.</>
                }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

export default observer(VehicleInspectionDetails);