import React, { useContext, useEffect } from "react";
import { Header, Grid, Segment, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link, RouteComponentProps } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { ICorrectiveAction } from "../../../app/models/correctiveAction";
import CorrectiveActionDetails from "./CorrectiveActionDetails";
import { prettyPrintDateTime } from "../../../app/common/util/util";
import './FieldSafetyAuditDetailsPrint.scss';
import ApproveAsSSL from "../../modals/ApproveAsSSL";
import MarkFSAComplete from "../../modals/MarkFSAComplete";
import RenderIf from "../../../app/common/form/RenderIf";
import { IFieldSafetyAudit } from "../../../app/models/fieldSafetyAudit";
import { boolToYesNoNA } from "../../../app/common/util/util";
import DeleteFieldSafetyAudit from "../../modals/DeleteFieldSafetyAudit";
interface DetailParams {
  id: string;
}

const FieldSafetyAuditDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { fieldSafetyAudit, loadFieldSafetyAudit, loadingInitial } = rootStore.fieldSafetyAuditStore;
  const { loadDisciplines } = rootStore.disciplineStore;
  const { openModal } = rootStore.modalStore;
  const { isAdmin } = rootStore.generalStore;
  const { accountInfoUserEmail } = rootStore.userStore;

  const sslCanApprove = (fieldSafetyAudit: IFieldSafetyAudit) => {
    if(fieldSafetyAudit.correctiveActions !== null){
      if(fieldSafetyAudit.correctiveActions.some(ca => ca.completionDate === null))
        return false;
    }
    if(!isAdmin(accountInfoUserEmail) && fieldSafetyAudit.safetyChecklist.siteSafetyLeaderEmail.toLowerCase() !== accountInfoUserEmail?.toLowerCase())
      return false;
    if(fieldSafetyAudit.sslApproval === true)
      return false;
    // check if all corrective actions are completed
    return true;
  }

  const canMarkComplete = (fieldSafetyAudit: IFieldSafetyAudit) => {
    if(fieldSafetyAudit.correctiveActions !== null){
      if(fieldSafetyAudit.correctiveActions.some(ca => ca.completionDate === null))
        return false;
    }
    if(!isAdmin(accountInfoUserEmail) && fieldSafetyAudit.submitterEmail.toLowerCase() !== accountInfoUserEmail?.toLowerCase())
      return false;
    if(fieldSafetyAudit.sslApproval === false)
      return false;
    if(fieldSafetyAudit.completed === true)
      return false;
    // other checks for sure
    return true;
  }

  useEffect(() => {
    loadDisciplines();
    loadFieldSafetyAudit(match.params.id);
  }, [loadDisciplines, loadFieldSafetyAudit, match.params.id, history]);

  if (loadingInitial)
    return <LoadingComponent content="Loading FSA..." />;

  if (!fieldSafetyAudit)
    return <h2>Field Safety Audit Not Found</h2>;

  if (fieldSafetyAudit.deleted) return <h2>This Field Safety Audit has been deleted.</h2>
    
  return (
    <>
      <PageTitle title="Field Safety Audit" />
      <Segment>
        <Header as="h3">Field Safety Audit Details
          <RenderIf condition={isAdmin(accountInfoUserEmail) || fieldSafetyAudit.submitterEmail === accountInfoUserEmail}>
            <Button color='red' floated="right" onClick={() => openModal(<DeleteFieldSafetyAudit fsa={fieldSafetyAudit} />)} className='noPrint'>Delete</Button>
            <Button as={Link} to={`/field-safety-audit/manage/${fieldSafetyAudit.id}`} color="orange" floated="right" className='noPrint'>Edit FSA</Button>
          </RenderIf>
          <RenderIf condition={canMarkComplete(fieldSafetyAudit)}>
            <Button floated="right" color="green" className='noPrint' onClick={() => {openModal(<MarkFSAComplete fsa={fieldSafetyAudit}/>)}}>Mark As Complete</Button>
          </RenderIf>
          <RenderIf condition={sslCanApprove(fieldSafetyAudit)}>
            <Button floated="right" color="green" className='noPrint' onClick={() => {openModal(<ApproveAsSSL fsa={fieldSafetyAudit}/>)}}>Approve as SSL</Button>
          </RenderIf>
        </Header>
        <Grid stackable columns={2}>
          <Grid.Column>
            <div>Safety Checklist Project: <span className='accidentDetail'><a href={`/safety-checklist/${fieldSafetyAudit.safetyChecklist.id}`}>{fieldSafetyAudit.safetyChecklist.projectNumber ? fieldSafetyAudit.safetyChecklist.projectNumber : 'N/A'}</a></span></div>
            <div>Auditees:
              {fieldSafetyAudit.auditeeEmails ? <>
                <ul>{fieldSafetyAudit.auditeeEmails.map((f:string, i:number) => 
                  <li key={i}>{f}</li>)}
                </ul> 
                </>  : <>None found.</>}
            </div>
          </Grid.Column>

          <Grid.Column>
            <div>Created by: <span className='accidentDetail'>{fieldSafetyAudit.submitterEmail ? fieldSafetyAudit.submitterEmail : 'N/A'}</span></div>
            <div>Created on: <span className='accidentDetail'>{fieldSafetyAudit.created ? prettyPrintDateTime(fieldSafetyAudit.created) : 'N/A'}</span></div>
          </Grid.Column>
          <Grid.Column>
            <div>SSL Approval: <span className='accidentDetail'>{boolToYesNoNA(fieldSafetyAudit.sslApproval)}</span></div>
            <div>Completed: <span className='accidentDetail'>{boolToYesNoNA(fieldSafetyAudit.completed)}</span></div>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment>
        <Segment>
          <Header>Administrative</Header>
          <Grid className="fsaContainer">
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                  H&amp;S checklist or Site Specific HASP completed, available, and acknowledged by all on site:
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Employees trained to conduct assigned tasks
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Applicable SOPs understood
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Medical Surveillance is completed
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                OSHA postings and logs posted or available
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Competent person(s) assigned by contractor where required
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Documented site inspections conducted by contractor on a regular basis
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Near misses properly reported in a timely fashion
                </div>

                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.administrative8}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Electrical (only if personnel are using power tools/equipment)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                  GFCI usage throughout the jobsite, or Assured Equip Grounding Conductor Program
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                  Construction rated conductors, prongs intact, and protected from damage
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Portable generators grounded per NEC requirements
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Temporary lighting and site electricity wired on separate circuits
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Electrical panels labeled. Completely enclosed, free of debris
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Are terminal boxes equipped with required covers? Circuits labeled
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Temporary lighting protected from damage
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.electrical7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Emergencies</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Employees protected from cave-ins &amp; loose rock/soil that could roll into the excavation
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.emergencies1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                First aid kit provided, properly stocked
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.emergencies2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Emergency telephone numbers posted
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.emergencies3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Spill kit available, including PPE
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.emergencies4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Emergency exits &amp; routes kept free of trip &amp; slip hazard, free of obstructions
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.emergencies5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Excavation and Trenching (only if personnel must enter or work adjacent to excavations and/or trenches)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Employees protected from cave-ins &amp; loose rock/soil that could roll into the excavation
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Spoils, materials &amp; equipment set back at least 2 feet from the edge of the excavation
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                One call. Utility locations identified and precautions taken to ensure contact does not occur
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Precautions taken to protect employees from water accumulation. Hoses braced from tipping
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Surface water or runoff diverted /controlled to prevent accumulation in the excavation
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Air in the excavation tested for oxygen deficiency, combustibles, other contaminants
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Exit (i.e. ladder, sloped wall) no further than 08 feet from ANY employee
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Combustion engines outside of excavation
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching8}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Excavation inspected and deemed safe by construction Competent Person
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.excavationTrenching9}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Fall Protection (only if personnel are exposed to fall hazards)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Anchorage secured to a structural member capable of supporting 5,000 lbs of force
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Anchorage placed above the user, not shared with other devices (i.e. lifting blocks)
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Free of damage
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Connections rated for fall protection. Double snap hooks
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                System deployment distance less than the distance to the ground
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Conventional fall protection systems employed near wall openings, edges and open holes
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Fall protection systems in use when working above 6 feet from a surface
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Full body harness snug and properly connected
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection8}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Working surface structural stability and falling object protection
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection9}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Top rail of guardrail capable of handling 200 lbs of force. Midrail and toeboard installed
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection10}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Full railing in place, free of gaps. Toprail 42" (+/- 3"), midrail between toprail and surface, and toeboard
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.fallProtection11}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Fire Prevention (only if personnel are using or exposed to flammable liquids)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                ABC Fire extinguishers available, readily accessible &amp; inspected (monthly, annual)
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.firePrevention1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Flammable liquids stored in safety cans
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.firePrevention2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Flammable materials stored away from electrical panels
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.firePrevention3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Fuel tanks grounded. Grounding &amp; bonding used when transferring contents
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.firePrevention4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>General Site Safety</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Materials stacked and stored properly, nails removed from lumber
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Passageways and access points clear and free of obstructions
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Adequate illumination in work areas and access points
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Toilet and hand washing facilities available. Trash removed on a regular basis
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Potable water labeled and available. Drinking cups available
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Hazardous Materials labeled and properly stored
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Heavy equipment operated at a safe speed
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.general7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Ladders (only if personnel are using ladders for access on site)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Ladders secured. Extend 3 feet above the edge of the landing
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ladders1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Properly placed (3:4 ratio). Protected from accidental displacement
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ladders2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Free of damage. Capable of handling intended weight
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ladders3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Safe landings
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ladders4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Personal Protection Equipment</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Eye &amp; face protection worn by workers exposed to potential eye/face injury
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppe1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Hearing protective devices worn by workers where required
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppe2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Foot protection worn where required
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppe3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Hand protection in use where necessary
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppe4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Hard hats serviceable and worn by all employees and visitors
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppe5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Respirators properly stored &amp; worn. APR cartridges appropriate for hazard
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppe6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Briefly describe PPE in use
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.ppeInUse}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Radiation Safety (only if personnel use equipment that contain radioactive materials or produce radiation)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Shipping papers are readily accessible during transportation
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.radiation1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Gauges handled properly, protected from damage, under control of user, with source material shielded when not in active use
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.radiation2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Gauges transported in closed, doubly locked containers, secured against movement and theft
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.radiation3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Gauges properly secured when not in use
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.radiation4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Dosimetry badges worn properly (if personal dosimetry is required)
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.radiation5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Roadway and Traffic Safety (only if personnel must work on or adjacent to active roadways)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                ANSI approved reflective vests/jackets worn when working near vehicular traffic
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.roadway1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Traffic control plan has established by construction contractor, reviewed with employees
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.roadway2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Traffic control devices meet Manual on Uniform Traffic Control Devices (MUTCD) specifications
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.roadway3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Traffic control zones established and properly divided/marked
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.roadway4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Scaffolding (only if personnel are accessing scaffold)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Plum, alike components used. Damaged components removed. Mudsills connected to scaffold
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Adequate access provided
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Fully railed (above 04 feet from a lower level). Railing adequate for the type of scaffold
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Working levels fully planked. Planking not bowing or cracking. OSHA-rated planking in use
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Erected away from electrical lines and not in use during inclement weather (unless authorized by CP)
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Adequate plank spacing and overlap; between the scaffold and the structure
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Scaffolds not used to stored materials overnight
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Assembled under the supervision of a competent person
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.scaffolding8}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Tools (only if personnel are using tools)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                UL rated three prong or double insulated. Cords free of damage
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Used in accordance with the manufacturer's intended purpose
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Equipment guards installed
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Air connections secured. Compressed air for cleaning limited to 30 PSI
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Wet cutting equipment available or in use when cutting concrete
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools5}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Cutting &amp; grinding disks RPM rating is less than or equal to rating of the tool
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools6}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Grinder disks ring tested and guards adequately spaced
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools7}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Tools free of cracks, splinters and mushroomed heads. Handles properly attached to the tool
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools8}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Proper tool used for the job. Neatly stored, safely carried
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.tools9}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Working Over or Near Water (only if personnel work over or near water)</Header>
          <Grid>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                US Coast Guard-approved personal flotation device (PFD) worn
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.water1}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                PFD in good condition
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.water2}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Water rescue devices (ring or throw buoy) available
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.water3}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="fsaRow">
              <Grid.Column>
                <div className="fsaQuestions">
                Approved means to board watercraft provided
                </div>
                <div className="fsaAnswers">
                  <label>
                    {fieldSafetyAudit.water4}
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment>
          <Header>Corrective Actions</Header>
          {fieldSafetyAudit.correctiveActions.length > 0 ? 
            <Grid stackable columns={2}>
              {fieldSafetyAudit.correctiveActions.map((ca: ICorrectiveAction, i: number) => 
                <Grid.Column>
                  <CorrectiveActionDetails corAct={ca} key={ca.id}/>
                </Grid.Column>
                )}
            </Grid>
            : <>None found.</>
          }
        </Segment>
        
        <Segment>
          <Header>Other Items</Header>
          {fieldSafetyAudit.otherItems ? <>
              <ul>{fieldSafetyAudit.otherItems.map((f:string, i:number) => 
                <li key={i}>{f}</li>)}
              </ul> 
              </>  : <>None found.</>}
        </Segment>

        <Segment>
          <Header>Attachments</Header>
          {fieldSafetyAudit.files ? <>
              <ul>{fieldSafetyAudit.files.map((filename:string, i:number) => 
                <li key={i}><a target="_blank" rel='noreferrer' href={process.env.REACT_APP_ATTACHMENTS_URL + `/${fieldSafetyAudit.id}/` + filename}>{filename}</a></li>)}
              </ul> 
              </>  : <>None found.</>}
        </Segment>
      </Segment>
    </>
  );
};

export default observer(FieldSafetyAuditDetails);
