import React, { Fragment, useContext, useEffect } from "react";
import { Item, Grid, Segment } from "semantic-ui-react";
import { observer } from 'mobx-react-lite';

import SafetyChecklistListItem from "./SafetyChecklistListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";

const SafetyChecklistList: React.FC = () => {

  const rootStore = useContext(RootStoreContext);
  const { safetyChecklistsByDefault } = rootStore.safetyChecklistStore;
  const { loadDisciplines } = rootStore.disciplineStore;

  useEffect(() => {
    //loadDisciplines();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
		<Grid columns={2} stackable>
			<Fragment>
				{safetyChecklistsByDefault.map((checklist) => (
					<Grid.Column key={checklist.id}>
						<Segment clearing>
							<Item.Group>
								<SafetyChecklistListItem key={checklist.id} safetyChecklist={checklist} />
							</Item.Group>
						</Segment>
					</Grid.Column>
				))}
			</Fragment>
		</Grid>
	);
};

export default observer(SafetyChecklistList);
