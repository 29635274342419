export interface IServerBoundTrafficControl {
    checklist_id: string;
    trafficControl: ITrafficControl
}

export interface ITrafficControl {
    id: string;
    b_EmplTrafficExposure: boolean;
    b_EmplCompliance: boolean;
    subcontractor: string;

    Line_1_1: string;
    Line_1_2: string
    Line_1_3: string
    Line_1_4: string
    Line_1_5: string
    Line_1_6: string
    Line_1_7: string
    Line_1_8: string
    Line_1_9: string
    Line_1_10: string
    Line_1_11: string
    Line_1_12: string
    Line_1_13: string
    Line_1_14: string
    Line_1_15: string
    Line_2_1: string
    Line_2_2: string
    Line_2_3: string
    Line_2_4: string
    Line_2_5: string
    Line_2_6: string
    Line_2_7: string
    Line_2_8: string
    Line_2_9: string
    Line_3_1: string
    Line_3_2: string
    Line_3_3: string
    Line_3_4: string
    Line_3_5: string
    Line_3_6: string
    Line_3_7: string
    Line_3_8: string
    Line_3_9: string
    Line_3_10: string
    Line_4_1: string
    Line_4_2: string
    Line_4_3: string
    Line_4_4: string
    Line_4_5: string
    Line_4_6: string
    Line_4_7: string
    Line_4_8: string
    Line_4_9: string
    Line_4_10: string
    Line_4_11: string
    Line_4_12: string
    Line_4_13: string
    Line_4_14: string
    Line_4_15: string
    Line_4_16: string
    Line_4_17: string
    Line_5_1: string
    Line_5_2: string
    Line_5_3: string
    Line_5_4: string
    Line_5_5: string
    Line_5_6: string
    Line_5_7: string
    Line_5_8: string
    Line_6_1: string
    Line_6_2: string
    Line_6_3: string
    Line_6_4: string
    Line_6_5: string

    correctiveActions: string;

    createdByEmail: string;
    createDate: Date;
    modifiedBy: string;
    modifiedOn: Date;

}

export class TrafficControlFormValues implements ITrafficControl {
    id: string = '';
    b_EmplTrafficExposure: boolean = false;
    b_EmplCompliance: boolean = false;
    subcontractor: string = '';

    Line_1_1: string = "";
    Line_1_2: string = "";
    Line_1_3: string = "";
    Line_1_4: string = "";
    Line_1_5: string = "";
    Line_1_6: string = "";
    Line_1_7: string = "";
    Line_1_8: string = "";
    Line_1_9: string = "";
    Line_1_10: string = "";
    Line_1_11: string = "";
    Line_1_12: string = "";
    Line_1_13: string = "";
    Line_1_14: string = "";
    Line_1_15: string = "";
    Line_2_1: string = "";
    Line_2_2: string = "";
    Line_2_3: string = "";
    Line_2_4: string = "";
    Line_2_5: string = "";
    Line_2_6: string = "";
    Line_2_7: string = "";
    Line_2_8: string = "";
    Line_2_9: string = "";
    Line_3_1: string = "";
    Line_3_2: string = "";
    Line_3_3: string = "";
    Line_3_4: string = "";
    Line_3_5: string = "";
    Line_3_6: string = "";
    Line_3_7: string = "";
    Line_3_8: string = "";
    Line_3_9: string = "";
    Line_3_10: string = "";
    Line_4_1: string = "";
    Line_4_2: string = "";
    Line_4_3: string = "";
    Line_4_4: string = "";
    Line_4_5: string = "";
    Line_4_6: string = "";
    Line_4_7: string = "";
    Line_4_8: string = "";
    Line_4_9: string = "";
    Line_4_10: string = "";
    Line_4_11: string = "";
    Line_4_12: string = "";
    Line_4_13: string = "";
    Line_4_14: string = "";
    Line_4_15: string = "";
    Line_4_16: string = "";
    Line_4_17: string = "";
    Line_5_1: string = "";
    Line_5_2: string = "";
    Line_5_3: string = "";
    Line_5_4: string = "";
    Line_5_5: string = "";
    Line_5_6: string = "";
    Line_5_7: string = "";
    Line_5_8: string = "";
    Line_6_1: string = "";
    Line_6_2: string = "";
    Line_6_3: string = "";
    Line_6_4: string = "";
    Line_6_5: string = "";

    correctiveActions: string = '';

    createdByEmail: string = '';
    createDate: Date = new Date();
    modifiedBy: string = '';
    modifiedOn: Date = new Date();

    constructor(init?: TrafficControlFormValues) {
        Object.assign(this, init);
    }
}

export const trafficControlLabels: any[] = [
    { key: '1_3', label: 'TCP has been approved by regulatory or contractual authority prior to work' },
    { key: '1_4', label: 'TCP considers all factors that may influence traffic-related hazards and controls' },
    { key: '1_5', label: 'Rigid barriers protect work areas' },
    { key: '1_6', label: 'Lookouts are used when applicable' },
    { key: '1_7', label: 'TCP considers all factors that may influence traffic-related hazards and controls' },
    { key: '1_8', label: 'Rigid barriers protect work areas' },
    { key: '1_9', label: 'Lookouts are used when applicable' },
    { key: '1_10', label: 'Vehicles are parked 40 feet away from work zone or are equipped with hazard beacon/strobe' },
    { key: '1_11', label: 'TMCC or TMA vehicle is used where appropriate' },
    { key: '1_12', label: 'All CED traffic control devices conform to MUTCD standards' },
    { key: '1_13', label: 'Traffic control devices are inspected continuously' },
    { key: '1_14', label: 'Flagging is only used when other means of traffic control are inadequate' },
    { key: '1_15', label: 'Additional traffic control zone controls have been implemented' },
    { key: '2_1', label: 'Lane closings are performed when required by this SOP' },
    { key: '2_2', label: 'Traffic control configurations are based on an engineering study of the location' },
    { key: '2_3', label: 'If no study, traffic control is performed with approval of the authority having jurisdiction' },
    { key: '2_4', label: 'TCP has been prepared and understood by all responsible parties prior to work' },
    { key: '2_5', label: 'Special preparation/coordination with external parties has been conducted where applicable' },
    { key: '2_6', label: 'All contractor traffic control devices conform to MUTCD standards' },
    { key: '2_7', label: 'Traffic movement and flow are inhibited or disrupted as little as possible' },
    { key: '2_8', label: 'Supplemental equipment and activities do not interfere with traffic' },
    { key: '2_9', label: 'Drivers and pedestrians are considered when entering and traversing traffic control zone.' },
    { key: '3_1', label: 'Traffic control zones are divided into the necessary five areas' },
    { key: '3_2', label: 'Advanced warning area is designed based on conditions of speed, roads, and driver needs' },
    { key: '3_3', label: 'Advanced warning signage is spaced according to roadway type and conditions' },
    { key: '3_4', label: 'Transition areas are used to channelize traffic around the work area' },
    { key: '3_5', label: 'Buffer areas are used to provide a margin of safety for traffic and workers' },
    { key: '3_6', label: 'The buffer area is free of equipment, workers, materials, and worker vehicles' },
    { key: '3_7', label: 'The length of the buffer area is two times the posted speed limit in fee' },
    { key: '3_8', label: 'All work is contained in the work area and is closed to all traffic' },
    { key: '3_9', label: 'A termination area is used to return traffic to normal lanes' },
    { key: '3_10', label: 'A downstream taper is installed in the termination area' },
    { key: '4_1', label: 'All vehicles involved with device installation/removal have hazard beacons/strobes' },
    { key: '4_2', label: 'Devices are installed according to the order established by this SOP' },
    { key: '4_3', label: 'Devices are removed in the opposite order of installation' },
    { key: '4_4', label: 'Tapers are used to move traffic out of its normal path' },
    { key: '4_5', label: 'Tapers are created using channeling devices' },
    { key: '4_6', label: 'The length of taper is determined by speed and width of lane to be closed (see formula)' },
    { key: '4_7', label: 'Local police or highway patrol assists during taper installation and removal' },
    { key: '4_8', label: 'TMCC/TMA vehicles are used during installation and removal of traffic control devices' },
    { key: '4_9', label: 'Cone trucks are equipped with platforms and railings' },
    { key: '4_10', label: 'Cones are the appropriate height for the specific roadway and are reflectorized' },
    { key: '4_11', label: 'Temporary sign supports are secured to prevent movement' },
    { key: '4_12', label: 'Arrow panels are used on lane closures where required' },
    { key: '4_13', label: 'Concrete barriers are used where required' },
    { key: '4_14', label: 'Barrels, crash cushions, or energy absorbing terminals are used to protect traffic as required' },
    { key: '4_15', label: 'Changeable message signs (CMS) are used as required' },
    { key: '4_16', label: 'CMS are not used to replace required signage' },
    { key: '4_17', label: 'No more than two message panels are used in any message cycle on CMS' },
    { key: '5_1', label: 'Flagging is used only when other traffic control methods are inadequate' },
    { key: '5_2', label: 'Only approved personnel with current certification are used as flaggers' },
    { key: '5_3', label: 'Flaggers are located off the traveled portion of the roadway' },
    { key: '5_4', label: 'A communication system is established when more than one flagger is used' },
    { key: '5_5', label: 'Hand signaling by flaggers is by means of red flags, sign paddles, or red lights' },
    { key: '5_6', label: 'Flaggers are alert, positioned to warn work crews, and easily identified from crew' },
    { key: '5_7', label: 'An escape plan is established by crew and flaggers prior to traffic control set up' },
    { key: '5_8', label: 'Signs indicating a flagger is present are used and removed as required' },
    { key: '6_1', label: 'Traffic control zones are monitored to determine their effectiveness under varying conditions' },
    { key: '6_2', label: 'Traffic control devices are inspected at the beginning and continuously during work shift' },
    { key: '6_3', label: 'Traffic control devices are restored to their proper position immediately and continuously' },
    { key: '6_4', label: 'Damaged, old, or ineffective devices are removed and replace immediately' },
    { key: '6_5', label: 'Devices using reflected light for illumination are cleaned and monitored continuously' },
]