import React, { useContext, useState, useEffect } from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired, } from "revalidate";
import arrayMutators from 'final-form-arrays'

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { CorrectiveActionUpdateFormValues } from "../../../app/models/correctiveAction";
import { RouteComponentProps } from "react-router-dom";
import ConfirmCancelForm from '../../modals/ConfirmCancelForm';
import { FieldDate } from "../../../app/common/form/ConvenienceFields";

const validate = combineValidators({
  dueDate: isRequired('Due Date'),
  //completionDate: isRequired('Completion'),
  description: isRequired('Description'),
  responsiblePartyEmail: isRequired('Responsible Party')
});

interface DetailParams {
  id: string;
}

const CorrectiveActionForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadCorrectiveAction, editCorrectiveAction, submitting } = rootStore.correctiveActionStore;
  const { userList, loadUsers } = rootStore.generalStore;
	const { openModal } = rootStore.modalStore;

  const [corAct, setCorAct] = useState(new CorrectiveActionUpdateFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (match.params.id) {
      loadCorrectiveAction(match.params.id)
        .then((corAct: CorrectiveActionUpdateFormValues | null | undefined) => {
          if (corAct !== null && corAct !== undefined)
            setCorAct(corAct);
        })
        .finally(() => setLoading(false));
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinalFormSubmit = (values: CorrectiveActionUpdateFormValues) => {
    const payload = new FormData();   
    payload.set('dueDate', values.dueDate!.toISOString());
    if(values.completionDate !== undefined)
      payload.set('completionDate', values.completionDate!.toISOString());
    payload.set('responsiblePartyEmail', values.responsiblePartyEmail!);
    payload.set('description', values.description!);
    
    editCorrectiveAction(values.id, payload);
  };

  return (
    <>
      <PageTitle title="Edit Corrective Action" />
      <Grid>
        <Grid.Column width={16}>
          <Segment clearing>
            <FinalForm
              validate={validate}
              initialValues={corAct}
              onSubmit={handleFinalFormSubmit}
              mutators={{ ...arrayMutators }}
              loading={loading}
              render={({
                handleSubmit,
                values }) => (
                <>
                  Non-Conformance: {corAct.nonConformance}<br/>
                  Comments: {corAct.comments}
                  <Form onSubmit={handleSubmit}>

                    <p>All fields are required.</p>
                    
                    <Form.Group widths={'equal'}>
                      <FieldDate
                        name="dueDate"
                        placeholder="MM/DD/YYYY"
                        label="Due Date"
                        value={corAct.dueDate}
                        />
                      <FieldDate
                        name="completionDate"
                        placeholder="MM/DD/YYYY"
                        label="Completion Date"
                        value={corAct.completionDate}
                        />
                    </Form.Group>
                    
                    <Field
                      name="responsiblePartyEmail"
                      label="Reponsible Party"
                      placeholder="Select Party"
                      search={true}
                      defaultValue={corAct.responsiblePartyEmail}
                      component={SelectInput}
                      options={userList?.map((user) => ({
                        key: user.aoid,
                        value: user.email,
                        text: user.formattedName,
                      }))}
                    />

                    <Field
                      label="Description"
                      component={TextAreaInput}
                      rows={4}
                      name='description'
                      placeholder='Description'
                      value={corAct.description}
                    />

                    <Button
                      disabled={loading}
                      color='yellow'
                      type="button"
                      content="Cancel"
                      onClick={()=>openModal(<ConfirmCancelForm />)}
                    />
                    <Button
                      loading={submitting}
                      disabled={loading}
                      floated="right"
                      positive
                      type="submit"
                      content="Submit"
                    />
                  </Form>
                </>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(CorrectiveActionForm);
