import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import FieldSafetyAuditList from "./FieldSafetyAuditList";
import FieldSafetyAuditSearch from "./FieldSafetyAuditSearch";
import FieldSafetyAuditCharts from "./FieldSafetyAuditCharts";
import RenderIf from "../../../app/common/form/RenderIf";

const FieldSafetyAuditDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { 
        loadFieldSafetyAudits, 
        loadingInitial, 
        setPage, 
        page, 
        totalPages,
		showCharts,
    } = rootStore.fieldSafetyAuditStore;

	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadFieldSafetyAudits().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadFieldSafetyAudits();
	}, [loadFieldSafetyAudits]);
	// TODO confirm we dont need loadFSAs in dependency array, pretty sure it can be removed - M.Kane 3-17

	if (loadingInitial && page === 0) return <LoadingComponent content="Loading Audits..." />;
	else
		return (
			<>
				<PageTitle title="Field Safety Audit Dashboard" />

				<FieldSafetyAuditSearch />
				<RenderIf condition={showCharts}>
					<FieldSafetyAuditCharts />
				</RenderIf>

				<InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false}>
					<FieldSafetyAuditList />
				</InfiniteScroll>
			</>
		);
};

export default observer(FieldSafetyAuditDashboard);
