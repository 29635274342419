import React, { useContext, useEffect, useState } from "react";
import { Segment, Table } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { prettyPrintDate } from "../../../app/common/util/util";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PageTitle from "../../../app/common/form/PageTitle";

interface DetailParams {
    id: string;
}

const ConfinedSpaceADetails: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext);
    const { confinedSpaceA, loadConfinedSpaceA, loadingInitial } = rootStore.safetyChecklistStore;
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadConfinedSpaceA(match.params.id).then(() => setLoading(false));
    }, [loadConfinedSpaceA, match.params.id, history]);

    if (loadingInitial || loading) return <LoadingComponent content="Loading Confined Space A Details..." />;

	if (!confinedSpaceA) return <h2>Confined Space Assessment Not Found</h2>;
	return (
		<>
      <PageTitle title={`Confined Space Assessment - ${prettyPrintDate(confinedSpaceA.createDate)} `} /> 
      <Segment clearing>
			  <Table>
          <Table.Row>
              <Table.Cell>
              Location of Space
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.location}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              Description of Space
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.description}
              </Table.Cell>
          </Table.Row>
          {confinedSpaceA.emplCanEnterAndWork && <>
          <Table.Row>
              <Table.Cell>
              1. Can an employee enter the space and perform assigned work within the space?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.emplCanEnterAndWork ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          </>}
          {confinedSpaceA.limitedEntry && <>
          <Table.Row>
              <Table.Cell>
              2. Are there limited or restricted means of entry and/or exit?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.limitedEntry ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          </>}
          <Table.Row>
              <Table.Cell>
              3. Was the space NOT designed for human occupancy?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.designedForHumans ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              4. Can the space be oxygen deficient or enriched?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.o2Problem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          {confinedSpaceA.o2Problem_Explain && <>
          <Table.Row>
              <Table.Cell>
                  Explaination
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.o2Problem_Explain}
              </Table.Cell>
          </Table.Row>
          </>}
          <Table.Row>
              <Table.Cell>
              5. Can the space contain a flammable atmosphere?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.fireProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
                  {confinedSpaceA.fireProblem_Explain && <>
          <Table.Row>
              <Table.Cell>
                  Explaination
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.fireProblem_Explain}
              </Table.Cell>
          </Table.Row>
                  </>}
          <Table.Row>
              <Table.Cell>
              6. Can the space contain a toxic atmosphere?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.toxicProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
                  {confinedSpaceA.toxicProblem_Explain && <>
          <Table.Row>
              <Table.Cell>
                  Explaination
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.toxicProblem_Explain}
              </Table.Cell>
          </Table.Row>
                  </>}
          <Table.Row>
              <Table.Cell>
              7. Is there material in the space that could engulf an entrant?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.engulfProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              7a. If yes, can the material be removed prior to entry?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.engulfProblem_Removeable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          {confinedSpaceA.engulfProblem_Removeable_Explain && <>
              <Table.Row>
                  <Table.Cell>
                  If “no” to 7a, space is a permit required confined space. If “yes” to 7a, explain...
                  </Table.Cell>
                  <Table.Cell>
                      {confinedSpaceA.engulfProblem_Removeable_Explain}
                  </Table.Cell>
          </Table.Row>
          </>}
          <Table.Row>
              <Table.Cell>
              8. Are there hazards on the walking/working surfaces that could cause entrants to slip or fall while in the confined space?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.slipProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              8a. If “yes” can these hazards be removed prior to entry? If “no” to 8a, space is permit required confined space.
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.slipProblem_Removeable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
                  {confinedSpaceA.slipProblem_Removeable_Explain && <>
          <Table.Row>
              <Table.Cell>
                  Explaination
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.slipProblem_Removeable_Explain}
              </Table.Cell>
          </Table.Row>
                  </>}
          <Table.Row>
              <Table.Cell>
              9. Does the space have inward converging walls?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.inwardConvergingWalls ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              10. Does the space contain an energy source?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.energyProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              10a. If “yes” can the energy source be de-energized and locked out prior to entry?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.energyProblem_Removeable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
                  {confinedSpaceA.energyProblem_Removeable_Explain && <>
          <Table.Row>
              <Table.Cell>
              If “no” to 10a, space is permit required confined space. If “yes” to 10a, explain..
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.energyProblem_Removeable_Explain}
              </Table.Cell>
          </Table.Row>
                  </>}
          <Table.Row>
              <Table.Cell>
              11. Does the space contain moving machinery or equipment?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.movingMachineryProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
              11a. If “yes” can it be secured from outside the space?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.movingMachineryProblem_Removeable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
                  {confinedSpaceA.movingMachineryProblem_Removeable_Explain && <>
          <Table.Row>
              <Table.Cell>
              If “no” to 11a, space is permit required confined space. If “yes” to 11a, explain..
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.movingMachineryProblem_Removeable_Explain}
              </Table.Cell>
          </Table.Row>
                  </>}
          <Table.Row>
              <Table.Cell>
              12. Is there process piping connected to the space?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.processPipingProblem ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell>
                  12a. If “yes” can the piping be blanked off, disconnected or isolated using a double block valve and bleeder system?
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.processPipingProblem_Removeable ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
              </Table.Cell>
          </Table.Row>
                  {confinedSpaceA.processPipingProblem_Removeable_Explain && <>
          <Table.Row>
              <Table.Cell>
              If “no” to 12a, space is a permit required confined space. If “yes” to 12a, explain...
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.processPipingProblem_Removeable_Explain}
              </Table.Cell>
          </Table.Row>
                  </>}
          <Table.Row>
              <Table.Cell>
              Based on the above assessment this classifies the space as:
              </Table.Cell>
              <Table.Cell>
                  {confinedSpaceA.spaceClass}
              </Table.Cell>
          </Table.Row>
        </Table>
      </Segment>
		</>
	);
};

export default observer(ConfinedSpaceADetails);
