import React, { useContext } from "react";
import { Card, Image, Segment, Button } from "semantic-ui-react";
import PageTitle from "../../app/common/form/PageTitle";
import RenderIf from "../../app/common/form/RenderIf";

import { RootStoreContext } from "../../app/stores/rootStore";
import ProjectValidator from "../modals/ProjectValidator";

const Home: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;

	const { openModal } = rootStore.modalStore;
	const { isAdmin, isVehicleAdmin } = rootStore.generalStore;

	return (
		<>
			<PageTitle title="Health &amp; Safety Home" />

			{<h2>Welcome {accountInfoUserName}</h2>}
			<Segment textAlign="center" vertical className="masthead">
				<Card.Group centered>
					<Card className='cardShadow'>
						<Card.Content>
							<Card.Header>Safety Checklist</Card.Header>
						</Card.Content>
						<Image src='/assets/safetyChecklist.png' wrapped ui={false} />
						<Card.Content>
							<div className='ui two buttons'>
								<Button className='viewBtn' basic color='black' href='/safety-checklists'>View All</Button>
								<Button className='createBtn' basic color='green' onClick={() => openModal(<ProjectValidator />)}>Create New</Button>
							</div>
						</Card.Content>
					</Card>
					<Card className='cardShadow'>
						<Card.Content>
							<Card.Header>Field Safety Audit</Card.Header>
						</Card.Content>
						<Image src='/assets/fsa.png' wrapped ui={false} />
						<Card.Content>
							<div className='ui two buttons'>
								<Button className='viewBtn' basic color='black' href='/field-safety-audits'>View All</Button>
							</div>
						</Card.Content>
					</Card>
					<Card className="cardShadow">
						<Card.Content>
							<Card.Header>Near Miss</Card.Header>
						</Card.Content>
						<Image src="/assets/employees.png" wrapped ui={false} />
						<Card.Content>
							<div className="ui two buttons">
								<RenderIf condition={isAdmin(accountInfoUserEmail!)}>
									<Button basic color="black" href="/near-misses">
										View All
									</Button>
								</RenderIf>
								<Button basic color="green" href="/new-near-miss">
									Create New
								</Button>
							</div>
						</Card.Content>
					</Card>
					<Card className="cardShadow">
						<Card.Content>
							<Card.Header>Vehicle Inspections</Card.Header>
						</Card.Content>
						<Image size="small" src="/assets/vehicleChecklist.png" wrapped ui={false} />
						<Card.Content>
							<div className="ui two buttons">
								<Button basic color="black" href="/vehicle-inspections">
									View All
								</Button>
								<Button basic color="green" href="/new-vehicle-inspection">
									Create New
								</Button>
							</div>
						</Card.Content>
					</Card>
					<Card className="cardShadow">
						<Card.Content>
							<Card.Header>Vehicle Accident Report</Card.Header>
						</Card.Content>
						<Image size="small" src="/assets/vehicle.png" wrapped ui={false} />
						<Card.Content>
							<div className="ui two buttons">
								<RenderIf condition={isVehicleAdmin(accountInfoUserEmail!)}>
									<Button basic color="black" href="/vehicle-accident-reports">
										View All
									</Button>
								</RenderIf>
								<Button basic color="green" href="/new-vehicle-accident-report">
									Create New
								</Button>
							</div>
						</Card.Content>
					</Card>
					<Card className='cardShadow'>
						<Card.Content>
							<Card.Header>Incident/Injury Report</Card.Header>
						</Card.Content>
						<Image size='small' src='/assets/accidentReport.png' wrapped ui={false} />
						<Card.Content>
							<div className='ui two buttons'>
								<RenderIf condition={isAdmin(accountInfoUserEmail!)}>
									<Button basic color='black' href='/incident-reports'>View All</Button>
								</RenderIf>
								<Button basic color='green' href='/new-incident-report'>Create New</Button>
							</div>
						</Card.Content>
					</Card>
				</Card.Group>
			</Segment>
		</>
	);
};

export default Home;
