import React, { useContext, useState, useEffect, Fragment } from "react";

import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Form, Segment, Header, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { ChemicalInvFormValues, ChemicalInvListFormValues } from "../../../app/models/chemicalInv";
import TextInput from "../../../app/common/form/TextInput";

const ChemicalInvList = (p:any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addChemicalList, loadingInitial } = rootStore.safetyChecklistStore;

	const [chemicalInvList, setChemicalInvList] = useState(new ChemicalInvListFormValues());
	const [loading, setLoading] = useState(false);

	const handleFinalFormSubmit = (values: ChemicalInvListFormValues) => {
		const { ...chemicalInvList } = values;
		// put this in request body to /api/.../{p.id}/addchemlist
		addChemicalList({
			checklist_id: p.id,
			list: chemicalInvList.list
		});
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					// validate={validate}
					initialValues={chemicalInvList}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					render={({
						handleSubmit,
						invalid,
						pristine,
						form: {
							mutators: { push },
						},
						values,
					}) => (
						<Form onSubmit={handleSubmit} loading={loading}>
							<Header as="h3">Chemical List</Header>

							<FieldArray name="list">
								{({ fields }) =>
									fields.map((fieldName, i) => (
										<Fragment key={"item" + i}>
											<Header as="h4">Item {i + 1}</Header>
											<Field
												name={`${fieldName}.name`}
												label="Name"
												// value={chemicalInvList.name}
												component={TextInput}
											/>
											<Field
												name={`${fieldName}.components`}
												label="Components"
												// value={chemicalInvList.components}
												component={TextInput}
											/>

											<Field
												name={`${fieldName}.amounts`}
												label="Amounts"
												// value={chemicalInvList.amounts}
												component={TextInput}
											/>

											<Field
												name={`${fieldName}.location`}
												label="Location"
												// value={chemicalInvList.location}
												component={TextInput}
											/>

											<div>
												{values.list && values.list.length !== 1 && (
													<Button basic color="red" onClick={() => fields.remove(i)} type="button">
														Remove
													</Button>
												)}
												{values.list.length - 1 === i && (
													<Button basic color="green" onClick={() => push("list", new ChemicalInvFormValues())} type="button">
														Add
													</Button>
												)}
											</div>
										</Fragment>
									))
								}
							</FieldArray>

							<Button loading={submitting} disabled={loading || invalid || pristine} floated="right" positive type="submit" content="Submit" />
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(ChemicalInvList);
