import React from "react";
import { Link } from "react-router-dom";
import { Button, Item, Segment, Icon } from "semantic-ui-react";
import { isStringEmpty, prettyPrintDateTime } from "../../../app/common/util/util";
import { IVehicleAccidentReport } from "../../../app/models/vehicleAccidentReport";
import RenderIf from "../../../app/common/form/RenderIf";

const VehicleAccidentReportListItem: React.FC<{ report: IVehicleAccidentReport }> = ({ report }) => {

  return (
    <>
      <Segment.Group>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>Accident Reported By {<a href={"mailto: " + report.createdBy}>{report.createdBy}</a>}</Item.Header>
                <Item.Meta>
                  <RenderIf condition={!isStringEmpty(report.operatorName1)}>
                    <Item.Description><Icon name="user"/>Colliers Operator: {report.operatorName1}</Item.Description>
                  </RenderIf>
                  <Item.Description><Icon name="calendar alternate outline" />Submitted {prettyPrintDateTime(report.createdDate)}</Item.Description>
                </Item.Meta>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <Segment>
          <div><Icon name="car" /><b>Vehicle Number:</b> {report.vehicleNumber1}</div>
          <div><Icon name="calendar alternate outline" />Accident occurred on {prettyPrintDateTime(report.dateTime)}</div>
        </Segment>
        <Segment clearing><Button
            as={Link}
            to={`/vehicle-accident-report/${report.id}`}
            floated="right"
            content="View"
            color="blue"
          />
        </Segment>
      </Segment.Group>
    </>
  );
};

export default VehicleAccidentReportListItem;
