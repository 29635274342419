import React, { useEffect, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import Dropzone, { DropzoneOptions, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';

import './FileDropZone.scss';

const uniques = (arr:any[]) => Array.from(new Set(arr));

interface IInternalDropzoneProps extends IFieldFileDropZoneProps {
	fieldprops: FieldRenderProps<any, HTMLElement, any>
}

const InternalDropzone = (props:IInternalDropzoneProps) => {
	const [files, setFiles] = useState([] as File[]);
	
	useEffect(() => {
		props.fieldprops.input.onChange(files);
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);
	
	const removeFileAtIndex = (n:number) => setFiles(old => {
		let tmp = [...old];
		tmp.splice(n, 1);
		return tmp;
	});
	
	const pushFiles = (f:File[]) => setFiles(old => {
		let tmp = [...old];
		tmp.push(...f);
		return tmp;
	});

	const rejectFiles = (files:FileRejection[]) => {
		const errcodes = uniques(files.map(x => x.errors[0].code));
		errcodes.forEach(err => {
			switch(err) {
				case 'file-invalid-type': toast.error('You can only upload images or documents.'); break;
				case 'too-many-files':    toast.error(`You can only upload up to ${props.maxFiles!} files.`); break;
				default:                  toast.error('An unexpected error has occured.'); console.log(err); break;
			}
		})
	}
	
	const drop = (newfiles:File[]) => {
		if(newfiles.length > 0) {
			if(props.maxFiles && newfiles.length + files.length > props.maxFiles)
				toast.error(`You can only upload up to ${props.maxFiles} files.`);
			else 
				pushFiles(newfiles);
		}
	}
	
	return (
		<Dropzone onDropRejected={rejectFiles} onDrop={drop} {...props}>
			{({getRootProps, getInputProps, isDragActive}) =>
				<div className='filedropzone' {...getRootProps()}>
					<input {...getInputProps()} />
					<div>
						<p>{isDragActive? "→ Drop files here ←" : "Click here or drag & drop to attach files..." }</p>
						<ul>
							{files.map((f,i) =>
								<InternalFileListItem
									key={i}
									filename={f.name}
									killfile={evt => {
										evt.stopPropagation();
										removeFileAtIndex(i);
									}}
								/>
							)}
						</ul>
					</div>
				</div>
			}
		</Dropzone>
	);
}

interface IInternalFileListItemProps {
	filename: string,
	killfile: React.MouseEventHandler<HTMLButtonElement>,
}

const InternalFileListItem = (p:IInternalFileListItemProps) =>
	<li>
		<button type='button' onClick={p.killfile}>❌</button>
		<span>{p.filename}</span>
	</li>

interface IFieldFileDropZoneProps extends DropzoneOptions {
	name: string
}

export const FieldFileDropZone = (p:IFieldFileDropZoneProps) =>
	<Field name={p.name}>
		{x => <InternalDropzone fieldprops={x} {...p}/>}
	</Field>
