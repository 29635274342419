import UserStore from './userStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import ModalStore from './modalStore';
import SafetyChecklistStore from './safetyChecklistStore';
import VehicleInspectionStore from './vehicleInspectionStore';
import NearMissStore from './nearMissStore';
import VehicleStore from './vehicleStore';
import DisciplineStore from './disciplineStore';
import SituationStore from './situationStore';
import FieldSafetyAuditStore from './fieldSafetyAuditStore';
import VehicleAccidentReportStore from './vehicleAccidentReportStore';
import GeneralStore from './generalStore';
import IncidentReportStore from './incidentReportStore';
import CorrectiveActionStore from './correctiveActionStore';
import NotificationStore from './notificationStore';

configure({enforceActions: 'always'});

export class RootStore {
    disciplineStore: DisciplineStore;
    fieldSafetyAuditStore: FieldSafetyAuditStore;
    generalStore: GeneralStore;
    modalStore: ModalStore;
    nearMissStore: NearMissStore;
    safetyChecklistStore: SafetyChecklistStore;
    situationStore: SituationStore;
    vehicleAccidentReportStore: VehicleAccidentReportStore;
    vehicleInspectionStore: VehicleInspectionStore;
    vehicleStore: VehicleStore;
    userStore: UserStore;
    incidentReportStore: IncidentReportStore;
    correctiveActionStore: CorrectiveActionStore
    notificationStore: NotificationStore
    constructor() {
        this.userStore = new UserStore(this);
        this.modalStore = new ModalStore(this);
        this.safetyChecklistStore = new SafetyChecklistStore(this);
        this.fieldSafetyAuditStore = new FieldSafetyAuditStore(this);
        this.vehicleInspectionStore = new VehicleInspectionStore(this);
        this.nearMissStore = new NearMissStore(this);
        this.vehicleStore = new VehicleStore(this);
        this.disciplineStore = new DisciplineStore(this);
        this.situationStore = new SituationStore(this);
        this.vehicleAccidentReportStore = new VehicleAccidentReportStore(this);
        this.generalStore = new GeneralStore(this);
        this.vehicleAccidentReportStore = new VehicleAccidentReportStore(this);
        this.incidentReportStore = new IncidentReportStore(this);
        this.correctiveActionStore = new CorrectiveActionStore(this);
        this.notificationStore = new NotificationStore(this)
    }
}

export const RootStoreContext = createContext(new RootStore());
