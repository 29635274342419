export const months = [
  { key: 1, text: 'January', value: 1 },
  { key: 2, text: 'February', value: 2 },
  { key: 3, text: 'March', value: 3 },
  { key: 4, text: 'April', value: 4 },
  { key: 5, text: 'May', value: 5 },
  { key: 6, text: 'June', value: 6 },
  { key: 7, text: 'July', value: 7 },
  { key: 8, text: 'August', value: 8 },
  { key: 9, text: 'September', value: 9 },
  { key: 10, text: 'October', value: 10 },
  { key: 11, text: 'November', value: 11 },
  { key: 12, text: 'December', value: 12 },
];

// dynamically create years dropdown options back to Maser's first year
// H&S data only goes back to 2018
let curYear = new Date().getFullYear();
const years_temp = [{ key: 0, text: String(curYear), value: curYear }];
for (let i = 1; curYear - i >= 2018; i++) {
  years_temp.push({ key: i, text: String(curYear - i), value: curYear - i });
}
export const years = years_temp;