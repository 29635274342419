import React, { useContext, useState, Fragment } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Form, Segment, Header, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { AerialLiftChecklistFormValues } from "../../../app/models/aerialLiftChecklist";
import { combineValidators } from "revalidate";
import SelectInput from "../../../app/common/form/SelectInput";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import RenderIf from "../../../app/common/form/RenderIf";

const validate = combineValidators({
	// location: isRequired("Location of Space"),
	// description: isRequired("Description of Space"),
	// class: isRequired("Classification"),
});

const unitTypes = [
	{ key: 0, text: "Scissor Lift", value: "0" },
	{ key: 1, text: "Articulating Boom", value: "1" },
	{ key: 2, text: "Vertical Man Lift", value: "2" },
	{ key: 3, text: "Other", value: "3" },
];

const passFailOptions = [
	{ key: 0, text: "Pass", value: "0" },
	{ key: 1, text: "Fail", value: "1" },
	{ key: 2, text: "N/A", value: "2" },
];

const questions = [
	{
		key: 0,
		name: "controlsOkay",
		text: "Operating and emergency controls are in proper working condition, EMO button or Emergency Stop Device*",
	},
	{
		key: 1,
		name: "upperDriveControl",
		text: "Functional upper drive control interlock (i.e. foot pedal, spring lock, or two hand controls)",
	},
	{ key: 2, name: "emergencyLoweringOkay", text: "Emergency Lowering function operates properly" },
	{ key: 3, name: "lowerOverride", text: "Lower operating controls successfully override the upper controls" },
	{ key: 4, name: "controlsProtected", text: "Both upper and lower controls are adequately protected from inadvertent operation" },
	{ key: 5, name: "controlPanelClean", text: "Control panel is clean & all buttons/switches are clearly visible (no paint over spray, etc.)" },
	{ key: 6, name: "switchesOkay", text: "All switch & mechanical guards are in good condition and properly installed" },
	{ key: 7, name: "lightsOkay", text: "All Safety Indicator lights work" },
	{ key: 8, name: "driveControlsOkay", text: "Drive controls function properly & accurately labeled (up, down, right, left, forward, back)	" },
	{ key: 9, name: "alarmsOkay", text: "Motion alarms are functional" },
	{ key: 10, name: "decalsOkay", text: "Safety decals are in place and readable" },
	{ key: 11, name: "guardRails", text: "All guard rails are sound and in place, including basket chains	" },
	{ key: 12, name: "platformClean", text: "Work platform & extension slides are clean, dry, & clear of debris" },
	{
		key: 13,
		name: "extensionLocks",
		text: "Work platform extension slides in and out freely with safety locking pins in place to lock setting on models with extension platforms.",
	},
	{
		key: 14,
		name: "checkForDefects",
		text: "Inspect for defects such as cracked welds, fuel leaks, hydraulic leaks, damaged control cables or wire harness, etc.",
	},
	{ key: 15, name: "tiresOkay", text: "Tires and wheels are in good condition, with adequate air pressure if pneumatic" },
	{ key: 16, name: "brakesOkay", text: "Braking devices are operating properly" },
	{ key: 17, name: "manualOnMWEP", text: "The manufacturer’s operations manual is stored on MEWP (in all languages of the operators)" },
	{ key: 18, name: "fluidsOkay", text: "Oil level, Hydraulic Oil Level, Fuel Level, Coolant Level" },
	{ key: 19, name: "batteryCharged", text: "Battery Charge" },
	{ key: 20, name: "outriggersOkay", text: "Outriggers in place or functioning. Associated alarms working" },
];

const AerialLiftChecklistForm = (params: any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addAerialLift } = rootStore.safetyChecklistStore;
	const [aerialLift, setAerialLift] = useState(new AerialLiftChecklistFormValues());

	const handleFinalFormSubmit = (values: AerialLiftChecklistFormValues) => {
		const { ...siteSafety } = values;

		addAerialLift({
			checklist_id: params.id,
			aerialLiftChecklist: values,
		});
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					validate={validate}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					initialValues={aerialLift}
					render={({ handleSubmit, invalid, pristine, values }) => (
						<Form onSubmit={handleSubmit} className="sopForm">
							<Header as="h3">Aerial Lift Checklist Form</Header>

							<Grid>
								<Grid.Row className="gridRow">
									<Field
										label="Are you the operator?"
										name="isOperator"
										component={CheckboxInput}
									/>
								</Grid.Row>
								<RenderIf condition={values.isOperator}>
									<Grid.Row className="gridRow">
										<Field
											name="liftId"
											placeholder="Lift Id"
											component={TextInput}
										/>
									</Grid.Row>
									<Grid.Row className="gridRow">
										<Field
											name="unitType"
											placeholder="Unit Type"
											component={SelectInput}
											options={unitTypes}
										/>
									</Grid.Row>
									{values.unitType.toString() ===  "3" &&
										<Grid.Row className="gridRow">
											<Field
												name="otherType"
												placeholder="Other Type"
												component={TextInput}
												hidden={values.unitType !== "3"}
											/>
										</Grid.Row>
									}
									<Grid.Row className="gridRow">
										<div className="block">
											<Field
												name="locationOfUse"
												placeholder="Location of Use"
												component={TextInput}
											/>
										</div>
									</Grid.Row>
									{questions.map((item) => (
										<Fragment key={item.name}>
											<Grid.Row className="gridRow">
												<div className="block">
													{item.text}
													<Field
														name={item.name}
														component={SelectInput}
														options={passFailOptions}
														placeholder="----"
													/>
												</div>
											</Grid.Row>
										</Fragment>
									))}
									<Grid.Row className="gridRow">
										<Field
											label="Personal Protection in use and inspected. (Harness, lanyard, hardhat etc.)"
											name={"PPEOkay"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="In windy conditions see manufacturer guidelines or if not in guidelines then…if lift begins to rock in the wind lower the lift"
											name={"isWindy"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Floor conditions: Drop offs, holes, uneven surfaces, and sloped floors."
											name={"floorConditions"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Housekeeping: Debris, floor obstructions, cords, construction material and supplies."
											name={"housekeeping"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Electrical power cables or panels, (minimum 10 feet away). Refer to 29 CPR 1910.333 (c)"
											name={"electrical"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Chemical lines, gas lines, drain lines, and utilities."
											name={"utilities"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Overhead obstructions"
											name={"overheadObstructions"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Loads (do not exceed capacity)"
											name={"loads"}
											component={CheckboxInput}
										/>
									</Grid.Row>{" "}
									<Grid.Row className="gridRow">
										<Field
											label="Watch for vehicular and pedestrian traffic. Set up barricades if necessary."
											name={"traffic"}
											component={CheckboxInput}
										/>
									</Grid.Row>
									<Grid.Row className="gridRow">
										<div className="block">
											<Field
												placeholder="Precation Comments"
												name="comments"
												component={TextAreaInput}
											/>
										</div>
									</Grid.Row>
							</RenderIf>
							</Grid>
							<br />
							<Button
								loading={submitting}
								disabled={invalid}
								floated="right"
								positive
								type="submit"
								content="Submit"
							/>
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(AerialLiftChecklistForm);
