import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import VehicleInspectionList from "./VehicleInspectionList";
import VehicleInspectionSearch from "./VehicleInspectionSearch";

const VehicleInspectionDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { loadVehicleInspections, loadVehicles, loadingInitial, setPage, page, totalPages } = rootStore.vehicleInspectionStore;

	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadVehicleInspections().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadVehicleInspections();
		loadVehicles();
	}, [loadVehicleInspections, loadVehicles]);

	if (loadingInitial && page === 0) return <LoadingComponent content="Loading Vehicle Inspections..." />;
	else
		return (
			<>
				<PageTitle title="Vehicle Inspection Dashboard" />

				<VehicleInspectionSearch />

				<InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false}>
					<VehicleInspectionList />
				</InfiniteScroll>
			</>
		);
};

export default observer(VehicleInspectionDashboard);
