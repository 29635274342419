export const offices = [
  'Agawam, MA',
  'Albany, NY',
  'Albuquerque, NM',
  'Atlanta, GA',
  'Austin, TX',
  'Binghamton, NY',
  'Boca Raton, FL',
  'Boston, MA',
  'Brunswick, ME',
  'Bryan, TX',
  'Buffalo, NY',
  'Charlotte, NC',
  'Clinton, NJ',
  'Columbia, MD',
  'Columbia, SC',
  'Conshohocken, PA',
  'Cranberry, PA',
  'Dallas, TX',
  'Denver, CO',
  'Egg Harbor, NJ',
  'Exton, PA',
  'Fort Myers, FL',
  'Fort Worth, TX',
  'Grand Rapids, MI',
  'Hamilton, NJ',
  'Holmdel, NJ',
  'Houston, TX',
  'Jacksonville, FL',
  'Knoxville, TN',
  'Lansing, MI',
  'Lehigh Valley, PA',
  'Long Island, NY',
  'Los Angeles, CA',
  'Madison, CT',
  'Mays Landing, NJ',
  'Miami, FL',
  'Montvale, NJ',
  'Mt. Arlington, NJ',
  'Mt. Laurel, NJ',
  'Nashville, TN',
  'New Braunfels, TX',
  'New York, NY',
  'Newburgh, NY',
  'Norfolk, VA',
  'Orlando, FL',
  'Philadelphia, PA',
  'Phoenix, AZ',
  'Pittsburgh, PA',
  'Providence, RI',
  'Raleigh, NC',
  'Remote',
  'Rochester, NY',
  'San Antonio, TX',
  'Southfield, MI',
  'Sterling, VA',
  'Syracuse, NY',
  'Tampa, FL',
  'Toledo, OH',
  'Totowa, NJ',
  'Trevose, PA',
  'Westchester, NY',
  'Wilmington, NC',
  'Woodcliff Lake, NJ',
]