import React, { useContext } from "react";
import { Button, Segment, Header, Form } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";

import CheckboxInput from "../../app/common/form/CheckboxInput";
import { IFieldSafetyAudit } from "../../app/models/fieldSafetyAudit";

const MarkFSAComplete: React.FC<{fsa: IFieldSafetyAudit}> = (p) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const {
      markComplete,
      loadFieldSafetyAudit,
  } = rootStore.fieldSafetyAuditStore;

  const labelText = 'Marking this Field Safety Audit as complete will finalize this Field Safety Audit in the CED Health and Safety site and make the results available to the appropriate Discipline Leader and Discipline Safety Coordinator.';

  const handleFinalFormSubmit = () => {
    markComplete(p.fsa.id).then(() => loadFieldSafetyAudit(p.fsa.id));
    closeModal();
  }

  return (
    <Segment clearing>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        render={({
          handleSubmit,
          values }) => (

          <Form onSubmit={handleSubmit}>
            <Header as='h3' content='Field Safety Audit - Mark Complete' />

            <Field
              component={CheckboxInput}
              name='confirm'
              label={labelText}
              type='checkbox'
            />
          
            <div className='ui two buttons'>
              <Button
                color='red'
                onClick={() => closeModal()}
                content="Cancel"
                type='button'
              />
              <Button
                disabled={!values.confirm}
                color='green'
                content="Acknowledge"
                type='submit'
              />
            </div>
          </Form>
        )}
      />
    </Segment>
  );
};

export default MarkFSAComplete;
