import { ISafetyChecklist, SafetyChecklistFormValues } from "./safetyChecklist";

export interface INotification {
    id: string;
    deleted: boolean;
    acknowledged: boolean;
    userEmail: string;
    created: Date;
    updated: Date;
    updatedBy: string;
    lastAcknowledged: Date;
    safetyChecklist: ISafetyChecklist;
}

export class NotificationFormValues implements INotification {
    id: string = '';
    deleted: boolean = false;
    acknowledged: boolean = false;
    userEmail: string = '';
    created: Date = new Date();
    updated: Date = new Date();
    updatedBy: string = '';
    lastAcknowledged: Date = new Date();
    safetyChecklist: ISafetyChecklist = new SafetyChecklistFormValues();

    constructor(init?: NotificationFormValues) {
        Object.assign(this, init);
    }
}

export interface INotificationSearch {
    users: string[];
    checklists: string[];
    acknowledged?: boolean;
}
export class NotificationSearchFormValues implements INotificationSearch {
    users: string[] = [];
    checklists: string[] = [];
    acknowledged?: boolean | undefined;

    constructor(init?: INotificationSearch) {
        Object.assign(this, init);
    }
}