import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment } from "semantic-ui-react";
import { ISafetyChecklist } from "../../../app/models/safetyChecklist";
// import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { isStringEmpty, prettyPrintDateTime } from "../../../app/common/util/util";
import RenderIf from "../../../app/common/form/RenderIf";

const SafetyChecklistListItem: React.FC<{ safetyChecklist: ISafetyChecklist }> = ({ safetyChecklist }) => {

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>{safetyChecklist.projectNumber} - {safetyChecklist.discipline ? safetyChecklist.discipline.name : 'N/A'}</Item.Header>
              <RenderIf condition={!isStringEmpty(safetyChecklist.projectManagerEmail)}>
                <Item.Description>Project Manager: {<a href={"mailto: " + safetyChecklist.projectManagerEmail}>{safetyChecklist.projectManagerEmail}</a>}</Item.Description>
              </RenderIf>
              <Item.Description><Icon name="user" />Site Safety Leader: {<a href={"mailto: " + safetyChecklist.siteSafetyLeaderEmail}>{safetyChecklist.siteSafetyLeaderEmail}</a>}</Item.Description>
              <Item.Description><Icon name="user" />Created by {<a href={"mailto: " + safetyChecklist.submitterEmail}>{safetyChecklist.submitterEmail}</a>}</Item.Description>
              <Item.Description><Icon name="calendar alternate outline" />Created {prettyPrintDateTime(safetyChecklist.created)}</Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <div><b>Project Name:</b> {safetyChecklist.projectName}</div>
        <RenderIf condition={!isStringEmpty(safetyChecklist.projectAddress)}>
          <div><b>Project Address:</b> {safetyChecklist.projectAddress}</div>
        </RenderIf>
      </Segment>
      <Segment clearing>
        <Button
          as={Link}
          to={`/safety-checklist/${safetyChecklist.id}`}
          floated="right"
          content="View"
          color="blue"
        />
        <Button
          as={Link}
          to={`/safety-checklist/template/${safetyChecklist.id}`}
          floated="right"
          content="Use as Template"
          color="green"
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(SafetyChecklistListItem);
