import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label, Select } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const SelectInput: React.FC<IProps> = ({
  input,
  width,
  label,
  clearable,
  search,
  options,
  placeholder,
  meta: { touched, error },
  required
}) => 
  // After tested, touched in the select will always be false, which casues the error message won't show
  <Form.Field error={touched && !!error} width={width} required={required}>
    <label>{label}</label>
    <Select 
      value={input.value}
      search={search}
      clearable={clearable}
      onChange={(e, data) => input.onChange(data.value)}
      placeholder={placeholder}
      options={options} 
    />
    {touched && error && (
      <Label basic color="red">
        {error}
      </Label>
    )}
  </Form.Field>
;

export default SelectInput;
