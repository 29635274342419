import React from "react";
import { Container } from "semantic-ui-react";

import './Footer.scss';

const getCurrentYear = () => new Date().getFullYear();

const Footer = () =>
	<div className="footer noPrint">
		<Container>
			<p>Designed &amp; Developed by <a href="https://colliersengineering.com/">Colliers Engineering &amp; Design</a> &mdash; Copyright &copy; 2020 &ndash; {getCurrentYear()}</p>
		</Container>
	</div>
;

export default Footer;
