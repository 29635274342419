import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Item, Segment } from "semantic-ui-react";
import { INotification } from "../../../app/models/notification";
// import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { prettyPrintDateTime } from "../../../app/common/util/util";

const NotificationListItem: React.FC<{ notification: INotification }> = ({ notification }) => {

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>{notification.safetyChecklist.projectNumber} - {notification.safetyChecklist.discipline?.name}</Item.Header>
                <Item.Description>{notification.userEmail} - {notification.acknowledged ? <Icon name='eye' color="green" /> : <Icon name='eye slash' color="red" />}</Item.Description>
                <Item.Description><Icon name="clock" /> {prettyPrintDateTime(notification.created)}</Item.Description>
              </Item.Content>
            </Item>
        </Item.Group>
      </Segment>
      <Segment clearing>
        {/* <Button
          as={Link}
          to={`/notification/${notification.id}`}
          floated="right"
          content="View Notification"
          color="blue"
        /> */}
        <Button
        as={Link}
        to={`/safety-checklist/${notification.safetyChecklist.id}`}
        floated="right"
        content="View Checklist"
        color="blue"
      />
      </Segment>
    </Segment.Group>
  );
};

export default observer(NotificationListItem);