export interface IVehicle {
    vehicleID: number;
    inact: boolean;
    wexpin: string;
    //licplno: string;
    //equipno: string;
}

export class VehicleFormValues implements IVehicle {
    vehicleID: number = 0;
    inact: boolean = false;
    wexpin: string = '';
    //licplno: string = '';
    //equipno: string = '';

    constructor(init?: VehicleFormValues) {
        Object.assign(this,init);
      }
}


// keyid == Keyid, the primary key
// inact == inactive
// wexpin == wex number <- also called Vehicle Number
// filter out any with non-numeric, four characters only
// 3/31/22 - Mike L talked to Jorge
// licplno == license plate
// equipno == donnelin number



// Updated Model from Kyle

// public Guid Id { get; set; }
// public int VehicleID { get; set; }
// public string Make { get; set; }
// public string Model { get; set; }
// public string Wexpin { get; set; } <- also called Vehicle Number
// public Boolean Inact { get; set; }