import React from "react";
import { Button } from "semantic-ui-react";
import { toast } from 'react-toastify';

interface IStepButtonsProps {
    isValid(): string | null; // Returns a description of what is wrong or null if the form is valid
    step: number; // The current step
    nextStep(): void; // A function that takes us to the next step, include any needed logic
    prevStep(): void; // A function that takes us to the prev step, include any needed logic
    //setStep: React.Dispatch<React.SetStateAction<number>>; // commented out 2022-3-17, turns out there's added logic we needed from next/prevStep
    stepmax: number; // The maximum value step can have
    scrollToTop?: boolean;
    floated?: string;
}

export const StepButtons: React.FC<IStepButtonsProps> = (p: IStepButtonsProps) =>
<>
  <Button
    content="Previous Step"
    onClick={() => {
      p.prevStep();
      if (p.scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }}
    type="button"
    disabled={p.step === 1}
  />
  <Button
    content="Next Step"
    onClick={() => {
      let err = p.isValid();

      if(err === null) {
        p.nextStep();
        if (p.scrollToTop) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      } 
        else 
        toast.error(err);       
    }}
    type="button"
    color="green"
    disabled={p.step === p.stepmax}
    floated='right'
  />
</>