import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label, Dropdown } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string[], HTMLElement>,
    FormFieldProps {}

const MultipleSelectInput: React.FC<IProps> = ({
  input,
  width,
  label,
  options,
  placeholder,
  search,
  meta: { touched, error }
}) => {
  return (
    <Form.Field error={touched && !!error} width={width}>
      <label>{label}</label>
      <Dropdown
        placeholder={placeholder}
        fluid
        multiple
        selection
        search={search}
        onChange={(e, data) => input.onChange(data.value)}
        options={options} 
        value={input.value}
      />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  )
}

export default MultipleSelectInput
