import React, { useContext, useEffect, useState, Fragment } from "react";
import { Header, Form, Grid, Segment, Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import ChemicalInvListForm from "../forms/ChemicalInvListForm";
import ChemicalInvListDetails from '../details/ChemicalInvListDetails';
import FallHazForm from "../forms/FallHazForm";
import TrafficControlForm from "../forms/TrafficControlForm";
import ConfinedSpaceAForm from "../forms/ConfinedSpaceAForm";
import SiteSafetyBriefingForm from "../forms/SiteSafetyForm";
import AerialLiftChecklistForm from "../forms/AerialLiftChecklist";
import SafetyChecklistCommentForm from "../forms/SafetyChecklistCommentForm";
import RenderIf from "../../../app/common/form/RenderIf";
import CreateNotification from "../../modals/CreateNotification";
import DeleteChecklist from "../../modals/DeleteChecklist";
import { ISafetyChecklist } from "../../../app/models/safetyChecklist";
import { IComment } from "../../../app/models/comment";
import { prettyPrintDate, prettyPrintDateTime } from "../../../app/common/util/util";
import { INotification } from "../../../app/models/notification";
import { ISOP } from "../../../app/models/sop";

interface DetailParams {
	id: string;
}

const SafetyChecklistDetails: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {
	const rootStore = useContext(RootStoreContext);
	const { safetyChecklist, loadSafetyChecklistDetail, loadingInitial, } = rootStore.safetyChecklistStore;
	const { accountInfoUserEmail } = rootStore.userStore;
	const { openModal } = rootStore.modalStore;
	const { isAdmin } = rootStore.generalStore;
	const [loading, setLoading] = useState(false);

	const [chemFormVisible, setChemFormVisible] = useState(false);
	const [fallHazVisible, setFallHazVisible] = useState(false);
	const [trafficControlVisible, setTrafficControlVisible] = useState(false);
	const [confinedSpaceAVisible, setConfinedSpaceAVisible] = useState(false);
	const [siteSafetyBriefingVisible, setSiteSafetyBriefingVisible] = useState(false);
	const [aerielLiftCHecklistVisible, setAerialLiftChecklistVisible] = useState(false);

	const sopPresent = (checklist: ISafetyChecklist, number: number) => {
		// go through hazards
		// see if SOP # is in any of the associatedSOPs
		// return true and break the loop if found, no need to keep going
		var found = false;
		checklist.hazards?.forEach((hazard) => {
			if (hazard.associatedSOPs?.some(sop => sop.number === number)){
				// returning false just breaks out of the inner loop, use a flag to track the actual value
				// see https://stackoverflow.com/questions/4774311/javascript-return-not-breaking-out-of-function
				found = true;
				return false;
			}
		});
		return found;
	}

	const canView = () => {
		if(accountInfoUserEmail !== null){
			// if (users.includes(accountInfoUserEmail!)){
			// 	return true
			// }
		}
		return true;
	}

	const convertAddressToGMapsLink = (string: string) => {
		if(string !== null){
			var temp = string.replaceAll('\n', ' ');
			var temp = string.replaceAll('  ', ' ');
			var temp = temp.replaceAll(' ', '+');
			return `https://www.google.com/maps/search/?api=1&query=${temp}`
		}
		return ``
	}

	const notificationForUserExists = (email: string, notifications?: INotification[]) => {
		if(notifications === undefined){
			return false;
		}
		return notifications.some(n => n.userEmail === email);
	}

	useEffect(() => {
		setLoading(true);
		loadSafetyChecklistDetail(match.params.id).then(() => setLoading(false));
	}, [loadSafetyChecklistDetail, match.params.id, history]);

	if (loadingInitial || loading) return <LoadingComponent content="Loading Safety Checklist ..." />;

	if (!safetyChecklist) return <h2>Safety Checklist Not Found</h2>;

	if (safetyChecklist.deleted) return <h2>This Safety Checklist has been deleted.</h2>

	return (
		<>
			<PageTitle title="Safety Checklist Details" />
			<Grid>
				<Grid.Column width={16}>
					<Segment clearing>
						<Segment clearing className="noPrint">
							<Button.Group floated="right">
								{/* <RenderIf condition={isAdmin(accountInfoUserEmail!) || accountInfoUserEmail === safetyChecklist.submitterEmail}> */}
								<Button as={Link} to={`/safety-checklist/manage/${safetyChecklist.id}`} color="orange">Edit</Button>
								{/* </RenderIf> */}
								<RenderIf condition={isAdmin(accountInfoUserEmail!) || accountInfoUserEmail === safetyChecklist.submitterEmail}>
									<Button color='red' onClick={() => openModal(<DeleteChecklist safetyChecklist={safetyChecklist} />)}>Delete</Button>
								</RenderIf>
							</Button.Group>
							<Button.Group floated="right">
								<Button as={Link} to={`/new-field-safety-audit/${safetyChecklist.id}`} color='yellow'>Create FSA</Button>
								<RenderIf condition={notificationForUserExists(accountInfoUserEmail!, safetyChecklist.notifications) === false}>
									<Button color='green' onClick={() => openModal(<CreateNotification safetyChecklist={safetyChecklist} />)}>Add Self to Notifications</Button>
								</RenderIf>
							</Button.Group>
						</Segment>
						<Form.Group widths="equal">
							<Segment>
								<div><b>Submitter Email:</b> {safetyChecklist.submitterEmail}</div>
								<div><b>Discipline:</b> {safetyChecklist.discipline ? safetyChecklist.discipline.name : "N/A"}</div>
								<div><b>Site Safety Leader:</b> {safetyChecklist.siteSafetyLeaderEmail}</div>
								<div>
									<b>Other Assigned Personnel:</b>
									<ul>
										{safetyChecklist.otherAssignedPersonnelEmails?.map((e) => (
											<Fragment key={e}>
												<li>{e}</li>
											</Fragment>
										))}
									</ul>
								</div>
							</Segment>

							<Header>Project Information</Header>
							<Segment>
								<Grid stackable columns={2}>
									<Grid.Column>
										<div><b>Project Number:</b> {safetyChecklist.projectNumber}</div>
										<RenderIf condition={safetyChecklist.projectName !== null || safetyChecklist.projectName !== ''}>
											<div><b>Project Name:</b> {safetyChecklist.projectName}</div>
										</RenderIf>
										<div><b>Project Manager:</b> {safetyChecklist.projectManagerEmail}</div>
										<div><b>Project Address:</b> <a target="_blank" href={`${convertAddressToGMapsLink(safetyChecklist.projectAddress)}`}>{safetyChecklist.projectAddress}</a></div>
										<div><b>Estimate Start Date:</b> {prettyPrintDate(safetyChecklist.estimatedStartDate)}</div>
										<div><b>Estimated Completion Date:</b> {prettyPrintDate(safetyChecklist.estimatedCompletionDate)}</div>
									</Grid.Column>
								</Grid>
							</Segment>
							
							<RenderIf condition={canView()}>
								<Header>Notifications</Header>
								<Segment>
									<Grid stackable columns={2}>
										{safetyChecklist.notifications?.map(notification => (<>
											<Grid.Row className='gridRow'>
												<Grid.Column>
													<div>
														{notification.userEmail}: {notification.acknowledged ? <Icon name='check' color="green" /> : <Icon name='x' color="red" />}
													</div>
												</Grid.Column>
												<Grid.Column>
													<div>
														Last Acknowledged: <RenderIf condition={notification.lastAcknowledged !== null}>{prettyPrintDateTime(notification.lastAcknowledged)}</RenderIf>
													</div>
													<div>
														Last Updated: <RenderIf condition={notification.updated !== null}>{prettyPrintDateTime(notification.updated)}</RenderIf>
													</div>
												</Grid.Column>
											</Grid.Row>
										</>))}
									</Grid>
								</Segment>
							</RenderIf>

							<Header>Emergency Information</Header>
							<Segment>
								<Grid stackable columns={2}>
									<Grid.Column>
										<div><b>Emergency Coordinator:</b> {safetyChecklist.emergencyCoordinatorEmail}</div>
										<div><b>Emergency Reporting Number:</b> {safetyChecklist.emergencyReportingNumber}</div>
										<div><b>Emergency Signal:</b> {safetyChecklist.emergencySignal}</div>
										<div><b>Evacuation Route and Muster Point:</b> {safetyChecklist.evacRouteAndMusterPoint}</div>
										<div><b>Emergency Medical Facility:</b> {safetyChecklist.emergencyMedicalFacility}</div>
										<div><b>Medical Facility Address:</b> <a target="_blank" href={`${convertAddressToGMapsLink(safetyChecklist.medicalFacilityAddress)}`}>{safetyChecklist.medicalFacilityAddress}</a></div>
										<div><b>Medical Facility Number:</b> {safetyChecklist.medicalFacilityNumber}</div>
									</Grid.Column>
								</Grid>
							</Segment>

							<Header>Hazard Information</Header>
							<Segment>
								<Grid stackable columns='equal' divided='vertically'>
									{safetyChecklist.hazards?.map((hazard) => (
										<Fragment key={hazard.id}>
											<Grid.Row centered>
												<Grid.Column ><b>{hazard.name}</b></Grid.Column>
											</Grid.Row>
											<Grid.Column>
												<Grid.Row><b>Associated SOPs</b></Grid.Row>
												<Grid.Row>
													<ul>
														{hazard.associatedSOPs?.map((sop) => (
															<Fragment key={sop.id}>
																<li>
																	<a href={sop.link}>
																		{sop.number ? `SOP #${sop.number} ` : null}
																		{sop.name}
																	</a>
																</li>
															</Fragment>
														))}
													</ul>
												</Grid.Row>
											</Grid.Column>
											<Grid.Column>
												<Grid.Row><b>Controls</b></Grid.Row>
												<Grid.Row>
													<ul>
														{hazard.associatedControls?.map((control) => (
															<Fragment key={control.id}>
																<li>{control.description}</li>
															</Fragment>
														))}
													</ul>
												</Grid.Row>
											</Grid.Column>
										</Fragment>
									))}
								</Grid>
							</Segment>
							
							<Header>Attachments</Header>
							<Segment>
								{safetyChecklist.files ? <>
									<ul>{safetyChecklist.files.map((filename:string, i:number) => 
										<li key={i}><a target="_blank" rel='noreferrer' href={process.env.REACT_APP_ATTACHMENTS_URL + `/${safetyChecklist.id}/` + filename}>{filename}</a></li>)}</ul> 
									</>  : <>None found.</>
								}
							</Segment>
							<Header>Comments</Header>
							<Segment>
								<SafetyChecklistCommentForm id={match.params.id} />
								{safetyChecklist.comments ? <>
									<ul>{safetyChecklist.comments.map((comment: IComment) => <li key={comment.id}>"{comment.content}"<br />- {comment.userEmail} | {prettyPrintDateTime(comment.created!)}</li>)}</ul> 
									</>  : <>None found.</>
								}
							</Segment>
							
							{/* Only render Additional SOP Forms if SOP is in associatedSOPs */}
							<RenderIf condition={sopPresent(safetyChecklist, 5) === true}>
								<Header>Chemical List</Header>
								<Button color="orange" type="button" content={`${chemFormVisible ? `Hide` : `Show`} Form`} onClick={() => setChemFormVisible(!chemFormVisible) }/>
								<RenderIf condition={chemFormVisible}>
									<ChemicalInvListForm id={match.params.id} />
								</RenderIf>
								<ChemicalInvListDetails chemicalList={safetyChecklist.chemicalList}/>
							</RenderIf>

							<RenderIf condition={sopPresent(safetyChecklist, 7) === true}>
								<Header>Excavation and Trenches</Header>
								<Segment>
									<div className="margin1">SOP #7 Appendix A PDF<span className="floatRight"><Button href="https://maserc.sharepoint.com/:b:/s/HealthAndSafetyFiles/EQQcAG-Ov7BAiTRlAXUCC1YB0j9mCcGAev3BE705ctdn8w?e=poHomq" color='blue' target="_blank">View</Button></span></div>
								</Segment>
							</RenderIf>

							<RenderIf condition={sopPresent(safetyChecklist, 8) === true}>
								<Header>Fall Hazard Assessment</Header>
								<Button color="orange" type="button" content={`${fallHazVisible ? `Hide` : `Show`} Form`} onClick={() => setFallHazVisible(!fallHazVisible) }/>
								<RenderIf condition={fallHazVisible}>
									<FallHazForm id={match.params.id} />
								</RenderIf>
								{safetyChecklist.fallHazardAssessments?.map((fha) => (
									<Segment>
										<div className="margin1">Submitted {prettyPrintDate(fha.createDate)} by {fha.createdByEmail} <span className="floatRight"><Button as={Link} to={`/fall-hazard-assessment/${fha.id}`} color='blue'>View</Button></span></div>
									</Segment>
								))}
							</RenderIf>

							<RenderIf condition={sopPresent(safetyChecklist, 16) === true}>
								<Header>Traffic Control Assessment</Header>
								<Button color="orange" type="button" content={`${trafficControlVisible ? `Hide` : `Show`} Form`} onClick={() => setTrafficControlVisible(!trafficControlVisible) }/>
								<RenderIf condition={trafficControlVisible}>
									<TrafficControlForm id={match.params.id} />
								</RenderIf>
								{safetyChecklist.trafficControlAssessments?.map((tca) => (
									<Segment>
										<div className="margin1">Submitted {prettyPrintDate(tca.createDate)} by {tca.createdByEmail} <span className="floatRight"><Button as={Link} to={`/traffic-control-assessment/${tca.id}`} color='blue'>View</Button></span></div>
									</Segment>
								))}
							</RenderIf>

							<RenderIf condition={sopPresent(safetyChecklist, 23) === true}>
								<Header>Confined Space Appendix A</Header>
								<Button color="orange" type="button" content={`${confinedSpaceAVisible ? `Hide` : `Show`} Form`} onClick={() => setConfinedSpaceAVisible(!confinedSpaceAVisible) }/>
								<RenderIf condition={confinedSpaceAVisible}>
									<ConfinedSpaceAForm id={match.params.id} />
								</RenderIf>
								{safetyChecklist.confinedSpaceAssessments?.map((csa) => (
									<Segment>
										<div className="margin1">Submitted {prettyPrintDateTime(csa.createDate)} by {csa.createdByEmail} <span className="floatRight"><Button as={Link} to={`/confined-space-assessment/${csa.id}`} color='blue'>View</Button></span></div>
									</Segment>
								))}
							</RenderIf>

							<RenderIf condition={sopPresent(safetyChecklist, 30) === true}>
								<Header>Aerial Lift Checklist Appendix A</Header>
								<Button color="orange" type="button" content={`${aerielLiftCHecklistVisible ? `Hide` : `Show`} Form`} onClick={() => setAerialLiftChecklistVisible(!aerielLiftCHecklistVisible) }/>
								<RenderIf condition={aerielLiftCHecklistVisible}>
									<AerialLiftChecklistForm id={match.params.id} />
								</RenderIf>
								{safetyChecklist.aerialLifts?.map((al) => (
									<Segment>
										<div className="margin1">Submitted {prettyPrintDateTime(al.createDate)} by {al.createdByEmail} <span className="floatRight"><Button as={Link} to={`/aerial-lift-assessment/${al.id}`} color='blue'>View</Button></span></div>
									</Segment>
								))}
							</RenderIf>


							<RenderIf condition={safetyChecklist.discipline?.name.includes('Survey')! || safetyChecklist.discipline?.name === 'SUE Services'}>
								<Header>Site Safety Briefings</Header>
								<Button color="orange" type="button" content={`${siteSafetyBriefingVisible ? `Hide` : `Show`} Form`} onClick={() => setSiteSafetyBriefingVisible(!siteSafetyBriefingVisible) }/>
								<RenderIf condition={siteSafetyBriefingVisible}>
									<SiteSafetyBriefingForm id={match.params.id} />
								</RenderIf>	
								{safetyChecklist.siteSafetyBriefings?.map((ssb) => (
									<Segment>
										<div className="margin1">Submitted {prettyPrintDateTime(ssb.createDate)} by {ssb.createdByEmail} <span className="floatRight"><Button as={Link} to={`/site-safety-briefing/${ssb.id}`} color='blue'>View</Button></span></div>
									</Segment>
								))}							
							</RenderIf>
						</Form.Group>
						<div>Checklist created on {prettyPrintDateTime(safetyChecklist.created)}</div>
						<RenderIf condition={safetyChecklist.lastModifiedBy !== null}>
							<div>Last modified on {prettyPrintDateTime(safetyChecklist.lastModified)} by {safetyChecklist.lastModifiedBy}</div>
						</RenderIf>
					</Segment>
				</Grid.Column>
			</Grid>
		</>
	);
};

export default observer(SafetyChecklistDetails);
