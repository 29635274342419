import React, { useContext, useEffect } from "react";
import { Grid, Segment, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { prettyPrintDateTime } from "../../../app/common/util/util";

interface DetailParams {
  id: string;
}

const NotificationDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { notification, loadNotification, loadingInitial } = rootStore.notificationStore;

  useEffect(() => {
    loadNotification(match.params.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, history]);

  if (loadingInitial)
    return <LoadingComponent content="Loading Notification..." />;

  if (!notification) return <h2>Notification Not Found</h2>;

  return (
    <>
      <PageTitle title="Notification Details" />
      <Segment clearing>
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column>
              <p><b>User:</b><span className="accidentDetail"> {notification.userEmail}</span></p>
              <p><b>Safety Checklist:      </b><span className="accidentDetail">{notification.safetyChecklist.projectNumber}</span></p>
            </Grid.Column>
            <Grid.Column width={8}>
              <p><b>Created:</b> <span className="accidentDetail"> {prettyPrintDateTime(notification.created)}</span></p>
              <p><b>Last Updated:</b> <span className="accidentDetail"> {prettyPrintDateTime(notification.updated)} </span></p>
              <p><b>Last Acknowlegded:</b> <span className="accidentDetail"> {prettyPrintDateTime(notification.lastAcknowledged)}</span></p>

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Button as={Link} to={`/notification/manage/${notification.id}`} color="orange" floated="right">
        Edit Notification
      </Button>

    </>
  );
};

export default observer(NotificationDetails);
