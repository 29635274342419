import { observable, action, runInAction, computed } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import { RootStore } from "./rootStore";
import agent from "../api/agent";
import { IIncidentReport } from "../models/incidentReport";
import { localizeDateTime } from "../common/util/util";

const PAGE_SIZE = 8;

export default class IncidentInjuryReportStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable incidentReport: IIncidentReport | null = null;
  @observable incidentReportRegistry = new Map();
  @observable submitting = false;
  @observable loadingInitial = false;

  @observable responseFormVisible: boolean = false;

  @observable incidentReportCount: number = 0;
  @observable page = 0;

  @observable predicate = new Map<string, string | number | boolean>();

  @action redefineDatesWithTimezones = (incidentReport: IIncidentReport) => {
    incidentReport.incidentDate = localizeDateTime(new Date(incidentReport.incidentDate));
    incidentReport.incidentTime = localizeDateTime(new Date(incidentReport.incidentTime));
    incidentReport.reportDate = localizeDateTime(new Date(incidentReport.reportDate));
    incidentReport.startedTime = localizeDateTime(new Date(incidentReport.startedTime));
    if (incidentReport.treatmentDate) incidentReport.treatmentDate = localizeDateTime(new Date(incidentReport.treatmentDate));
    if (incidentReport.outStartDate) incidentReport.outStartDate = localizeDateTime(new Date(incidentReport.outStartDate));
    if (incidentReport.outEndDate) incidentReport.outEndDate = localizeDateTime(new Date(incidentReport.outEndDate));
  }

  /*************************************
   *
   *************************************/

  @action setCount = (count: number) => {
    this.incidentReportCount = count;
  };

  /*************************************
   *
   *************************************/

  @action setResponseFormVisible = (value: boolean) => {
    this.responseFormVisible = value;
  };

  /*************************************
   *
   *************************************/

  @computed get totalPages() {
    return Math.ceil(this.incidentReportCount / PAGE_SIZE);
  }

  /*************************************
   *
   *************************************/

  @action setPage = (page: number) => {
    this.page = page;
  };

  /*************************************
   *
   *************************************/

  get incidentReportsLength() {
    return this.incidentReportRegistry.size;
  }

  /*************************************
   *
   *************************************/

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  /*************************************
   *
   *************************************/

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));

    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });

    return params;
  }

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearIncidentReportRegistry = () => {
    this.incidentReportRegistry.clear();
    this.page = 0;
  };

  @computed get incidentReportsByDefault() {
    return Array.from(this.incidentReportRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.createdDate.toString()) -
        Date.parse(a.createdDate.toString())
    ); // sort in descending order
  }

  /*************************************
   *
   *************************************/

  @computed get incidentReportsByDate() {
    return this.groupIncidentReportsByDate(
      Array.from(this.incidentReportRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  groupIncidentReportsByDate(incidentReports: IIncidentReport[]) {
    const sortedIncidentReports = incidentReports.sort(
      (a, b) =>
        Date.parse(b.incidentDate.toString()) -
        Date.parse(a.incidentDate.toString())
    );

    return Object.entries(
      sortedIncidentReports.reduce((incidentReports, incidentReport) => {
        const date = new Date(incidentReport.incidentDate.toString()).toDateString();
        incidentReports[date] = incidentReports[date]
          ? [...incidentReports[date], incidentReport]
          : [incidentReport];
        return incidentReports;
      }, {} as { [key: string]: IIncidentReport[] })
    );
  }

  @action loadIncidentReports = async () => {
    this.loadingInitial = true;

    try {
      const response = await agent.IncidentReport.list(this.axiosParams);
      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);
      runInAction("loading incidentReports", () => {

        response.data.incidentReports.forEach((incidentReport: IIncidentReport) => {
          this.redefineDatesWithTimezones(incidentReport);
          this.incidentReportRegistry.set(incidentReport.id, incidentReport);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load incidentReports error", () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };
  
  /*************************************
   *
   *************************************/
  
  getIncidentReport = (id: string) => {
    return this.incidentReportRegistry.get(id);
  };

  /*************************************
   *
   *************************************/
  
  @action loadIncidentReport = async (id: string) => {
    let incidentReport = this.getIncidentReport(id);
    this.loadingInitial = true;

    try {
      const response = await agent.IncidentReport.details(id);
      runInAction("getting incidentReport", () => {
        incidentReport = response;
        this.redefineDatesWithTimezones(incidentReport);
        this.incidentReport = incidentReport!;
        this.incidentReportRegistry.set(incidentReport.id, incidentReport);
        this.loadingInitial = false;
      });
      return incidentReport;
    } catch (error) {
      runInAction("get incidentReport error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  @action createIncidentReport = async (incidentReport: FormData) => {
    this.submitting = true;
    try {
      const response = await agent.IncidentReport.create(incidentReport);
      runInAction("create incidentReport", () => {
        this.incidentReportRegistry.set(response.id, response);
        this.submitting = false;
      });
      history.push(`/incident-report/${response.id}`);
      toast.success("New Incident/Injury Report Added");
    } catch (error: any) {
      runInAction("create accident Report form error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
      console.error(error.response);
    }
  };

  /*************************************
   *
   *************************************/

  @action editIncidentReport = async (incidentReport: FormData) => {
    this.submitting = true;
    try {
      const response = await agent.IncidentReport.update(incidentReport);
      runInAction("editing incidentReport", () => {
        response.incidentDate = new Date(response.incidentDate);
        response.reportDate = new Date(response.reportDate);
        response.startedTime = new Date(response.startedTime);
        response.treatmentDate = new Date(response.treatmentDate);
        response.outEndDate = new Date(response.outEndDate);
        response.outStartDate = new Date(response.outStartDate);
        this.incidentReportRegistry.set(response.id, response);
        this.incidentReport = response;
        this.submitting = false;
      });
      history.push(`/incident-report/${response.id}`);
      toast.success("Incident/Injury Report Edited");
    } catch (error: any) {
      runInAction("edit incidentReport error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
      console.log(error.response);
    }
  };

  @action deleteIncidentReport = async (id: string) => {
    this.submitting = true;
    try {
      var sc = await agent.IncidentReport.delete(id);
      runInAction("deleting incident report", () => {
        this.submitting = false;
      });
      history.push('/');
      toast.success('Incident/Injury Report Deleted');
    } catch (error) {
      toast.error("Problem submitting data");
      console.log(error);
    }
  };
}
