import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Segment, Grid } from "semantic-ui-react";
// import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import te from "date-fns/esm/locale/te/index.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    CategoryScale,
    Title,
    Tooltip,
    Legend
);

const getCurrentYear = () => new Date().getFullYear();

const completedAuditDataInitial = {
    labels: [''],
    datasets: [
        {
            label: 'YTD - Up To Goal',
            data: [0],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'YTD - All',
            data: [0],
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
        },
    ],
};

const completedByDivisionDataInitial = {
    labels: ['Discipline 1', 'Discipline 2', 'Discipline 3', 'Discipline 4', 'Division 5', 'Division 6',  ],
    datasets: [
        {
            label: 'Completed Audits',
            data: [12, 1, 7, 3, 2, 5, ],
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
        {
            label: 'To Reach Goal',
            data: [3, 4, 1, 10, 2, 3, ],
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
        },
    ],
};


const completedByDivisionOptions = {
    plugins: {
        title: {
        display: true,
        text: 'Completed Audits By Division',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};


const pieDataInitial = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Occurrences',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};


const FieldSafetyAuditCharts: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { 
        loadingInitial,
        chartData,
        loadChartData,
    } = rootStore.fieldSafetyAuditStore;

    const [showChart, setShowChart] = useState(false);
    const [completedAuditData, setCompletedAuditData] = useState(completedAuditDataInitial);
    const [completedByDivisionData, setCompletedByDivisionData] = useState(completedByDivisionDataInitial);
    const [pieData, setPieData] = useState(pieDataInitial)
    const [loading, setLoading] = useState(false);

    const completedAuditOptions = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
            borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const,
            },
            title: {
                display: true,
                text: `${getCurrentYear()} Completion Goal: ${chartData.completedCompanyAudits?.goal}`,
            },
        },
    };

	useEffect(() => {
        setLoading(true);
		loadChartData().then(() => {
            var temp = completedAuditDataInitial;
            temp.datasets[0].data = [chartData.completedCompanyAudits!.goalCompleted];
            temp.datasets[1].data = [chartData.completedCompanyAudits!.totalCompleted];
            setCompletedAuditData(temp);
            var temp2 = completedByDivisionDataInitial;
            temp2.labels = [];
            temp2.datasets[0].data = [];
            temp2.datasets[1].data = [];
            for(var i = 0; i < chartData.completedDisciplineAuditData!.length; i++){
                temp2.labels.push(chartData.completedDisciplineAuditData![i].name);
                temp2.datasets[0].data.push(chartData.completedDisciplineAuditData![i].completed);
                temp2.datasets[1].data.push(chartData.completedDisciplineAuditData![i].toReachGoal);
            }
            setCompletedByDivisionData(temp2);

            var temp3 = pieDataInitial;
            temp3.labels = []
            temp3.datasets[0].data = [];
            for(var i = 0; i < chartData.nonConformancesByType!.length; i++){
                temp3.labels.push(chartData.nonConformancesByType![i].nonConformance);
                temp3.datasets[0].data.push(chartData.nonConformancesByType![i].amount);
            }
            setPieData(temp3);
        }).finally(() => setLoading(false));
	}, []);

    if(loading){
        return <LoadingComponent content="Loading FSA Charts..." />
    }

    return (
        <>
            <Segment>
                <Grid container>
                    <Grid.Row columns={2}>
                        <Grid.Column width={10}>
                            <Bar options={completedAuditOptions} data={completedAuditData}/> 
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Pie data={pieData}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Bar options={completedByDivisionOptions} data={completedByDivisionData} />
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
};

export default observer(FieldSafetyAuditCharts);
