import React, { useContext, useState, useEffect } from "react";
import { Header, Button, Form, Grid, Segment, DropdownProps, Step, Icon} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { RouteComponentProps } from "react-router-dom";

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { IncidentReportFormValues, IIncidentReportFormValues } from "../../../app/models/incidentReport";
import DateInput from "../../../app/common/form/DateInput";
import TimeInput from "../../../app/common/form/TimeInput";
import { FieldFileDropZone } from "../../../app/common/form/FileDropZone";
import SelectInput from "../../../app/common/form/SelectInput";
import RenderIf from "../../../app/common/form/RenderIf";
import ConfirmCancelForm from '../../modals/ConfirmCancelForm';
import { StepButtons } from '../../../app/common/form/StepButtons';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { isFieldEmpty, to24TimeString } from "../../../app/common/util/util";

/* eslint-disable no-cond-assign */
interface DetailParams {
	id: string;
}

const IncidentReportForm: React.FC<RouteComponentProps<DetailParams>> = ({ match , history}) => {
	const rootStore = useContext(RootStoreContext);
	const { createIncidentReport, loadIncidentReport, editIncidentReport, submitting } = rootStore.incidentReportStore;
	const { loadUsers, userList, loadingInitial } = rootStore.generalStore;
	const { openModal } = rootStore.modalStore;

	const [incidentReport, setIncidentReport] = useState(new IncidentReportFormValues());
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);

	const isStepValid = (values: IIncidentReportFormValues): string | null => {
		switch(step) {
			case 1:
				return null;
			case 2:
				let e2;
				if (e2 = isFieldEmpty(values.projNum)) return e2;
				if (e2 = isFieldEmpty(values.clientName)) return e2;
				else if (e2 = isFieldEmpty(values.location)) return e2;
				return null;
			case 3:
				return null;
			case 4:
				return null;
			default:
				return 'invalid step number';
		}
	  };

	useEffect(() => {
		loadUsers();
		if (match.params.id) {
			setLoading(true);
			loadIncidentReport(match.params.id)
				.then((incidentReport: IncidentReportFormValues) => {
					let tempIncidentReport = new IncidentReportFormValues(incidentReport);
					setIncidentReport(new IncidentReportFormValues(tempIncidentReport));
				})
				.finally(() => setLoading(false));
		}
	}, [loadIncidentReport, match.params.id, loadUsers]);

	useEffect(() => {
		if (step === 2) setTimeInputs();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step]);

	const setTimeInputs = () => {
		let time = to24TimeString(incidentReport.startedTime);
		let timeControl = document.querySelector('input[name=startedTime') as any;
		timeControl.value = time;
		time = to24TimeString(incidentReport.incidentTime);
		timeControl = document.querySelector('input[name=incidentTime') as any;
		timeControl.value = time;
	}

	const handleNameChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setIncidentReport({ ...incidentReport, name: data.value as string });
	};
	
	const handleOnDutyChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		setIncidentReport({ ...incidentReport, onDuty: data.value as string });
	};

	const handleFinalFormSubmit = (values: IncidentReportFormValues) => {
		let shortStartTime: any = values.startedTime;
		let shortIncTime: any = values.incidentTime;
		if (typeof values.startedTime === 'object') {
			shortStartTime = to24TimeString(values.startedTime);
		}
		if (typeof values.incidentTime === 'object') {
			shortIncTime = to24TimeString(values.incidentTime);
		}

		var startedTimeConversion = new Date(`1970-01-01T${shortStartTime}:00`);
		console.log(startedTimeConversion);
		var incidentTimeConversion = new Date(`1970-01-01T${shortIncTime}:00`);
		console.log(incidentTimeConversion);
		const payload = new FormData();
		payload.set('id', values.id);				
		payload.set('lostTime', values.lostTime.toString());				
		payload.set('firstAid', values.firstAid.toString());
		payload.set('vehicleDamage', values.vehicleDamage.toString());
		payload.set('injuryIllness', values.injuryIllness.toString());
		payload.set('disabling', values.disabling.toString());
		payload.set('restrictedDuty', values.restrictedDuty.toString());
		payload.set('propDamage', values.propDamage.toString());
		payload.set('airEmission', values.airEmission.toString());
		payload.set('accReleaseSpill', values.accReleaseSpill.toString());
		payload.set('nearMiss', values.nearMiss.toString());
		payload.set('thirdParty', values.thirdParty.toString());
		payload.set('theft', values.theft.toString());
		payload.set('other', values.other.toString());
		if (values.otherDesc !== undefined && values.otherDesc !== null) payload.set('otherDesc', values.otherDesc);
		if (values.whoseInjury !== undefined && values.whoseInjury !== null) payload.set('whoseInjury', values.whoseInjury);
		payload.set('incidentDate', values.incidentDate.toISOString());
		payload.set('incidentTime', incidentTimeConversion.toISOString());
		payload.set('reportDate', values.reportDate.toISOString());
		payload.set('startedTime', startedTimeConversion.toISOString());
		console.log(startedTimeConversion.toISOString());
		if (values.projNum !== undefined && values.projNum !== null) payload.set('projNum', values.projNum);
		if (values.clientName !== undefined && values.clientName !== null) payload.set('clientName', values.clientName);
		if (values.onDuty !== undefined && values.onDuty !== null) payload.set('onDuty', values.onDuty);
		if (values.location !== undefined && values.location !== null) payload.set('location', values.location);
		if (values.witness !== undefined && values.witness !== null) payload.set('witness', values.witness);
		if (values.nature !== undefined && values.nature !== null) payload.set('nature', values.nature);
		if (values.task !== undefined && values.task !== null) payload.set('task', values.task);
		if (values.desc !== undefined && values.desc !== null) payload.set('desc', values.desc);
		if (values.factors !== undefined && values.factors !== null) payload.set('factors', values.factors);
		if (values.treatmentLocation !== undefined && values.treatmentLocation !== null) payload.set('treatmentLocation', values.treatmentLocation);
		if (values.treatmentDate) payload.set('treatmentDate', values.treatmentDate.toISOString());
		if (values.treatedBy !== undefined && values.treatedBy !== null) payload.set('treatedBy', values.treatedBy);
		if (values.drPhone !== undefined && values.drPhone !== null) payload.set('drPhone', values.drPhone);
		if (values.restrictions !== undefined && values.restrictions !== null) payload.set('restrictions', values.restrictions);
		if (values.nextAppt !== undefined && values.nextAppt !== null) payload.set('nextAppt', values.nextAppt);
		if (values.diagnosis !== undefined && values.diagnosis !== null) payload.set('diagnosis', values.diagnosis);
		payload.set('outOfWork', values.outOfWork.toString());				
		if (values.outStartDate) payload.set('outStartDate', values.outStartDate.toISOString());
		if (values.outEndDate) payload.set('outEndDate', values.outEndDate.toISOString());
		if (values.comments !== undefined && values.comments !== null) payload.set('comments', values.comments);
		
		if (values.fileArray && values.fileArray !== undefined) {
			for (var i = 0; i < values.fileArray.length; i++) {
				payload.append("newFiles", values.fileArray[i]);
			}
		}
	  
		if (!incidentReport.id) {
			createIncidentReport(payload);
		} else {
			editIncidentReport(payload);
		}
	};

	if (loadingInitial)
	    return <LoadingComponent content="Loading Incident/Injury Report Form..." />;

	return (
		<>
			<PageTitle title="Incident/Injury Report Form" />
				<Step.Group fluid size='mini'>
					<Step completed={step > 1} active={step === 1}>
						<Icon name='users' />
						<Step.Content>
							<Step.Title>People</Step.Title>
						</Step.Content>
					</Step>

					<Step completed={step > 2} active={step === 2}>
						<Icon name='exclamation triangle' />
						<Step.Content>
							<Step.Title>Incident</Step.Title>
						</Step.Content>
					</Step>		

					<Step completed={step > 3} active={step === 3}>
						<Icon name='stethoscope' />
						<Step.Content>
							<Step.Title>Medical</Step.Title>
						</Step.Content>
					</Step>		

					<Step completed={step > 4} active={step === 4}>
						<Icon name='file alternate' />
						<Step.Content>
							<Step.Title>Misc</Step.Title>
						</Step.Content>
					</Step>		
				</Step.Group>
			
			<Grid>
				<Grid.Column width={16}>
					<Segment clearing>
						<FinalForm initialValues={incidentReport} onSubmit={handleFinalFormSubmit} render={({ handleSubmit, invalid, values }) => 
							(
								<Form onSubmit={handleSubmit} loading={loading} className="sopForm" >	

									<Button disabled={loading} color='yellow' type="button" content="Cancel" onClick={()=>openModal(<ConfirmCancelForm />)} />	
									<RenderIf condition={step === 4}>
										<Button loading={submitting} disabled={loading || invalid} floated="right" positive type="submit" content="Submit" />
									</RenderIf>
									<StepButtons isValid={() => isStepValid(values)} step={step} prevStep={() => { setStep(step - 1); }} nextStep={() => { setStep(step + 1); }} stepmax={4} />
									
									<RenderIf condition={step === 1}>
										<Segment>
											<Grid>
												<Grid.Row>
													<Header as="h5">Was someone injured, if so who?</Header>
													<Field name="whoseInjury" clearable search fluid className="link item" placeholder="Employee Injured" onChange={handleNameChange} value={incidentReport.name} component={SelectInput}
														options={userList.map((user) => ({ key: user.aoid, value: user.email, text: user.formattedName,}))}
													/>
												</Grid.Row>
												<Grid.Row>
													<Header as="h5">DL/GDL or PM/SSL on Duty</Header>
													<Field name="onDuty" clearable search fluid className="link item" placeholder="On Duty" onChange={handleOnDutyChange} value={incidentReport.onDuty} component={SelectInput}
														options={userList.map((user) => ({ key: user.aoid, value: user.email, text: user.formattedName, }))}
													/>
												</Grid.Row>
											</Grid>
										</Segment>
									</RenderIf>

									<RenderIf condition={step === 2}>
										<Segment>
											<Grid columns={'equal'}>
												<Grid.Row id="accRepBoxes">
													<Header as="h5">Incident Type (Check all that apply)</Header>
													<Grid.Column>
														<Field label="Lost Time" name="lostTime" component={CheckboxInput} type='checkbox' />
														<Field label="First Aid" name="firstAid" component={CheckboxInput} type='checkbox' />
														<Field label="Vehicle Damage" name="vehicleDamage" component={CheckboxInput} type='checkbox' />
														<Field label="Injury/Illness" name="injuryIllness" component={CheckboxInput} type='checkbox' />
													</Grid.Column>
													<Grid.Column>
														<Field label="Disabling" name="disabling" component={CheckboxInput} type='checkbox' />
														<Field label="Restricted Duty" name="restrictedDuty" component={CheckboxInput} type='checkbox' />
														<Field label="Property Damage" name="propDamage" component={CheckboxInput} type='checkbox' />
														<Field label="Air Emission" name="airEmission" component={CheckboxInput} type='checkbox' />
													</Grid.Column>
													<Grid.Column>
														<Field label="Accidental Release/Spill" name="accReleaseSpill" component={CheckboxInput} type='checkbox' />
														<Field label="3rd Party Involved" name="thirdParty" component={CheckboxInput} type='checkbox' />
														<Field label="Theft" name="theft" component={CheckboxInput} type='checkbox' />
														<Field label="Other" name="other" component={CheckboxInput} type='checkbox' />
													</Grid.Column>
													<Field width={16} fluid name="otherDesc" placeholder="Other Description..." component={TextInput} type={values.other ? "text" : "hidden"}
													/>
												</Grid.Row>
												<Grid.Row>
													<Field width={8} label="Project Number" placeholder="Project Number" name="projNum" component={TextInput} validate={() => isFieldEmpty(values.projNum)} required />
													<Field width={8} label="Client Name" placeholder="Client Name" name="clientName" component={TextInput} validate={() => isFieldEmpty(values.clientName)} required />
												</Grid.Row>
												<Grid.Row>
													<Field label="Date of Incident" name="incidentDate" component={DateInput} date={true} />
													<Field label="Time of Incident" name="incidentTime" component={TimeInput}	time={true} />
													<Field label="Shift/Time Started" name="startedTime" component={TimeInput} time={true} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Location of Incident" placeholder="Incident Location" name="location" component={TextInput} validate={() => isFieldEmpty(values.location)} required />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Witness to Incident" placeholder="Incident Witness" name="witness" component={TextInput} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Nature of Injury" placeholder="Incident Nature" name="nature" component={TextInput}
													/>
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Task Being Performed" placeholder="Brief description of task and equipment used at time of the incident" name="task" component={TextAreaInput} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Detailed Incident Description" placeholder="What was the work assignment, nature of the incident, result of the incident?" name="desc" component={TextAreaInput} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Why Did it Happen?" placeholder="What were the contributing factors?" name="factors" component={TextAreaInput} />
												</Grid.Row>
											</Grid>
										</Segment>
									</RenderIf>
									
									<RenderIf condition={step === 3}>
										<Segment>
											<Grid width='equal'>
												<Grid.Row>
													<Field width={12} label="Treatment Location" placeholder="Treatment Location" name="treatmentLocation" component={TextInput} />
													<Field label="Date" name="treatmentDate" component={DateInput} date={true} />
												</Grid.Row>
												<Grid.Row>
													<Field width={8} label="Treated By" placeholder="Treated By" name="treatedBy" component={TextInput} />
													<Field width={8} label="Physician Phone" placeholder="Physician Phone" name="drPhone" component={TextInput} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="If employee is placed on restricted duty, provide details of restriction" placeholder="If employee is placed on restricted duty, provide details of restriction" name="restrictions" component={TextAreaInput} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="If employee is removed from duty, provide the next schedule medical appointment (date and doctor's name)" placeholder="If employee is removed from duty, provide the next schedule medical appointment (date and doctor's name)" name="nextAppt" component={TextAreaInput} />
												</Grid.Row>
												<Grid.Row>
													<Field width={16} label="Diagnosis (if any)" placeholder="Diagnosis (if any)" name="diagnosis" component={TextAreaInput} />
												</Grid.Row>

												<Grid.Row>
													<Field label="Are they out of work?" name="outOfWork" component={CheckboxInput} type='checkbox' />
												</Grid.Row>
												{values.outOfWork && <>
													<Grid.Row>
														<Field label="Out of Work From" name="outStartDate" component={DateInput} date={true} />
														<Field label="Out of Work To" name="outEndDate" component={DateInput} date={true} />
													</Grid.Row>
												</>}
											</Grid>
										</Segment>
									</RenderIf>
									
									<RenderIf condition={step === 4}>
										<Segment>
											<Grid>
												<Grid.Row>
													<Field width={16} name="comments" placeholder="Comments" label="Additional Comments" component={TextAreaInput} />
												</Grid.Row>

												<Grid.Row>
													<b>Additional Files</b>
													<FieldFileDropZone name="fileArray" maxFiles={6}
														accept={[
															"image/*",
															"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
															"application/msword",
															"application/pdf",
														].join(",")}
													/>
												</Grid.Row>
											</Grid>
										</Segment>					
										<Button loading={submitting} disabled={loading || invalid} floated="right" positive type="submit" content="Submit" />
									</RenderIf>
									<Button disabled={loading} color='yellow' type="button" content="Cancel" onClick={()=>openModal(<ConfirmCancelForm />)} />
									<StepButtons isValid={() => isStepValid(values)} step={step} prevStep={() => { setStep(step - 1); }} nextStep={() => { setStep(step + 1); }} stepmax={4} scrollToTop />
								</Form>
							)}
						/>
					</Segment>
				</Grid.Column>
			</Grid>
		</>
	);
};

export default observer(IncidentReportForm);
