export interface ICorrectiveAction {
    id: string;
    nonConformance: string;
    comments: string;
    description?: string;
    responsiblePartyEmail?: string;
    dueDate?: Date;
    completionDate?: Date;
}

export interface IServerboundCorrectiveAction {
    nonConformance: string | null;
    comments: string | null;
    description: string | null;
    responsiblePartyEmail: string | null;
    dueDate: string | null;
    completionDate: string | null;
}

export class ServerboundNewCorrectiveAction implements Pick<IServerboundCorrectiveAction, "nonConformance" | "comments"> {
    nonConformance: string | null = null;
    comments: string | null = null;
    
    constructor(ca: ICorrectiveAction) {
        if(ca.nonConformance) this.nonConformance = ca.nonConformance;
        if(ca.comments) this.comments = ca.comments;
    }
}

export class ServerboundCorrectiveAction implements IServerboundCorrectiveAction {
    nonConformance: string | null = null;
    comments: string | null = null;
    description: string | null = null;
    responsiblePartyEmail: string | null = null;
    dueDate: string | null = null;
    completionDate: string | null = null;
    
    constructor(ca : ICorrectiveAction) {
        if(ca.nonConformance) this.nonConformance = ca.nonConformance;
        if(ca.comments) this.comments = ca.comments;
        if(ca.description) this.description = ca.description;
        if(ca.responsiblePartyEmail) this.responsiblePartyEmail = ca.responsiblePartyEmail;
        if(ca.dueDate) this.dueDate = ca.dueDate.toISOString();
        if(ca.completionDate) this.completionDate = ca.completionDate?.toISOString();
    }
}

export class CorrectiveActionFormValues implements ICorrectiveAction {

    id: string= '';
    nonConformance: string = '';
    comments: string = '';

    constructor(init?: CorrectiveActionFormValues) {
        Object.assign(this,init);
    }
}
    
export class CorrectiveActionUpdateFormValues implements ICorrectiveAction {
    id: string= '';
    nonConformance: string = '';
    comments: string = '';
    description?: string = '';               
    responsiblePartyEmail?: string = '';
    dueDate?: Date = new Date();
    completionDate?: Date = new Date();

    constructor(init?: CorrectiveActionUpdateFormValues) {
        Object.assign(this,init);
      }

}