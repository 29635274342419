import React, { useContext, useEffect, useState } from "react";
import { Segment, Table} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, Link } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";

interface DetailParams {
	id: string;
}

const FallHazDetails: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
  const rootStore = useContext(RootStoreContext);
	const { fallHazardAssessment, loadFallHazardAssessment, loadingInitial } = rootStore.safetyChecklistStore;
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		setLoading(true);
		loadFallHazardAssessment(match.params.id).then(() => setLoading(false));
	}, [loadFallHazardAssessment, match.params.id, history]);

	if (loadingInitial || loading) return <LoadingComponent content="Loading Fall Hazard Assessment..." />;

	if (!fallHazardAssessment) return <h2>Fall Hazard Assessment Not Found</h2>;

	return (
		<>
      <PageTitle title="Fall Hazard Assessment Details" />
      <Segment clearing>
        <Table>
          {fallHazardAssessment.location_1_1 && <>
              <Table.Row>
                  <Table.Cell>
                      Any other walking/working surface with an unprotected side or edge 6 feet or more above a lower level
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_1_1}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_1_2 && <>
              <Table.Row>
                  <Table.Cell>
                      Hoist areas 6 feet or more above a lower level
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_1_2}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_1_3 && <>
              <Table.Row>
                  <Table.Cell>
                      Excavations 6 feet or more in depth
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_1_3}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_1_4 && <>
              <Table.Row>
                  <Table.Cell>
                      Fall hazards from open pits, tanks, vats, ditches, etc
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_1_4}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_2_1 && <>
              <Table.Row>
                  <Table.Cell>
                      Stairway floor openings
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_2_1}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_2_2 && <>
              <Table.Row>
                  <Table.Cell>
                      Ladderway floor opening or platform
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_2_2}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_2_3 && <>
              <Table.Row>
                  <Table.Cell>
                      Hatchway and chute floor openings
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_2_3}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_2_4 && <>
              <Table.Row>
                  <Table.Cell>
                      Skylight floor openings
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_2_4}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_2_5 && <>
          <Table.Row>
              <Table.Cell>
                  Pit and trapdoor floor openings
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_5}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_6 && <>
          <Table.Row>
              <Table.Cell>
                  Manhole floor openings
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_6}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_7 && <>
              <Table.Row>
                  <Table.Cell>
                      Temporary floor openings
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.location_2_7}
                  </Table.Cell>
              </Table.Row>
          </>}
          {fallHazardAssessment.location_2_8 && <>
          <Table.Row>
              <Table.Cell>
                  Floorholes into which persons can accidentally walk
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_8}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_9 && <>
          <Table.Row>
              <Table.Cell>
                  Doors or gates which open directly into a stairway
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_9}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_10 && <>
          <Table.Row>
              <Table.Cell>
                  Wall openings with drops of more than 6 feet
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_10}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_11 && <>
          <Table.Row>
              <Table.Cell>
                  Window wall opening at stairway landings, floors, platforms, or balconies from which there is a drop of more than 6 feet
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_11}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_12 && <>
          <Table.Row>
              <Table.Cell>
                  Temporary wall openings
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_12}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_13 && <>
          <Table.Row>
              <Table.Cell>
                  Hazard of material falling
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_13}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_14 && <>
          <Table.Row>
              <Table.Cell>
                  Open-sided platforms 6 feet or more above the adjacent floor or ground level
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_14}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_15 && <>
          <Table.Row>
              <Table.Cell>
                  Runways with open sides 6 feet or more above the floor or ground level
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_15}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_16 && <>
          <Table.Row>
              <Table.Cell>
                  Open-sided floors, walkways, platforms, or runways above or adjacent to dangerous equipment regardless of heights
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_16}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_2_17 && <>
          <Table.Row>
              <Table.Cell>
                  Unguarded stairs with four or more risers with no handrail or guardrail
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_2_17}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_3_1 && <>
          <Table.Row>
              <Table.Cell>
                  Ladders of more than 24 feet climb without cage or fall protection
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_3_1}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_3_2 && <>
          <Table.Row>
              <Table.Cell>
                  Ladder landings
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_3_2}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_3_3 && <>
          <Table.Row>
              <Table.Cell>
                  Landing platforms
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_3_3}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_4_1 && <>
          <Table.Row>
              <Table.Cell>
                  Scaffolding with open sides more than 10 feet above the ground or the floor
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_4_1}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.location_4_2 && <>
          <Table.Row>
              <Table.Cell>
                  Crawl boards or chicken ladders
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.location_4_2}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.harness_Manufacturer && <>
          <Table.Row>
              <Table.Cell>
                  Harness manufacturer
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.harness_Manufacturer}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.harness_Model && <>
          <Table.Row>
              <Table.Cell>
                  Harness model
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.harness_Model}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.harness_Serial && <>
          <Table.Row>
              <Table.Cell>
                  Harness serial
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.harness_Serial}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.lanyard_Manufacturer && <>
          <Table.Row>
              <Table.Cell>
                  Lanyard manufacturer
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.lanyard_Manufacturer}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.lanyard_Model && <>
          <Table.Row>
              <Table.Cell>
                  Lanyard model
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.lanyard_Model}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.lanyard_Serial && <>
          <Table.Row>
              <Table.Cell>
                  Lanyard serial
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.lanyard_Serial}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.anchor_Manufacturer && <>
          <Table.Row>
              <Table.Cell>
                  Achor strap manufacturer
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.anchor_Manufacturer}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.anchor_Model && <>
          <Table.Row>
              <Table.Cell>
                  Anchor strap model
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.anchor_Model}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.anchor_Serial && <>
          <Table.Row>
              <Table.Cell>
                  Anchor strap serial
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.anchor_Serial}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.carabineer_Manufacturer && <>
          <Table.Row>
              <Table.Cell>
                  Carabineer manufacturer
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.carabineer_Manufacturer}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.carabineer_Model && <>
          <Table.Row>
              <Table.Cell>
                  Carabineer model
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.carabineer_Model}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.carabineer_Serial && <>
          <Table.Row>
              <Table.Cell>
                  Carabineer serial
              </Table.Cell>
              <Table.Cell>
                  {fallHazardAssessment.carabineer_Serial}
              </Table.Cell>
          </Table.Row>
          </>}
          {fallHazardAssessment.harness_Manufacturer && <>
              <Table.Row>
                  <Table.Cell>
                      Metal connectors, snap hooks, D-rings etc, must not show signs of damage, including: broken, distorted, sharp edges, burrs, cracks, worn parts, or corrosion. Note: Make sure all buckles work freely.
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.b_MetalConnectorsNoDmg.toString()}
                  </Table.Cell>
              </Table.Row>
              <Table.Row>
                  <Table.Cell>
                      The harness and straps must not show signs of damage including: frayed, cut or broken fibers; tears, abrasions, mold, burns, or discoloration, pulled or cut stitches, knots, excessive soiling or paint buildup. Note: Broken stitches or exposed fall indicator stitching may be an indication the harness has been shock- loaded during a fall and must be discarded.
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.b_HarnessAndStrapsNoDmg.toString()}
                  </Table.Cell>
              </Table.Row>
              <Table.Row>
                  <Table.Cell>
                      The harness must have an original label indicating the manufacturers intended load capacity.
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.b_HarnessOriginalLabel.toString()}
                  </Table.Cell>
              </Table.Row>
              <Table.Row>
                  <Table.Cell>
                      The lanyards must not show signs of damage or stress or shock loading including: rust or staining, chemical or heat damage, or degradation. The lanyard must also have complete and legible labels including the manufacturer name and rated capacity. Note: Broken stitches or exposed fall indicator stitching may be an indication the harness has been shock loaded in a fall and must be discarded.
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.b_LanyardNoDmg.toString()}
                  </Table.Cell>
              </Table.Row>
              <Table.Row>
                  <Table.Cell>
                      Hardware items must not show signs of damage, including: broken, distorted, sharp edges, burrs, cracks, worn parts, or corrosion.
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.b_HardwareNoDmg.toString()}
                  </Table.Cell>
              </Table.Row>
              <Table.Row>
                  <Table.Cell>
                      Wire rope must not be damaged, including: six (6) or more broken wires in one rope lay or three (3) or more broken wires in one strand, corrosion, permanent kinks, burn marks, bird caging or exposed core.
                  </Table.Cell>
                  <Table.Cell>
                      {fallHazardAssessment.b_WireRopeNoDmg.toString()}
                  </Table.Cell>
              </Table.Row>
          </>}
        </Table>
      </Segment>
		</>
	);
};

export default observer(FallHazDetails);