import React from 'react'
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const TimeInput: React.FC<IProps> = ({
    input,
    width,
    label,
    placeholder,
    meta: { touched, error },
    id,
    use12HourClock = true,
    value,
  }) => {

  return (
    <Form.Field error={touched && !!error} width={width}>
      <label>{label}</label>
      <input {...input} type='time' value={value} />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
  </Form.Field>
  );
};

export default TimeInput;
