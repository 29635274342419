import React, { useContext, useState } from "react";
import { Form, Button, Dropdown, DropdownProps, Segment, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { observer } from "mobx-react-lite";
import { OnChange } from "react-final-form-listeners";

import { RootStoreContext } from "../../../app/stores/rootStore";
import { months, years } from "../../../app/common/options/datesOptions";
import TextInput from "../../../app/common/form/TextInput";
import { VehicleAccidentReportSearchFormValues } from "../../../app/models/vehicleAccidentReport";

/*************************************
 *
 *************************************/

const VehicleInspectionSearch = () => {
	const rootStore = useContext(RootStoreContext);
	const { setPredicate, clearPredicate, loadReports, clearVehicleAccidentReportRegistry } =
		rootStore.vehicleAccidentReportStore;

	// required for dropdowns
	const [searchParams, setSearchParams] = useState(new VehicleAccidentReportSearchFormValues());

	const handleMonthsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setSearchParams({...searchParams, months: data.value as number[] }); 
		setPredicate("months", JSON.stringify(data.value));
	};
	const handleYearsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => { 
		setSearchParams({...searchParams, years: data.value as number[] }); 
		setPredicate("years", JSON.stringify(data.value));
	};
	
	/*************************************
	 *
	 *************************************/
	const handleFinalFormSubmit = (values: VehicleAccidentReportSearchFormValues) => {
		clearVehicleAccidentReportRegistry();
		loadReports();
	};

	/*************************************
	 *
	 *************************************/
	const resetSearch = (form: any) => {
		setSearchParams(new VehicleAccidentReportSearchFormValues());
		clearPredicate();
		form.reset();
		clearVehicleAccidentReportRegistry();
		loadReports();
	};

	return (
		<Segment clearing>
			<FinalForm
				onSubmit={handleFinalFormSubmit}
				render={({ handleSubmit, form }) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group widths="equal">
							<Field
								name='operatorNameSubstring'
								placeholder='Search by Operator Name'
								component={TextInput}
								value={searchParams.desc}
							/>
							<OnChange name="operatorNameSubstring">
								{(value, previous) => {
									setSearchParams({...searchParams, desc: value});
									setPredicate("operatorNameSubstring", JSON.stringify(value));
								}}
							</OnChange>
							<Dropdown
								name="months"
								selection
								clearable
								multiple
								search
								fluid
								// className="link item"
								text="Filter by Month"
								onChange={handleMonthsChange}
								options={months}
								value={searchParams.months}
							/>
							<Dropdown
								name="years"
								selection
								clearable
								multiple
								search
								fluid
								// className="link item"
								text="Filter by Year"
								onChange={handleYearsChange}
								options={years}
								value={searchParams.years}
							/>
						</Form.Group>

						<Grid>
							<Grid.Column textAlign="center">
								<Button positive type="submit" content="Search" />
								<Button color="red" type="button" content="Reset" onClick={() => resetSearch(form)} />
							</Grid.Column>
						</Grid>
					</Form>
				)}
			/>
		</Segment>
	);
};

export default observer(VehicleInspectionSearch);
