import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import NearMissList from "./NearMissList";
import NearMissSearch from "./NearMissSearch";

const NearMissDashboard: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { loadNearMisses, loadingInitial, setPage, page, totalPages } = rootStore.nearMissStore;

	const [loadingNext, setLoadingNext] = useState(false);

	const handleGetNext = () => {
		setLoadingNext(true);
		setPage(page + 1);
		loadNearMisses().then(() => setLoadingNext(false));
	};

	useEffect(() => {
		loadNearMisses();
	}, [loadNearMisses]);

	if (loadingInitial && page === 0) return <LoadingComponent content="Loading Near Misses..." />;
	else
		return (
			<>
				<PageTitle title="Near Miss Dashboard" />

				<NearMissSearch />

				<InfiniteScroll pageStart={0} loadMore={handleGetNext} hasMore={!loadingNext && page + 1 < totalPages} initialLoad={false}>
					<NearMissList />
				</InfiniteScroll>
			</>
		);
};

export default observer(NearMissDashboard);
