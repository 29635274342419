import { observable, action, computed, runInAction } from "mobx";
import agent from "../api/agent";

import { IDiscipline } from "../models/discipline";
import { ISafetyChecklist } from "../models/safetyChecklist";
import { IUser } from "../models/user";
import { IFieldSafetyAudit, IFieldSafetyAuditDashboardChartData, ICompanyAuditChartData, FieldSafetyAuditDashboardChartValues } from "../models/fieldSafetyAudit";

import { RootStore } from "./rootStore";

// import { SyntheticEvent } from "react";

import { history } from "../..";
import { toast } from "react-toastify";
import { localizeDateTime } from "../common/util/util";
import { act } from "react-dom/test-utils";

const PAGE_SIZE = 8;

export default class FieldSafetyAuditStore {

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }


  @observable fieldSafetyAuditRegistry = new Map<string, IFieldSafetyAudit>();
  @observable fieldSafetyAudit: IFieldSafetyAudit | null = null;
  @observable safetyChecklists: ISafetyChecklist[] = [];
  @observable users: IUser[] = [];
  @observable disciplines: IDiscipline[] = [];
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable discipline: IDiscipline | null = null;
  @observable fieldSafetyAuditCount = 0;
  @observable page = 0;
  @observable predicate = new Map<string, string | number | boolean>();
  @observable chartData: IFieldSafetyAuditDashboardChartData = new FieldSafetyAuditDashboardChartValues;
  @observable showCharts: boolean = false;

  /*************************************
   * begin pagination
   *************************************/

   @action setCount = (count: number) => {
    this.fieldSafetyAuditCount = count;
  };

  @action setPage = (page: number) => {
    this.page = page;
  }

  @action redefineDatesWithTimezones = (fieldSafetyAudit: IFieldSafetyAudit) => {
    fieldSafetyAudit.created = localizeDateTime(new Date(fieldSafetyAudit.created));
  }

  /*************************************
   * begin params
   *************************************/
   getPredicate = () => {
    return this.predicate;
  };

   @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));
    
    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });
    
    return params;
  }

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @computed get fieldSafetyAuditsByDefault() {
    return Array.from(this.fieldSafetyAuditRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.created.toString()) -
        Date.parse(a.created.toString())
    ); // sort in descending order
  }

  @action clearFieldSafetyAuditRegistry = () => {
    this.fieldSafetyAuditRegistry.clear();
    this.page = 0;
  };

  @computed get totalPages() {
    return Math.ceil(this.fieldSafetyAuditCount / PAGE_SIZE);
  }

  /*************************************
  *
  *************************************/

  @computed get fieldSafetyAuditsByDate() {
    return this.groupFieldSafetyAuditsByDate(
      Array.from(this.fieldSafetyAuditRegistry.values())
    );
  }



  groupFieldSafetyAuditsByDate(fieldSafetyAudits: IFieldSafetyAudit[]) {
    const sortedFieldSafetyAudits = fieldSafetyAudits.sort(
      (a, b) => b.created.getTime() - a.created.getTime()
    );

    return Object.entries(
      sortedFieldSafetyAudits.reduce((audits, audit) => {
        const date = audit.created.toISOString().split('T')[0];
        audits[date] = audits[date] ? [...audits[date], audit] : [audit];
        return audits;
      }, {} as { [key: string]: IFieldSafetyAudit[] })
    );
  }

  /*************************************
   *
   *************************************/

   @action loadSafetyChecklistValidatorInitial = async () => {
    this.loadingInitial = true;
    try {
      //const response = await agent.SafetyChecklist.newChecklistInfo();
      const response = await agent.FieldSafetyAudit.modalInfo();

      runInAction("loading fsa modal info", () => {
        this.disciplines = response.disciplinesToReturn;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load fsa modal info error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @action loadFSAInitial = async () => {
    this.loadingInitial = true;
    const response = await agent.FieldSafetyAudit.newChecklistInfo();
    try {

      runInAction("getting FSA ready", () => {
        this.users = response.usersToReturn;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load FSA info error", () => {
        this.loadingInitial = false;
      });
    }
  };

  /*************************************
   *
   *************************************/

  @action loadFieldSafetyAudit = async (id: string) => {
    this.loadingInitial = true;
    try {
      let fieldSafetyAudit = await agent.FieldSafetyAudit.details(id);
      runInAction("loading existing FSA", () => {
        if (fieldSafetyAudit) {
          this.redefineDatesWithTimezones(fieldSafetyAudit);
          this.fieldSafetyAudit = fieldSafetyAudit;
          this.fieldSafetyAuditRegistry.set(fieldSafetyAudit.id, fieldSafetyAudit);
        }
        this.loadingInitial = false;
      });
      return fieldSafetyAudit;
    } catch (error) {
      runInAction("get FSA error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };
  
  @action loadFieldSafetyAudits = async () => {
    this.loadingInitial = true;
    
    try {
      const response = await agent.FieldSafetyAudit.list(this.axiosParams);
      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);
      runInAction("loading fsas", () => {
        response.data.fieldSafetyAudits.forEach((fsa: IFieldSafetyAudit) => {
          this.redefineDatesWithTimezones(fsa);
          this.fieldSafetyAuditRegistry.set(fsa.id, fsa);
      });
      
      this.loadingInitial = false;
    });
    
  } catch (error) {
    runInAction("load fsas error", () => {
      this.loadingInitial = false;
    });
    
    console.log(error);
  }
};

/*************************************
 *
 *************************************/

@action editFieldSafetyAudit = async (id:string, fieldSafetyAudit: FormData) => {
  this.submitting = true;
  
  try {
    const fsa = await agent.FieldSafetyAudit.update(id, fieldSafetyAudit);
    
    runInAction("editing fsa", () => {
      fsa.created = new Date(fsa.created);
      this.fieldSafetyAuditRegistry.set(fsa.id, fsa);
      this.fieldSafetyAudit = fsa;
      this.submitting = false;
    });
    history.push(`/field-safety-audit/${fsa.id}`);
    toast.success('Field Safety Audit Updated.');
  } catch (error: any) {
      runInAction("edit fsa error", () => {
        this.submitting = false;
      });
      
      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  @action deleteFieldSafetyAudit = async (id: string) => {
    this.submitting = true;
    try {
      var sc = await agent.FieldSafetyAudit.delete(id);
      runInAction("deleting fsa", () => {
        this.submitting = false;
      });
      history.push('/');
      toast.success('Field Safety Audit Deleted.');
    } catch (error) {
      toast.error("Problem submitting data");
      console.log(error);
    }
  };
  
  /*************************************
   *
   *************************************/

  @action clearFieldSafetyAudit = () => {
    this.fieldSafetyAudit = null;
  };

  @action clearFieldSafetyAudits = () => {
    this.fieldSafetyAuditRegistry.clear();
  };

  /*************************************
   *
   *************************************/

  getFieldSafetyAudit = (id: string) => {
    return this.fieldSafetyAuditRegistry.get(id);
  };

  /*************************************
   *
   *************************************/

  @action createFieldSafetyAudit = async (fieldSafetyAudit: FormData) => {
    this.submitting = true;
    try {
      const newfsa = await agent.FieldSafetyAudit.create(fieldSafetyAudit);
      runInAction("create FSA", () => {
        this.fieldSafetyAuditRegistry.set(newfsa.id, newfsa);
        this.submitting = false;
      });

      history.push(`/field-safety-audit/${newfsa.id}`);
      toast.success("New Field Safety Audit Added.")

    } catch (error: any) {
      runInAction("create FSA error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
      console.log(error.response);
    }
  };

  /*************************************
  * Approval Process Functions
  *************************************/

   @action sslApproval = async (id: string) => {
    this.loadingInitial = true;
    try {
      let response = await agent.FieldSafetyAudit.sslApproval(id);
      runInAction("setting ssl approval for fsa", () => {
        this.loadingInitial = false;
        toast.success('FSA approved');
      });
      return;
    } catch (error) {
      runInAction("ssl approval error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @action markComplete = async (id: string) => {
    this.loadingInitial = true;
    try {
      let response = await agent.FieldSafetyAudit.markComplete(id);
      runInAction("setting fsa to complete", () => {
        this.loadingInitial = false;
        //history.push(`/`);
        toast.success('FSA marked as complete!');
        // redirect home? or to safety checklist? or nothing since it's just a modal and can close?
      });
      return;
    } catch (error) {
      runInAction("fsa completion error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  /*************************************
   * Safety Checklist Validation
   *************************************/

  @computed get checkSafetyChecklistParams() {
    const params = new URLSearchParams();
    
    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });
    
    return params;
  }


  @action checkSafetyChecklist = async () => {
    // TODO rewrite so it doesnt crash when two are found
    // But if multiple are found, we need to let the user pick
    try {
      const response = await agent.SafetyChecklist.getGuid(this.checkSafetyChecklistParams);
      return response.data.guidToReturn;
    } catch (error) {
      console.log(error);
      console.log('Error checking for safety checklist');
    }
  };


  /*************************************
   * FSA Dashboard Chart Data
   *************************************/
  @action loadChartData = async () => {
    //this.loadingInitial = true;
    try {
      // can we leave room here to have the chart data share filters of the FSA Dashboard, passing them as parameters?
      const response = await agent.FieldSafetyAudit.getChartData();
      runInAction("load fsa chart data", () => {
        this.chartData.completedCompanyAudits!.goal = response.completedCompanyAudits.goal;
        this.chartData.completedCompanyAudits!.goalCompleted = response.completedCompanyAudits.goalCompleted;
        this.chartData.completedCompanyAudits!.totalCompleted = response.completedCompanyAudits.totalCompleted;
        this.chartData.completedDisciplineAuditData! = response.completedAuditsByDiscipline.list;
        this.chartData.nonConformancesByType! = response.nonConformanceData.list;
        this.loadingInitial = false;
      });
    } catch (error){
      console.log(error);
      console.log('unable to retrieve FSA Dashboard chart data');
    }
  };


  @action toggleShowCharts = async () => {
    try {
      runInAction("toggle chart visibility", () => {
        this.showCharts = !this.showCharts;
      });
    } catch (error){
      console.log(error);
      console.log('unable to toggle fsa chart data');
    }
  };

}