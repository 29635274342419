import React, { useContext, useState, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Segment, Button, Header, Step } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import TextInput from "../../../app/common/form/TextInput";
import DateInput from "../../../app/common/form/DateInput";
import SelectInput from "../../../app/common/form/SelectInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import MultipleSelectInput from "../../../app/common/form/MultipleSelectInput";
import { ControlFormValues, IControl } from "../../../app/models/control";
import { HazardEmptyFormValues, IHazard } from "../../../app/models/hazard";
import { ISafetyChecklist, SafetyChecklistFormValues } from "../../../app/models/safetyChecklist";
import { FieldFileDropZone } from "../../../app/common/form/FileDropZone";
import RenderIf from "../../../app/common/form/RenderIf";
import ConfirmCancelForm from '../../modals/ConfirmCancelForm';
import { StepButtons } from '../../../app/common/form/StepButtons';
import { isFieldEmpty } from "../../../app/common/util/util";
import { ISOP } from "../../../app/models/sop";

/* eslint-disable no-cond-assign */

interface DetailParams {
  id: string;
  projectNumber: string;
  templateId: string;
}

const SafetyChecklistForm: React.FC<RouteComponentProps<DetailParams>> = ({  match,
  // history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    submitting,
    loadingInitial,
    loadSafetyInitial,
    loadSafetyChecklist,
    loadSafetyChecklistTemplate,
    createSafetyChecklist,
    editSafetyChecklist,
    outdoorsGeneral,
    structuresBuildings,
    activeRoadways,
    railroad,
    construction,
    trenching,
    waterfront,
    waste,
    confinedSpaces,
    scaffolds,
    chemical,
    pipeline,
    wastewater,
    associatedHazards,
    users,
    siteSafetyLeaders,
    disciplines,
    sops,
    setHazards,
  } = rootStore.safetyChecklistStore;
	const { openModal } = rootStore.modalStore;

  const [safetyChecklist, setSafetyChecklist] = useState(new SafetyChecklistFormValues());

  const { accountInfoUserEmail } = rootStore.userStore;

  const associatedHazardsFiltered = (hazards: IHazard[]) => {
    var filtered: IHazard[] = [];
    hazards.forEach(hazard => {
      if(hazard.associatedJobNumber === safetyChecklist.projectNumber){
        filtered.push(hazard);
      }
    });
    return filtered;
  }

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  

  const isStepValid = (values: ISafetyChecklist): string | null => {
    switch(step) {
      case 1:
        let e1;
        if (e1 = isFieldEmpty(values.siteSafetyLeaderEmail)) return e1;
        else if (e1 = isFieldEmpty(values.discipline_id)) return e1;
        else return null;
      case 2: return null;
      case 3: return null;
      case 4:
        let e4; 
        if (e4 = isFieldEmpty(values.emergencyCoordinatorEmail)) return e4;
        else if (e4 = isFieldEmpty(values.emergencyReportingNumber)) return e4;
        else if (e4 = isFieldEmpty(values.emergencyMedicalFacility)) return e4;
        else if (e4 = isFieldEmpty(values.medicalFacilityNumber)) return e4;
		else if (e4 = isFieldEmpty(values.medicalFacilityAddress)) return e4;
        else if (e4 = isFieldEmpty(values.emergencySignal)) return e4;
		else if (e4 = isFieldEmpty(values.evacRouteAndMusterPoint)) return e4;
        else return null;
      case 5: return null;
      default: return 'invalid step number';
    }
  };

  useEffect(() => {
    setLoading(true);
    if (match.params.id) { /* if we're editing an existing */
      Promise.all([
        loadSafetyInitial(),
        loadSafetyChecklist(match.params.id).then((safetyChecklist: any) => setSafetyChecklist(new SafetyChecklistFormValues(safetyChecklist)))
      ]).finally(() => setLoading(false));
    } else if (match.params.projectNumber){ /* if we're making a new one */
      let temp = new SafetyChecklistFormValues(safetyChecklist);
      temp.projectNumber = match.params.projectNumber;
      setSafetyChecklist(temp);
      loadSafetyInitial().finally(() => setLoading(false));
    } else if (match.params.templateId){ /* if we're using an existing SC as a template */
      Promise.all([
        loadSafetyInitial(),
        loadSafetyChecklistTemplate(match.params.templateId).then((safetyChecklist: any) => setSafetyChecklist(new SafetyChecklistFormValues(safetyChecklist))),
      ]).finally(() => setLoading(false));
    }else{ /* defualt, error; unreachable code */
      //TODO proper error handling
      safetyChecklist.projectNumber = "ERROR";
      loadSafetyInitial().finally(() => setLoading(false));
      console.log('shouldnt get here'); 
    }
    //setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, match.params.projectNumber]);


  if (loading || loadingInitial)
    return <LoadingComponent content="Loading Safety Checklist..." />;

  const handleFinalFormSubmit = (values: any) => {
    const payload = new FormData();
    payload.set('lastModified', values.lastModified);
    payload.set('discipline_id', values.discipline_id);
    payload.set('projectManagerEmail', values.projectManagerEmail);
    payload.set('projectNumber', values.projectNumber);
    payload.set('projectName', values.projectName);
    payload.set('projectAddress', values.projectAddress);
    payload.set('siteSafetyLeaderEmail', values.siteSafetyLeaderEmail);
    payload.set('estimatedStartDate', values.estimatedStartDate.toISOString());
    payload.set('estimatedCompletionDate', values.estimatedCompletionDate.toISOString());
    payload.set('emergencyCoordinatorEmail', values.emergencyCoordinatorEmail);
    payload.set('emergencyReportingNumber', values.emergencyReportingNumber);
    payload.set('emergencySignal', values.emergencySignal);
    payload.set('evacRouteAndMusterPoint', values.evacRouteAndMusterPoint);
    payload.set('emergencyMedicalFacility', values.emergencyMedicalFacility);
    payload.set('medicalFacilityAddress', values.medicalFacilityAddress);
    payload.set('medicalFacilityNumber', values.medicalFacilityNumber);
    if(values.otherAssignedPersonnelEmails && values.otherAssignedPersonnelEmails !== undefined)
      for(let i=0; i < values.otherAssignedPersonnelEmails.length; i++)
        payload.append('otherAssignedPersonnelEmails', values.otherAssignedPersonnelEmails[i]);

    if(values.outdoorsGeneral_ids && values.outdoorsGeneral_ids !== undefined)
      for(let i=0; i<values.outdoorsGeneral_ids.length; i++)
        payload.append('outdoorsGeneral_ids', values.outdoorsGeneral_ids[i]);

    if(values.structuresBuildings_ids && values.structuresBuildings_ids !== undefined)
      for(let i=0; i<values.structuresBuildings_ids.length; i++)
        payload.append('structuresBuildings_ids', values.structuresBuildings_ids[i]);

    if(values.activeRoadways_ids && values.activeRoadways_ids !== undefined)
      for(let i=0; i<values.activeRoadways_ids.length; i++)
        payload.append('activeRoadways_ids', values.activeRoadways_ids[i]);

    if(values.railroad_ids && values.railroad_ids !== undefined)
      for(let i=0; i<values.railroad_ids.length; i++)
        payload.append('railroad_ids', values.railroad_ids[i]);

    if(values.construction_ids && values.construction_ids !== undefined)
      for(let i=0; i<values.construction_ids.length; i++)
        payload.append('construction_ids', values.construction_ids[i]);

    if(values.trenching_ids && values.trenching_ids !== undefined)
      for(let i=0; i<values.trenching_ids.length; i++)
        payload.append('trenching_ids', values.trenching_ids[i]);

    if(values.waterfront_ids && values.waterfront_ids !== undefined)
      for(let i=0; i<values.waterfront_ids.length; i++)
        payload.append('waterfront_ids', values.waterfront_ids[i]);

    if(values.waste_ids && values.waste_ids !== undefined)
      for(let i=0; i<values.waste_ids.length; i++)
        payload.append('waste_ids', values.waste_ids[i]);
    
    if(values.confinedSpaces_ids && values.confinedSpaces_ids !== undefined)
      for(let i=0; i<values.confinedSpaces_ids.length; i++)
        payload.append('confinedSpaces_ids', values.confinedSpaces_ids[i]);

    if(values.scaffolds_ids && values.scaffolds_ids !== undefined)
      for(let i=0; i<values.scaffolds_ids.length; i++)
        payload.append('scaffolds_ids', values.scaffolds_ids[i]);

    if(values.chemical_ids && values.chemical_ids !== undefined)
      for(let i=0; i<values.chemical_ids.length; i++)
        payload.append('chemical_ids', values.chemical_ids[i]);

    if(values.pipeline_ids && values.pipeline_ids !== undefined)
      for(let i=0; i<values.pipeline_ids.length; i++)
        payload.append('pipeline_ids', values.pipeline_ids[i]);

    if(values.wastewater_ids && values.wastewater_ids !== undefined)
      for(let i=0; i<values.wastewater_ids.length; i++)
        payload.append('wastewater_ids', values.wastewater_ids[i]);

    // handles EXISTING hazards that are associated with the project number, or if the checklist that
    // created it is edited
    if(values.additionalHazards_ids && values.additionalHazards_ids !== undefined)
      for(let i=0; i<values.additionalHazards_ids.length; i++)
        payload.append('additonalHazards_ids', values.additionalHazards_ids[i]);

    // handles NEW hazards that will be associated with the project number of this checklist
    if(values.additionalHazards && values.additionalHazards !== undefined){
      for(let i=0; i<values.additionalHazards.length; i++){
        payload.append(`additionalHazards[${i}][name]`, values.additionalHazards[i].name);
        for(let j=0; j<values.additionalHazards[i].associatedControls.length; j++){
          payload.append(`additionalHazards[${i}][associatedControls][${j}].description`, values.additionalHazards[i].associatedControls[j].description);
        }
        for(let k=0; k<values.additionalHazards[i].associatedSOPs.length; k++){
          payload.append(`additionalHazards[${i}][associatedSOPs][${k}].id`, values.additionalHazards[i].associatedSOPs[k]);
        }
        payload.append(`additionalHazards[${i}][associatedJobNumber]`, values.projectNumber);
      }
    }

    if(values.fileArray && values.fileArray !== undefined)
      for(let i=0; i < values.fileArray.length; i++)
        payload.append("newFiles", values.fileArray[i]);

    if(!safetyChecklist.id || safetyChecklist.id == ''){
      createSafetyChecklist(payload);
    } else {
      editSafetyChecklist(values.id, payload);
    }
  }

  return (
    <>
      <RenderIf condition={!safetyChecklist.id || safetyChecklist.id == ''}>
        <PageTitle title="New Safety Checklist" />
      </RenderIf>
      <RenderIf condition={safetyChecklist.id !== ''}>
        <PageTitle title="Edit Safety Checklist" />
      </RenderIf>

      <Segment clearing>
        <Step.Group ordered fluid size='mini'>
          <Step completed={step > 1} active={step === 1}>
            <Step.Content>
              <Step.Title>General</Step.Title>
            </Step.Content>
          </Step>

          <Step completed={step > 2} active={step === 2}>
            <Step.Content>
              <Step.Title>Hazards</Step.Title>
            </Step.Content>
          </Step>

          <Step completed={step > 3} active={step === 3}>
            <Step.Content>
              <Step.Title>OAP</Step.Title>
            </Step.Content>
          </Step>

          <Step completed={step > 4} active={step === 4}>
            <Step.Content>
              <Step.Title>Emergency Coordinator</Step.Title>
            </Step.Content>
          </Step>

          <Step completed={step > 5} active={step === 5}>
            <Step.Content>
              <Step.Title>Files &amp; Submission</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
        
        <FinalForm
          initialValues={safetyChecklist}
          onSubmit={handleFinalFormSubmit}
          mutators={{ ...arrayMutators }}
          loading={loading}
          render={({ 
            handleSubmit, 
            invalid, 
            pristine, 
            form: {
              mutators: { push, pop }
            },
            values }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <Button
                disabled={loading}
                color='yellow'
                type="button"
                content="Cancel"
                onClick={()=>openModal(<ConfirmCancelForm />)}
                />	

              <StepButtons
                isValid={() => isStepValid(values)}
                step={step}
                prevStep={() => { setStep(step - 1); }}
                nextStep={() => { setStep(step + 1); }}
                stepmax={5}
                floated="right"
              />
              
              <RenderIf condition={step === 1}>
                <Segment>
                  <Form.Group widths="equal">

                  <Field
                    name="projectNumber"
                    label="Project Number"
                    value={safetyChecklist.projectNumber}
                    component={TextInput}
                    disabled={true}
                  />

                  <Field
                    name="discipline_id"
                    label="Discipline"
                    value={safetyChecklist.discipline_id}
                    clearable
                    options={disciplines.map((discipline) => ({
                      key: discipline.id,
                      value: discipline.id,
                      text: discipline.name,
                    }))}
                    component={SelectInput}
                    search={true}
                    // TODO does this do anything? I don't think errors display below dropdowns
                    validate={isFieldEmpty}
					required
                  />

                  <Field
                    name="siteSafetyLeaderEmail"
                    label="Site Safety Leader"
                    value={safetyChecklist.siteSafetyLeaderEmail}
                    clearable
                    options={siteSafetyLeaders?.map((ssl) => ({
                      key: "ssl-"+ssl.aoid,
                      value: ssl.email,
                      text: ssl.formattedName,
                    }))}
                    component={SelectInput}
                    search={true}
					required
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="estimatedStartDate"
                    label="Estimated Start Date"
                    value={safetyChecklist.estimatedStartDate}
                    component={DateInput}
                  />

                  <Field
                    name="estimatedCompletionDate"
                    label="Estimated Completion Date"
                    value={safetyChecklist.estimatedCompletionDate}
                    component={DateInput}
                  />
                </Form.Group>

                <RenderIf condition={(safetyChecklist.id !== null && safetyChecklist.id !== '')}>
                  <Form.Group widths="equal">
                    <Field
                      name="projectName"
                      label="Project Name"
                      value={safetyChecklist.projectName}
                      component={TextInput}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    {/* <Field
                      name="projectManagerEmail"
                      label="Project Manager"
                      value={safetyChecklist.projectManagerEmail}
                      clearable
                      options={users?.map((user) => ({
                        key: "pm-"+user.aoid,
                        value: user.email,
                        text: user.formattedName,
                      }))}
                      component={SelectInput}
                      search={true}
                    /> */}

                    <Field
                      name="projectAddress"
                      label="Project Address"
                      value={safetyChecklist.projectAddress}
                      component={TextAreaInput}
                    />
                  </Form.Group>
                </RenderIf>
              </Segment>
              </RenderIf>

              <RenderIf condition={step === 2}>
                <Segment>
                  <Form.Group widths="equal">
                  <Field
                    name="outdoorsGeneral_ids"
                    label="Outdoors/General"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.outdoorsGeneral_ids}
                    component={MultipleSelectInput}
                    options={outdoorsGeneral?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />

                  <Field
                    name="structuresBuildings_ids"
                    label="Work on Structures/Buildings"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.structuresBuildings_ids}
                    component={MultipleSelectInput}
                    options={structuresBuildings?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="activeRoadways_ids"
                    label="Work on Active Roadways"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.activeRoadways_ids}
                    component={MultipleSelectInput}
                    options={activeRoadways?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />

                  <Field
                    name="railroad_ids"
                    label="Work on Active Railroad/Railroad Right-of-way"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.railroad_ids}
                    component={MultipleSelectInput}
                    options={railroad?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="construction_ids"
                    label="Construction Sites"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.construction_ids}
                    component={MultipleSelectInput}
                    options={construction?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />

                  <Field
                    name="trenching_ids"
                    label="Trenching/Excavation"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.trenching_ids}
                    component={MultipleSelectInput}
                    options={trenching?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="waterfront_ids"
                    label="Waterfront/Bridgework"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.waterfront_ids}
                    component={MultipleSelectInput}
                    options={waterfront?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />

                  <Field
                    name="waste_ids"
                    label="Hazardous Waste/Chemicals"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.waste_ids}
                    component={MultipleSelectInput}
                    options={waste?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="confinedSpaces_ids"
                    label="Confined Spaces"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.confinedSpaces_ids}
                    component={MultipleSelectInput}
                    options={confinedSpaces?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />

                  <Field
                    name="scaffolds_ids"
                    label="Scaffolds"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.scaffolds_ids}
                    component={MultipleSelectInput}
                    options={scaffolds?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="chemical_ids"
                    label="Work on Large Chemical/Petrochemical Sites"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.chemical_ids}
                    component={MultipleSelectInput}
                    options={chemical?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />

                  <Field
                    name="pipeline_ids"
                    label="Work on Pipeline Sites"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.pipeline_ids}
                    component={MultipleSelectInput}
                    options={pipeline?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="wastewater_ids"
                    label="Wastewater Treatment Facilities"
                    placeholder="Select Hazards..."
                    value={safetyChecklist.wastewater_ids}
                    component={MultipleSelectInput}
                    options={wastewater?.map((hazard) => ({
                      key: hazard.id,
                      value: hazard.id,
                      text: hazard.name,
                    }))}
                  />
                </Form.Group>

                <RenderIf condition={associatedHazardsFiltered(associatedHazards).length > 0}>
                  <Form.Group widths="equal">
                    <Field
                      name="additionalHazards_ids"
                      label="Additional Hazards"
                      placeholder="Select Hazards..."
                      value={safetyChecklist.additionalHazards_ids}
                      component={MultipleSelectInput}
                      options={associatedHazardsFiltered(associatedHazards).map((hazard) => ({
                        key: hazard.id,
                        value: hazard.id,
                        text: hazard.name,
                      }))}
                    />
                  </Form.Group>
                </RenderIf>
              </Segment>
              
              <Segment>
                <Header>New Additional Hazards</Header>
                <Button basic color='green' onClick={() => push('additionalHazards', new HazardEmptyFormValues())} type='button'>Add</Button>
                  <FieldArray name="additionalHazards">
                    {({ fields }) => fields.map((hazard_field_name, i) =>
                      <Segment key={hazard_field_name}>
                        <Header as='h4'>Additional Hazard {i + 1}</Header>
                        <Form.Group widths='equal'>
                          <Field component={TextInput} placeholder='Name' name={`${hazard_field_name}.name`} />
                        </Form.Group>
                        <Field 
                          component={MultipleSelectInput} 
                          placeholder='Associated SOPs' 
                          name={`${hazard_field_name}.associatedSOPs`}
                          options={sops?.map((sop) => ({
                            key: sop.id,
                            value: sop.id,
                            text: `#${sop.number} ${sop.name}`,
                          }))}
                        />
                        <label>Associated Controls</label>
                          <FieldArray name={`additionalHazards[${i}].associatedControls`}>
                          {({ fields }) => fields.map((associated_controls_field_name, j) =>
                          <Segment key={associated_controls_field_name}>
                            <Header as='h4'>Control {j + 1}</Header>
                            <Form.Group widths='equal'>
                              <Field component={TextInput} placeholder='Description...' name={`${associated_controls_field_name}.description`} />
                            </Form.Group>
                            <div>
                            {values.additionalHazards.associatedControls?.length !== 1 && <Button basic color='red' onClick={() => fields.remove(i)} type='button'>Remove</Button>}
                            <Button basic color='green' onClick={() => push(`additionalHazards[${i}].associatedControls`, new ControlFormValues())} type='button'>Add</Button>
                            </div>
                          </Segment>
                          )}
                        </FieldArray>
                        
                        <div>
                          {values.additionalHazards && values.additionalHazards.length !== 0 && <Button basic color="red" type='button' onClick={() => fields.remove(i)}>Remove This Hazard</Button>}
                          {values.additionalHazards && values.additionalHazards.length - 1 === i && <Button basic color='green' type='button' onClick={() => push('additionalHazards', new HazardEmptyFormValues())}>Add Another Hazard...</Button>}
                        </div>
                      </Segment>
                    )}
                  </FieldArray>
                </Segment>
              </RenderIf>

              <RenderIf condition={step === 3}>
                <Segment>
                <Form.Group widths="equal">
                  <Field
                    name="otherAssignedPersonnelEmails"
                    label="Other Assigned Personnel"
                    placeholder="Select Personnel..."
                    value={safetyChecklist.otherAssignedPersonnelEmails}
                    component={MultipleSelectInput}
                    search={true}
                    options={users?.map((user) => ({
                      key: "oap-"+user.aoid,
                      value: user.email,
                      text: user.formattedName,
                    }))}
                  />
                </Form.Group>
                </Segment>
              </RenderIf>

              <RenderIf condition={step === 4}>
                <Segment>
                  <Form.Group widths="equal">
                    <Field
                      name="emergencyCoordinatorEmail"
                      label="Emergency Coordinator on Site"
                      value={safetyChecklist.emergencyCoordinatorEmail}
                      options={users?.map((user) => ({
                        key: "ec-"+user.aoid,
                        value: user.email,
                        text: user.formattedName,
                      }))}
                      component={SelectInput}
                      search={true}
                      // TODO errors don't appear on SelectInput
                      validate={isFieldEmpty}
					  clearable
					  required
                      />
                  </Form.Group>

                  <Form.Group widths="equal">

                  <Field
                    name="emergencyReportingNumber"
                    label="Emergency Reporting Number"
                    value={safetyChecklist.emergencyReportingNumber}
                    component={TextInput}
                    validate={isFieldEmpty}
					required
                	>
						
				  </Field>

                  <Field
                    name="emergencyMedicalFacility"
                    label="Emergency Medical Facility"
                    value={safetyChecklist.emergencyMedicalFacility}
                    component={TextInput}
                    validate={isFieldEmpty}
					required
                    />
                  <Field
                    name="medicalFacilityNumber"
                    label="Emergency Medical Facility Number"
                    value={safetyChecklist.medicalFacilityNumber}
                    component={TextInput}
                    validate={isFieldEmpty}
					required
                    />
                </Form.Group>

                <Form.Group widths="equal">
                  <Field
                    name="emergencySignal"
                    label="Emergency Signal"
                    rows={2}
                    value={safetyChecklist.emergencySignal}
                    component={TextAreaInput}
					validate={isFieldEmpty}
					required
                    />

                  <Field
                    name="evacRouteAndMusterPoint"
                    label="Evacuation Route and Muster Point"
                    rows={2}
                    value={safetyChecklist.evacRouteAndMusterPoint}
                    component={TextAreaInput}
					validate={isFieldEmpty}
					required
                    />

                  <Field
                    name="medicalFacilityAddress"
                    label="Medical Facility Address"
                    rows={2}
                    value={safetyChecklist.medicalFacilityAddress}
                    component={TextAreaInput}
                    validate={isFieldEmpty}
					required
                  />
                </Form.Group>
                </Segment>
              </RenderIf>
              
              <RenderIf condition={step === 5}>
                <Segment>
                  <Form.Group widths="equal">
                    <FieldFileDropZone name="fileArray" accept={[
                      "image/*",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "application/msword",
                      "application/pdf"
                    ].join(',')} maxFiles={6} />
                  </Form.Group>
                </Segment>
              </RenderIf>

              <RenderIf condition={step === 5}>
                <Button
                  loading={submitting}
                  //disabled={loading || invalid || pristine}
                  floated="right"
                  positive
                  type="submit"
                  content="Submit"
                />
              </RenderIf>
              
              <Button
                disabled={loading}
                color='yellow'
                type="button"
                content="Cancel"
                onClick={()=>openModal(<ConfirmCancelForm />)}
                />
      
              <StepButtons
                isValid={() => isStepValid(values)}
                step={step}
										prevStep={() => { setStep(step - 1); }}
										nextStep={() => { setStep(step + 1); }}
                stepmax={5}
              />
            </Form>
          )}
        />
      </Segment>
    </>
  );
};

export default observer(SafetyChecklistForm);
