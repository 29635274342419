import { observable, action, computed, runInAction } from "mobx";
// import { SyntheticEvent } from "react";
import { ISituation } from "../models/situation";

import agent from "../api/agent";
// import { history } from "../..";
// import { toast } from "react-toastify";
import { RootStore } from "./rootStore";
import { IHazard } from "../models/hazard";

export default class SituationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable situationRegistry = new Map<string, ISituation>();

  @observable situation: ISituation | null = null;
  
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";

  @observable outdoorsGeneral: IHazard[] = [];
  @observable structuresBuildings: IHazard[] = [];
  @observable activeRoadways: IHazard[] = [];
  @observable railroad: IHazard[] = [];
  @observable construction: IHazard[] = [];
  @observable trenching: IHazard[] = [];
  @observable waterfront: IHazard[] = [];
  @observable waste: IHazard[] = [];
  @observable confinedSpaces: IHazard[] = [];
  @observable scaffolds: IHazard[] = [];
  @observable chemical: IHazard[] = [];
  @observable pipeline: IHazard[] = [];
  @observable wastewater: IHazard[] = [];

  @computed get allSituations() {
    //return Array.from(this.activityRegistry.values()).sort(
    //    (a, b) => Date.parse(a.date) - Date.parse(b.date))
    return Array.from(this.situationRegistry.values());
  }

  /*************************************
   *
   *************************************/

  @action clearSituation = () => {
      this.situation = null;
  };
    
/*************************************
 *
 *************************************/
      
    getSituation = (id: string) => {
        return this.situationRegistry.get(id);
    };
    

  /*************************************
   *
   *************************************/

   @action loadSituations = async () => {
    this.loadingInitial = true;

    try {
      const situations = await agent.Situation.list();

      runInAction("loading situations", () => {
        situations.forEach((situation) => {
         this.situationRegistry.set(situation.id, situation);
        });
        this.outdoorsGeneral = situations.find((situationName: ISituation) => situationName.name === "Outdoors/General")!.hazards;
        this.structuresBuildings = situations.find((situationName: ISituation) => situationName.name === "Work on Structures/Buildings")!.hazards;
        this.activeRoadways = situations.find((situationName: ISituation) => situationName.name === "Work on Active Roadways")!.hazards;
        this.railroad = situations.find((situationName: ISituation) => situationName.name === "Work on Active Railroad/Railroad Right-of-way")!.hazards;
        this.construction = situations.find((situationName: ISituation) => situationName.name === "Construction Sites")!.hazards;
        this.trenching = situations.find((situationName: ISituation) => situationName.name === "Trenching/Excavation")!.hazards;
        this.waterfront = situations.find((situationName: ISituation) => situationName.name === "Waterfront/Bridgework")!.hazards;
        this.waste = situations.find((situationName: ISituation) => situationName.name === "Hazardous Waste/Chemicals")!.hazards;
        this.confinedSpaces = situations.find((situationName: ISituation) => situationName.name === "Confined Spaces")!.hazards;
        this.scaffolds = situations.find((situationName: ISituation) => situationName.name === "Scaffolds")!.hazards;
        this.chemical = situations.find((situationName: ISituation) => situationName.name === "Work on Large Chemical/Petrochemical Sites")!.hazards;
        this.pipeline = situations.find((situationName: ISituation) => situationName.name === "Work on Pipeline Sites")!.hazards;
        this.wastewater = situations.find((situationName: ISituation) => situationName.name === "Wastewater Treatment Facilities")!.hazards;

        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load situations error", () => {
        this.loadingInitial = false;
      });

      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

   @action loadSituation = async (id: string) => {
    let situation = this.getSituation(id);

    if (situation) {
      this.situation = situation;
      return situation;
    } else {
      this.loadingInitial = true;

      try {
        situation = await agent.Situation.details(id);

        runInAction("getting situation", () => {
          if(situation) {
            this.situation = situation;
            this.situationRegistry.set(situation.id, situation);
          }
          this.loadingInitial = false;
        });
        return situation;
      } catch (error) {
        runInAction("get situation error", () => {
          this.loadingInitial = false;
        });

        console.log(error);
      }
    }
  };
}