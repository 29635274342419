import React, { useContext, useEffect, useState } from "react";
import { Segment, Table, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";


const mapUnitType = (val: string) => {
	switch (val) {
		case "0":
			return "Scissor Lift";
		case "1":
			return "Articulating Boom";
		case "2":
			return "Vertical Man Lift";
		default:
			return "Other";
	}
};

const mapPassFailOptions = (val: string) => {
	switch (val) {
		case "0":
			return "Pass";
		case "1":
			return "Fail";
		case "2":
			return "N/A";
	}
};

interface DetailParams {
	id: string;
}

const AerialLiftDetails: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
	const rootStore = useContext(RootStoreContext);
	const { aerialLift, loadAerialLift, loadingInitial } = rootStore.safetyChecklistStore;
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		setLoading(true);
		loadAerialLift(match.params.id).then(() => setLoading(false));
	}, [loadAerialLift, match.params.id, history]);

	if (loadingInitial || loading) return <LoadingComponent content="Loading Aerial Lift Assessment..." />;

	if (!aerialLift) return <h2>Aerial Lift Assessment Not Found</h2>;

	return (
		<>
			<PageTitle title="Aerial Lift Assessment Details" />
			<Segment clearing>
				<Table>
					<Table.Row>
						<Table.Cell>
						Are you the operator?
						</Table.Cell>
						<Table.Cell>
							{aerialLift.isOperator ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
						</Table.Cell>
					</Table.Row>
					{/* only render remaining if operator */}
					{aerialLift.isOperator && <>
						<Table.Row>
							<Table.Cell>
								Lift Id
							</Table.Cell>
							<Table.Cell>
								{aerialLift.liftId}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								Unit Type
							</Table.Cell>
							<Table.Cell>
								{mapUnitType(aerialLift.unitType)}
							</Table.Cell>
						</Table.Row>
						{aerialLift.unitType === '3' &&
							<Table.Row>
								<Table.Cell>
									Other Type
								</Table.Cell>
								<Table.Cell>
									{aerialLift.otherType}
								</Table.Cell>
							</Table.Row>
						}
						<Table.Row>
							<Table.Cell>
								Location Of Use
							</Table.Cell>
							<Table.Cell>
								{aerialLift.locationOfUse}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Operating and emergency controls are in proper working condition, EMO button or Emergency Stop Device
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.controlsOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Functional upper drive control interlock (i.e. foot pedal, spring lock, or two hand controls)
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.upperDriveControl)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Emergency Lowering function operates properly
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.emergencyLoweringOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Lower operating controls successfully override the upper controls
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.lowerOverride)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Both upper and lower controls are adequately protected from inadvertent operation
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.controlsProtected)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Control panel is clean &amp; all buttons/switches are clearly visible (no paint over spray, etc.)
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.controlPanelClean)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							All switch &amp; mechanical guards are in good condition and properly installed
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.switchesOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							All Safety Indicator lights work
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.lightsOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Drive controls function properly &amp; accurately labeled (up, down, right, left, forward, back)
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.driveControlsOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Motion alarms are functional
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.alarmsOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Safety decals are in place and readable
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.decalsOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							All guard rails are sound and in place, including basket chains
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.guardRails)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Work platform &amp; extension slides are clean, dry, &amp; clear of debris
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.platformClean)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Work platform extension slides in and out freely with safety locking pins in place to lock setting on models with extension platforms
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.extensionLocks)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Inspect for defects such as cracked welds, fuel leaks, hydraulic leaks, damaged control cables or wire harness, etc
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.checkForDefects)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Tires and wheels are in good condition, with adequate air pressure if pneumatic
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.tiresOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Braking devices are operating properly
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.brakesOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							The manufacturer’s operations manual is stored on MEWP (in all languages of the operators)
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.manualOnMWEP)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Oil level, Hydraulic Oil Level, Fuel Level, Coolant Level
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.fluidsOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Battery Charge
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.batteryCharged)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Outriggers in place or functioning. Associated alarms working
							</Table.Cell>
							<Table.Cell>
								{mapPassFailOptions(aerialLift.outriggersOkay)}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Personal Protection in use and inspected. (Harness, lanyard, hardhat etc.)
							</Table.Cell>
							<Table.Cell>
								{aerialLift.PPEOkay ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							In windy conditions see manufacturer guidelines or if not in guidelines then…if lift begins to rock in the wind lower the lift
							</Table.Cell>
							<Table.Cell>
								{aerialLift.isWindy ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Floor conditions: Drop offs, holes, uneven surfaces, and sloped floors
							</Table.Cell>
							<Table.Cell>
								{aerialLift.floorConditions ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Housekeeping: Debris, floor obstructions, cords, construction material and supplies
							</Table.Cell>
							<Table.Cell>
								{aerialLift.housekeeping ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Electrical power cables or panels, (minimum 10 feet away). Refer to 29 CPR 1910.333 (c)
							</Table.Cell>
							<Table.Cell>
								{aerialLift.electrical ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Chemical lines, gas lines, drain lines, and utilities.
							</Table.Cell>
							<Table.Cell>
								{aerialLift.utilities ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								Overhead Obstructions
							</Table.Cell>
							<Table.Cell>
								{aerialLift.overheadObstructions ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Loads (do not exceed capacity)
							</Table.Cell>
							<Table.Cell>
								{aerialLift.loads ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
							Watch for vehicular and pedestrian traffic. Set up barricades if necessary
							</Table.Cell>
							<Table.Cell>
								{aerialLift.traffic ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
							</Table.Cell>
						</Table.Row>
					</>}
				</Table>
				{aerialLift.isOperator && <>
					<Table>
						<Table.Row>
							<Table.Cell>
								Precaution Comments
							</Table.Cell>
							<Table.Cell>
								{aerialLift.comments}
							</Table.Cell>
						</Table.Row>
					</Table>
				</>}
			</Segment>
		</>
	);
};

export default observer(AerialLiftDetails);
