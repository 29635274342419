import React, { useContext, useEffect } from "react";
import { Segment, Table, Button, TableCell, Icon, Grid, Header } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { prettyPrintDate, prettyPrintDateTime, prettyPrintTime } from "../../../app/common/util/util";
import RenderIf from "../../../app/common/form/RenderIf";
import DeleteIncidentReport from "../../modals/DeleteIncidentReport";

interface DetailParams {
  id: string;
}

const IncidentReportDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { 
    incidentReport, 
    loadIncidentReport, 
    loadingInitial 
  } = rootStore.incidentReportStore;
  const { accountInfoUserEmail } = rootStore.userStore;
  const { openModal } = rootStore.modalStore;
  const { 
    isAdmin,
    isVehicleAdmin
  } = rootStore.generalStore;

  useEffect(() => {
    loadIncidentReport(match.params.id);
  }, [loadIncidentReport, match.params.id]);

  if (!incidentReport || loadingInitial)
    return <LoadingComponent content="Loading Incident/Injury Report ..." />;

  if (!incidentReport) return <h2>Incident/Injury Report Not Found</h2>;

  const canView = (email: string | undefined) => {
    if(email === null){
      return false;
    }else if(email === incidentReport.createdBy || isVehicleAdmin(email) || email == incidentReport.supervisorEmail){
      return true;
    }else{
      return false;
    }
  }
  
	if (!canView(accountInfoUserEmail)) return <h2>Access restricted.</h2>

  if(incidentReport.deleted) return <h2>This Incident/Injury Report has been deleted.</h2>

  return (
    <>
      <PageTitle title="Incident/Injury Report" />
      <Segment clearing>
        <Header as="h3">Incident/Injury Report Details
          <RenderIf condition={isAdmin(accountInfoUserEmail) || incidentReport.createdBy == accountInfoUserEmail}>
            <Button color='red' floated="right" onClick={() => openModal(<DeleteIncidentReport incidentReport={incidentReport} />)} className='noPrint'>Delete</Button>
            <Button floated='right' color='orange' content='Edit' as={Link} to={`/incident-report/manage/${incidentReport.id}`} className='noPrint'/>
          </RenderIf>
        </Header>
        <Table className='altColorTable'>
          <Table.HeaderCell colSpan='2'>People</Table.HeaderCell>
          <Table.Row>
            <Table.Cell>
              Was someone injured, if so who? 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.whoseInjury} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              DL/GDL or PM/SSL on Duty 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.onDuty} 
            </Table.Cell>
          </Table.Row>
        </Table>
         
        <Table className='altColorTable'>
        <Table.HeaderCell colSpan='2'>Incident</Table.HeaderCell>
          <Table.Row>
            <Table.Cell>
              Incident Type(s)
            </Table.Cell>
            <Table.Cell>
              {incidentReport.lostTime && 'Lost Time;\t'}
              {incidentReport.firstAid && 'First Aid;\t'} 
              {incidentReport.vehicleDamage && 'Vehicle Damage;\t'} 
              {incidentReport.injuryIllness && 'Injury/Illness;\t'}                     
              {incidentReport.disabling && 'Disabling;\t'} 
              {incidentReport.restrictedDuty && 'Restricted Duty;\t'} 
              {incidentReport.propDamage && 'Property Damage;\t'} 
              {incidentReport.airEmission && 'Air Emission;\t'} 
              {incidentReport.accReleaseSpill && 'Accidental Release/Spill;\t'} 
              {incidentReport.thirdParty && '3rd Party Involved;\t'}  
              {incidentReport.theft && 'Theft;\t'}             
              {incidentReport.other && <> Other: {incidentReport.otherDesc}</>} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Project Number 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.projNum} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Client Name 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.clientName} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Incident Date and Time 
            </Table.Cell>
            <Table.Cell>
              {prettyPrintDateTime(incidentReport.incidentDate, incidentReport.incidentTime)} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Report Date 
            </Table.Cell>
            <Table.Cell>
              {prettyPrintDate(incidentReport.reportDate)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Shift/Time Started 
            </Table.Cell>
            <Table.Cell>
              {prettyPrintTime(incidentReport.startedTime)} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Location of Incident 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.location} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Witness to Incident 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.witness} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Nature of Injury 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.nature} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Task Being Performed 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.task} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Detailed Incident Description 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.desc} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Why Did it Happen? 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.factors} 
            </Table.Cell>
          </Table.Row>
        </Table>

        <Table className='altColorTable'>
          <Table.HeaderCell colSpan='2'>Medical</Table.HeaderCell>
          <Table.Row>
            <Table.Cell>
              Treatment Location 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.treatmentLocation} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Treatment Date 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.treatmentDate && prettyPrintDate(incidentReport.treatmentDate)} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Treated By 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.treatedBy} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Physician Phone 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.drPhone} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
            If employee is placed on restricted duty, provide details of restriction 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.restrictions} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
            If employee is removed from duty, provide the next schedule medical appointment (date and doctor's name) 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.nextAppt} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
            Diagnosis (if any) 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.diagnosis} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <TableCell>
              Are they out of work?
            </TableCell>
            <TableCell>
              {incidentReport.outOfWork ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}
            </TableCell>
          </Table.Row>
          {incidentReport.outOfWork && <>
            <Table.Row>
              <Table.Cell>
              Out of Work From 
              </Table.Cell>
              <Table.Cell>
                {incidentReport.outStartDate && prettyPrintDate(incidentReport.outStartDate)} 
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
              Out of Work To 
              </Table.Cell>
              <Table.Cell>
                {incidentReport.outEndDate && prettyPrintDate(incidentReport.outEndDate)} 
              </Table.Cell>
            </Table.Row>
          </>}
        </Table>
        
        <Table className='altColorTable'>
          <Table.HeaderCell colSpan='2'>Misc.</Table.HeaderCell>
          <Table.Row>
            <Table.Cell>
              Additional Comments 
            </Table.Cell>
            <Table.Cell>
              {incidentReport.comments} 
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Files
            </Table.Cell>
            <Table.Cell>
              {incidentReport.files ? <>
                  <ul>{incidentReport.files.map((filename:string, i:number) => <li key={i}><a target="_blank" rel='noreferrer' href={process.env.REACT_APP_ATTACHMENTS_URL + `/${incidentReport.id}/` + filename}>{filename}</a></li>)}</ul> 
                </>  : <>None found.</>
              }
            </Table.Cell>
          </Table.Row>
        </Table>

        <Grid.Column>
          <div>Created by: {incidentReport.createdBy ? incidentReport.createdBy : 'N/A'}</div>
          <div>Created on: {incidentReport.reportDate ? prettyPrintDateTime(incidentReport.reportDate) : 'N/A'}</div>
        </Grid.Column>
      </Segment>
      </>
  );
};

export default observer(IncidentReportDetails);