import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";

import { RootStore } from "./rootStore";
import { INotification } from "../models/notification";
import { localizeDateTime } from "../common/util/util";
import { history } from "../..";
import { toast } from "react-toastify";
import { act } from "react-dom/test-utils";

const PAGE_SIZE = 10;
export default class NotificationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable notificationRegistry = new Map<string, INotification>();
  @observable notification: INotification| null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";

  @observable notificationCount = 0;
  @observable page = 0;
  @observable predicate = new Map<string, string | number | boolean>();

  @observable unreadNotifications: number = 0;

  @action redefineDatesWithTimezones = (notification: INotification) => {
    notification.created = localizeDateTime(new Date(notification.created));
    notification.lastAcknowledged = localizeDateTime(new Date(notification.lastAcknowledged));
  }

    /*************************************
   * begin pagination
   *************************************/
  
     @action setCount = (count: number) => {
      this.notificationCount = count;
    };
  
    @action setPage = (page: number) => {
      this.page = page;
    }


  
    /*************************************
   * begin params
   *************************************/
  getPredicate = () => {
    return this.predicate;
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));
    
    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });
    
    return params;
  }

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearNotificationRegistry = () => {
    this.notificationRegistry.clear();
    this.page = 0;
  };

  @computed get notificationsByDefault() {
    // return Array.from(this.notificationRegistry.values()).sort(
    //   (a, b) =>
    //     b.created.getTime() - a.created.getTime()
    // ); // sort in descending order

    return Array.from(this.notificationRegistry.values());
  }

  @computed get unacknowlegedNotifications() {
    return Array.from(this.notificationRegistry.values()).filter(n => n.acknowledged === false).sort(
      (a, b) =>
        b.created.getTime() - a.created.getTime()
    ); // sort in descending order
  }

  @computed get totalPages() {
    return Math.ceil(this.notificationCount / PAGE_SIZE);
  }

  /*************************************
  *
  *************************************/
   get isEmpty() {
    return this.notificationRegistry.size === 0;
  }

  /*************************************
  *
  *************************************/
 
 @computed get notificationList() {
   //return Array.from(this.activityRegistry.values()).sort(
     //    (a, b) => Date.parse(a.date) - Date.parse(b.date))
     
     return Array.from(this.notificationRegistry.values());
    }

  @computed get unacknowledgedNotificationList() {
    return Array.from(this.notificationRegistry.values()).filter( n => n.acknowledged === false);
  }
    
  /*************************************
   *
   *************************************/

  @action loadUserNotifications = async () => {
    this.loadingInitial = true;
    try {
      const response = await agent.Notification.list(this.axiosParams);
      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);
      runInAction("loading notifications", () => {
        response.data.notifications.forEach((notification: INotification) => {
          this.redefineDatesWithTimezones(notification);
          this.notificationRegistry.set(notification.id, notification);
        });
        this.loadingInitial = false;
      });
    } catch (error){
      runInAction("load notifications error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };


  
   @action loadNotification = async (id: string) => {
    this.loadingInitial = true;
    try {
      let notification = await agent.Notification.details(id);
      runInAction("getting notification", () => {
        if (notification) {
          this.redefineDatesWithTimezones(notification);
          this.notification = notification;
          this.notificationRegistry.set(notification.id, notification);
        }
        this.loadingInitial = false;
      });
      return notification;
    } catch (error) {
      runInAction("get notification error", () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };

  /*************************************
  *
  *************************************/

  @action createNotification = async (notification: FormData) => {
    this.loadingInitial = true;
    try{
      var n = await agent.Notification.create(notification);
      runInAction("create new notification", () => {
        this.redefineDatesWithTimezones(n);
        this.notificationRegistry.set(n.id, n);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${n.safetyChecklistId}`);
      toast.success("New Notification Created");
    } catch (error) {
      runInAction("create notification error", () => {
        this.submitting = false;
      });
      toast.error("Problem creating new notification");
      console.log(error);
    }
  };
 
  @action acknowledgeNotification = async (id: string) => {
    this.loadingInitial = true;
    try {
      let response = await agent.Notification.acknowledge(id);
      runInAction("acknowledging notification", () => {
        this.loadingInitial = false;
        this.getUnreadNotificationCount();
        toast.success('Notification acknowledged');
      });
      return;
    } catch (error) {
      runInAction("acknowledge notif error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };
  

  /*************************************
  *
  *************************************/
  @action getUnreadNotificationCount = async () => {
    this.loadingInitial = true;
    try {
      let response = await agent.Notification.unreadCount();
      runInAction("unread count", () => {
        this.unreadNotifications = response.data.countToReturn;
        this.loadingInitial = false;
      });
      return response;
    } catch (error) {
      runInAction("unread count error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };
};