export interface IControl{ 
    id: string;
    description: string;
}

export class ControlFormValues implements IControl {
    id: string = '';
    description: string = '';

    constructor(init?: ControlFormValues) {
        Object.assign(this,init);
      }
}