import { IControl, ControlFormValues } from "./control";
import { ISOP, SOPFormValues } from "./sop";
import { ISafetyChecklist, SafetyChecklistFormValues } from "./safetyChecklist";

export interface IHazard { 
    id: string;
    name: string;
    associatedControls?: IControl[];
    associatedControlsString?: string;
    associatedJobNumber?: string;
    associatedSOPs: ISOP[];
    associatedSOP_ids?: string[];
    //hazardSOPs?: IHazardSOP[];
    //checklistHazards?: IChecklistHazard[];
    checklists: ISafetyChecklist[];
}

export class HazardFormValues implements IHazard {

    id: string = '';
    name: string = '';
    associatedControls?: IControl[] = [new ControlFormValues(),];
    associatedJobNumber?: string = '';
    //hazardSOPs?: IHazardSOP[] = [new HazardSOPFormValues(),];
    associatedSOPs: ISOP[] = [new SOPFormValues(),]
    //checklistHazards?: IChecklistHazard[] = [new ChecklistHazardFormValues(),];
    checklists: ISafetyChecklist[] = [new SafetyChecklistFormValues(),]

    constructor(init?: HazardFormValues) {
        Object.assign(this,init);
      }
}

export class HazardEmptyFormValues implements IHazard {
    id: string = '';
    name: string = '';
    associatedControls?: IControl[] = [new ControlFormValues(),];
    associatedJobNumber?: string = '';
    //hazardSOPs?: IHazardSOP[] = [];
    associatedSOPs: ISOP[] = [];
    //checklistHazards?: IChecklistHazard[] = [];
    checklists: ISafetyChecklist[] = [];


    constructor(init?: HazardEmptyFormValues) {
        Object.assign(this,init);
      }
}