import { observer } from "mobx-react-lite";
import React, { useEffect, useContext, Fragment } from "react";
import { Dropdown, Card, Button, Feed, Divider} from "semantic-ui-react";

import '../Notification.scss';
import { RootStoreContext } from "../../../app/stores/rootStore";
import { NotificationFormValues } from '../../../app/models/notification';
import AcknowledgeNotification from '../../modals/AcknowledgeNotification';

const NotificationBell: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { unreadNotifications, getUnreadNotificationCount, unacknowlegedNotifications } = rootStore.notificationStore;
	const { openModal } = rootStore.modalStore;

    useEffect(() => {
        getUnreadNotificationCount();
    }, [getUnreadNotificationCount]);

    return (
        <>
            {unreadNotifications !== 0 &&
                <div className='bellRedDot'>
                    <div className='notifCount'>{unreadNotifications}</div>
                </div>
            }

            <Dropdown icon='bell'>
                <Dropdown.Menu>
                    <Card id='notificationDetails'>
                        <Card.Content header='Safety Checklist Notifications' />
                        <Card.Content>
                            <Feed>
                                {unacknowlegedNotifications.slice(0, 4).map((notification: NotificationFormValues) => { return (
                                    <Fragment key={notification.id}>
                                        {notification.acknowledged === false && <>
                                            <Feed.Event className='notificationListItem'>
                                                <Feed.Content>
                                                    <Feed.Summary>
                                                        {notification.safetyChecklist.projectNumber} - {notification.safetyChecklist.discipline?.name}
                                                    </Feed.Summary>
                                                    
                                                    <Card.Content extra>
                                                        <div className='ui two buttons'>
                                                            <Button basic content='View Checklist' color='black' href={`/safety-checklist/${notification.safetyChecklist.id}`}/>
                                                            <Button basic content='Acknowledge' color='green' onClick={() => {openModal(<AcknowledgeNotification notification={notification}/>)}} />
                                                        </div>
                                                    </Card.Content>
                                                </Feed.Content>
                                            </Feed.Event>
                                            <Divider />
                                        </> }
                                    </Fragment>
                                )})}

                                <Feed.Event>
                                    <Feed.Content>
                                        <a href='/notifications'>See all</a>
                                    </Feed.Content>
                                </Feed.Event>
                            </Feed>
                        </Card.Content>
                    </Card>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
};

export default observer(NotificationBell);