export interface IChemicalList {
    checklist_id: string;
    list: IChemical[];
}

export interface IChemicalFormList {
    list: IChemical[];
}
/* eslint-disable no-cond-assign */

export class ChemicalInvFormValues implements IChemical {
    name: string = "";
    components: string = "";
    amounts: string = "";
    location: string = "";
}

export class ChemicalInvListFormValues implements IChemicalFormList {
    list: IChemical[] = [new ChemicalInvFormValues()];

    constructor(init?: ChemicalInvFormValues) {
        Object.assign(this, init);
    }
}

export interface IChemical {
    name: string;
    components: string;
    amounts: string;
    location: string;
}