import React, { Fragment } from "react";
import { Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

const ChemicalInvListDetails = (params: any) => {

	return (
		<>
			<Segment>
				<Grid columns={2}>
					{params.chemicalList.map((chem: any) => (
						<Fragment key={chem.name}>
							<Segment>
								<Grid.Row>
									<Grid.Column>Name:</Grid.Column>
									<Grid.Column>{chem.name}</Grid.Column>
									<Grid.Column>Amounts:</Grid.Column>
									<Grid.Column>{chem.amounts}</Grid.Column>
									<Grid.Column>Components:</Grid.Column>
									<Grid.Column>{chem.components}</Grid.Column>
									<Grid.Column>Location:</Grid.Column>
									<Grid.Column>{chem.location}</Grid.Column>
								</Grid.Row>
							</Segment>
						</Fragment>
					))}
				</Grid>
				<br />
			</Segment>
		</>
	);
};

export default observer(ChemicalInvListDetails);
