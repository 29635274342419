import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Dropdown, DropdownProps, Segment, Grid } from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import { observer } from "mobx-react-lite";

import { RootStoreContext } from "../../../app/stores/rootStore";
import { NearMissSearchFormValues } from "../../../app/models/nearMiss";
import { months, years } from "../../../app/common/options/datesOptions";

const NearMissSearch = () => {
	const rootStore = useContext(RootStoreContext);
	const {setPredicate, clearPredicate, loadNearMisses, clearNearMissRegistry, getPredicate } = rootStore.nearMissStore;
	const { allDisciplines, loadDisciplines } = rootStore.disciplineStore;

	const [nearMissSearch, setNearMissSearch] = useState(new NearMissSearchFormValues());

	const handleDiscChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setNearMissSearch({...nearMissSearch, disciplines: data.value as string[] });
		setPredicate("disciplines", JSON.stringify(data.value));
	};
	const handleMonthsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setNearMissSearch({...nearMissSearch, months: data.value as number[] }); 
		setPredicate("months", JSON.stringify(data.value));
	};
	const handleYearsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => { 
		setNearMissSearch({...nearMissSearch, years: data.value as number[] }); 
		setPredicate("years", JSON.stringify(data.value));
	};
	
	/*************************************
	 * Submit the search
	 *************************************/
	const handleFinalFormSubmit = (values: NearMissSearchFormValues) => {
		clearNearMissRegistry();
		loadNearMisses();
	};
	
	/*************************************
	 * Clear the search values
	 *************************************/
	const resetSearch = (form: any) => {
		setNearMissSearch(new NearMissSearchFormValues());
		clearPredicate();
		form.reset();
		loadNearMisses();
	};
	
	
	/*************************************
	 * Render the selected filters
	 *************************************/
	const loadStorePredicate = () => {
		let storePred = getPredicate();
		
		let monthsStr: string | undefined = storePred.get("months")?.toString().replace('[', '').replace(']', '');
		let monthsNumAry: number[] | undefined = monthsStr?.split(',').map(y => parseInt(y, 10));
		
		let yearsStr: string | undefined = storePred.get("years")?.toString().replace('[', '').replace(']', '');
		let yearsNumAry: number[] | undefined = yearsStr?.split(',').map(y => parseInt(y, 10));
		
		let discsStr: string | undefined = storePred.get("disciplines")?.toString().replace('[', '').replace(']', '').replaceAll('"', '');
		let discsAry: string[] | undefined = discsStr?.split(',');

		let searchVals = new NearMissSearchFormValues();
		if (monthsNumAry !== undefined) searchVals.months = monthsNumAry;
		if (yearsNumAry !== undefined) searchVals.years = yearsNumAry;
		if (discsAry !== undefined) searchVals.disciplines = discsAry;

		setNearMissSearch(searchVals);
	}

	useEffect(() => {
		loadDisciplines();
		loadStorePredicate();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Segment clearing>
			<FinalForm
				onSubmit={handleFinalFormSubmit}
				initialValues={nearMissSearch}
				render={({ handleSubmit, form }) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group widths="equal">
							<Dropdown
								name="disciplines"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Discipline"
								onChange={handleDiscChange}
								value={nearMissSearch.disciplines}
								options={allDisciplines.map((discipline) => ({
									key: discipline.id,
									value: discipline.id,
									text: discipline.name,
								}))}
								/>
							<Dropdown
								name="months"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Month"
								onChange={handleMonthsChange}
								options={months}
								value={nearMissSearch.months}
								/>
							<Dropdown
								name="years"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Filter by Year"
								onChange={handleYearsChange}
								options={years}
								value={nearMissSearch.years}
							/>
						</Form.Group>
						
						<Grid>
							<Grid.Column textAlign="center">
								<Button positive type="submit" content="Search" />
								<Button color="red" type="button" content="Reset" onClick={() => resetSearch(form)} />
							</Grid.Column>
						</Grid>
					</Form>
				)}
			/>
		</Segment>
	);
};

export default observer(NearMissSearch);
