import React from "react";
import { Link } from "react-router-dom";
import { Button, Item, Segment } from "semantic-ui-react";
import { IFieldSafetyAudit } from "../../../app/models/fieldSafetyAudit";
// import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { boolToYesNoNA } from "../../../app/common/util/util";

const FieldSafetyAuditListItem: React.FC<{ fieldSafetyAudit: IFieldSafetyAudit }> = ({ fieldSafetyAudit }) => {

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header>{fieldSafetyAudit.safetyChecklist?.projectNumber} | {fieldSafetyAudit.safetyChecklist?.discipline ? fieldSafetyAudit.safetyChecklist?.discipline.name : 'N/A'}</Item.Header>
              <Item.Description>Auditor: {fieldSafetyAudit.submitterEmail}</Item.Description>
              <Item.Description>SSL Approval: {boolToYesNoNA(fieldSafetyAudit.sslApproval)}</Item.Description>
              <Item.Description>Completed: {boolToYesNoNA(fieldSafetyAudit.completed)}</Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment clearing>
        <Button
          as={Link}
          to={`/field-safety-audit/${fieldSafetyAudit.id}`}
          floated="right"
          content="View"
          color="blue"
        />
      </Segment>
    </Segment.Group>
  );
};

export default observer(FieldSafetyAuditListItem);
