import React from 'react';
import { Field } from 'react-final-form';
import DateInput from './DateInput';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import TimeInput from './TimeInput';

export const FieldDate = (p:any) =>
	<Field {...p} date={true} component={DateInput}/>
;

export const FieldTime = (p:any) =>
	<Field {...p} time={true} component={TimeInput}/>
;

export const FieldTextLine = (p:any) =>
	<Field {...p} component={TextInput}/>
;

export const FieldTextBox = (p:any) =>
	<Field {...p} rows={2} component={TextAreaInput}/>
;
