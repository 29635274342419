import React, { useContext, useEffect } from "react";
import { Header, Form, Grid, Segment, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { IVehicleAccidentPassenger } from "../../../app/models/vehicleAccidentPassenger";
import { IVehicleAccidentWitness } from "../../../app/models/vehicleAccidentWitness";
import { isStringEmpty, prettyPrintDate, prettyPrintDateTime } from "../../../app/common/util/util";
import DeleteVehicleAccidentReport from "../../modals/DeleteVehicleAccidentReport";

import './VehicleAccidentReportDetailsPrint.scss'
import RenderIf from "../../../app/common/form/RenderIf";
interface DetailParams {
  id: string;
}

const VehicleAccidentReportDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { report, loadReport, loadingInitial } = rootStore.vehicleAccidentReportStore;
  const { accountInfoUserEmail } = rootStore.userStore;
  const { openModal } = rootStore.modalStore;
  const { isAdmin, isVehicleAdmin } = rootStore.generalStore;

  useEffect(() => {
    loadReport(match.params.id);
  }, [loadReport, match.params.id, history]);

  if (loadingInitial)
    return <LoadingComponent content="Loading Vehicle Accident Report..." />;

  if (!report)
    return <h2>Vehicle Accident Report Not Found</h2>;

  const canView = (email: string | undefined) => {
    if(email === null){
      return false;
    }else if(email?.toLowerCase() === report.createdBy.toLowerCase() || isVehicleAdmin(email) || email?.toLowerCase() === report.supervisorEmail?.toLowerCase()){
      return true;
    }else{
      return false;
    }
  }

  if (!canView(accountInfoUserEmail))
    return <h2>Access restricted.</h2>

  
  if (report.deleted) return <h2>This Vehicle Accident Report has been deleted.</h2>;
  
  else
    return (
      <>
        <PageTitle title="Vehicle Accident Report Details" />
        <Grid>
			    <Grid.Column width={16}>
            <Segment clearing>

              <RenderIf condition={canView(accountInfoUserEmail)}>
                <Segment clearing className="noPrint">
							    <Button.Group floated="right">
                    <Button as={Link} to={`/vehicle-accident-report/manage/${report.id}`} color="orange" floated="right" className="noPrint">Edit</Button>
                    <Button color='red' floated="right" onClick={() => openModal(<DeleteVehicleAccidentReport var={report} />)} className='noPrint'>Delete</Button>
                    </Button.Group>
                </Segment>
              </RenderIf>
                
            <Segment>
              <Grid stackable columns={2}>
                <Grid.Column>
                  <div><b>Date and Time: </b><span className='floatRight'>{prettyPrintDateTime(report.dateTime)}</span></div>
                  <div><b>Street / Intersection: </b><span className='floatRight'>{report.streetIntersection}</span></div>
                  <div><b>City: </b><span className='floatRight'>{report.city}</span></div>
                  <div><b>State: </b> <span className='floatRight'>{report.state}</span></div>
                  <div><b>Weather Conditions: </b><span className='floatRight'>{report.weatherConditions}</span></div>
                </Grid.Column>
                <Grid.Column>
                  <div><b>Accident Description: </b>{report.accidentDescription}</div>
                </Grid.Column>
                <RenderIf condition={!isStringEmpty(report.whoWasInjured)}>
                  <Grid.Column>
                    <div><b>Who was injured?</b> <span className='floatRight'>{report.whoWasInjured}</span></div>
                    <div><b>What treatment was received?</b> <span className='floatRight'>{report.treatmentReceived}</span></div>
                  </Grid.Column>
                </RenderIf>
              </Grid>
            </Segment>

                <Header as='h3'>Operator Information</Header>
                <Segment.Group horizontal>
                  <Segment>
                    <Grid stackable columns={2}>
                      <Grid.Column>
                        <Header as='h4' className='gridHeader'>Colliers Operator</Header>
                        <div><b>Operator Name: </b><span className='floatRight'>{report.operatorName1}</span></div>
                        <div><b>Operator Address: </b><span className='floatRight'>{report.operatorAddress1}</span></div>
                        <div><b>Operator Phone: </b><span className='floatRight'>{report.operatorPhone1}</span></div>
                        <div><b>Operator License: </b><span className='floatRight'>{report.operatorLicense1}</span></div>
                        <div><b>Operator Date of Birth: </b><span className='floatRight'>{report.operatorDob1 && prettyPrintDate(report.operatorDob1)}</span></div>
                        <div><b>Operator Insurance Company / Policy #: </b><span className='floatRight'>{report.operatorInsurance1}</span></div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h4' className='gridHeader'>Operator 2</Header>
                        <div><b>Operator Name: </b><span className='floatRight'>{report.operatorName2}</span></div>
                        <div><b>Operator Address: </b><span className='floatRight'>{report.operatorAddress2}</span></div>
                        <div><b>Operator Phone: </b><span className='floatRight'>{report.operatorPhone2}</span></div>
                        <div><b>Operator License: </b><span className='floatRight'>{report.operatorLicense2}</span></div>
                        <div><b>Operator Date of Birth: </b><span className='floatRight'>{report.operatorDob2 && prettyPrintDate(report.operatorDob2)}</span></div>
                        <div><b>Operator Insurance Company / Policy #: </b><span className='floatRight'>{report.operatorInsurance2}</span></div>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Segment.Group>

                <Header>Vehicle Information</Header>
                <Segment.Group horizontal>
                  <Segment>
                    <Grid stackable columns={2}>
                      <Grid.Column>
                        <Header as='h4' className='gridHeader'>Colliers Vehicle</Header>
                        <div><b>Vehicle Number: </b><span className='floatRight'>{report.vehicleNumber1}</span></div>
                        <div><b>Vehicle Make/Model: </b><span className='floatRight'>{report.vehicleMakeModel1}</span></div>
                        <div><b>Vehicle Year: </b><span className='floatRight'>{report.vehicleYear1}</span></div>
                        <div><b>Vehicle License: </b><span className='floatRight'>{report.vehicleLicense1}</span></div>
                        <div><b>Vehicle Registration: </b><span className='floatRight'>{report.vehicleRegistration1}</span></div>
                      </Grid.Column>

                      <Grid.Column>
                        <Header as='h4' className='gridHeader'>Vehicle 2</Header>
                        <div><b>Vehicle Number: </b><span className='floatRight'>{report.vehicleNumber2}</span></div>
                        <div><b>Vehicle Make/Model: </b><span className='floatRight'>{report.vehicleMakeModel2}</span></div>
                        <div><b>Vehicle Year: </b><span className='floatRight'>{report.vehicleYear2}</span></div>
                        <div><b>Vehicle License: </b><span className='floatRight'>{report.vehicleLicense2}</span></div>
                        <div><b>Vehicle Registration: </b><span className='floatRight'>{report.vehicleRegistration2}</span></div>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Segment.Group>

                <Header>Vehicle Owner Information</Header>
                <Segment.Group horizontal>
                  <Segment>
                    <Grid stackable columns={2}>
                      <Grid.Column>
                        <Header as='h4' className='gridHeader'>Colliers Vehicle Owner</Header>
                          <div><b>Owner Registered Name:</b><span className='floatRight'>{report.ownerRegisteredName1}</span></div>
                          <div><b>Owner Address:</b><span className='floatRight'>{report.ownerAddress1}</span></div>
                          <div><b>Owner Phone:</b><span className='floatRight'>{report.ownerPhone1}</span></div>
                          <div><b>Owner Insurance Company / Policy Number:</b><span className='floatRight'>{report.ownerInsurance1}</span></div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h4' className='gridHeader'>Vehicle Owner 2</Header>
                          <div><b>Owner Registered Name:</b><span className='floatRight'>{report.ownerRegisteredName2}</span></div>
                          <div><b>Owner Address:</b><span className='floatRight'>{report.ownerAddress2}</span></div>
                          <div><b>Owner Phone:</b><span className='floatRight'>{report.ownerPhone2}</span></div>
                          <div><b>Owner Insurance Company / Policy Number:</b><span className='floatRight'>{report.ownerInsurance2}</span></div>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Segment.Group>
                
                <Header >Passengers Information</Header>
                <Segment>
                  <Grid stackable columns={2}>
                    <Grid.Column>
                      {report.passengers && report.passengers.length > 0 && report.passengers[0].name !== '' ?
                        <>
                          {report.passengers.sort((a: IVehicleAccidentPassenger, b: IVehicleAccidentPassenger) => {
                            return a.listedOrder - b.listedOrder;
                          }).map((passenger, i) =>
                            <Segment key={passenger.id}>
                              <Header as='h4'>Passenger {i + 1}</Header>
                              <Form.Group widths='equal'>
                                <div><b>Passenger Name: </b><span className='floatRight'>{passenger.name}</span></div>
                                <div><b>Passenger Phone: </b><span className='floatRight'>{passenger.phone}</span></div>
                              </Form.Group>
                              <div><b>Passenger Vehicle: </b><span className='floatRight'>{passenger.vehicle}</span></div>
                              <div><b>Passenger Address: </b><span className='floatRight'>{passenger.address}</span></div>
                              <div><b>Passenger Injuries: </b><span className='floatRight'>{passenger.injuries}</span></div>
                            </Segment>
                          )}
                        </>
                        : 'No passengers reported.'
                      }
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Header>Witness Information</Header>
                <Segment>
                  <Grid stackable columns={2}>
                    <Grid.Column>
                      {report.witnesses && report.witnesses.length > 0 && report.witnesses[0].name !== '' ?
                        <>
                          {report.witnesses.sort((a: IVehicleAccidentWitness, b: IVehicleAccidentWitness) => {
                            return a.listedOrder - b.listedOrder;
                          }).map((witness, i) =>
                            <Segment key={witness.id}>
                              <Header as='h4'>Witness {i + 1}</Header>
                              <Form.Group widths='equal'>
                                <div><b>Witness Name: </b><span className='floatRight'>{witness.name}</span></div>
                                <div><b>Witness Phone: </b><span className='floatRight'>{witness.phone}</span></div>
                              </Form.Group>
                              <div><b>Witness Address: </b><span className='floatRight'>{witness.address}</span></div>
                            </Segment>
                          )}
                        </>
                        : 'No witnesses reported.'
                      }
                    </Grid.Column>
                  </Grid>
                </Segment>

                <Header>Emergency Personnel Information</Header>
                <Segment>
                  <Grid stackable columns={2}>
                    <Grid.Column>
                      <div>Who contacted emergency personnel: <span className='floatRight'>{report.whoContactedEmergency}</span></div>
                      <div>Police reponded: <span className='floatRight'>{report.respondedPolice ? 'Yes' : 'No'}</span></div>
                      <div>EMT reponded: <span className='floatRight'>{report.respondedEmt ? 'Yes' : 'No'}</span></div>
                      <div>FIRE reponded: <span className='floatRight'>{report.respondedFire ? 'Yes' : 'No'}</span></div>
                      <div>Was a citation issued? <span className='floatRight'>{report.citationIssued ? 'Yes' : 'No'}</span></div>
                      <div>Citation description: <span className='floatRight'>{report.citationIssued ? report.citationDesc : 'N/A'}</span></div>
                    </Grid.Column>
                    <Grid.Column>
                      <div>Police Officer Name <span className='floatRight'>{report.policeOfficerName}</span></div>
                      <div>Officer / Badge Numbe <span className='floatRight'>{report.policeBadgeNumber}</span></div>
                      <div>Precinct / Department <span className='floatRight'>{report.policePrecinctDept}</span></div>
                    </Grid.Column>
                  </Grid>
                </Segment>	

							<Header>Attachments</Header>
							<Segment>
                {report.files ? <>
                  <ul>{report.files.map((filename:string, i:number) => 
                    <li key={i}><a target="_blank" rel='noreferrer' href={process.env.REACT_APP_ATTACHMENTS_URL + `/${report.id}/` + filename}>{filename}</a></li>)}</ul> 
                  </>  : <>None found.</>
                }
							</Segment>
              <div>Report created on {prettyPrintDateTime(report.createdDate)} by {report.createdBy}</div>

          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(VehicleAccidentReportDetails);
