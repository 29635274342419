import React from 'react'
import { FieldRenderProps } from "react-final-form";
import { DatePicker } from 'react-widgets';
//import "react-widgets/dist/css/react-widgets.css";
import { FormFieldProps, Form, Label } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<Date, HTMLElement>,
    FormFieldProps {}

const DateInput: React.FC<IProps> = ({
    input,
    width,
    label,
    placeholder,
    meta: { touched, error },
    id,
    ...rest
  }) => {
    return (
        <Form.Field error={touched && !!error} width={width}>
        <label>{label}</label>
        <DatePicker 
          placeholder={placeholder}
          value={input.value || null}
          onChange={input.onChange}
          //onBlur={input.onBlur}
          //onKeyDown={(e) => e.preventDefault()}
          {...rest}
        />
        {touched && error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
      </Form.Field>
      )
}

export default DateInput
