import React, { useContext, useState, useEffect, Fragment } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { Form, Segment, Header, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import CheckboxInput from "../../../app/common/form/CheckboxInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";
import { fallHazLabels } from "../../../app/common/options/fallHazQuestions";

const FallHazForm = (params: any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addFallHaz } = rootStore.safetyChecklistStore;
	const [locationsVisible, setLocationsVisible] = useState(new Map<string, boolean>());
	const [harnessWorn, setHarnessWorn] = useState(false);

	// create dictionary to map 'no' answers to visible text fields
	useEffect(() => {
		let tempDict: any = new Map<string, boolean>();
		fallHazLabels[0].labels
			.map((x: any) => x.key)
			.forEach((element: any) => {
				tempDict[element] = false;
			});
		setLocationsVisible(tempDict);
	}, []);

	const handleFinalFormSubmit = (values: any) => {
		const { ...fallHaz } = values;

		addFallHaz({
			checklist_id: params.id,
			fallhaz: values,
		});
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					render={({ handleSubmit, invalid, pristine }) => (
						<Form onSubmit={handleSubmit} id="fallHazForm" className="sopForm">
							<Header as="h3">Fall Hazard Assessment</Header>
							<br />
							<Grid>
								{fallHazLabels.map((group) => (
									<Fragment key={group.groupName}>
										{group.groupName === "locations" && (
											<>
												{group.labels.map((question: any) => (
													<Fragment key={"" + question.key}>
														<Grid.Row className="gridRow">
															<div className="block">
																<Field label={question.label} name={question.label} component={CheckboxInput} />

																<OnChange name={question.label}>
																	{(val: any, preVal: any) => {
																		let tempDict = new Map(locationsVisible);
																		tempDict.set(`${question.key}`, val);
																		setLocationsVisible(tempDict);
																	}}
																</OnChange>
																<Field
																	name={question.key}
																	placeholder="Locations..."
																	component={TextInput}
																	type={locationsVisible.get(question.key) ? "text" : "hidden"}
																/>
															</div>
														</Grid.Row>
													</Fragment>
												))}
											</>
										)}
									</Fragment>
								))}

								<Grid.Row className="gridRow">
									<Field
										label="Are you wearing Personal Fall Protection?"
										name="harnessWorn"
										component={CheckboxInput}
									/>
									<OnChange name="harnessWorn">
										{(val: any, preVal: any) => {
											setHarnessWorn(val);
										}}
									</OnChange>
								</Grid.Row>

								{/* Only render if harness is warn  */}
								{harnessWorn && (
									<>
										{fallHazLabels.map((group) => (
											<Fragment key={group.groupName}>
												{group.groupName === "texts" && (
													<>
														{group.labels.map((question: any) => (
															<Fragment key={"" + question.key}>
																<Grid.Row className="gridRow">
																	<div className="block">
																		<div>{question.label}:</div>
																		<Field name={question.key} placeholder="Description..." component={TextInput} />
																	</div>
																</Grid.Row>
															</Fragment>
														))}
													</>
												)}

												{group.groupName === "checkboxes" && (
													<>
														{group.labels.map((question: any) => (
															<Fragment key={"" + question.key}>
																<Grid.Row className="gridRow">
																	<Field label={question.label} name={question.key} component={CheckboxInput} />
																</Grid.Row>
															</Fragment>
														))}
													</>
												)}
											</Fragment>
										))}
									</>
								)}
							</Grid>
							<br/>
							<Button
								loading={submitting}
								disabled={invalid || pristine}
								floated="right"
								positive
								type="submit"
								content="Submit"
							/>
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(FallHazForm);
