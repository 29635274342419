import { observable, action, computed, runInAction } from "mobx";
// import { SyntheticEvent } from "react";
import { IDiscipline } from "../models/discipline";

import agent from "../api/agent";
// import { history } from "../..";
// import { toast } from "react-toastify";
import { RootStore } from "./rootStore";

export default class DisciplineStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable disciplineRegistry = new Map<string, IDiscipline>();

  @observable discipline: IDiscipline | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";

  @computed get allDisciplines() {
    return Array.from(this.disciplineRegistry.values());
  }

  // @computed get safetyDisciplines() {
  //   return Array.from(this.disciplineRegistry.values()).filter(d => d.safetyDropdowns === true);
  // }

  getAllDisciplines(disciplines: IDiscipline[]) {
    return this.disciplineRegistry.values();
  }

    /*************************************
     *
     *************************************/

    @action clearDiscipline = () => {
        this.discipline = null;
    };
    
/*************************************
 *
 *************************************/
      
    getDiscipline = (id: string) => {
        return this.disciplineRegistry.get(id);
    };
    

  /*************************************
   *
   *************************************/

   @action loadDisciplines = async () => {
    this.loadingInitial = true;
    try {
      const disciplines = await agent.Discipline.list();

      runInAction("loading disciplines", () => {
        disciplines.forEach((discipline) => {
         this.disciplineRegistry.set(discipline.id, discipline);
        });

        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load disciplines error", () => {
        this.loadingInitial = false;
      });

      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

   @action loadDiscipline = async (id: string) => {
    let discipline = this.getDiscipline(id);

    if (discipline) {
      this.discipline = discipline;
      return discipline;
    } else {
      this.loadingInitial = true;

      try {
        discipline = await agent.Discipline.details(id);

        runInAction("getting discipline", () => {
          if(discipline) {
            this.discipline = discipline;
            this.disciplineRegistry.set(discipline.id, discipline);
          }
          this.loadingInitial = false;
        });
        return discipline;
      } catch (error) {
        runInAction("get discipline error", () => {
          this.loadingInitial = false;
        });

        console.log(error);
      }
    }
  };
}