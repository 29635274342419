import React, { useContext, useState, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Segment, Button, Table } from "semantic-ui-react";
import { VehicleInspectionFormValues } from "../../../app/models/vehicleInspection";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { toast } from 'react-toastify';

import TextInput from "../../../app/common/form/TextInput";
import DateInput from "../../../app/common/form/DateInput";
import NumberInput from "../../../app/common/form/NumberInput";
import NumberSelectInput from "../../../app/common/form/NumberSelectInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import { FieldFileDropZone } from "../../../app/common/form/FileDropZone";
import ComfirmCancelForm from '../../modals/ConfirmCancelForm';
import { FieldDate } from "../../../app/common/form/ConvenienceFields";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import CheckboxInput from "../../../app/common/form/CheckboxInput";

const ACCEPTABLE = 'A';
const UNACCEPTABLE = 'U';
const NOT_APPLICABLE = 'NA';

// TODO Either clean this up or outright pull it into  it's own Validator class or something, this can be re-used elsewhere
// and just looks sloppy here

const Required = (x:any, err:string = 'Required') => (x !== undefined && x !== null && x !== '')? undefined : err;
const RequiredAndGt1 = (x:any, err:string = 'Must be greater than 1') => (x !== undefined && x !== null && x > 1)? undefined : err;
const RequiredIf = (x:any, condition:boolean, err:string = 'Required') => condition? Required(x, err) : undefined;
// const DoesNotEqual = (x:any, condition:boolean, err:string = 'Select a vehicle') => condition? Required(x, err): undefined;

const validate = (values: any) => {
  const mergeObjs = (...objs:any[]) => Object.assign({}, ...objs);
  
  const statuses = Object.entries(values).filter(x => x[0].endsWith('Status'));
  const comments = Object.entries(values).filter(x => x[0].endsWith('Comment'));
  
  const getKeyPartBeforeComment = (s:string):string => s.substring(0, s.indexOf('Comment'));
  const getKeyPartBeforeStatus  = (s:string):string => s.substring(0, s.indexOf('Status'));
  
  // Absolutely rancid (lol)
  const o = mergeObjs(
    {
      vehicle_Keyid: Required(values.vehicle_Keyid),
      currentMileage: RequiredAndGt1(values.currentMileage),
      nextOilChangeMileage: RequiredAndGt1(values.nextOilChangeMileage),
      insurance: Required(values.insurance),
      registration: Required(values.registration),
      otherIssuesExplanation: RequiredIf(values.otherIssuesExplanation, values.otherIssues)
    },
    // example object for below code: {'a': 1, 'b': 'c', 'c': null}
    // x[0]: the 'key', like 'a', 'b', or 'c'
    // x[1]: the 'value', like 1, 'c', or null
    Object.fromEntries(statuses.map(x => [x[0], Required(x[1])])),
    Object.fromEntries(comments.map(x => [x[0], RequiredIf(x[1], statuses.filter(y => getKeyPartBeforeStatus(y[0]) === getKeyPartBeforeComment(x[0]))[0]![1] === UNACCEPTABLE)]))
  );
  
  return o;
}

const clearExplanation = (name: string, values: any) => {
  var commentFieldName = name.split("Status")[0] + "Comment";
  values[commentFieldName] = null;
}

interface BaseRadioProps { name: string, value: string, onClick?: any, }
interface RadioProps { name: string, values?: any, }

const BaseRadio          = (p:BaseRadioProps) => <Field name={p.name} component='input' type='radio' value={p.value} onClick={p.onClick}/>
const AcceptableRadio    = (p:RadioProps) => <div className="bottomMargin"><label><BaseRadio name={p.name} value={ACCEPTABLE} onClick={() => clearExplanation(p.name, p.values)} />Acceptable</label></div>
const UnacceptableRadio  = (p:RadioProps) => <div className="bottomMargin"><label><BaseRadio name={p.name} value={UNACCEPTABLE}/>Unacceptable</label></div>
const NotApplicableRadio = (p:RadioProps) => <div className="bottomMargin"><label><BaseRadio name={p.name} value={NOT_APPLICABLE} onClick={() => clearExplanation(p.name, p.values)} />Not Applicable</label></div>

interface DetailParams {
  id: string;
}

const VehicleInspectionForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createVehicleInspection,
    editVehicleInspection,
    submitting,
    vehicles,
    loadVehicles,
    loadVehicleInspection,
  } = rootStore.vehicleInspectionStore;

	const { openModal } = rootStore.modalStore;

  const [vehicleInspection, setVehicleInspection] = useState(new VehicleInspectionFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadVehicles().then(() => setLoading(false));
    
    if (match.params.id) {
      loadVehicleInspection(match.params.id)
        .then((vehicleInspection) => setVehicleInspection(new VehicleInspectionFormValues(vehicleInspection)))
        .finally(() => setLoading(false));
    }
    
  }, [loadVehicles, loadVehicleInspection, match.params.id]);


  const handleFinalFormSubmit = (values: any) => {
    let valid = true;
    let v = validate(values);
    Object.entries(v).forEach(x => {
      if (x[1] === 'ERROR'){
          console.log(x);
          valid = false;
      }
    });
    
    if (!valid) {
      toast.error('Form is invalid. Please make sure all selections have been made.');
      return;
    }

    const payload = new FormData();
    // payload.set('id', values.id);
    payload.set('vehicle_Keyid', values.vehicle_Keyid);
    payload.set('currentMileage', values.currentMileage); 
    payload.set('nextOilChangeMileage', values.nextOilChangeMileage);
    if(values.inspectionDueDate){
      payload.set('inspectionDueDate', values.inspectionDueDate.toISOString());
    }
    payload.set('insurance', values.insurance.toISOString());
    payload.set('registration', values.registration.toISOString());

    // TODO how do we clear a field?

    if (values.brakesStatus !== null && values.brakesStatus !== undefined) payload.set('brakesStatus', values.brakesStatus);
    if (values.brakesComment !== null && values.brakesComment !== undefined) payload.set('brakesComment', values.brakesComment);
    if (values.emergencyBrakeStatus !== null && values.emergencyBrakeStatus !== undefined) payload.set('emergencyBrakeStatus', values.emergencyBrakeStatus);
    if (values.emergencyBrakeComment !== null && values.emergencyBrakeComment !== undefined) payload.set('emergencyBrakeComment', values.emergencyBrakeComment);
    if (values.hornStatus !== null && values.hornStatus !== undefined) payload.set('hornStatus', values.hornStatus);
    if (values.hornComment !== null && values.hornComment !== undefined) payload.set('hornComment', values.hornComment);
    if (values.headlightsStatus !== null && values.headlightsStatus !== undefined) payload.set('headlightsStatus', values.headlightsStatus);
    if (values.headlightsComment !== null && values.headlightsComment !== undefined) payload.set('headlightsComment', values.headlightsComment);
    if (values.tailLightsStatus !== null && values.tailLightsStatus !== undefined) payload.set('tailLightsStatus', values.tailLightsStatus);
    if (values.tailLightsComment !== null && values.tailLightsComment !== undefined) payload.set('tailLightsComment', values.tailLightsComment);
    if (values.brakeLightsStatus !== null && values.brakeLightsStatus !== undefined) payload.set('brakeLightsStatus', values.brakeLightsStatus);
    if (values.brakeLightsComment !== null && values.brakeLightsComment !== undefined) payload.set('brakeLightsComment', values.brakeLightsComment);
    if (values.turnSignalsStatus !== null && values.turnSignalsStatus !== undefined) payload.set('turnSignalsStatus', values.turnSignalsStatus);
    if (values.turnSignalsComment !== null && values.turnSignalsComment !== undefined) payload.set('turnSignalsComment', values.turnSignalsComment);
    if (values.backupLightsStatus !== null && values.backupLightsStatus !== undefined) payload.set('backupLightsStatus', values.backupLightsStatus);
    if (values.backupLightsComment !== null && values.backupLightsComment !== undefined) payload.set('backupLightsComment', values.backupLightsComment);
    if (values.windshieldWipersStatus !== null && values.windshieldWipersStatus !== undefined) payload.set('windshieldWipersStatus', values.windshieldWipersStatus);
    if (values.windshieldWipersComment !== null && values.windshieldWipersComment !== undefined) payload.set('windshieldWipersComment', values.windshieldWipersComment);
    if (values.vehicleInteriorStatus !== null && values.vehicleInteriorStatus !== undefined) payload.set('vehicleInteriorStatus', values.vehicleInteriorStatus);
    if (values.vehicleInteriorComment !== null && values.vehicleInteriorComment !== undefined) payload.set('vehicleInteriorComment', values.vehicleInteriorComment);
    if (values.vehicleExteriorStatus !== null && values.vehicleExteriorStatus !== undefined) payload.set('vehicleExteriorStatus', values.vehicleExteriorStatus);
    if (values.vehicleExteriorComment !== null && values.vehicleExteriorComment !== undefined) payload.set('vehicleExteriorComment', values.vehicleExteriorComment);
    if (values.equipmentStorageAreaStatus !== null && values.equipmentStorageAreaStatus !== undefined) payload.set('equipmentStorageAreaStatus', values.equipmentStorageAreaStatus);
    if (values.equipmentStorageAreaComment !== null && values.equipmentStorageAreaComment !== undefined) payload.set('equipmentStorageAreaComment', values.equipmentStorageAreaComment);
    if (values.mirrorsStatus !== null && values.mirrorsStatus !== undefined) payload.set('mirrorsStatus', values.mirrorsStatus);
    if (values.mirrorsComment !== null && values.mirrorsComment !== undefined) payload.set('mirrorsComment', values.mirrorsComment);
    if (values.windshieldStatus !== null && values.windshieldStatus !== undefined) payload.set('windshieldStatus', values.windshieldStatus);
    if (values.windshieldComment !== null && values.windshieldComment !== undefined) payload.set('windshieldComment', values.windshieldComment);
    if (values.rearWindowStatus !== null && values.rearWindowStatus !== undefined) payload.set('rearWindowStatus', values.rearWindowStatus);
    if (values.rearWindowComment !== null && values.rearWindowComment !== undefined) payload.set('rearWindowComment', values.rearWindowComment);
    if (values.windowsStatus !== null && values.windowsStatus !== undefined) payload.set('windowsStatus', values.windowsStatus);
    if (values.windowsComment !== null && values.windowsComment !== undefined) payload.set('windowsComment', values.windowsComment);
    if (values.tireConditionStatus !== null && values.tireConditionStatus !== undefined) payload.set('tireConditionStatus', values.tireConditionStatus);
    if (values.tireConditionComment !== null && values.tireConditionComment !== undefined) payload.set('tireConditionComment', values.tireConditionComment);
    if (values.tireTreadStatus !== null && values.tireTreadStatus !== undefined) payload.set('tireTreadStatus', values.tireTreadStatus);
    if (values.tireTreadComment !== null && values.tireTreadComment !== undefined) payload.set('tireTreadComment', values.tireTreadComment);
    if (values.brakeFluidStatus !== null && values.brakeFluidStatus !== undefined) payload.set('brakeFluidStatus', values.brakeFluidStatus);
    if (values.brakeFluidComment !== null && values.brakeFluidComment !== undefined) payload.set('brakeFluidComment', values.brakeFluidComment);
    if (values.coolantAntifreezeStatus !== null && values.coolantAntifreezeStatus !== undefined) payload.set('coolantAntifreezeStatus', values.coolantAntifreezeStatus);
    if (values.coolantAntifreezeComment !== null && values.coolantAntifreezeComment !== undefined) payload.set('coolantAntifreezeComment', values.coolantAntifreezeComment);
    if (values.oilStatus !== null && values.oilStatus !== undefined) payload.set('oilStatus', values.oilStatus);
    if (values.oilComment !== null && values.oilComment !== undefined) payload.set('oilComment', values.oilComment);
    if (values.steeringFluidStatus !== null && values.steeringFluidStatus !== undefined) payload.set('steeringFluidStatus', values.steeringFluidStatus);
    if (values.steeringFluidComment !== null && values.steeringFluidComment !== undefined) payload.set('steeringFluidComment', values.steeringFluidComment);
    if (values.transmissionFluidStatus !== null && values.transmissionFluidStatus !== undefined) payload.set('transmissionFluidStatus', values.transmissionFluidStatus);
    if (values.transmissionFluidComment !== null && values.transmissionFluidComment !== undefined) payload.set('transmissionFluidComment',values.transmissionFluidComment);
    if (values.windshieldFluidStatus !== null && values.windshieldFluidStatus !== undefined) payload.set('windshieldFluidStatus',values.windshieldFluidStatus);
    if (values.windshieldFluidComment !== null && values.windshieldFluidComment !== undefined) payload.set('windshieldFluidComment',values.windshieldFluidComment);
    if (values.firstAidKitStatus !== null && values.firstAidKitStatus !== undefined) payload.set('firstAidKitStatus',values.firstAidKitStatus);
    if (values.firstAidKitComment !== null && values.firstAidKitComment !== undefined) payload.set('firstAidKitComment',values.firstAidKitComment);
    if (values.flaresStatus !== null && values.flaresStatus !== undefined) payload.set('flaresStatus',values.flaresStatus);
    if (values.flaresComment !== null && values.flaresComment !== undefined) payload.set('flaresComment',values.flaresComment);
    if (values.sdsStatus !== null && values.sdsStatus !== undefined) payload.set('sdsStatus',values.sdsStatus);
    if (values.sdsComment !== null && values.sdsComment !== undefined) payload.set('sdsComment',values.sdsComment);
    if (values.flashlightStatus !== null && values.flashlightStatus !== undefined) payload.set('flashlightStatus',values.flashlightStatus);
    if (values.flashlightComment !== null && values.flashlightComment !== undefined) payload.set('flashlightComment',values.flashlightComment);
    if (values.fireExtinguisherStatus !== null && values.fireExtinguisherStatus !== undefined) payload.set('fireExtinguisherStatus',values.fireExtinguisherStatus);
    if (values.fireExtinguisherComment !== null && values.fireExtinguisherComment !== undefined) payload.set('fireExtinguisherComment',values.fireExtinguisherComment);
    if (values.spillKitStatus !== null && values.spillKitStatus !== undefined) payload.set('spillKitStatus',values.spillKitStatus);
    if (values.spillKitComment !== null && values.spillKitComment !== undefined) payload.set('spillKitComment',values.spillKitComment);
    if (values.backupCameraStatus !== null && values.backupCameraStatus !== undefined) payload.set('backupCameraStatus',values.backupCameraStatus);
    if (values.backupCameraComment !== null && values.backupCameraComment !== undefined) payload.set('backupCameraComment',values.backupCameraComment);
    if (values.backupAlarmStatus !== null && values.backupAlarmStatus !== undefined) payload.set('backupAlarmStatus',values.backupAlarmStatus);
    if (values.backupAlarmComment !== null && values.backupAlarmComment !== undefined) payload.set('backupAlarmComment',values.backupAlarmComment);
    if (values.strobeFrontStatus !== null && values.strobeFrontStatus !== undefined) payload.set('strobeFrontStatus',values.strobeFrontStatus);
    if (values.strobeFrontComment !== null && values.strobeFrontComment !== undefined) payload.set('strobeFrontComment',values.strobeFrontComment);
    if (values.strobeBackStatus !== null && values.strobeBackStatus !== undefined) payload.set('strobeBackStatus',values.strobeBackStatus);
    if (values.strobeBackComment !== null && values.strobeBackComment !== undefined) payload.set('strobeBackComment',values.strobeBackComment);
    if (values.otherIssues !== null && values.otherIssues !== undefined) payload.set('otherIssues', values.otherIssues);    
    if (values.otherIssuesExplanation !== null && values.otherIssuesExplanation !== undefined) payload.set('otherIssuesExplanation', values.otherIssuesExplanation);

    if(values.fileArray && values.fileArray !== undefined)
      for(var i = 0; i < values.fileArray.length; i++)
        payload.append("newFiles", values.fileArray[i]);

    if(!vehicleInspection.id) {
      createVehicleInspection(payload);
    } else {
      editVehicleInspection(match.params.id, payload);
    }
  };

  
	if (loading) return <LoadingComponent content="Loading Vehicle Inspection..." />;
  
  return (
    <>
      <PageTitle title="Vehicle Inspection Form" />

      <Segment clearing>
        <FinalForm
          validate={validate}
          initialValues={vehicleInspection}
          onSubmit={() => {}} // onSubmit is taken over by the Submit onClick prop
          render={({ handleSubmit, invalid, pristine, values }) => (
            <Form onSubmit={handleSubmit} className='vehicleInspectionForm'>
              <Form.Group widths="equal">
                <Field
                  name="vehicle_Keyid"
                  label="Vehicle"
                  value={vehicleInspection.vehicle_Keyid}
                  options={vehicles.map(vehicle => ({
                    key: vehicle.vehicleID,
                    value: vehicle.vehicleID,
                    text: vehicle.wexpin,
                  }))}
                  search={true}
                  component={NumberSelectInput}
                />

                <Field
                  name='currentMileage'
                  label='Current Mileage'
                  value={vehicleInspection.currentMileage}
                  component={NumberInput}
                />

                <Field
                  name='nextOilChangeMileage'
                  label='Next Oil Change Mileage'
                  value={vehicleInspection.nextOilChangeMileage}
                  component={NumberInput}
                />
              </Form.Group>

              <Form.Group widths="equal">
                
                <Field
                  name="inspectionDueDate"
                  date={true}
                  label="State Inspection Due Date"
                  value={vehicleInspection.inspectionDueDate}
                  component={DateInput}
                />
                
                <FieldDate
                  name="registration"
                  label="Registration Expiration Date"
                  value={vehicleInspection.registration}
                />

                <FieldDate
                  name="insurance"
                  label="Insurance Expiration Date"
                  value={vehicleInspection.insurance}
                />
              </Form.Group>

              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Item</Table.HeaderCell>
                    <Table.HeaderCell>Condition</Table.HeaderCell>
                    <Table.HeaderCell>Defect/Explanation</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Proper operation of:</Table.HeaderCell>
                    <Table.HeaderCell>
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Brakes</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="brakesStatus" values={values} />
                      <UnacceptableRadio name="brakesStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="brakesComment"
                        value={vehicleInspection.brakesComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.brakesStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                  
                  <Table.Row>
                    <Table.Cell>Emergency Brakes</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name='emergencyBrakeStatus' values={values} />
                      <UnacceptableRadio name='emergencyBrakeStatus' />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="emergencyBrakeComment"
                        value={vehicleInspection.emergencyBrakeComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.emergencyBrakeStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Horn</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name='hornStatus' values={values} />
                      <UnacceptableRadio name='hornStatus' />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="hornComment"
                        value={vehicleInspection.hornComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.hornStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>

                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Proper operation and acceptable condition of:</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Headlights</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name='headlightsStatus' values={values} />
                      <UnacceptableRadio name='headlightsStatus' />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="headlightsComment"
                        value={vehicleInspection.headlightsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.headlightsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Taillights</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="tailLightsStatus" values={values} />
                      <UnacceptableRadio name="tailLightsStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="tailLightsComment"
                        value={vehicleInspection.tailLightsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.tailLightsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Brake Lights</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="brakeLightsStatus" values={values} />
                      <UnacceptableRadio name="brakeLightsStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="brakeLightsComment"
                        value={vehicleInspection.brakeLightsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.brakeLightsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Turn Signals</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="turnSignalsStatus" values={values} />
                      <UnacceptableRadio name="turnSignalsStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="turnSignalsComment"
                        value={vehicleInspection.turnSignalsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.turnSignalsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Back-up Lights</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="backupLightsStatus" values={values} />
                      <UnacceptableRadio name="backupLightsStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="backupLightsComment"
                        value={vehicleInspection.backupLightsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.backupLightsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Windshield Wipers</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="windshieldWipersStatus" values={values}/>
                      <UnacceptableRadio name="windshieldWipersStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="windshieldWipersComment"
                        value={vehicleInspection.windshieldWipersComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.windshieldWipersStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>

                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Acceptable condition of:</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Vehicle Interior (clean & intact)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="vehicleInteriorStatus" values={values} />
                      <UnacceptableRadio name="vehicleInteriorStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="vehicleInteriorComment"
                        value={vehicleInspection.vehicleInteriorComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.vehicleInteriorStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Vehicle Exterior (clean & intact)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="vehicleExteriorStatus" values={values} />
                      <UnacceptableRadio name="vehicleExteriorStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="vehicleExteriorComment"
                        value={vehicleInspection.vehicleExteriorComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.vehicleExteriorStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Equipment Storage Area (clean & organized)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="equipmentStorageAreaStatus" values={values} />
                      <UnacceptableRadio name="equipmentStorageAreaStatus" />
                      <NotApplicableRadio name="equipmentStorageAreaStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="equipmentStorageAreaComment"
                        value={vehicleInspection.equipmentStorageAreaComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.equipmentStorageAreaStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Mirrors (clean &amp; intact)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="mirrorsStatus" values={values} />
                      <UnacceptableRadio name="mirrorsStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="mirrorsComment"
                        value={vehicleInspection.mirrorsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.mirrorsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Windshield (clean &amp; intact)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="windshieldStatus" values={values} />
                      <UnacceptableRadio name="windshieldStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="windshieldComment"
                        value={vehicleInspection.windshieldComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.windshieldStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Rear Window (clean &amp; intact)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="rearWindowStatus" values={values} />
                      <UnacceptableRadio name="rearWindowStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="rearWindowComment"
                        value={vehicleInspection.rearWindowComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.rearWindowStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Windows (clean &amp; intact)</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="windowsStatus" values={values} />
                      <UnacceptableRadio name="windowsStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="windowsComment"
                        value={vehicleInspection.windowsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.windowsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Tires – Inflation</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="tireConditionStatus" values={values} />
                      <UnacceptableRadio name="tireConditionStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="tireConditionComment"
                        value={vehicleInspection.tireConditionComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.tireConditionStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Tires – Condition &amp; Tread</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="tireTreadStatus" values={values} />
                      <UnacceptableRadio name="tireTreadStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="tireTreadComment"
                        value={vehicleInspection.tireTreadComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.tireTreadStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Backup Camera</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="backupCameraStatus" values={values} />
                      <UnacceptableRadio  name="backupCameraStatus" />
                      <NotApplicableRadio name="backupCameraStatus" values={values}/>
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="backupCameraComment"
                        value={vehicleInspection.backupCameraComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.backupCameraStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Backup Alarm</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="backupAlarmStatus" values={values} />
                      <UnacceptableRadio  name="backupAlarmStatus" />
                      <NotApplicableRadio name="backupAlarmStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="backupAlarmComment"
                        value={vehicleInspection.backupAlarmComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.backupAlarmStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Strobe Light - Front</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="strobeFrontStatus" values={values} />
                      <UnacceptableRadio  name="strobeFrontStatus" />
                      <NotApplicableRadio name="strobeFrontStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="strobeFrontComment"
                        value={vehicleInspection.strobeFrontComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.strobeFrontStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Strobe Light - Back</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="strobeBackStatus" values={values} />
                      <UnacceptableRadio  name="strobeBackStatus" />
                      <NotApplicableRadio name="strobeBackStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="strobeBackComment"
                        value={vehicleInspection.strobeBackComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.strobeBackStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>

                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Proper fluid levels:</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Brake</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="brakeFluidStatus" values={values} />
                      <UnacceptableRadio name="brakeFluidStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="brakeFluidComment"
                        value={vehicleInspection.brakeFluidComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.brakeFluidStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Coolant/Antifreeze</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="coolantAntifreezeStatus" values={values} />
                      <UnacceptableRadio name="coolantAntifreezeStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="coolantAntifreezeComment"
                        value={vehicleInspection.coolantAntifreezeComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.coolantAntifreezeStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Oil</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="oilStatus" values={values} />
                      <UnacceptableRadio name="oilStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="oilComment"
                        value={vehicleInspection.oilComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.oilStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Steering</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="steeringFluidStatus" values={values} />
                      <UnacceptableRadio  name="steeringFluidStatus" />
                      <NotApplicableRadio name="steeringFluidStatus" values={values}/>
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="steeringFluidComment"
                        value={vehicleInspection.steeringFluidComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.steeringFluidStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Transmission</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="transmissionFluidStatus" values={values}/>
                      <UnacceptableRadio name="transmissionFluidStatus" />
                      <NotApplicableRadio name="transmissionFluidStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="transmissionFluidComment"
                        value={vehicleInspection.transmissionFluidComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.transmissionFluidStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Windshield</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="windshieldFluidStatus" values={values} />
                      <UnacceptableRadio name="windshieldFluidStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="windshieldFluidComment"
                        value={vehicleInspection.windshieldFluidComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.windshieldFluidStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>

                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Safety equipment present and in good condition:</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>First-Aid Kit</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="firstAidKitStatus" values={values} />
                      <UnacceptableRadio name="firstAidKitStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="firstAidKitComment"
                        value={vehicleInspection.firstAidKitComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.firstAidKitStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Reflective warning triangle</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="flaresStatus" values={values} />
                      <UnacceptableRadio  name="flaresStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="flaresComment"
                        value={vehicleInspection.flaresComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.flaresStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>SDSs</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="sdsStatus" values={values} />
                      <UnacceptableRadio  name="sdsStatus" />
                      <NotApplicableRadio name="sdsStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="sdsComment"
                        value={vehicleInspection.sdsComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.sdsStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Flashlight</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="flashlightStatus" values={values} />
                      <UnacceptableRadio  name="flashlightStatus" />
                      <NotApplicableRadio name="flashlightStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="flashlightComment"
                        value={vehicleInspection.flashlightComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.flashlightStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Fire Extinguisher</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio    name="fireExtinguisherStatus" values={values} />
                      <UnacceptableRadio  name="fireExtinguisherStatus" />
                      <NotApplicableRadio name="fireExtinguisherStatus" values={values} />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="fireExtinguisherComment"
                        value={vehicleInspection.fireExtinguisherComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.fireExtinguisherStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Spill Kit</Table.Cell>
                    <Table.Cell>
                      <AcceptableRadio   name="spillKitStatus" values={values} />
                      <UnacceptableRadio name="spillKitStatus" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field
                        name="spillKitComment"
                        value={vehicleInspection.spillKitComment}
                        component={TextInput}
                        placeholder="Explanation"
                        disabled={values.spillKitStatus !== UNACCEPTABLE}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Field label="Other Deficiencies/Issues" name="otherIssues" component={CheckboxInput} type='checkbox' />
							<Field fluid name="otherIssuesExplanation" placeholder="Description of Deficiency or Issue..." component={TextInput} type={values.otherIssues ? "text" : "hidden"} />


              <Form.Group widths="equal">
                <FieldFileDropZone name="fileArray" accept={[
                  "image/*",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  "application/msword",
                  "application/pdf"
                ].join(',')} maxFiles={6} />
              </Form.Group>

              <Button
                loading={submitting}
                disabled={loading || pristine}
                floated="right"
                positive
                type="button"
                content="Submit"
                onClick={() => handleFinalFormSubmit(values)}
              />

              <Button
                disabled={loading}
                color='yellow'
                floated="right"
                type="button"
                content="Cancel"
                onClick={()=>openModal(<ComfirmCancelForm />)}
                />
            </Form>
          )}
        />
      </Segment>
    </>
  );
};

export default observer(VehicleInspectionForm);
