import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Dropdown, DropdownProps, Segment, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { observer } from "mobx-react-lite";

import { RootStoreContext } from "../../../app/stores/rootStore";
import { months, years } from "../../../app/common/options/datesOptions";
import TextInput from "../../../app/common/form/TextInput";
import { OnChange } from "react-final-form-listeners";
import { FieldSafetyAuditSearchFormValues } from "../../../app/models/fieldSafetyAudit";
import RenderIf from "../../../app/common/form/RenderIf";
import CalendarView from "react-widgets/cjs/CalendarView";

const FieldSafetyAuditSearch = () => {
	const rootStore = useContext(RootStoreContext);
	const {
        setPredicate, 
        clearPredicate,
        loadFieldSafetyAudits,
        clearFieldSafetyAuditRegistry,
		getPredicate,
		showCharts,
		toggleShowCharts,
    } = rootStore.fieldSafetyAuditStore;
	const { isAdmin, isFSAAdmin } = rootStore.generalStore;
	const { disciplines, loadDisciplines} = rootStore.safetyChecklistStore;
	const { accountInfoUserEmail } = rootStore.userStore;
    const [searchParams, setSearchParams] = useState(new FieldSafetyAuditSearchFormValues());

	const handleDiscChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setSearchParams({...searchParams, disciplines: data.value as string[] });
		setPredicate("disciplines", JSON.stringify(data.value));
	};
	const handleMonthsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => {
		setSearchParams({...searchParams, months: data.value as number[] }); 
		setPredicate("months", JSON.stringify(data.value));
	};
	const handleYearsChange = (event: React.SyntheticEvent<HTMLElement>, data:DropdownProps) => { 
		setSearchParams({...searchParams, years: data.value as number[] }); 
		setPredicate("years", JSON.stringify(data.value));
	};
	
	/*************************************
	 *
	 *************************************/
	const handleFinalFormSubmit = (values: FieldSafetyAuditSearchFormValues) => {
		clearFieldSafetyAuditRegistry();
		loadFieldSafetyAudits();
	};
	
	/*************************************
	 *
	 *************************************/
	const resetSearch = (form: any) => {
		setSearchParams(new FieldSafetyAuditSearchFormValues());
		clearPredicate();
		form.reset();
		loadFieldSafetyAudits();
	};

	const loadStorePredicate = () => {
		let storePred = getPredicate();
		
		let monthsStr: string | undefined = storePred.get("months")?.toString().replace('[', '').replace(']', '');
		let monthsNumAry: number[] | undefined = monthsStr?.split(',').map(y => parseInt(y, 10));
		
		let yearsStr: string | undefined = storePred.get("years")?.toString().replace('[', '').replace(']', '');
		let yearsNumAry: number[] | undefined = yearsStr?.split(',').map(y => parseInt(y, 10));
		
		let discsStr: string | undefined = storePred.get("disciplines")?.toString().replace('[', '').replace(']', '').replaceAll('"', '');
		let discsAry: string[] | undefined = discsStr?.split(',');

		let projectNumber: string | undefined = storePred.get("projectNumber")?.toString();

		let searchVals = new FieldSafetyAuditSearchFormValues();
		if (monthsNumAry !== undefined) searchVals.months = monthsNumAry;
		if (yearsNumAry !== undefined) searchVals.years = yearsNumAry;
		if (discsAry !== undefined) searchVals.disciplines = discsAry;
		if (projectNumber !== undefined) searchVals.projectNumber = projectNumber;
		setSearchParams(searchVals);
	}
	
	useEffect(() => {
		loadDisciplines();
		loadStorePredicate();
	}, []);

	const canView = (email: string | undefined) => {
		if(email === null){
		  return false;
		}else if(isFSAAdmin(email) || isAdmin(email)){
		  return true;
		}else{
		  return false;
		}
	  }
	
	return (
		<Segment clearing>
			<FinalForm
				onSubmit={handleFinalFormSubmit}
				initialValues={searchParams}
				render={({ handleSubmit, form }) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group widths="equal">
							<Field
								name='projectNumber'
								placeholder='Search by Project Number'
								component={TextInput}
								value={searchParams.projectNumber}
								/>
							<OnChange name="projectNumber">
								{(value, previous) => {
									// setSearchParams({...searchParams, desc: value});
									setPredicate("projectNumber", value);
								}}
							</OnChange>
							<Dropdown
								name="disciplines"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Discipline"
								onChange={handleDiscChange}
								value={searchParams.disciplines}
								options={disciplines.map((discipline) => ({
									key: discipline.id,
									value: discipline.id,
									text: discipline.name,
								}))}
								/>
							<Dropdown
								name="months"
								selection
								clearable
								multiple
								search
								fluid
								text="Filter by Month"
								onChange={handleMonthsChange}
								options={months}
								value={searchParams.months}
								/>
							<Dropdown
								name="years"
								selection
								clearable
								multiple
								search
								fluid
								className="link item"
								text="Filter by Year"
								onChange={handleYearsChange}
								options={years}
								value={searchParams.years}
							/>
						</Form.Group>
						
						<Grid>
							<Grid.Column textAlign="center">
								<Button positive type="submit" content="Search" />
								<Button color="red" type="button" content="Reset" onClick={() => resetSearch(form)} />
								<RenderIf condition={canView(accountInfoUserEmail)}>
									<Button color="orange" type="button" content="Show Charts" onClick={() => toggleShowCharts() }/>
								</RenderIf>
							</Grid.Column>
						</Grid>
					</Form>
				)}
			/>
		</Segment>
	);
};

export default observer(FieldSafetyAuditSearch);
