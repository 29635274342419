import React, { useContext, Fragment } from "react";

import arrayMutators from "final-form-arrays";
import { Form, Segment, Header, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { combineValidators } from "revalidate";

import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { trafficControlLabels } from "../../../app/models/trafficControl";
import TextInput from "../../../app/common/form/TextInput";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import SelectInput from "../../../app/common/form/SelectInput";

const validate = combineValidators({
});



const SafetyChecklistCommentForm = (params: any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addComment } = rootStore.safetyChecklistStore;

	const handleFinalFormSubmit = (values: any) => {
		addComment({
			checklist_id: params.id,
			comment: values,
		});
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					validate={validate}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					render={({
						handleSubmit,
						invalid,
						pristine,
						values,
					}) => (
						<Form onSubmit={handleSubmit} className="sopForm">
							<Header as="h3">Safety Checklist Comment</Header>
							<Form.Group widths="equal">
                                <Field name="content" placeholder="Comment..." component={TextInput} />
							</Form.Group>
							<Button loading={submitting} disabled={invalid || pristine} floated="right" positive type="submit" content="Submit" />
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(SafetyChecklistCommentForm);
