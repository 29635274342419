import { observable, action, runInAction, computed } from "mobx";
import { toast } from "react-toastify";
import { history } from "../..";
import { RootStore } from "./rootStore";
import agent from "../api/agent";
import { IVehicleAccidentReport } from "../models/vehicleAccidentReport";
import { localizeDateTime } from "../common/util/util";

const PAGE_SIZE = 8;

export default class VehicleAccidentReportStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable vehicleAccidentReportRegistry = new Map<string, IVehicleAccidentReport>();
  @observable report: IVehicleAccidentReport | null = null;
  @observable submitting = false;
  @observable loadingInitial = false;

  @observable vehicleAccidentReportCount = 0;
  @observable page = 0;
  @observable predicate = new Map<string, string | number | boolean>();

  @action redefineDatesWithTimeZones = (vehicleAccidentReport: any) => {
    if (vehicleAccidentReport.dateTime) vehicleAccidentReport.dateTime = localizeDateTime(new Date(vehicleAccidentReport.dateTime));
    if (vehicleAccidentReport.date) vehicleAccidentReport.date = localizeDateTime(new Date(vehicleAccidentReport.dateTime));
    if (vehicleAccidentReport.dateTime) vehicleAccidentReport.time = localizeDateTime(new Date(vehicleAccidentReport.dateTime));
    if (vehicleAccidentReport.operatorDob1) vehicleAccidentReport.operatorDob1 = localizeDateTime(new Date(vehicleAccidentReport.operatorDob1));
    if (vehicleAccidentReport.operatorDob2) vehicleAccidentReport.operatorDob2 = localizeDateTime(new Date(vehicleAccidentReport.operatorDob2));
    vehicleAccidentReport.createdDate = localizeDateTime(new Date(vehicleAccidentReport.createdDate));
  }

  @computed get totalPages() {
    return Math.ceil(this.vehicleAccidentReportCount / PAGE_SIZE);
  }

  @action setCount = (count: number) => {
    this.vehicleAccidentReportCount = count;
  };

  @action setPage = (page: number) => {
    this.page = page;
  }
  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  /*************************************
   *
   *************************************/

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));

    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });

    return params;
  }

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @action clearVehicleAccidentReportRegistry = () => {
    this.vehicleAccidentReportRegistry.clear();
    this.page = 0;
  };

  @computed get vehicleAccidentReportsByDefault() {
    return Array.from(this.vehicleAccidentReportRegistry.values()).sort(
      (a, b) => b.createdDate.getTime() - a.createdDate.getTime()
    ); // sort in descending order
  }

  /*************************************
  *
  *************************************/
  get reportsLength() {
    return this.vehicleAccidentReportRegistry.size;
  }

  /*************************************
  *
  *************************************/
  @computed get reportsByDate() {
    return this.groupReportsByDate(
      Array.from(this.vehicleAccidentReportRegistry.values())
    );
  }


  /*************************************
  *
  *************************************/
  groupReportsByDate(reports: IVehicleAccidentReport[]) {
    const sortedReports = reports.sort(
      (a, b) => b.dateTime.getTime() - a.dateTime.getTime()
    );

    return Object.entries(
      sortedReports.reduce((reports, report) => {
        const date = report.dateTime.toISOString().split('T')[0];
        reports[date] = reports[date] ? [...reports[date], report] : [report];
        return reports;
      }, {} as { [key: string]: IVehicleAccidentReport[] })
    );
  }

  /*************************************
  *
  *************************************/
  @action loadReports = async () => {
    this.loadingInitial = true;

    try {
      const response = await agent.VehicleAccidentReport.list(this.axiosParams);

      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);

      runInAction("loading vehicleAccidentReports", () => {
        response.data.vehicleAccidentReports.forEach((report: IVehicleAccidentReport) => {
          this.redefineDatesWithTimeZones(report);
          
          this.vehicleAccidentReportRegistry.set(report.id, report);
        });
        this.loadingInitial = false;
      });

    } catch (error) {
      runInAction("load activities error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action clearReports = () => {
    this.vehicleAccidentReportRegistry.clear();
  }

  /*************************************
  *
  *************************************/
  getReport = (id: string) => {
    return this.vehicleAccidentReportRegistry.get(id);
  };

  /*************************************
  *
  *************************************/
  @action loadReport = async (id: string) => {
    try {
      let report = await agent.VehicleAccidentReport.details(id);
      runInAction('load existing vehicle accident report', () => {
        this.redefineDatesWithTimeZones(report);
       
        this.report = report;
        this.vehicleAccidentReportRegistry.set(report.id, report);
        this.loadingInitial = false;
      });
      return report;
    } catch (error) {
      runInAction('get vehicle accident report error', () => {
        this.loadingInitial = false;
      });
    }

  };

  /*************************************
   *
   *************************************/
  @action createVehicleAccidentReport = async (vehicleAccidentReport: FormData) => {
    this.submitting = true;
    try {
      const newreport = await agent.VehicleAccidentReport.create(vehicleAccidentReport);

      runInAction("create vehicleAccidentReport", () => {
        this.vehicleAccidentReportRegistry.set(newreport.id, newreport);
        this.submitting = false;
      });

      history.push(`/vehicle-accident-report/${newreport.id}`);
      toast.success("New Vehicle Accident Report created.");
    } catch (error: any) {
      runInAction("create vehicleAccidentReport form error", () => {
        this.submitting = false;
      });
      
      toast.error("Problem submitting data");
      console.log(error.response);
    }
  }
  
  /*************************************
   *
   *************************************/
  
  @action editReport = async (id:string, report: FormData) => {
    this.submitting = true;
    
    try {
      const modified_report = await agent.VehicleAccidentReport.update(id, report);
      
      runInAction("editing report", () => {
        this.vehicleAccidentReportRegistry.set(modified_report.id, modified_report);
        this.redefineDatesWithTimeZones(modified_report);
        this.report = modified_report;
        this.submitting = false;
      });
      
      history.push(`/vehicle-accident-report/${modified_report.id}`);
      toast.success("Vehicle Accident Report edited");
    } catch (error: any) {
      runInAction("edit reports error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error.response);
    }
  };

  @action deleteVehicleAccidentReport = async (id: string) => {
    this.submitting = true;
    try {
      var sc = await agent.VehicleAccidentReport.delete(id);
      runInAction("deleting var", () => {
        this.submitting = false;
      });
      history.push('/');
      toast.success('Vehicle Accident Report deleted');
    } catch (error) {
      toast.error("Problem submitting data");
      console.log(error);
    }
  };
};