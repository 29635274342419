import React, { useContext, useEffect, useState } from "react";
import { Table, Icon, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../../app/stores/rootStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PageTitle from "../../../app/common/form/PageTitle";

interface DetailParams {
	id: string;
}

const TrafficControlDetails: React.FC<RouteComponentProps<DetailParams>> = ({match, history}) => {
	const rootStore = useContext(RootStoreContext);
	const { 
		trafficControlAssessment,
		loadTrafficControlAssessment,
		loadingInitial
	} = rootStore.safetyChecklistStore;
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		setLoading(true);
		loadTrafficControlAssessment(match.params.id).then(() => setLoading(false));
	}, [loadTrafficControlAssessment, match.params.id, history]);

	const mapAnswer = (num: any) => {
		switch (num) {
			case '0':
				return "Yes";
			case '1':
				return "No";
			case '2':
				return "N/A";
			case '3':
				return "N/O";
		}
	};

	if (loading || loadingInitial){
		return <LoadingComponent content="Loading Traffic Control Assessment..." />
	}

	if (!trafficControlAssessment){
		return <h2>Traffic Control Assessment Not Found</h2>
	}

	return (
		<>
			<PageTitle title="Traffic Control Assessment Details" />
			<Segment clearing>
				<Table className="tableAltColors">
				  <Table.Row>
				  	<Table.Cell>Personnel working on/adjacent to active roadways/in control zones are wearing safety vests</Table.Cell>
				  	<Table.Cell>{trafficControlAssessment.b_EmplTrafficExposure ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}</Table.Cell>
				  </Table.Row>
				  <Table.Row>
				  	<Table.Cell>Traffic Control Plan (TCP) is consistent with roadway, traffic, and working conditions</Table.Cell>
				  	<Table.Cell>{trafficControlAssessment.b_EmplCompliance ? <Icon name='check' color='green' /> : <Icon name='x' color='red' />}</Table.Cell>
				  </Table.Row>
				  <Table.Row>
				  	<Table.Cell>Subcontractor</Table.Cell>
				  	<Table.Cell>{trafficControlAssessment.subcontractor}</Table.Cell>
				  </Table.Row>
				  <Table.Row>
				  	<Table.Cell>Personnel working on/adjacent to active roadways/in control zones are wearing safety vests</Table.Cell>
				  	<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_1)}</Table.Cell>
				  </Table.Row>
				  <Table.Row>
				  	<Table.Cell>Traffic Control Plan (TCP) is consistent with roadway, traffic, and working conditions</Table.Cell>
				  	<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_2)}</Table.Cell>
				  </Table.Row>
				{trafficControlAssessment.Line_1_2 !== '2'&& <>
					<Table.Row>
						<Table.Cell>TCP has been approved by regulatory or contractual authority prior to work</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_3)}</Table.Cell>
					</Table.Row>

					<Table.Row>
						<Table.Cell>TCP considers all factors that may influence traffic-related hazards and controls</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_4)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Rigid barriers protect work areas</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_5)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Lookouts are used when applicable</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_6)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>TCP considers all factors that may influence traffic-related hazards and controls</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_7)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Rigid barriers protect work areas</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_8)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Lookouts are used when applicable</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_9)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell> Vehicles are parked 40 feet away from work zone or are equipped with hazard beacon/strobe</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_10)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>TMCC or TMA vehicle is used where appropriate</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_11)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>All CED traffic control devices conform to MUTCD standards</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_12)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Traffic control devices are inspected continuously</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_13)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Flagging is only used when other means of traffic control are inadequate</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_14)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Additional traffic control zone controls have been implemented</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_1_15)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Lane closings are performed when required by this SOP
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_1)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Traffic control configurations are based on an engineering study of
							the location
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_2)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							If no study, traffic control is performed with approval of the
							authority having jurisdiction
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_3)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							TCP has been prepared and understood by all responsible parties
							prior to work
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_4)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Special preparation/coordination with external parties has been
							conducted where applicable
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_5)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							All contractor traffic control devices conform to MUTCD standards
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_6)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Traffic movement and flow are inhibited or disrupted as little as
							possible
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_7)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Supplemental equipment and activities do not interfere with traffic
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_8)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Drivers and pedestrians are considered when entering and traversing
							traffic control zone
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_2_9)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Traffic control zones are divided into the necessary five areas
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_1)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Advanced warning area is designed based on conditions of speed,
							roads, and driver needs
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_2)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Advanced warning signage is spaced according to roadway type and
							conditions
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_3)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Transition areas are used to channelize traffic around the work area
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_4)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Buffer areas are used to provide a margin of safety for traffic and
							workers
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_5)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							The buffer area is free of equipment, workers, materials, and worker
							vehicles
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_6)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							The length of the buffer area is two times the posted speed limit in
							fee
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_7)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							All work is contained in the work area and is closed to all traffic
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_8)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							A termination area is used to return traffic to normal lanes
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_9)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							A downstream taper is installed in the termination area
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_3_10)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							All vehicles involved with device installation/removal have hazard
							beacons/strobes
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_1)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Devices are installed according to the order established by this SOP
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_2)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Devices are removed in the opposite order of installation
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_3)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Tapers are used to move traffic out of its normal path
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_4)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Tapers are created using channeling devices</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_5)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							The length of taper is determined by speed and width of lane to be
							closed (see formula)
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_6)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Local police or highway patrol assists during taper installation and
							removal
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_7)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							TMCC/TMA vehicles are used during installation and removal of
							traffic control devices
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_8)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Cone trucks are equipped with platforms and railings
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_9)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Cones are the appropriate height for the specific roadway and are
							reflectorized
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_10)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Temporary sign supports are secured to prevent movement
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_11)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Arrow panels are used on lane closures where required
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_12)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Concrete barriers are used where required</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_13)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Barrels, crash cushions, or energy absorbing terminals are used to
							protect traffic as required
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_14)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Changeable message signs (CMS) are used as required
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_15)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>CMS are not used to replace required signage</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_16)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							No more than two message panels are used in any message cycle on CMS
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_4_17)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Flagging is used only when other traffic control methods are
							inadequate
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_1)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Only approved personnel with current certification are used as
							flaggers
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_2)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Flaggers are located off the traveled portion of the roadway
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_3)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							A communication system is established when more than one flagger is
							used
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_4)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Hand signaling by flaggers is by means of red flags, sign paddles,
							or red lights
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_5)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Flaggers are alert, positioned to warn work crews, and easily
							identified from crew
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_6)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							An escape plan is established by crew and flaggers prior to traffic
							control set up
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_7)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Signs indicating a flagger is present are used and removed as
							required
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_5_8)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Traffic control zones are monitored to determine their effectiveness
							under varying conditions
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_6_1)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Traffic control devices are inspected at the beginning and
							continuously during work shift
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_6_2)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Traffic control devices are restored to their proper position
							immediately and continuously
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_6_3)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Damaged, old, or ineffective devices are removed and replace
							immediately
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_6_4)}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>
							Devices using reflected light for illumination are cleaned and
							monitored continuously
						</Table.Cell>
						<Table.Cell>{mapAnswer(trafficControlAssessment.Line_6_5)}</Table.Cell>
					</Table.Row>
				</>}
				<Table.Row>
					<Table.Cell>Corrective Actions</Table.Cell>
					<Table.Cell>{trafficControlAssessment.correctiveActions}</Table.Cell>
				</Table.Row>
				</Table>
			</Segment>
		</>

	);
};

export default observer(TrafficControlDetails);
