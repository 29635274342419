import React, { useContext, Fragment } from "react";

import arrayMutators from "final-form-arrays";
import { Form, Segment, Header, Button, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { combineValidators } from "revalidate";

import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { trafficControlLabels } from "../../../app/models/trafficControl";
import TextInput from "../../../app/common/form/TextInput";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import SelectInput from "../../../app/common/form/SelectInput";

const validate = combineValidators({
	// TODO these don't work
	// q1_1: isRequired('This field')
});

const evalEmpText = "Evaluate a CED employee's exposure to traffic hazards.";
const evalSubNameText = "Evaluate a CED subcontractor’s compliance with traffic control requirements.";
const options = [
	{ key: 0, value: "0", text: "Yes" },
	{ key: 1, value: "1", text: "No" },
	{ key: 2, value: "2", text: "N/A" },
	{ key: 3, value: "3", text: "N/O" },
];

const TrafficControlForm = (params: any) => {
	const rootStore = useContext(RootStoreContext);
	const { submitting, addTrafficControl } = rootStore.safetyChecklistStore;

	const handleFinalFormSubmit = (values: any) => {
		const { ...traffic } = values;

		addTrafficControl({
			checklist_id: params.id,
			trafficControl: values,
		});
	};

	return (
		<>
			<Segment clearing>
				<FinalForm
					validate={validate}
					// initialValues={traffic}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					render={({
						handleSubmit,
						invalid,
						pristine,
						values,
					}) => (
						<Form onSubmit={handleSubmit} className="sopForm">
							<Header as="h3">Traffic Control Form</Header>

							<Grid>
							<Grid.Row className='gridRow'>
								<Field label={evalEmpText} name="b_EmplTrafficExposure" component={CheckboxInput} />
							</Grid.Row>
							<Grid.Row className='gridRow'>
								<Field label={evalSubNameText} name="b_EmplCompliance" component={CheckboxInput} />
							</Grid.Row>
							<Grid.Row className='gridRow'>
								<div className='block'>
									<Field name="subcontractor" placeholder="Subcontractor Name" component={TextInput} />
								</div>
							</Grid.Row>
						
							<Grid.Row className='gridRow'>
								<div className="block">
									1_1: Personnel working on/adjacent to active roadways/in control zones are wearing safety vests
								</div>
								<Field name='Line_1_1' placeholder="----" value={"Line_1_1"} options={options} component={SelectInput} />
							</Grid.Row>
							
							<Grid.Row className='gridRow'>
								<div className="block">
									1_2: Traffic Control Plan (TCP) is consistent with roadway, traffic, and working conditions
								</div>
								<Field name='Line_1_2' placeholder="----" value={"Line_1_2"} options={options} component={SelectInput} />
							</Grid.Row>
							
							{/* hide remaining form if q1_2 is 'N/A' */}
							{values.Line_1_2 !== '2'&& <>
								{trafficControlLabels.map((question: any) => (
									<Fragment key={question.key}>
										<Grid.Row className="gridRow">
											<div className="block">
												{question.key}: {question.label}.
											</div>
											<Field name={"Line_" + question.key} placeholder="----" options={options} component={SelectInput} />
										</Grid.Row>
									</Fragment>
								))}
							</>}

							</Grid>
							<br />

							<div>Corrective Actions:</div>
							<Field name="correctiveActions" placeholder="Description..." component={TextInput} />
							<Button loading={submitting} disabled={invalid || pristine} floated="right" positive type="submit" content="Submit" />
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(TrafficControlForm);
