import React, { Fragment, useContext } from "react";
import { Item, Grid, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

import NotificationListItem from "./NotificationListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";

const NotificationList: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { notificationsByDefault } = rootStore.notificationStore;

	return (
		<Grid columns={2} stackable>
			<Fragment>
				{notificationsByDefault.map((notification) => (
					<Grid.Column key={notification.id}>
						<Segment clearing>
							<Item.Group>
								<NotificationListItem key={notification.id} notification={notification} />
							</Item.Group>
						</Segment>
					</Grid.Column>
				))}
			</Fragment>
		</Grid>
	);
};

export default observer(NotificationList);
